import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import { PatternFormat } from 'react-number-format'
import CardError from '../common_error/card-error'

import { Trans } from 'react-i18next'

import { contactConstants } from '../../constants/contact-constants'
import { myAccountConstants } from '../../constants/myaccount-constants'

import utils from '../../srp_modules/utils'
import validate from '../../srp_modules/validate'

const BREAKER_CHECK_TEXT = "I've checked or reset my breaker and power is still out"
const BREAKER_CHECK_TEXT_ERROR = "I've checked or reset my breaker and power is still out. (required)"

const ReportOutagePage = ({ selectedBillAccount, isCommercial, serviceAddress, requestCallback, phoneForCallback, getPhoneForCallback,
    togglePowerRestoredCallbackOnClick, submitReportOutageFormOnClick, isReportingOutage, showAffectedAddressModal,
    closeAffectedAddressModalOnClick, openAffectedAddressModalOnClick, outageSubscription, availableOutageNotification, outageSubscriptionEmail,
    outageSubscriptionEmailError, validateOutageSubscriptionEmail, outageSubscriptionPhone, outageSubscriptionPhoneError, validateOutageSubscriptionPhone,
    getOutageSubscriptionEmail, getOutageSubscriptionPhone, newSubscriptionEmailDialog, newSubscriptionPhoneDialog, contacts, addAndSubscribeContact,
    t, i18n,
    validateCallbackPhone, callbackError, callbackErrorMessage, breakerCheck, generalError, onBreakerCheckClicked, setGeneralError, errorCallingReportOutageAPI }) => {

    const isSpanish = (i18n.language === "es")

    const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
    const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

    let getReportOutageDisabled = (isReportingOutage, isCommercial, breakerCheck) => {
        if (isReportingOutage) {
            return true
        }

        if (!isCommercial && !breakerCheck) {
            return true
        }

        return false
    }

    let checkExistingContacts = (contactToAdd) => {
        let contactExists = false
        if (contactToAdd !== "") {
            for (let i = 0; i < contacts.length; i++) {
                if (contactToAdd === contacts[i].contactAddress) {
                    contactExists = true
                }

            }
        }

        return contactExists
    }

    let validateSubscriptionContacts = (email, phone, callbackPhone, isCallback) => {
        let phoneError = false
        let emailError = false
        let callbackError = false
        let callbackErrorMessage = ""
        if (phone.length > 0 && phone.length < 10) {
            phoneError = true
        }

        if (email.length > 0 && !validate.emailAddress(email)) {
            emailError = true
        }

        if (isCallback) {
            if (callbackPhone.length === 0 || callbackPhone === undefined) {
                callbackError = true
                callbackErrorMessage = "Phone number is required"
            }
            else if (callbackPhone.length > 0 && callbackPhone.length < 10) {
                callbackError = true
                callbackErrorMessage = "You must enter a valid phone number"
            }
            else {
                callbackError = false
                callbackErrorMessage = ""
            }
        }

        validateOutageSubscriptionPhone(phoneError)
        validateOutageSubscriptionEmail(emailError)
        validateCallbackPhone(callbackError, callbackErrorMessage)

        return (phoneError || emailError || callbackError)
    }

    let reportOutage = (subscription, billAccount, emailForSubscription, phoneForSubscription, phoneForCallback, callback) => async event => {
        event.preventDefault()
        let formError = validateSubscriptionContacts(emailForSubscription, phoneForSubscription, phoneForCallback, callback)
        if (formError)
            return

        if (!breakerCheck && !isCommercial) {
            setGeneralError(true)
            window.scrollTo(0, 0)
            return
        }

        if (emailForSubscription === "" && phoneForSubscription === "") {
            submitReportOutageFormOnClick(billAccount, phoneForCallback, callback)
            return
        }

        let emailContactExists = checkExistingContacts(emailForSubscription)
        let phoneContactExists = checkExistingContacts(phoneForSubscription)
        let emailSubscriptionExists = 0
        let phoneForSubscriptionExists = 0

        let notificationToAdd = {}
        if (Object.keys(availableOutageNotification).length !== 0) {
            notificationToAdd = availableOutageNotification
        }
        else {
            notificationToAdd = subscription
        }

        if (Object.keys(subscription).length !== 0) {
            for (let i = 0; i < subscription.contacts.length; i++) {
                if (subscription.contacts[i].contactType === contactConstants.CONTACT_TYPE.EMAIL && subscription.contacts[i].contactAddress === emailForSubscription) {
                    emailSubscriptionExists++
                }

                if (subscription.contacts[i].contactType === contactConstants.CONTACT_TYPE.PHONE && subscription.contacts[i].contactAddress === phoneForSubscription) {
                    phoneForSubscriptionExists++
                }
            }
        }

        let contactsForNewSubscription = []
        let contactsToAdd = []
        if (emailSubscriptionExists === 0 || phoneForSubscriptionExists === 0) {
            if (emailForSubscription.length !== 0) {
                if (!emailContactExists) {
                    let emailContact = {
                        contactId: 0,
                        contactAddress: emailForSubscription,
                        contactType: contactConstants.CONTACT_TYPE.EMAIL,
                        displaySequenceNumber: 0,
                        contactLabel: "",
                        accountNumber: billAccount,
                        isLoginContact: false,
                        isTextAuthorized: false,
                        contactAddressDisplay: ""
                    }

                    contactsToAdd.push(emailContact)
                    contactsForNewSubscription.push(emailContact)
                }
                else {
                    if (emailSubscriptionExists === 0) {
                        let emailContact = {
                            contactAddress: emailForSubscription,
                            contactType: contactConstants.CONTACT_TYPE.EMAIL,
                            accountNumber: billAccount
                        }
                        contactsForNewSubscription.push(emailContact)
                    }
                }
            }

            if (phoneForSubscription.length !== 0) {
                if (!phoneContactExists) {
                    let phoneContact = {
                        contactId: 0,
                        contactAddress: phoneForSubscription,
                        contactType: contactConstants.CONTACT_TYPE.PHONE,
                        displaySequenceNumber: 0,
                        contactLabel: "",
                        accountNumber: billAccount,
                        isLoginContact: false,
                        isTextAuthorized: false,
                        contactAddressDisplay: ""
                    }

                    contactsToAdd.push(phoneContact)
                    contactsForNewSubscription.push(phoneContact)
                }
                else {
                    if (phoneForSubscriptionExists === 0) {
                        let phoneContact = {
                            contactAddress: phoneForSubscription,
                            contactType: contactConstants.CONTACT_TYPE.PHONE,
                            accountNumber: billAccount
                        }
                        contactsForNewSubscription.push(phoneContact)
                    }
                }
            }

            let newSubscription = {
                billAccount: billAccount,
                notificationToAdd: {
                    ...notificationToAdd,
                    accountNumber: billAccount,
                    contacts: contactsForNewSubscription
                }
            }

            addAndSubscribeContact(billAccount, contactsToAdd, newSubscription)
        }

        submitReportOutageFormOnClick(billAccount, phoneForCallback, callback)
    }

    return (
        <div className="row">
            <div className="col-lg-6 col-12 mt-4">
                <div className="srp-card-header">
                    {t("Outage details")}
                </div>

                <div className="srp-card-body">
                    <div className="srp-card-details">
                        {(generalError || errorCallingReportOutageAPI) &&
                            <div className="mb-4">
                                <CardError
                                    open={true}
                                    message={generalError
                                        ? t("Something went wrong. Please complete all required fields.")
                                        : t("unable_to_complete_request", {supportNumber: supportNumberFmt })
                                    }
                                />
                            </div>}
                        <label className="text-muted">{t("Report an outage at")}</label>
                        <div className="mb-4">{serviceAddress}</div>
                        <button className="ml-3 displayAsLink button-focus" onClick={() => openAffectedAddressModalOnClick()}>{t("Not the affected address?")}</button>
                        <Dialog
                            modal="true"
                            open={showAffectedAddressModal}
                            PaperProps={{ className: "srp-modal" }}
                        >
                            <div className="srp-modal-body">
                                <div className="srp-modal-close" onClick={() => closeAffectedAddressModalOnClick()} />
                                <div>
                                    <div className="srp-modal-header">
                                        <h4 className="srp-modal-title">{t("Affected address not shown")}</h4>
                                    </div>
                                    <div className="d-lg-block d-none">{t("report_outage_for_different_location_desktop", {supportNumber: supportNumberFmt})}</div>
                                    <div className="d-lg-none d-block">
                                        <Trans i18nKey="report_outage_for_different_location_mobile" t={t}>
                                        Only service addresses associated with the current account are displayed. Either change accounts or call SRP Customer Services at <a href={"tel:"+supportNumber}>{{supportNumber: supportNumberFmt}}</a> to report an outage for a different location.
                                        </Trans>
                                    </div>
                                    <div className="srp-modal-footer mb-0">
                                        <button className="btn srp-btn btn-blue" onClick={() => closeAffectedAddressModalOnClick()}>{t("OK")}</button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                    <Divider />
                    <div className="srp-card-details" style={isCommercial ? {} : {paddingBottom: "0"}}>
                        <div className="text-muted mb-2">{t("outage_updates_offer")}</div>
                        <form id="report-outage-form" onSubmit={reportOutage(outageSubscription, selectedBillAccount, outageSubscriptionEmail, outageSubscriptionPhone, phoneForCallback, requestCallback)}>
                            <label className="mt-3 text-muted">{t("Email address")}</label>
                            <TextField
                                id="emailAlert"
                                value={outageSubscriptionEmail}
                                onChange={event => getOutageSubscriptionEmail(event.target.value)}
                                fullWidth={true}
                                error={outageSubscriptionEmailError}
                                helperText={outageSubscriptionEmailError ? t("You must enter a valid email address.") : ""}
                            />
                            <div className="srp-subscription-warn mt-2" style={newSubscriptionEmailDialog}>
                                {t("will_subscribe_to_outage_alerts")}
                            </div>

                            <label className="text-muted mt-4">{t("Phone number (for text alerts)")}</label>
                            <PatternFormat
                                id="textAlert"
                                value={outageSubscriptionPhone}
                                valueIsNumericString={true}
                                format="(###) ###-####"
                                type="tel"
                                customInput={TextField}
                                fullWidth={true}
                                onValueChange={values => getOutageSubscriptionPhone(values.value)}
                                error={outageSubscriptionPhoneError}
                                helperText={
                                    <span>
                                        {outageSubscriptionPhoneError ? <span>{t("You must enter a valid phone number")}<br /></span> : <span />}
                                        <span className="text-muted" style={{ fontSize: '13px' }}>
                                            {t("Standard_text_message_rates_apply")}
                                        </span>
                                    </span>
                                }
                            />
                            <div className="srp-subscription-warn mt-2" style={newSubscriptionPhoneDialog}>
                                {t("phone_number_will_subscribe")}
                            </div>

                            <div className="row mt-4 mb-2 d-flex align-items-center">
                                <div className="col-lg-2 col-sm-2 col-4 d-flex align-middle">
                                    <label className="switch">
                                        <input type="checkbox" checked={requestCallback} onChange={() => togglePowerRestoredCallbackOnClick(!requestCallback)} />
                                        <span className={"yesNoToggle" + (isSpanish ? " es" : "")} />
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-10 col-8 d-flex align-middle">
                                    <div className="text-muted">{t("Call me back when power is restored (this outage only)")}</div>
                                </div>
                            </div>

                            {requestCallback
                                ? <div className="mb-4">
                                    <PatternFormat
                                        id="callbackTextField"
                                        value={phoneForCallback}
                                        valueIsNumericString={true}
                                        format="(###) ###-####"
                                        type="tel"
                                        customInput={TextField}
                                        fullWidth={true}
                                        error={callbackError}
                                        helperText={t(callbackErrorMessage)}
                                        onValueChange={values => getPhoneForCallback(values.value)}
                                    />
                                </div>
                                : null
                            }
                        </form>
                    </div>
                    {!isCommercial &&<Divider />}
                    <div className="srp-card-details" style={{paddingTop: "0"}}>
                        {!isCommercial &&
                            <div className="row mt-3 mb-4 d-flex align-items-center">
                                <div className="col-lg-2 col-sm-2 col-4 d-flex align-middle">
                                    <label className="switch">
                                        <input type="checkbox" checked={breakerCheck} onChange={onBreakerCheckClicked} />
                                        <span className={"yesNoToggle" + (isSpanish ? " es" : "")} />
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-10 col-8 d-flex align-middle">
                                    <div className={generalError ? "srp-red-color" : "text-muted"}>{generalError ? t(BREAKER_CHECK_TEXT_ERROR) : t(BREAKER_CHECK_TEXT)}</div>
                                </div>
                            </div>}
                        <div className="d-flex justify-content-end">
                            <button
                                form="report-outage-form"
                                onClick={() => reportOutage(outageSubscription, selectedBillAccount, outageSubscriptionEmail, outageSubscriptionPhone, phoneForCallback, requestCallback)}
                                type="submit"
                                disabled={getReportOutageDisabled(isReportingOutage, isCommercial, breakerCheck)}
                                className="btn srp-btn btn-green">
                                {isReportingOutage
                                    ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                    : t("Report outage")
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-12 mt-4">
                <div className="srp-card-header">
                    {t("Need to provide more details?")}
                </div>

                <div className="srp-card-body">
                    <div className="srp-card-details">
                        <div className="mt-2 d-lg-block d-none">
                            {t("call_to_report_an_outage_desktop", {supportNumber: supportNumberFmt})}
                        </div>
                        <div className="mt-2 d-lg-none d-block">
                            <Trans i18nKey="call_to_report_an_outage_mobile" t={t}>
                            To report an outage for an unlisted location or provide additional outage information, please call SRP customer Services at <a href={"tel:"+supportNumber}>{{supportNumber: supportNumberFmt}}</a>.
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ReportOutagePage.propTypes = {
    selectedBillAccount: PropTypes.number,
    isCommercial: PropTypes.bool,
    serviceAddress: PropTypes.string,
    requestCallback: PropTypes.bool,
    phoneForCallback: PropTypes.string,
    getPhoneForCallback: PropTypes.func,
    validateCallbackPhone: PropTypes.func,
    callbackError: PropTypes.bool,
    callbackErrorMessage: PropTypes.string,
    togglePowerRestoredCallbackOnClick: PropTypes.func,
    submitReportOutageFormOnClick: PropTypes.func,
    isReportingOutage: PropTypes.bool,
    history: PropTypes.object,

    showAffectedAddressModal: PropTypes.bool,
    closeAffectedAddressModalOnClick: PropTypes.func,
    openAffectedAddressModalOnClick: PropTypes.func,

    outageSubscription: PropTypes.object,
    availableOutageNotification: PropTypes.object,
    outageSubscriptionEmail: PropTypes.string,
    outageSubscriptionEmailError: PropTypes.bool,
    validateOutageSubscriptionEmail: PropTypes.func,
    outageSubscriptionPhone: PropTypes.string,
    outageSubscriptionPhoneError: PropTypes.bool,
    validateOutageSubscriptionPhone: PropTypes.func,
    getOutageSubscriptionEmail: PropTypes.func,
    getOutageSubscriptionPhone: PropTypes.func,
    newSubscriptionEmailDialog: PropTypes.object,
    newSubscriptionPhoneDialog: PropTypes.object,
    contacts: PropTypes.arrayOf(PropTypes.object),
    addAndSubscribeContact: PropTypes.func,

    errorCallingReportOutageAPI: PropTypes.bool.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    breakerCheck: PropTypes.bool,
    generalError: PropTypes.bool,
    onBreakerCheckClicked: PropTypes.func,
    setGeneralError: PropTypes.func
}

export default ReportOutagePage