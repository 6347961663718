import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../../../styles/dashboard-page.css'

import format from '../../../srp_modules/format'

import { getBankAccountList } from '../../../actions/auth/payment/bank-account-actions'

import { submitMultiplePayments, sendReconnectRemark, clearSubmitPaymentError, updateReconnectChoice, getSelectedPaymentAccountRateCode } from '../../../actions/auth/payment/multi-payment-actions'

import VerifyMultiPaymentPage from './verify-multi-payment-page'
import utils from '../../../srp_modules/utils'
import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'

import { formCompleteEvent, formErrorEvent } from '../../../srp_modules/analytics-events'
import { paymentConstants } from '../../common_payment/payment-constants'
import { withRouter } from '../../../srp_modules/with-router'

let getSelectedBankAccountDescription = (bankAccountList, selectedBankAccountId) => {
    let selectedBankDiz = ''
    if (selectedBankAccountId > 0) {
        const selectedBank = bankAccountList.find((bankAccount) => bankAccount.bankId === selectedBankAccountId)
        if (selectedBank !== undefined)
            selectedBankDiz = format.bankAccount(selectedBank)
    }
    return selectedBankDiz
}

let getTotalPayments = (paymentList) => {
    let total = 0
    paymentList.forEach(payment => {
        total += utils.paymentToNumber(payment.paymentAmount)
    })

    return total
}

let getDisplayDateWarning = (paymentList, disconnectedPaymentAccounts) => {
    let display = false

    if(disconnectedPaymentAccounts.length === paymentList.length){
        display = false
    }else{
        paymentList.forEach(payment => {
            if (payment.paymentDateError.errorType === multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE ||
                payment.paymentDateError.errorType === multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL ||
                payment.paymentDateError.errorType === multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL_CIX_ELIGIBLE) {
                display = true
            }
        })
    }

    return display
}

class VerifyMultiPaymentPageContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.isStateEmpty())
            this.props.router.navigate('/myaccount/payment/multi', { replace: true })
    }

    isStateEmpty() { return this.props.paymentList === undefined || this.props.paymentList.length === 0 }

    render() {
        return (
            !this.isStateEmpty() &&
            <div>
                <VerifyMultiPaymentPage
                    sortedPaymentList={this.props.sortedPaymentList}
                    totalPayment={getTotalPayments(this.props.paymentList)}
                    submitMultiPaymentResultStatus={this.props.submitMultiPaymentResultStatus}
                    onSubmitClick={() => this.props.onSubmitClick(this.props.paymentList)}
                    selectedBankAccountDescription={this.props.selectedBankAccountDescription}
                    displayDateWarning={getDisplayDateWarning(this.props.paymentList, this.props.disconnectedPaymentAccounts)}
                    showSubmitError={this.props.showSubmitError}
                    clearSubmitPaymentError={this.props.clearSubmitPaymentError}
                    isSolar={this.props.isSolar}
                    disconnectedPaymentAccounts={this.props.disconnectedPaymentAccounts}
                    sendReconnectRemark={this.props.sendReconnectRemark}
                    getSelectedPaymentAccountRateCode={this.props.getSelectedPaymentAccountRateCode}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankAccountList: state.bankAccounts.bankAccountList,
        bankAccountListStatus: state.bankAccounts.bankAccountListStatus,
        paymentList: state.multiPayment.paymentList,
        sortedPaymentList: state.multiPayment.sortedPaymentList,
        submitMultiPaymentResultStatus: state.multiPayment.submitMultiPaymentResultStatus,
        selectedBankAccountDescription: getSelectedBankAccountDescription(state.bankAccounts.bankAccountList, state.multiPayment.selectedBankAccountId),
        showSubmitError: state.multiPayment.errorSubmittingPayments,
        isSolar: state.multiPayment.selectedPaymentAccountIsSolar,
        disconnectedPaymentAccounts : state.multiPayment.disconnectedPaymentAccounts
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmitClick: async (paymentList) => {
            const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME

            let submitPaymentResult = await dispatch(submitMultiplePayments())
            if (submitPaymentResult.error === true) {
                formErrorEvent(formName)
            } else if (submitPaymentResult.payload.isSuccess) {
                const payments = paymentList.map(payment => ({
                    paymentAmount: utils.paymentToNumber(payment.paymentAmount),
                    paymentDate: payment.paymentDate
                }))
                const totalPaymentAmount = payments.reduce((prevPaymentAmount, curr) => prevPaymentAmount + curr.paymentAmount, 0)

                const meta = {
                    paymentCount: payments.length,
                    paymentTotal: totalPaymentAmount,
                    payments,
                }
                formCompleteEvent(formName, meta)

                ownProps.router.navigate('/myaccount/payment/multi/submitted', { replace: true })
            }
        },
        sendReconnectRemark : async (billAccount, authorizeReconnect) => {
            let response = await dispatch(sendReconnectRemark(billAccount, authorizeReconnect))
            if(response.payload.success === true){
                dispatch(updateReconnectChoice(billAccount, authorizeReconnect))
            }else{
                dispatch(updateReconnectChoice(billAccount, false))
            }
        },
        clearSubmitPaymentError: () => {
            dispatch(clearSubmitPaymentError())
        },
        getBankAccountList: () => {
            dispatch(getBankAccountList())
        },
        getSelectedPaymentAccountRateCode: (billAccount) => {
            dispatch(getSelectedPaymentAccountRateCode(billAccount))
        }
    }
}

VerifyMultiPaymentPageContainer.propTypes = {
    bankAccountList: PropTypes.arrayOf(PropTypes.object),
    bankAccountListStatus: PropTypes.string.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.object).isRequired,
    sortedPaymentList: PropTypes.object.isRequired,
    submitMultiPaymentResultStatus: PropTypes.string.isRequired,
    selectedBankAccountDescription: PropTypes.string.isRequired,
    isSolar: PropTypes.bool.isRequired,
    disconnectedPaymentAccounts: PropTypes.array.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    getBankAccountList: PropTypes.func.isRequired,
    showSubmitError: PropTypes.bool.isRequired,
    clearSubmitPaymentError: PropTypes.func.isRequired,
    sendReconnectRemark: PropTypes.func.isRequired,
    getSelectedPaymentAccountRateCode: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyMultiPaymentPageContainer))