import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'

import CardBill from './card-bill'
import CardLoading from './../card-loading'
import CardBillNoBill from './card-bill-no-bill'
import CardBillClosedAcct from './card-bill-closed-acct'
import CardBillSummaryMaster from './card-bill-summary-master'
import EmptyState from '../../empty-state'
import MPowerBillCard from './card-bill-mpower'
import MPowerReconnectBillCard from './card-bill-mpower-reconnect'
import MPowerReconnectSuccessCard from './card-bill-mpower-reconnect-success'
import MPowerPendingConnectBillCard from './card-bill-mpower-pending-connect'
import CIXCardContainer from './credit_extension/cix-card-container'
import PaymentArrangementDetails from './payment-arrangement-details'

import CreditAdvanceContainer from './credit_advance/credit-advance-container'

import { startCIXFlow } from '../../../actions/auth/payment/cix-actions'
import { getNotifications, toggleEbill, refreshNotification } from '../../../actions/auth/notification/notification-actions'
import {
    getPayment,
    getCreditExtension,
    getEnrolledProgramsDetails,
    getScheduledPaymentsByAcct,
    authorizeTokenlessReconnect
} from '../../../actions/auth/payment/payment-actions'
import { getDailyUsage } from '../../../actions/auth/usage/daily-usage-actions'
import { getAccountHistory, getCurrentBill, getBillingAddress, getMPowerAccountDetails } from '../../../actions/auth/bill_account/bill-account-actions'
import { getRateMetaData } from '../../../actions/auth/usage/rate-meta-data-actions'
import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getMpowerSelfServiceAdvance } from '../../../actions/auth/credit_advance/credit-advance-actions'
import { addSnackbarMessage } from '../../../actions/common/snackbar'
import { getAutopayInfo } from '../../../actions/auth/payment/autopay-actions'

import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { paymentConstants } from '../../../constants/payment-constants'
import { centralPrepayConstants } from '../../../constants/central-prepay-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import alertsModule from '../../../srp_modules/alerts-module'


class CardBillContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showEbillEnrollDialog: false,
            showEbillUnenrollDialog: false,
            isEbill: this.props.eBillState,
            disableEbillToggle: false,
            displayPaymentArrangementDetails: false,
            displaySelfServiceAdvance: false
        }

        this.getEbillEnrollmentChange = this.getEbillEnrollmentChange.bind(this)
        this.closeEbillEnrollDialog = this.closeEbillEnrollDialog.bind(this)
        this.closeEbillUnenrollDialog = this.closeEbillUnenrollDialog.bind(this)
        this.setPaymentArrangementDetailsDisplay = this.setPaymentArrangementDetailsDisplay.bind(this)
        this.toggleSelfServiceAdvance = this.toggleSelfServiceAdvance.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.paymentStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getPaymentInfo(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getEnrolledProgramsInfo(this.props.selectedBillAccount)
        }

        if (this.props.selectedBillAccountDetails.isPrePay) {
            if (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getMPowerAccountDetails(this.props.selectedBillAccount)
            }

            if (this.props.mPowerSelfServiceAdvanceStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getMpowerSelfServiceAdvance(this.props.selectedBillAccount)
            }

            if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getDailyUsage(this.props.selectedBillAccount)
            }
        }
        else {
            if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
            }

            if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getAccountHistory(this.props.selectedBillAccount)
            }

            if (this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getCurrentBillInfo(this.props.selectedBillAccount)
            }

            if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getNotificationsInfo(this.props.selectedBillAccount)
            }

            if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getContactsInfo(this.props.selectedBillAccount)
            }

            if (this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getBillingAddress(this.props.selectedBillAccount)
            }

            if (this.props.creditExtensionStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getCreditExtensionInfo(this.props.selectedBillAccount)
            }

            if (this.props.scheduledPaymentsByAcctStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getScheduledPayments(this.props.selectedBillAccount)
            }

            if (this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getAutopayInfo(this.props.selectedBillAccount)
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.eBillState !== nextProps.eBillState) {
            this.setState({
                isEbill: nextProps.eBillState
            })
        }

        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.enrolledProgramsDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnrolledProgramsInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.selectedBillAccountDetails.isPrePay) {
            if (nextProps.mPowerAcctDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getMPowerAccountDetails(nextProps.selectedBillAccount)
            }

            if (nextProps.mPowerSelfServiceAdvanceStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getMpowerSelfServiceAdvance(nextProps.selectedBillAccount)
            }

            if (nextProps.paymentStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getPaymentInfo(nextProps.selectedBillAccount)
            }

            if (nextProps.dailyUsageStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getDailyUsage(nextProps.selectedBillAccount)
            }
        } else {
            if (nextProps.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getRateMetaDataInfo(nextProps.selectedBillAccount)
            }

            if (nextProps.accountHistoryStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getAccountHistory(nextProps.selectedBillAccount)
            }

            if (nextProps.currentBillInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getCurrentBillInfo(nextProps.selectedBillAccount)
            }

            if (nextProps.notificationsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getNotificationsInfo(nextProps.selectedBillAccount)
            }

            if (nextProps.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getContactsInfo(nextProps.selectedBillAccount)
            }

            if (nextProps.billingAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getBillingAddress(nextProps.selectedBillAccount)
            }

            if (nextProps.creditExtensionStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getCreditExtensionInfo(nextProps.selectedBillAccount)
            }

            if (nextProps.scheduledPaymentsByAcctStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getScheduledPayments(nextProps.selectedBillAccount)
            }

            if (nextProps.paymentStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getPaymentInfo(nextProps.selectedBillAccount)
            }

            if (nextProps.autopayInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getAutopayInfo(nextProps.selectedBillAccount)
            }
        }
    }

    async getEbillEnrollmentChange(isEnrolling, preferPhone) {
        this.setState({
            disableEbillToggle: true
        })

        let success = await this.props.UpdateEbillEnrollment(isEnrolling, preferPhone, this.props.selectedBillAccount, this.props.t)

        this.setState({
            disableEbillToggle: false
        })

        if (success === false) {
            return
        }

        this.setState({
            isEbill: isEnrolling,
        })

        if (isEnrolling) {
            this.setState({
                showEbillEnrollDialog: true,
            })
        }
        else {
            this.setState({
                showEbillUnenrollDialog: true,
            })
        }
    }

    closeEbillEnrollDialog() {
        this.setState({
            showEbillEnrollDialog: false
        })
    }

    closeEbillUnenrollDialog() {
        this.setState({
            showEbillUnenrollDialog: false
        })
    }

    setPaymentArrangementDetailsDisplay(displayDetails) {
        this.setState({
            displayPaymentArrangementDetails: displayDetails
        })
    }

    toggleSelfServiceAdvance(display) {
        this.setState({ displaySelfServiceAdvance: display})
    }

    render() {
        const t = this.props.t

        if (this.props.selectedBillAccountDetails.isPrePay) {
            if (this.props.selectedBillAccountDetails.closedState === billAccountConstants.CLOSED_STATE.CLOSED) {
                return (<CardBillClosedAcct
                    t={this.props.t}
                    i18n={this.props.i18n}
                    isMPower={this.props.selectedBillAccountDetails.isPrePay}
                    isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                    closedDate={this.props.selectedBillAccountDetails.closedDate} />)
            }
            else if (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                // || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS 
                || this.props.paymentStatus === loadingStatus.LOADING_STATUS_INIT || this.props.paymentStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS || this.props.mPowerSelfServiceAdvanceStatus === loadingStatus.LOADING_STATUS_INIT || this.props.mPowerSelfServiceAdvanceStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                return <CardLoading title={t("My M-Power")} />
            }
            else if (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.paymentStatus === loadingStatus.LOADING_STATUS_SUCCESS
                // && this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
                if ((false && this.props.dailyReadCount === 0)
                    || (!this.props.payment.supportsRemotePrepay
                        // TODO: This might be unnecessary if eChex continues to return tokenless for central prepay
                        && this.props.mPowerAcctDetails.centralPrepayStatus !== centralPrepayConstants.PREPAY_CENTRAL)) {
                    return (<CardBillNoBill
                        t={this.props.t}
                        i18n={this.props.i18n}
                        isMPower={this.props.selectedBillAccountDetails.isPrePay}
                        isCommercial={this.props.selectedBillAccountDetails.isCommercial} />)
                }
                else if (this.props.mPowerAcctDetails.meterStatus==="CppAuthorizeReconnect") {
                    return this.props.authorizeReconnectSucceeded
                    ?
                    (<MPowerReconnectSuccessCard
                        mPowerAcctDetails={this.props.mPowerAcctDetails}
                        t={this.props.t}
                    />)
                    :
                    (<MPowerReconnectBillCard
                        mPowerAcctDetails={this.props.mPowerAcctDetails}
                        reconnectPowerStatus={this.props.authorizeTokenlessReconnectStatus}
                        reconnectPowerNow={this.props.reconnectPowerNow}
                        t={this.props.t}
                    />)
                }
                else if (this.props.mPowerAcctDetails.meterStatus==="CppConnectionInProgress") {
                    return (<MPowerPendingConnectBillCard
                        mPowerAcctDetails={this.props.mPowerAcctDetails}
                        t={this.props.t}
                    />)
                }
                else if (this.state.displaySelfServiceAdvance) {
                    return (<CreditAdvanceContainer
                        t={this.props.t}
                        i18n={this.props.i18n}
                        toggleSelfServiceAdvance={this.toggleSelfServiceAdvance} />)
                }
                else {
                    return (<MPowerBillCard
                        t={this.props.t}
                        i18n={this.props.i18n}
                        mPowerAcctDetails={this.props.mPowerAcctDetails}
                        paymentInfo={this.props.payment}
                        isCashOnly={this.props.isCashOnly}
                        authorizeTokenlessReconnect={this.props.authorizeTokenlessReconnect}
                        authorizeTokenlessReconnectStatus={this.props.authorizeTokenlessReconnectStatus}
                        mPowerSelfServiceAdvance={this.props.mPowerSelfServiceAdvance}
                        toggleSelfServiceAdvance={this.toggleSelfServiceAdvance} />)
                }
            }
            else {
                return (
                    <div className="srp-card">
                        <div className="srp-card-header">
                            {t("My M-Power")}
                    </div>
                        <EmptyState isCommercial={this.props.selectedBillAccountDetails.isCommercial} dataType={t("billing")} t={t} i18n={this.props.i18n} />
                    </div>
                )
            }
        }
        else {
            if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_INIT || this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.creditExtensionStatus === loadingStatus.LOADING_STATUS_INIT || this.props.creditExtensionStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.scheduledPaymentsByAcctStatus === loadingStatus.LOADING_STATUS_INIT || this.props.scheduledPaymentsByAcctStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.paymentStatus === loadingStatus.LOADING_STATUS_INIT || this.props.paymentStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS || this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                return <CardLoading title={t("My bill")} />
            }
            else if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.contactsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.creditExtensionStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.scheduledPaymentsByAcctStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.paymentStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS) {

                if (!this.props.currentBillInfo || this.props.accountHistoryList.length === 0) {
                    return (<CardBillNoBill
                        t={this.props.t}
                        i18n={this.props.i18n}
                        isMPower={this.props.selectedBillAccountDetails.isPrePay}
                        isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                        hasPendingAutopayChange={this.props.autopayInfo.hasPendingChange}
                        hasPendingAutopayEnrollment={this.props.autopayInfo.hasPendingEnrollment}
                        />)
                }
                else if (this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT) {
                    return <CardBillSummaryMaster t={this.props.t}/>
                }
                else {
                    if (this.props.showBillCard) {
                        if (!this.state.displayPaymentArrangementDetails) {
                            return (<CardBill
                                currentBillInfo={this.props.currentBillInfo}
                                accountHistoryList={this.props.accountHistoryList}
                                hasEBill={this.state.isEbill}
                                disableEbillToggle={this.state.disableEbillToggle}
                                ebillEnrollContacts={getEBillContactList(this.props.allAlerts.billingAlert, this.props.primaryEmail)}
                                billingAlertEmailContacts={this.props.allAlerts.billingAlert.email}
                                billingAlertPhoneContacts={this.props.allAlerts.billingAlert.phone}
                                billingAddress={this.props.singleLineBillingAddress}
                                getEbillEnrollmentChange={this.getEbillEnrollmentChange}
                                showEbillEnrollDialog={this.state.showEbillEnrollDialog}
                                showEbillUnenrollDialog={this.state.showEbillUnenrollDialog}
                                closeEbillEnrollDialog={this.closeEbillEnrollDialog}
                                closeEbillUnenrollDialog={this.closeEbillUnenrollDialog}
                                creditExtensionInfo={this.props.creditExtension}
                                isBudgetBilling={this.props.currentBillInfo === null ? false :
                                    this.props.isBudgetBilling
                                    && this.props.currentBillInfo.paymentPlanCode === paymentConstants.PAYMENT_PLAN_CODE.BUDGET_ACTIVE}
                                isCashOnly={this.props.isCashOnly}
                                isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                                scheduledPaymentsByAcct={this.props.scheduledPaymentsByAcct}
                                paymentInfo={this.props.payment}
                                startCIXFlowOnClick={this.props.startCIXFlowOnClick}
                                showCIXbutton={this.props.showCIXbutton}
                                isClosedAccount={this.props.selectedBillAccountDetails.closedState === billAccountConstants.CLOSED_STATE.CLOSED}
                                setPaymentArrangementDetailsDisplay={this.setPaymentArrangementDetailsDisplay}
                                hasPendingAutopayChange={this.props.autopayInfo.hasPendingChange}
                                hasPendingAutopayEnrollment={this.props.autopayInfo.hasPendingEnrollment}
                                t={this.props.t}
                                i18n={this.props.i18n}
                                 />)
                        }
                        else {
                            return (
                                <PaymentArrangementDetails
                                    t={this.props.t}
                                    setPaymentArrangementDetailsDisplay={this.setPaymentArrangementDetailsDisplay}
                                    paymentInfo={this.props.payment} />
                            )
                        }
                    }
                    else {
                        return (<CIXCardContainer />)
                    }
                }
            }
            else {
                return (
                    <div className="srp-card">
                        <div className="srp-card-header">
                            {t("My bill")}
                    </div>
                        <EmptyState isCommercial={this.props.selectedBillAccountDetails.isCommercial} dataType={t("billing")} t={t} i18n={this.props.i18n} />
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.billAccount, ...state.rateMetaData, ...state.accountHistory, ...state.accountInfo.notification, ...state.accountInfo.contact, ...state.accountInfo.payment, ...state.accountInfo.autopay,
        dailyUsageStatus: state.dailyUsage.dailyUsageStatus,
        dailyReadCount: (state.dailyUsage.dailyUsageList ?? []).length,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRateMetaDataInfo: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getAccountHistory: (billAccount) => {
            dispatch(getAccountHistory(billAccount))
        },
        getCurrentBillInfo: (billAccount) => {
            dispatch(getCurrentBill(billAccount))
        },
        getNotificationsInfo: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },
        getContactsInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getBillingAddress: (billAccount) => {
            dispatch(getBillingAddress(billAccount))
        },
        UpdateEbillEnrollment: async (isEnrolling, preferPhone, billAccount, t) => {
            let response = await dispatch(toggleEbill(isEnrolling, preferPhone))
            const success = !response.error && response.payload
            if (success) {
                dispatch(refreshNotification(billAccount))
            } else {
                const msg = t(isEnrolling ? "Unable_to_enroll_in_paperless_billing" : "Unable_to_unenroll_from_paperless_billing")
                dispatch(addSnackbarMessage(msg))
            }
            return success
        },
        getCreditExtensionInfo: (billAccount) => {
            dispatch(getCreditExtension(billAccount))
        },
        getEnrolledProgramsInfo: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        getScheduledPayments: (billAccount) => {
            dispatch(getScheduledPaymentsByAcct(billAccount))
        },
        getPaymentInfo: (billAccount) => {
            dispatch(getPayment(billAccount))
        },
        startCIXFlowOnClick: () => {
            dispatch(startCIXFlow())
        },
        getDailyUsage: (billAccount) => {
            dispatch(getDailyUsage(billAccount))
        },
        getMPowerAccountDetails: (billAccount) => {
            dispatch(getMPowerAccountDetails(billAccount))
        },
        getMpowerSelfServiceAdvance: (billAccount) => {
            dispatch(getMpowerSelfServiceAdvance(billAccount))
        },
        getAutopayInfo: (billAccount) => {
            dispatch(getAutopayInfo(billAccount))
        },
        reconnectPowerNow: async (billAccount) => {
            let response = await dispatch(authorizeTokenlessReconnect(billAccount))
            const authorizeReconnectSucceeded = (!response.error && response.payload===true)
            if (!authorizeReconnectSucceeded)
                dispatch(addSnackbarMessage(<span>Unable to send credit to meter</span>))
            return authorizeReconnectSucceeded
        },
        authorizeTokenlessReconnect: async (billAccount) => {
            let response = await dispatch(authorizeTokenlessReconnect(billAccount))
            if (response.error || !response.payload)
                dispatch(addSnackbarMessage(<span>Unable to send credit to meter</span>))
        }
    }
}

CardBillContainer.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,
    getRateMetaDataInfo: PropTypes.func,

    accountHistoryList: PropTypes.arrayOf(PropTypes.object),
    accountHistoryStatus: PropTypes.string,
    getAccountHistory: PropTypes.func,

    currentBillInfo: PropTypes.object,
    currentBillInfoStatus: PropTypes.string,
    getCurrentBillInfo: PropTypes.func,

    allAlerts: PropTypes.object,
    refreshNotificationStatus: PropTypes.string,
    notificationsStatus: PropTypes.string,
    getNotificationsInfo: PropTypes.func,

    eBillState: PropTypes.bool,
    eBillStateStatus: PropTypes.string,
    UpdateEbillEnrollment: PropTypes.func,

    primaryEmail: PropTypes.string,
    contactsStatus: PropTypes.string,
    getContactsInfo: PropTypes.func,

    singleLineBillingAddress: PropTypes.string,
    billingAddressStatus: PropTypes.string,
    getBillingAddress: PropTypes.func,

    creditExtension: PropTypes.object,
    creditExtensionStatus: PropTypes.string,
    getCreditExtensionInfo: PropTypes.func,

    isBudgetBilling: PropTypes.bool,
    isCashOnly: PropTypes.bool,
    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsInfo: PropTypes.func,

    scheduledPaymentsByAcct: PropTypes.arrayOf(PropTypes.object),
    scheduledPaymentsByAcctStatus: PropTypes.string,
    getScheduledPayments: PropTypes.func,

    payment: PropTypes.object,
    paymentStatus: PropTypes.string,
    getPaymentInfo: PropTypes.func,

    showBillCard: PropTypes.bool,
    showCIXbutton: PropTypes.bool,
    startCIXFlowOnClick: PropTypes.func,

    getDailyUsage: PropTypes.func.isRequired,
    dailyUsageStatus: PropTypes.string.isRequired,
    dailyReadCount: PropTypes.number.isRequired,

    mPowerAcctDetails: PropTypes.object,
    mPowerAcctDetailsStatus: PropTypes.string,
    getMPowerAccountDetails: PropTypes.func,

    mPowerSelfServiceAdvanceStatus: PropTypes.string,
    getMpowerSelfServiceAdvance: PropTypes.func,

    mPowerSelfServiceAdvance: PropTypes.shape({
        isAdvanceEligible: PropTypes.bool.isRequired,
        advanceEligibility: PropTypes.string.isRequired,
    }).isRequired,

    authorizeReconnectSucceeded: PropTypes.bool.isRequired,
    authorizeTokenlessReconnectStatus: PropTypes.string,
    authorizeTokenlessReconnect: PropTypes.func,
    reconnectPowerNow: PropTypes.func,

    autopayInfo: PropTypes.object,
    autopayInfoStatus: PropTypes.string,
    getAutopayInfo: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    enrolledProgramsDetails: PropTypes.object
}

export default withTranslation('dashboardMyBillCard')(connect(mapStateToProps, mapDispatchToProps)(CardBillContainer))

export function getEBillContactList(billingAlert, primaryEmail) {
    let contactList = ""
    if (billingAlert.subscribedToEmail || billingAlert.subscribedToText) {
        contactList = alertsModule.createEBillContactsString(billingAlert.email, billingAlert.phone)
    }
    else {
        contactList = primaryEmail
    }

    return contactList
}