import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import Dialog from '@mui/material/Dialog'

import '../../styles/map.css'

import OutageBanners from './outage-banners'

import OutagePageLoading from './outage-page-loading'
import OutagePageError from './outage-page-error'

import YellowIcon from '../../images/outage_icons/yellow.svg'
import OrangeIcon from '../../images/outage_icons/orange.svg'
import RedIcon from '../../images/outage_icons/red.svg'
import PurpleIcon from '../../images/outage_icons/purple.svg'
import GreenIcon from '../../images/outage_icons/maintenance.svg'

import {
    hideLegend,
    showLegend,
    hideOutageDetail,
    showOutageDetail
} from '../../actions/auth/outage/outage-map-actions'
import { getAllOutages, updatePublicOutageActions } from '../../actions/guest/outage/outage-public-actions'
import * as loadingStatus from '../../constants/loading-status-constants'

import outageRestoration from '../../srp_modules/outage-restoration'
import { withRouter } from '../../srp_modules/with-router'

let google
let publicOutageMarkers = []

class PublicOutageMap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPublicLegendModal: false,
            showPublicOutageDetailModal: false,

            showIceReimbursementModel: false
        }

        this.closePublicLegendModalOnClick = this.closePublicLegendModalOnClick.bind(this)
        this.openPublicLegendModalOnClick = this.openPublicLegendModalOnClick.bind(this)
        this.closePublicOutageDetailModalOnClick = this.closePublicOutageDetailModalOnClick.bind(this)
        this.openPublicOutageDetailModalOnClick = this.openPublicOutageDetailModalOnClick.bind(this)
        this.iceReimbursementModalOnClick = this.iceReimbursementModalOnClick.bind(this)

        this.refreshPublicOutageMap = this.refreshPublicOutageMap.bind(this)
    }

    componentDidMount() {
        if (this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            window.publicOutageMap = {}
            return
        }

        this.setPublicOutageMap()
    }

    componentDidUpdate(prevProps) {
        if (this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            window.publicOutageMap = {}
            return
        }

        if (prevProps.allOutagesInfo !== this.props.allOutagesInfo
            || prevProps.mapScriptLoadedStatus !== this.props.mapScriptLoadedStatus
            || prevProps.iceReimbursementInfoStatus !== this.props.iceReimbursementInfoStatus)
            this.setPublicOutageMap()
    }

    initMap() {
        google = window.google
        window.publicOutageMap = new google.maps.Map(ReactDOM.findDOMNode(this.refs.hiddenPublicMap), {
            zoom: 10,
            minZoom: 8,
            center: new google.maps.LatLng(33.448261, -112.075768),
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            styles: [{
                featureType: 'poi',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'poi.park',
                stylers: [{ visibility: 'on' }]
            }]
        })

        window.addEventListener('resize', function () {
            window.publicOutageMap.setCenter(new google.maps.LatLng(33.448261, -112.075768))
        })

        let southWest = new google.maps.LatLng(32.507020, -114.122679)
        let northEast = new google.maps.LatLng(36.956073, -109.112914)
        let allowedBounds = new google.maps.LatLngBounds(southWest, northEast)
        let lastValidCenter = window.publicOutageMap.getCenter()
        google.maps.event.addListener(window.publicOutageMap, 'center_changed', function () {
            if (allowedBounds.contains(window.publicOutageMap.getCenter())) {
                lastValidCenter = window.publicOutageMap.getCenter()
                return
            }

            window.publicOutageMap.panTo(lastValidCenter)
        })
    }

    setPublicOutageMap() {
        if (this.props.allOutagesStatus !== loadingStatus.LOADING_STATUS_SUCCESS
            || this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS
            || (this.props.iceReimbursementInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.iceReimbursementInfoStatus !== loadingStatus.LOADING_STATUS_FAILURE)) {
            return
        }

        if (!window.publicOutageMap || Object.keys(window.publicOutageMap).length === 0) {
            this.initMap()
        }

        if (window.publicOutageMap.controls[google.maps.ControlPosition.LEFT].length !== 0) {
            window.publicOutageMap.controls[google.maps.ControlPosition.LEFT].clear()
        }

        if (window.publicOutageMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].length !== 0) {
            window.publicOutageMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].clear()
        }

        ReactDOM.findDOMNode(this.refs.publicOutageMap).appendChild(window.publicOutageMap.getDiv())
        google.maps.event.trigger(window.publicOutageMap, 'resize')

        let legend = ReactDOM.findDOMNode(this.refs.publicLegend)
        window.publicOutageMap.controls[google.maps.ControlPosition.LEFT].push(legend)

        let legendButton = ReactDOM.findDOMNode(this.refs.publicLegendButton)
        window.publicOutageMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legendButton)

        let legendButtonR = ReactDOM.findDOMNode(this.refs.publicLegendButtonR)
        window.publicOutageMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legendButtonR)

        let outageDetail = ReactDOM.findDOMNode(this.refs.publicDetail)
        window.publicOutageMap.controls[google.maps.ControlPosition.LEFT].push(outageDetail)

        this.plotOutages(window.publicOutageMap)
    }

    plotOutages(map) {

        let outagePosition = {}
        let outageIcon = ""
        let outageMarkerInfo = {}

        if (publicOutageMarkers.length != 0) {
            for (let i = 0; i < publicOutageMarkers.length; i++) {
                publicOutageMarkers[i].setMap(null)
            }
        }

        if (Object.keys(this.props.allOutagesInfo).length !== 0) {
            for (let i = 0; i < this.props.allOutagesInfo.length; i++) {
                outagePosition = { lat: this.props.allOutagesInfo[i].latitude, lng: this.props.allOutagesInfo[i].longitude }

                let affectedCustomers = this.props.allOutagesInfo[i].numberCustomersAffected;
                let maintenanceOutage = this.props.allOutagesInfo[i].isMaintenanceOutage;

                if (1 <= affectedCustomers && affectedCustomers <= 100 && maintenanceOutage == false) {
                    outageIcon = YellowIcon;
                }
                else if (101 <= affectedCustomers && affectedCustomers <= 500 && maintenanceOutage == false) {
                    outageIcon = OrangeIcon;
                }
                else if (501 <= affectedCustomers && affectedCustomers <= 1000 && maintenanceOutage == false) {
                    outageIcon = RedIcon;
                }
                else if (affectedCustomers >= 1001 && maintenanceOutage == false) {
                    outageIcon = PurpleIcon;
                }
                else if (maintenanceOutage) {
                    outageIcon = GreenIcon;
                }

                outageMarkerInfo = {
                    startTime: this.props.allOutagesInfo[i].outageBegan,
                    estimatedRestorationTime: this.props.allOutagesInfo[i].estimatedRestorationTime,
                    customersImpacted: this.props.allOutagesInfo[i].numberCustomersAffected,
                    reason: this.props.allOutagesInfo[i].outageProblem,
                    reasonEs: this.props.allOutagesInfo[i].outageProblemEs,
                    impactedArea: this.props.allOutagesInfo[i].crossRoadText,
                    impactedAreaEs: this.props.allOutagesInfo[i].crossRoadTextEs
                }

                let markerIcon = {
                    url: outageIcon,
                    scaledSize: new google.maps.Size(25, 36),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(12.5, 36)
                }

                let markerIconClicked = {
                    url: outageIcon,
                    scaledSize: new google.maps.Size(37, 54),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(18.5, 54)
                }

                let marker = new google.maps.Marker({
                    icon: markerIcon,
                    position: outagePosition,
                    map: map,
                    data: outageMarkerInfo,
                    optimized: false
                })

                publicOutageMarkers.push(marker)

                let markerThis = this.props;
                google.maps.event.addListener(marker, 'click', () => {
                    if (window.innerWidth > 991) {
                        marker.setIcon(markerIconClicked)
                        marker.setAnimation(4)

                        for (let i = 0; i < publicOutageMarkers.length; i++) {
                            if (marker !== publicOutageMarkers[i]) {
                                publicOutageMarkers[i].setIcon({
                                    url: publicOutageMarkers[i].icon.url,
                                    scaledSize: new google.maps.Size(25, 36),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(12.5, 36)
                                })
                            }
                        }
                    }

                    markerThis.showOutageDetailOnClick(marker.data)

                    //mobile
                    if (window.innerWidth <= 991) {
                        this.openPublicOutageDetailModalOnClick()
                    }

                    ReactDOM.findDOMNode(this.refs.hidePublicDetails).addEventListener('click', () => {
                        marker.setIcon(markerIcon)
                    })
                })
            }
        }
    }

    closePublicLegendModalOnClick() {
        this.setState({
            showPublicLegendModal: false
        })
    }

    openPublicLegendModalOnClick() {
        this.setState({
            showPublicLegendModal: true
        })
    }

    closePublicOutageDetailModalOnClick() {
        this.setState({
            showPublicOutageDetailModal: false
        })

        this.props.hideOutageDetailOnClick()
    }

    openPublicOutageDetailModalOnClick() {
        this.setState({
            showPublicOutageDetailModal: true
        })
    }

    iceReimbursementModalOnClick(isOpen) {
        this.setState({
            showIceReimbursementModel: isOpen,
        })
    }

    refreshPublicOutageMap() {
        window.publicOutageMap.setZoom(10)
        window.publicOutageMap.setCenter(new google.maps.LatLng(33.448261, -112.075768))
        this.props.getPublicOutageInfo()

        this.props.hideOutageDetailOnClick()
        this.props.hideLegend()
    }

    updatePublicOutageInfo() {
        this.props.updateAllOutagesInfo
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        const isSpanish = (i18n.language === "es")

        let renderedCard = {}
        if ((this.props.allOutagesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.allOutagesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || (this.props.iceReimbursementInfoStatus === loadingStatus.LOADING_STATUS_INIT || this.props.iceReimbursementInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)) {
            renderedCard = <OutagePageLoading />
        }
        else if (this.props.allOutagesStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && (this.props.iceReimbursementInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS
                || this.props.iceReimbursementInfoStatus === loadingStatus.LOADING_STATUS_FAILURE)) {
            // If there's an error getting ice reimbursement info, don't prevent the outage map from being displayed.
            // We will simply not show ice reimbursement info. It's much more important to show outages.
            renderedCard = (<div className="srp-card-details">
                {this.props.iceReimbursementInfo.showIceReimbursementOffer
                    ? <div className="srp-alert-notice mb-3">
                        {t("in_outage_ice_reimbursement", {minOutageHoursRequired: this.props.iceReimbursementInfo.minOutageHoursRequired})}
                        {' '}
                        <button
                            className="displayAsLink"
                            onClick={() => this.iceReimbursementModalOnClick(true)}>
                            {t("Learn more »")}
                        </button>
                    </div>
                    : null}
                <Dialog
                    modal="true"
                    open={this.state.showIceReimbursementModel}
                    PaperProps={{ className: "srp-modal" }}
                >
                    <div className="srp-modal-body" id="legendModal">
                        <div className="srp-modal-close" onClick={() => this.iceReimbursementModalOnClick(false)} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Ice reimbursement available")}</h4>
                            </div>
                            <div className="mb-3">
                                {t("ice_reimbursement_details", {minOutageHoursRequired: this.props.iceReimbursementInfo.minOutageHoursRequired, maxReimbursementAmount: this.props.iceReimbursementInfo.maxReimbursementAmount})}
                            </div>
                            <div className="mb-4">
                                {t("Ice may be helpful in preserving perishables in refrigerators and freezers.")}
                            </div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("How to receive reimbursement")}</h4>
                            </div>
                            <div>{t("Send_an_email_to", {emailAddress: this.props.iceReimbursementInfo.sendEmailTo})}</div>
                            <div>{t("Subject line: Ice Reimbursement")}</div>
                            <div>{t("Include: Name, address, copy of receipt of ice purchase.")}</div>
                            <div className="srp-modal-footer mb-0">
                                <button
                                    className="btn srp-btn btn-lightblue"
                                    onClick={() => this.iceReimbursementModalOnClick(false)}>
                                    {t("Close")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div style={{ display: 'none' }}>
                    <div className="outage-map-style" id="hiddenPublicMap" ref="hiddenPublicMap" />
                </div>
                <div className="outage-map-style" id="publicOutageMapContainer" ref="publicOutageMap" />

                <div style={{ height: '0' }}>
                    <div className="legendDetail d-none d-lg-block" id="publicLegend" ref="publicLegend" style={this.props.legendStyle}>
                        <div className="legend text-white">
                            <div className="mb-4 pb-5">
                                <div className="mt-2 legend-title mb-2" ><strong>{t("Outages affecting...")}</strong></div>
                                <div><img className="legend-icons mb-2 mr-1" src={YellowIcon} />{t("1-100 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={OrangeIcon} />{t("101-500 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={RedIcon} />{t("501-1,000 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={PurpleIcon} />{t("+1,001 customers")}</div>
                                <div className="mt-5 legend-title mb-2" ><strong>{t("Other")}</strong></div>
                                <div><img className="legend-icons mb-2 mr-1" src={GreenIcon} />{t("Maintenance outage")}</div>
                            </div>
                            <div className="legend-details-btn-container legend-details-btn-container-position">
                                <i className="material-icons">keyboard_arrow_left</i>
                                <button onClick={() => this.props.hideLegend()} className="legend-details-btn text-white button-focus" name="hideLegend">{t("Hide legend")}</button>
                            </div>
                        </div>
                    </div>

                    <div className="d-none d-lg-block" id="publicLegendButton" ref="publicLegendButton">
                        <button onClick={this.props.showLegend} className="btn show-legend-btn" style={this.props.legendButtonStyle}>
                            <div className="d-flex align-items-center">
                                <span>{t("Show legend")} &nbsp;</span>
                                <i className="material-icons srp-icon">keyboard_arrow_right</i>
                            </div>
                        </button>
                    </div>

                    <div className="outageDetail d-none d-lg-block" id="publicDetail" ref="publicDetail" style={this.props.outageDetailStyle}>
                        <div className="legend text-white">
                            <div className="mt-2 legend-title"><strong>{t("Power outage details")}</strong></div>
                            <br />
                            <div className="legend-title"><strong>{t("Outage start time")}</strong></div>
                            <div className="mr-1" >{DateTime.fromISO(this.props.outageMarkerInfo.startTime).toFormat('M/dd/yyyy h:mm a')}</div>
                            <br />
                            <div className="legend-title"><strong>{t("Estimated restoration time")}</strong></div>
                            <div className="mr-1" >{outageRestoration.getRestorationForPage(this.props.outageMarkerInfo.estimatedRestorationTime, t)}</div>
                            <br />
                            <div className="legend-title"><strong>{t("Estimated customers impacted")}</strong></div>
                            <div className="mr-1" >{this.props.outageMarkerInfo.customersImpacted}</div>
                            <br />
                            <div className="legend-title"><strong>{t("Reason")}</strong></div>
                            <div className="mr-1" >{isSpanish ? this.props.outageMarkerInfo.reasonEs : this.props.outageMarkerInfo.reason}</div>
                            <br />
                            <div className="legend-title"><strong>{t("Impacted area")}</strong></div>
                            <div className="mr-1" >{isSpanish ? this.props.outageMarkerInfo.impactedAreaEs : this.props.outageMarkerInfo.impactedArea}</div>
                            <br />
                            <div className="legend-details-btn-container legend-details-btn-container-position">
                                <i className="material-icons">keyboard_arrow_left</i>
                                <button onClick={() => this.props.hideOutageDetailOnClick()} className="legend-details-btn text-white button-focus" id="hidePublicDetails" ref="hidePublicDetails">{t("Hide Details")}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*MOBILE*/}
                <div className="d-lg-none d-block" id="publicLegendButtonR" ref="publicLegendButtonR" >
                    <button className="btn show-legend-btn" onClick={() => this.openPublicLegendModalOnClick()}>
                        <div className="d-flex align-items-center">
                            <span>{t("Show legend")} &nbsp;</span>
                            <i className="material-icons srp-icon">keyboard_arrow_right</i>
                        </div>
                    </button>
                </div>

                <Dialog
                    modal="true"
                    open={this.state.showPublicLegendModal}
                    PaperProps={{ className: "srp-modal d-lg-none d-block" }}
                >
                    <div className="srp-modal-body" id="publicLegendModal">
                        <div className="srp-modal-close" onClick={() => this.closePublicLegendModalOnClick()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Outage Legend")}</h4>
                            </div>
                            <div>
                                <div className="mt-2 legend-title mb-2"><strong>{t("Outages affecting...")}</strong></div>
                                <div><img className="legend-icons mb-2 mr-1" src={YellowIcon} />{t("1-100 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={OrangeIcon} />{t("101-500 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={RedIcon} />{t("501-1,000 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={PurpleIcon} />{t("+1,001 customers")}</div>
                                <div className="mt-2 legend-title mb-2"><strong>{t("Other")}</strong></div>
                                <div><img className="legend-icons mb-2 mr-1" src={GreenIcon} />{t("Maintenance outage")}</div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    modal="true"
                    open={this.state.showPublicOutageDetailModal}
                    PaperProps={{ className: "srp-modal d-lg-none d-block" }}
                >
                    <div className="srp-modal-body" id="publicDetailModal">
                        <div className="srp-modal-close" id="hidePublicDetailsResp" ref="hidePublicDetailsResp" onClick={() => this.closePublicOutageDetailModalOnClick()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Power outage details")}</h4>
                            </div>
                            <div>
                                <div className="legend-title"><strong>{t("Outage start time")}</strong></div>
                                <div className="mr-1 mb-2">{DateTime.fromISO(this.props.outageMarkerInfo.startTime).toFormat('M/dd/yyyy h:mm a')}</div>
                                <div className="legend-title"><strong>{t("Estimated restoration time")}</strong></div>
                                <div className="mr-1 mb-2">{outageRestoration.getRestorationForPage(this.props.outageMarkerInfo.estimatedRestorationTime, t)}</div>
                                <div className="legend-title"><strong>{t("Estimated customers impacted")}</strong></div>
                                <div className="mr-1 mb-2">{this.props.outageMarkerInfo.customersImpacted}</div>
                                <div className="legend-title"><strong>{t("Reason")}</strong></div>
                                <div className="mr-1 mb-2">{isSpanish ? this.props.outageMarkerInfo.reasonEs : this.props.outageMarkerInfo.reason}</div>
                                <div className="legend-title"><strong>{t("Impacted area")}</strong></div>
                                <div className="mr-1 mb-2">{isSpanish ? this.props.outageMarkerInfo.impactedAreaEs : this.props.outageMarkerInfo.impactedArea}</div>
                                <div className="srp-modal-footer">
                                    <div className="btn srp-btn btn-blue" id="hidePublicDetailsOnOk" ref="hidePublicDetailsOnOk" onClick={() => this.closePublicOutageDetailModalOnClick()}>{t("OK")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="d-flex justify-content-end mt-4" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <button onClick={this.props.redirectToLogin} className="btn srp-btn btn-lightblue text-white mb-2">{t("View outage history")}</button>
                    <button onClick={() => this.refreshPublicOutageMap()} className="btn srp-btn btn-lightblue text-white ml-2 mb-2">{t("Refresh map")}</button>
                    <Link to="/myaccount/reportOutage" className="btn srp-btn btn-orange ml-2 mb-2">{t("Report outage")}</Link>
                </div>

                <OutageBanners t={t} i18n={i18n} heatReliefLink={this.props.iceReimbursementInfo.heatReliefLink} showHeatReliefLink={this.props.iceReimbursementInfo.showHeatReliefLink} />
            </div>)
        }
        else {
            renderedCard = <OutagePageError dataThatFailed={t("outage")} refreshData={this.props.refreshOutageMapCard} t={t} />
        }
        return renderedCard
    }
}

const mapStateToProps = state => {
    return { ...state.accountInfo.outage, ...state.accountInfo.billAccount, ...state.login }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        hideLegend: () => {
            dispatch(hideLegend())
        },
        showLegend: () => {
            dispatch(showLegend())
        },
        hideOutageDetailOnClick: () => {
            dispatch(hideOutageDetail())
        },
        showOutageDetailOnClick: (outageMarkerInfo) => {
            dispatch(showOutageDetail(outageMarkerInfo))
        },
        getPublicOutageInfo: () => {
            dispatch(getAllOutages())
        },
        updateAllOutagesInfo: () => {
            dispatch(updatePublicOutageActions())
        },
        redirectToLogin: () => {
            ownProps.router.navigate('/login', { replace: true, state: { from: ownProps.router.location } })
        }
    }
}

PublicOutageMap.propTypes = {
    mapScriptLoadedStatus: PropTypes.string,

    allOutagesInfo: PropTypes.arrayOf(PropTypes.object),
    allOutagesStatus: PropTypes.string,
    getPublicOutageInfo: PropTypes.func,
    updateAllOutagesInfo: PropTypes.func,

    legendStyle: PropTypes.object.isRequired,
    legendButtonStyle: PropTypes.object,
    hideLegend: PropTypes.func,
    showLegend: PropTypes.func,

    outageMarkerInfo: PropTypes.object,
    outageDetailStyle: PropTypes.object,
    hideOutageDetailOnClick: PropTypes.func,
    showOutageDetailOnClick: PropTypes.func,

    redirectToLogin: PropTypes.func,

    refreshOutageMapCard: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    iceReimbursementInfoStatus: PropTypes.string,
    iceReimbursementInfo: PropTypes.object,

    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicOutageMap))