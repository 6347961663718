import React from 'react'
import PropTypes from 'prop-types'
import { formConstants } from '../../../constants/form-constants'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { withTranslation } from 'react-i18next'
import config from 'my-account-config'

const SolarChoice = ({ selectSolarChoiceFunc, selectedSolarChoicePlan, isSolarChoiceDisabled, t, i18n }) => {
    const isSpanish = (i18n.language === "es")

    return (<div style={{ background: "#F1F1ED" }}>
        <div className="pt-3 px-3" style={{ color: "#737B4C" }}>
            <b>{t("solar_choice")}</b>
        </div>
        <div className="pt-3 px-3">
            {t("solar_choice_description")}
            {' '}
            <a target="_blank" href={isSpanish
                ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/programa-solar-choice`
                : `${config.srpnetBaseUrl}account/electric/manage-bill/solar-choice-program`}>
                {`${t("learn_more")} »`}
            </a>
        </div>
        <div className="pt-2 px-3">
            <div className="pt-3">{`${t("select_one")}: `}</div>

            <RadioGroup
                id="startSolarChoicePlanSelector"
                name="startSolarChoicePlanSelector"
                value={selectedSolarChoicePlan}
            >
                <FormControlLabel
                    name="start.SolarChoice100"
                    value="SolarChoice100"
                    control={
                        <Radio
                            color="primary"
                            onClick={() => selectSolarChoiceFunc(formConstants.SOLARCHOICE_100)}
                            disabled={isSolarChoiceDisabled}
                        />
                    }
                    style={{ marginBottom: '0' }}
                    label={<span className="checkmark-label-blue">{`${t("solar_choice_program")} (100%)`}</span>}
                />
                <FormControlLabel
                    name="start.SolarChoice50"
                    value="SolarChoice50"
                    control={
                        <Radio
                            color="primary"
                            onClick={() => selectSolarChoiceFunc(formConstants.SOLARCHOICE_50)}
                            disabled={isSolarChoiceDisabled}
                        />
                    }
                    style={{ marginBottom: '0' }}
                    label={<span className="checkmark-label-blue">{`${t("solar_choice_program")} (50%)`}</span>}
                />
            </RadioGroup>
        </div>
    </div>)
}

SolarChoice.propTypes = {
    selectSolarChoiceFunc: PropTypes.func,
    selectedSolarChoicePlan: PropTypes.string,
    isSolarChoiceDisabled: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('startService')(SolarChoice)