import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

import { withTranslation } from 'react-i18next'

import MyPrograms from './my-programs-card'
import ClosedAcctPrograms from './closed-acct-programs-card'
import SummaryMasterPrograms from './summary-master-programs'
import PricingPropgramsError from '../pricing-programs-error'

import { getEnrolledProgramsDetails, getCustomDueDateEligible } from '../../../actions/auth/payment/payment-actions'
import { getAutopayInfo } from '../../../actions/auth/payment/autopay-actions'
import { getCurrentBill, hashBillAccount } from '../../../actions/auth/bill_account/bill-account-actions'
import { getEnergyScoreCardLinks, getCommunitySolarLink } from '../../../actions/auth/document/document-center-actions'
import { getRateMetaData } from '../../../actions/auth/usage/rate-meta-data-actions'
import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { paymentConstants } from '../../../constants/payment-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "10%"
}

class MyProgramsContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showRemoveSafetyNetDialog: false
        }

        this.handleRemoveSafetyNetDialog = this.handleRemoveSafetyNetDialog.bind(this)
        this.refreshMyProgramsCard = this.refreshMyProgramsCard.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }

        if (this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getAutopayInfo(this.props.selectedBillAccount)
        }

        if (this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCurrentBillInfo(this.props.selectedBillAccount)
        }

        if (this.props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getEnergyScoreCard(this.props.selectedBillAccount)
        }

        if (this.props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCommunitySolar(this.props.selectedBillAccount)
        }

        if (this.props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomDueDateEligibility(this.props.selectedBillAccount)
        }

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getRateMetaData(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactsInfo(this.props.selectedBillAccount)
        }

        if (this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getHashedBillAccount(this.props.selectedBillAccount)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return
      
        if (this.props.hashBillAccountStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getHashedBillAccount(this.props.selectedBillAccount)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.enrolledProgramsDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnrolledProgramsDetails(nextProps.selectedBillAccount)
        }

        if (nextProps.autopayInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getAutopayInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.currentBillInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCurrentBillInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.energyScoreCardLinksResultStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnergyScoreCard(nextProps.selectedBillAccount)
        }

        if (nextProps.communitySolarLinkResultStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCommunitySolar(nextProps.selectedBillAccount)
        }

        if (nextProps.isCustomDueDateEligibleStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomDueDateEligibility(nextProps.selectedBillAccount)
        }

        if (nextProps.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getRateMetaData(nextProps.selectedBillAccount)
        }

        if (nextProps.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactsInfo(nextProps.selectedBillAccount)
        }
    }

    refreshMyProgramsCard() {
        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)

        if (this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getAutopayInfo(this.props.selectedBillAccount)

        if (this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getCurrentBillInfo(this.props.selectedBillAccount)

        if (this.props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getEnergyScoreCard(this.props.selectedBillAccount)

        if (this.props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getCommunitySolar(this.props.selectedBillAccount)

        if (this.props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getCustomDueDateEligibility(this.props.selectedBillAccount)

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getRateMetaData(this.props.selectedBillAccount)

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getContactsInfo(this.props.selectedBillAccount)

        if (this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getHashedBillAccount(this.props.selectedBillAccount)
    }

    handleRemoveSafetyNetDialog(openDialog) {
        this.setState({
            showRemoveSafetyNetDialog: openDialog
        })
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let renderedCard = {}
        let currentBillInfoAvailable = this.props.currentBillInfo !== null
        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_INIT || this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_INIT || this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_INIT || this.props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_INIT || this.props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_INIT || this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_INIT || this.props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            renderedCard =
                (<div className="srp-card-body">
                    <div className="srp-card-details">
                        <div className="d-flex justify-content-center">
                            <CircularProgress size={80} thickness={5} style={progressCirclestyle} />
                        </div>
                    </div>
                </div>
                )
        }
        else if (this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.currentBillInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE)) {

            let cddEligible = this.props.customDueDateInfo.isEligible
                && !this.props.selectedBillAccountDetails.isPrePay
                && (this.props.rateMetaData.summaryBilling !== rateMetaDataConstants.SUMMARY_BILLING_CHILD)

            let bbEligible = !this.props.selectedBillAccountDetails.isCommercial
                && !this.props.selectedBillAccountDetails.isPrePay
                && (this.props.rateMetaData.summaryBilling !== rateMetaDataConstants.SUMMARY_BILLING_CHILD)


            if (this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT) {
                renderedCard = (<SummaryMasterPrograms
                    programs={this.props.enrolledProgramsDetails}
                    numOfEnrolledPrograms={getNumOfEnrolledPrograms(this.props.enrolledProgramsDetails, this.props.communitySolarLink, this.props.energyScoreCardLinks, cddEligible, this.props.currentBillInfo)}
                    autopayInfo={this.props.autopayInfo}
                    budgetBillingAmount={this.props.currentBillInfo
                        ? this.props.currentBillInfo.budgetBillInstallmentAmount
                        : 0}
                    isBudgetBillingActive={currentBillInfoAvailable ?
                        this.props.currentBillInfo.paymentPlanCode === paymentConstants.PAYMENT_PLAN_CODE.BUDGET_ACTIVE
                        : false
                    }
                    t={t} i18n={i18n}
                    isCommercial={this.props.selectedBillAccountDetails.isCommercial} />)
            }
            else if (this.props.selectedBillAccountDetails.closedState === billAccountConstants.CLOSED_STATE.CLOSED) {
                renderedCard = (<ClosedAcctPrograms
                    programs={this.props.enrolledProgramsDetails}
                    hasCommunitySolar={this.props.communitySolarLink.url !== null}
                    hasEnergyScorecard={false && this.props.energyScoreCardLinks.length !== 0}
                    numOfEnrolledPrograms={getNumOfEnrolledPrograms(this.props.enrolledProgramsDetails, this.props.communitySolarLink, this.props.energyScoreCardLinks, cddEligible, this.props.currentBillInfo)}
                    autopayInfo={this.props.autopayInfo}
                    budgetBillingAmount={this.props.currentBillInfo
                        ? this.props.currentBillInfo.budgetBillInstallmentAmount
                        : 0}
                    isBudgetBillingActive={currentBillInfoAvailable ?
                        this.props.currentBillInfo.paymentPlanCode === paymentConstants.PAYMENT_PLAN_CODE.BUDGET_ACTIVE
                        : false
                    }
                    customDueDay={this.props.enrolledProgramsDetails.customDueDate.optionValue}
                    isCustomDueDateEligible={cddEligible}
                    sharePledge={this.props.enrolledProgramsDetails.share.optionValue}
                    t={t} i18n={i18n}
                    isCommercial={this.props.selectedBillAccountDetails.isCommercial} />)
            }
            else {
                renderedCard = (<MyPrograms
                    programs={this.props.enrolledProgramsDetails}
                    hasCommunitySolar={this.props.communitySolarLink.url !== null}
                    hasEnergyScorecard={false && this.props.energyScoreCardLinks.length !== 0}
                    numOfEnrolledPrograms={getNumOfEnrolledPrograms(this.props.enrolledProgramsDetails, this.props.communitySolarLink, this.props.energyScoreCardLinks, cddEligible, this.props.currentBillInfo)}
                    autopayInfo={this.props.autopayInfo}
                    budgetBillingAmount={this.props.currentBillInfo
                        ? this.props.currentBillInfo.budgetBillInstallmentAmount
                        : 0}
                    isBudgetBillingActive={currentBillInfoAvailable ?
                        this.props.currentBillInfo.paymentPlanCode === paymentConstants.PAYMENT_PLAN_CODE.BUDGET_ACTIVE
                        : false
                    }
                    isBudgetBillingEligible={bbEligible}
                    customDueDay={this.props.enrolledProgramsDetails.customDueDate.optionValue}
                    isCustomDueDateEligible={cddEligible}
                    isHomeEnergyProfileAvailable={!this.props.selectedBillAccountDetails.isCommercial}
                    hashedAccountForOpower={this.props.hashedBillAccount}
                    singleBillAccount={this.props.singleBillAccount}
                    solarChoicePercentage={this.props.enrolledProgramsDetails.solarChoice.optionValue}
                    sharePledge={this.props.enrolledProgramsDetails.share.optionValue}
                    isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                    customerNames={this.props.customerNames}
                    selectedBillAccountDetails={this.props.selectedBillAccountDetails}
                    handleRemoveSafetyNetDialog={this.handleRemoveSafetyNetDialog}
                    t={t} i18n={i18n}
                    showRemoveSafetyNetDialog={this.state.showRemoveSafetyNetDialog} />)
            }
        }
        else {
            renderedCard = <PricingPropgramsError errorMsgEnOrEs={t("We were unable to retrieve program data.")} refreshData={this.refreshMyProgramsCard} t={t} />
        }
        return renderedCard
    }
}

MyProgramsContainer.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    singleBillAccount: PropTypes.bool.isRequired,
    customerNames: PropTypes.object,
    serviceAddress: PropTypes.object,

    enrolledProgramsDetails: PropTypes.object,
    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetails: PropTypes.func,

    autopayInfo: PropTypes.object,
    autopayInfoStatus: PropTypes.string,
    getAutopayInfo: PropTypes.func,

    currentBillInfo: PropTypes.object,
    currentBillInfoStatus: PropTypes.string,
    getCurrentBillInfo: PropTypes.func,

    energyScoreCardLinks: PropTypes.array,
    energyScoreCardLinksResultStatus: PropTypes.string,
    getEnergyScoreCard: PropTypes.func,

    communitySolarLink: PropTypes.object,
    communitySolarLinkResultStatus: PropTypes.string,
    getCommunitySolar: PropTypes.func,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,
    getRateMetaData: PropTypes.func,

    getCustomDueDateEligibility: PropTypes.func,
    isCustomDueDateEligibleStatus: PropTypes.string,
    customDueDateInfo: PropTypes.object,

    getContactsInfo: PropTypes.func,
    contactsStatus: PropTypes.string,

    removeSafetyNetPartner: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    getHashedBillAccount: PropTypes.func.isRequired,
    hashedBillAccount: PropTypes.string.isRequired,
    hashBillAccountStatus: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.billAccount,
        singleBillAccount: (state.accountInfo.billAccount.billAccountList?.length ?? 0) == 1,
        ...state.accountInfo.payment,
        ...state.accountInfo.autopay,
        ...state.accountInfo.documentCenter,
        ...state.accountInfo.contact,
        ...state.budgetBilling,
        ...state.accountHistory,
        ...state.rateMetaData,

        hashedBillAccount: state.accountInfo.billAccount.hashedBillAccount,
        hashBillAccountStatus: state.accountInfo.billAccount.hashBillAccountStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEnrolledProgramsDetails: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        getAutopayInfo: (billAccount) => {
            dispatch(getAutopayInfo(billAccount))
        },
        getCurrentBillInfo: (billAccount) => {
            dispatch(getCurrentBill(billAccount))
        },
        getHashedBillAccount: (billAccount) => {
            dispatch(hashBillAccount(billAccount))
        },
        getEnergyScoreCard: (billAccount) => {
            dispatch(getEnergyScoreCardLinks(billAccount))
        },
        getCommunitySolar: (billAccount) => {
            dispatch(getCommunitySolarLink(billAccount))
        },
        getCustomDueDateEligibility: (billAccount) => {
            dispatch(getCustomDueDateEligible(billAccount))
        },
        getRateMetaData: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getContactsInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        }
    }
}

export default withTranslation('pricePlanAndPrograms')(connect(mapStateToProps, mapDispatchToProps)(MyProgramsContainer))

function getNumOfEnrolledPrograms(programs, communitySolar, energyScoreCard, isCustomDueDateEligible, currentBillInfo) {
    let numOfEnrolledPrograms = 0

    if (communitySolar.url !== null)
        numOfEnrolledPrograms++

    if (energyScoreCard.length !== 0) {
        numOfEnrolledPrograms++
        numOfEnrolledPrograms--
    }

    if (programs.customDueDate.isEnrolled && isCustomDueDateEligible)
        numOfEnrolledPrograms++

    if (programs.autopay.isEnrolled)
        numOfEnrolledPrograms++

    if (programs.budgetBilling.isEnrolled &&
        (currentBillInfo === null ? false
            : currentBillInfo.paymentPlanCode === paymentConstants.PAYMENT_PLAN_CODE.BUDGET_ACTIVE
        ))
        numOfEnrolledPrograms++

    if (programs.solarChoice.isEnrolled)
        numOfEnrolledPrograms++

    if (programs.share.isEnrolled)
        numOfEnrolledPrograms++

    if (programs.economicPricePlan.isEnrolled)
        numOfEnrolledPrograms++

    if (programs.healthyForest.isEnrolled)
        numOfEnrolledPrograms++

    if (programs.solarNonprofit.isEnrolled)
        numOfEnrolledPrograms++

    if (programs.safetyNet.isEnrolled)
        numOfEnrolledPrograms++

    if (programs.evCommunityCredits.isEnrolled)
        numOfEnrolledPrograms++

    return numOfEnrolledPrograms
}