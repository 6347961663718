import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import NavBarMenu from './navbar-menu'
import { getNavMenu } from '../../actions/guest/header-footer-actions'

let getNavMenuName = (myAccountType, isPrePay) => {
    return (isPrePay) ? 'power' : myAccountType
}

class NavBarMenuContainer extends React.Component {
    componentDidMount() {
        let navMenu = getNavMenuName(this.props.myAccountType, this.props.selectedBillAccountDetails.isPrePay)
        this.props.getNavMenu(navMenu, this.props.displayedLanguage)
    }

    componentDidUpdate(prevProps) {
        let currentNavMenu = getNavMenuName(prevProps.myAccountType, prevProps.selectedBillAccountDetails.isPrePay)
        let newNavMenu = getNavMenuName(this.props.myAccountType, this.props.selectedBillAccountDetails.isPrePay)

        if (currentNavMenu !== newNavMenu || prevProps.displayedLanguage !== this.props.displayedLanguage) {
            this.props.getNavMenu(newNavMenu, this.props.displayedLanguage)
        }
    }

    render() {
        return (
            <NavBarMenu i18n={this.props.i18n} navMenuHtml={this.props.navMenuHtml} preventLocalization={this.props.preventLocalization} t={this.props.t} />
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.billAccount,
        navMenuHtml: state.navigation.navMenuHtml,
        displayedLanguage: state.myAccount.displayedLanguage,
        preventLocalization: state.myAccount.preventLocalization
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getNavMenu: (menuType, language) => {
            dispatch(getNavMenu(menuType, language))
        }
    }
}

NavBarMenuContainer.propTypes = {
    displayedLanguage: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    getNavMenu: PropTypes.func.isRequired,
    myAccountType: PropTypes.string.isRequired,
    navMenuHtml: PropTypes.string.isRequired,
    preventLocalization: PropTypes.bool.isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    selectedBillAccountDetails: PropTypes.shape({
        isPrePay: PropTypes.bool
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarMenuContainer)