/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'

import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import TextField from '@mui/material/TextField'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { enUS, esES } from '@mui/x-date-pickers/locales'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { DateTime } from 'luxon'
import { CustomLuxonDateAdapter } from '../../../srp_modules/custom-luxon-date-adapter'
import format from '../../../srp_modules/format'
import utils from '../../../srp_modules/utils'
import Icon from '@mui/material/Icon'
import SrpTooltip from '../../common_tooltip/srp-tooltip'


let dateFieldProps = {
    minDate: DateTime.now().startOf('day'),
    maxDate: DateTime.now().startOf('day').plus({ days: 59 }),
    minDateMessage: "",
    maxDateMessage: "",
    invalidDateMessage: "",
    disablePast: true
}

function getSurePay2BusinessDaysWarning(paymentInfo, selectedInfo, t) {
    const paymentDate = getInitialPaymentDateValue(paymentInfo, selectedInfo)

    const paymentDateWithinTwoBusinessDaysOfSurePayRelease =
        DateTime.fromISO(paymentInfo.paymentInfo.twoBusinessDaysBeforeSurePayReleaseDate) <= DateTime.fromISO(paymentDate) &&
        DateTime.fromISO(paymentDate) <= DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)

    if (!paymentDateWithinTwoBusinessDaysOfSurePayRelease)
        return

    return (<SrpTooltip content={<span>{t("two_day_warning")}</span>}>
        <Icon style={{ color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
    </SrpTooltip>)
}

function getCashOnlyElement(t) {
    return (<SrpTooltip content={<span>{t("cash_only_text")}</span>}>
        <Icon style={{ color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
    </SrpTooltip>)
}

function getAutoPayElement(t) {
    return (<SrpTooltip content={<span>{t("surepay_three_payment_warning")}</span>}>
        <Icon style={{ color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
    </SrpTooltip>)
}

function getBankruptcyElement(t) {
    return (<SrpTooltip content={<span>{t("bankruptcy_warning")}</span>}>
        <Icon style={{ color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
    </SrpTooltip>)
}

function getDeletePaymentElement(deletePaymentClick, scheduledPayment = {}) {
    return (<Icon onClick={() => { deletePaymentClick(scheduledPayment) }} style={{ cursor: "pointer", color: "rgb(8, 126, 216)", verticalAlign: "middle", fontSize: "20px", marginBottom: "2px" }}>delete</Icon>)
}

function getStatusText(text, color) {
    return (
        <span style={{marginLeft: 'auto', marginRight: 'auto', color: color}}>{text}</span>
    )
}

function getPaymentStatus(selectedInfo, paymentInfo, deletePaymentClick, t, scheduledPayment = {}) {
    if (paymentInfo.isAdditionalPayment && paymentInfo.paymentInfo.isSurePay) {
        return <span>{t("One-time payment")}&nbsp;&nbsp;{getAutoPayElement(t)}{getSurePay2BusinessDaysWarning(paymentInfo, selectedInfo, t)}</span>
    }

    if (paymentInfo.isAdditionalPayment) {
        return t("One-time payment")
    }

    if (paymentInfo.paymentInfo.isSurePay) {
        return (
            getStatusText(t("SurePay"), 'rgb(115,123,76)')
        )
    }

    if (paymentInfo.isCashOnly) {
        return <span>{t("Cash only")}&nbsp;&nbsp;&nbsp;&nbsp;{getCashOnlyElement(t)}</span>
    }

    if (paymentInfo.paymentInfo.isBankrupt === true) {
        return <span>{t("Bankruptcy account")}&nbsp;&nbsp;&nbsp;&nbsp;{getBankruptcyElement(t)}</span>
    }

    if (paymentInfo.hasPendingPayment) {
        return (
            <span>
                {getStatusText(t("Scheduled"), 'rgb(115,123,76)')}&nbsp;&nbsp;
                {getDeletePaymentElement(deletePaymentClick, scheduledPayment)}
            </span>
        )
    }

    if (paymentInfo.paymentInfo.amountDue <= 0) {
        if (paymentInfo.paymentInfo.isClosedAccountWithZeroBalance) {
            return t("Closed account")
        }

        if (paymentInfo.hoursSinceLastPayment > myAccountConstants.SINGLE_DAY_HOURS) {
            return <span style={{ color: "rgb(115, 123, 76)" }}>{t("No payment due")}</span>
        } else {
            return <span style={{ color: "rgb(115, 123, 76)" }}>{t("Paid in full")}</span>
        }
    }

    // There's an amount due at this point.

    if (DateTime.fromISO(paymentInfo.paymentInfo.dueDate).toMillis() === DateTime.fromISO(myAccountConstants.MINIMUM_DATE).toMillis()) {
        // No due date but there's an amount due. This is possible
        // if the account is on MPower or it's write-off.
        return t("Payment due")
    }

    // There's a payment due date from this point on.

    if (paymentInfo.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
        return t("See bill")
    }

    if (paymentInfo.dueYesterday) {
        return getStatusText(t("Due yesterday"), '#9E2A2B')
    }

    if (paymentInfo.dueToday) {
        return getStatusText(t("Due today"), 'rgb(0,75,135)')
    }

    if (paymentInfo.dueTomorrow) {
        return getStatusText(t("Due tomorrow"), 'rgb(0,75,135)')
    }

    if (paymentInfo.paymentInfo.pastDue > 0) {
        let text = `${t("Past due")} (${DateTime.fromISO(paymentInfo.paymentInfo.dueDate).toFormat("M/d/yy")})`
        return getStatusText(text, '#9E2A2B')
    }

    return getStatusText(`${t("Due")} ${DateTime.fromISO(paymentInfo.paymentInfo.dueDate).toFormat("M/d/yy")}`, 'rgb(0,75,135)')
}

function getWarningStyle(errorLevel) {
    let color = "rgb(158,42,43)"

    if (errorLevel === myAccountConstants.ERROR_LEVEL.WARNING) {
        color = "rgb(204,138,0)"
    }

    return { color: color }
}

let getIsDueImmediately = paymentInfo => paymentInfo.paymentInfo.pastDue > 0

function getAmountDueDisplay(amountDue) {
    const displayAmountDue = Math.abs(amountDue).toLocaleString("en-US", { style: "currency", currency: "USD" })

    return amountDue >= 0 ?
        displayAmountDue :
        displayAmountDue + " CR"
}

function getAmountDue(paymentInfo) {
    if (paymentInfo.isAdditionalPayment)
        return "- -"

    let amountDue = paymentInfo.paymentInfo.amountDueWithShare

    const displayAmountDue = getAmountDueDisplay(amountDue)

    if (amountDue >= 0)
        return displayAmountDue

    return <span style={{ color: "green" }}>{displayAmountDue}</span>
}

function getDueImmediatelyWarning(paymentInfo, t) {
    if (getIsDueImmediately(paymentInfo)) {
        return (
            <SrpTooltip content={
                <span>
                    {`${t("Your amount due is")} $${paymentInfo.paymentInfo.amountDue.toFixed(2)}, ${t("of which")} $${paymentInfo.paymentInfo.pastDue.toFixed(2)} ${t("is due immediately")}`}
                </span>
            }>
                <Icon style={{ color: "rgb(158,42,43)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
            </SrpTooltip>
        )
    }

    return null
}

function getPaymentAmountErrorText(paymentAmountError, billAccount, cixClick, t) {
    const errorType = paymentAmountError.errorType
    let text = null
    switch (errorType) {
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.INVALID_AMOUNT:
            text = <span>{t("Invalid payment amount")}</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL:
            text = <span>{t("paying_less")}</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL_CIX_ELIGIBLE:
            text = (
                <span>
                    {t("Paying less than the total amount due may negatively affect your account, including disconnection.")} {t("Need")} <span className="myaccount-pointer" style={{ textDecoration: "underline" }} onClick={() => cixClick(billAccount)}>{t("more time to pay")}</span>? {t("We may be able to assist you.")}
                </span>
            )
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.MORE_THAN_TOTAL:
            text = <span>{t("You are making a payment that is more than the amount due.")}</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.PAYMENT_LIMIT:
            {
                let maxPaymentAmountDisplay = utils.delimitNumbers(paymentAmountError.paymentHardLimit)
                text = <span>{`${t("Payments of over")} ${maxPaymentAmountDisplay} ${t("are not allowed")}`}</span>
            }
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.REQUIRED:
            text = <span>{t("This field is required")}</span>
            break
    }

    return text
}

function getPaymentDateErrorText(errorType, billAccount, cixClick, t) {
    let text = null
    switch (errorType) {
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.REQUIRED:
            text = <span>{t("This field is required")}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.INVALID_DATE:
            text = <span>{t("Invalid payment date: Please enter a date in MM/DD/YY format")}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_CIX_ELIGIBLE:
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL_CIX_ELIGIBLE:
            text = (
                <span>
                    {t("pay_after_due_date")} {t("Need")} <span className="myaccount-pointer" style={{ textDecoration: "underline" }} onClick={() => cixClick(billAccount)}>{t("more time to pay")}</span>? {t("We may be able to assist you.")}
                </span>
            )
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE:
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL:
            text = <span>{t("pay_after_due_date")}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_BEFORE_TODAY:
            text = <span>{t("Payment date cannot be earlier than today's date")}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_MORE_THAN_60:
            text = <span>{t("You cannot schedule a payment more than 60 days in advance")}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.PAYMENT_ALREADY_SCHEDULED:
            text = <span>{t("already_scheduled_payment")}</span>
            break
    }

    return text
}

function getInitialPaymentDateValue(paymentInfo, selectedInfo) {
    let initialDate = DateTime.now().startOf('day').toISODate()
    let surePayDate = DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)
    let minimumDate = DateTime.fromISO(myAccountConstants.MINIMUM_DATE)

    if (paymentInfo.hasPendingPayment && !paymentInfo.isAdditionalPayment) {
        if (paymentInfo.paymentInfo.isSurePay) {
            if (surePayDate.toMillis() !== minimumDate.toMillis()) {
                initialDate = paymentInfo.paymentInfo.surePayDate
            }
        } else {
            initialDate = paymentInfo.scheduledPayments[0].paymentDate
        }
    } else if (selectedInfo.isSelected) {
        initialDate = selectedInfo.paymentDate
    }

    return initialDate
}

function getInitialPaymentValue(paymentInfo, selectedInfo) {
    let initialPayment = ''
    let surePayDate = DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)
    let minimumDate = DateTime.fromISO(myAccountConstants.MINIMUM_DATE)

    if (paymentInfo.hasPendingPayment && !paymentInfo.isAdditionalPayment) {
        if (paymentInfo.paymentInfo.isSurePay && surePayDate !== minimumDate) {
            initialPayment = paymentInfo.paymentInfo.amountDueWithShare.toFixed(2)
        } else {
            initialPayment = paymentInfo.scheduledPayments[0].paymentAmount.toFixed(2)
        }
    } else if (paymentInfo.paymentInfo.isSurePay && !paymentInfo.isAdditionalPayment) {
        // do nothing. If there is no SurePay payment scheduled, leave blank
    } else if (selectedInfo.isSelected) {
        initialPayment = selectedInfo.paymentAmount
    }

    return initialPayment
}

function getRowCheckbox(billAccount, paymentInfo, disabled, isSelected, selectableCount, totalSelected, getSelectedPayableAccountsOnClick, t) {
    if (selectableCount < multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS || disabled) {
        return (
            <Checkbox
                style={{marginLeft: '10px'}}
                onChange={() => getSelectedPayableAccountsOnClick(billAccount, paymentInfo)}
                disabled={disabled}
                color="primary"
                checked={isSelected}
            />
    )} else {
        let tooltipText = totalSelected > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS ? t("Payable account limit exceeded") : `${totalSelected}/${multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS} ${t("Selected")}`

        return (
            <SrpTooltip content={<span>{tooltipText}</span>}>
                <Checkbox
                    style={{marginLeft: '10px'}}
                    onChange={() => getSelectedPayableAccountsOnClick(billAccount, paymentInfo)}
                    disabled={disabled}
                    color="primary"
                    checked={isSelected}
                />
            </SrpTooltip>
        )
    }
}

function getSummaryMasterIcon(summaryBillingStatus, t) {
    if (summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
        return (
            <span className="text-nowrap summary-account-parent-chip" style={{paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px'}}>
                <span style={{marginLeft: 'auto', marginRight: 'auto'}}>{t("Master")}</span>
            </span>
        )
    } else {
        return null
    }
}

function getRowTitle(paymentInfo, viewBillClick, t) {
    if (paymentInfo.accountName && paymentInfo.accountName.trim() !== '') {
        return (
            <div style={{fontSize: '18px', fontWeight: '800'}}>
                <span>
                    <button className="displayAsLink text-truncate" style={{maxWidth: '50%'}} type="button" onClick={() => viewBillClick(paymentInfo.billAccount)}>
                        {paymentInfo.accountName}
                    </button>
                </span>
                &nbsp;
                <span style={{color: '#333333', fontWeight: '500'}}>
                    <PatternFormat
                        value={format.srpAccountNumber(paymentInfo.billAccount)}
                        valueIsNumericString={true}
                        format="###-###-###"
                        displayType="text"
                    />
                </span>
                &nbsp;
                {getSummaryMasterIcon(paymentInfo.summaryBillingStatus, t)}
            </div>
        )
    } else {
        return (
            <div>
                <button className="displayAsLink" style={{fontSize: '18px', fontWeight: '800'}} type="button" onClick={() => viewBillClick(paymentInfo.billAccount)}>
                    <PatternFormat
                        value={format.srpAccountNumber(paymentInfo.billAccount)}
                        valueIsNumericString={true}
                        format="###-###-###"
                        displayType="text"
                    />
                </button>
                &nbsp;
                {getSummaryMasterIcon(paymentInfo.summaryBillingStatus, t)}
            </div>
        )
    }
}

const PayableAccountRow = (props) => {
    let t = props.t
    let selectedInfo = props.selectedInfo
    let disabled = props.disabled
    let isSelected = disabled ? false : selectedInfo.isSelected
    let scheduledPayment = props.paymentInfo.scheduledPayments.length > 0 ? props.paymentInfo.scheduledPayments[0] : {}
    const scheduledRow = props.paymentInfo.isAdditionalPayment===false && props.paymentInfo.hasPendingPayment

    let tdClassName = props.displayBorder ? '' : 'pat-tr-disabled'
    let paymentInputId = "paymentAmount" + props.index + (props.paymentInfo.isAdditionalPayment ? "ap" : "")

    const i18n = props.i18n
    const isSpanish = (i18n.language === "es")
    const locale = isSpanish ? "es-MX" : "en-US"
    const localeText = (isSpanish ? esES : enUS).components.MuiLocalizationProvider.defaultProps.localeText

    return (
        <TableRow
            className={disabled ? "pat-payment-row-disabled" : "pat-payment-row"}
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            selected={isSelected}
            style={{height: '70px'}}
        >
            <TableCell padding="none" className={tdClassName}>
                {(!scheduledRow || props.paymentInfo.paymentInfo.isSurePay) && getRowCheckbox(props.paymentInfo.billAccount, props.paymentInfo.paymentInfo, disabled, isSelected, props.selectableCount, props.paymentListLength, props.getSelectedPayableAccountsOnClick, t)
                }
            </TableCell>
            <TableCell padding="none" className={tdClassName} {...scheduledRow ? {style: { borderBottomWidth: 0, color: '#087ED8' }} : {style: { color: '#087ED8' }}}>
                {props.paymentInfo.isAdditionalPayment===false && getRowTitle(props.paymentInfo, props.viewBillClick, t)}

                <div style={{color: '#333333', fontSize: '15px', fontWeight:'500'}}>
                    {props.paymentInfo.isAdditionalPayment===false && props.paymentInfo.serviceAddress.streetAddress}
                </div>
            </TableCell>
            <TableCell padding="none" className={tdClassName}>{getPaymentStatus(selectedInfo, props.paymentInfo, props.deletePaymentClick, t, scheduledPayment)}</TableCell>
            <TableCell padding="none" className={tdClassName} style={{ textAlign: "right" }}>{getAmountDue(props.paymentInfo)}</TableCell>
            <TableCell padding="none" className={tdClassName} style={{ paddingLeft: "4px" }}>{getDueImmediatelyWarning(props.paymentInfo, t)}</TableCell>
            <TableCell padding="none" className={tdClassName}>
                <div>
                    {!props.paymentInfo.isCashOnly &&
                        <NumericFormat
                            id={paymentInputId}
                            placeholder={t("Enter amount")}
                            disabled={disabled}
                            value={getInitialPaymentValue(props.paymentInfo, selectedInfo)}
                            valueIsNumericString={true}
                            onBlur={(event) => {
                                const paymentAmount = event.target.value
                                if (paymentAmount === "") {
                                    if (selectedInfo.isSelected)
                                        props.getSelectedPayableAccountsOnClick(props.paymentInfo.billAccount, props.paymentInfo.paymentInfo)
                                }
                                else {
                                    let fixedPaymentAmount = Number(paymentAmount.replace(/[$,]/g, '')).toFixed(2)
                                    props.getPaymentAmountOnChange(props.paymentInfo.billAccount, fixedPaymentAmount)
                                }
                            }}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                            fixedDecimalScale={false}
                            allowNegative={false}
                            customInput={TextField}
                            type="text"
                            InputProps={{
                                inputProps: {
                                    inputMode: 'decimal',
                                    style: {
                                        textAlign: "right"
                                    }
                                }
                            }}
                            style={{ float: "right" }}
                            className="pat-input-box"
                        />
                    }
                </div>
            </TableCell>
            <TableCell padding="none" className={tdClassName} sx={{ paddingLeft: '4px'}}>
                {isSelected && (selectedInfo.paymentAmountError.hasError) ?
                    props.datePickerDisabled 
                    && selectedInfo.paymentAmountError.errorType === (multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL 
                    || multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL_CIX_ELIGIBLE) 
                        ?
                        null : 
                        <SrpTooltip
                            content={<span>{getPaymentAmountErrorText(selectedInfo.paymentAmountError, props.paymentInfo.billAccount, props.cixClick, t)}</span>}>
                            <Icon style={{ ...getWarningStyle(selectedInfo.paymentAmountError.errorLevel), verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                        </SrpTooltip>
                    :null
                }
            </TableCell>
            <TableCell padding="none" className={tdClassName}>
                {!props.paymentInfo.isCashOnly &&
                    <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale={locale} localeText={localeText}>
                        <DatePicker
                            {...dateFieldProps}
                            keyboard
                            clearable
                            disableOpenOnEnter
                            disablePast
                            disabled={disabled || props.paymentInfo.isAdditionalPayment || props.datePickerDisabled}
                            className="multi-payment-date-picker pat-input-box"
                            value={DateTime.fromISO(getInitialPaymentDateValue(props.paymentInfo, selectedInfo))}
                            format="M/d/yyyy"
                            onChange={(selectedDate) => props.getPaymentDateOnChange(props.paymentInfo.billAccount, selectedDate.toISODate(), selectedInfo.paymentAmount)}
                            slotProps={{ textField: { fullWidth: true } }}
                            views={['day']}
                        />
                    </LocalizationProvider>
                }
            </TableCell>
            <TableCell padding="none" className={tdClassName} sx={{ paddingLeft: '4px'}}>
                {props.datePickerDisabled?
                    <div>
                        <SrpTooltip
                            content={<span>Future date payments not allowed. Please call to make a payment. </span>}>
                            <Icon style={{ color: "rgb(158,42,43)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                        </SrpTooltip>
                    </div>
                : isSelected && (selectedInfo.paymentDateError.hasError) ?
                    <div>
                        <SrpTooltip
                            content={<span>{getPaymentDateErrorText(selectedInfo.paymentDateError.errorType, props.paymentInfo.billAccount, props.cixClick, t)}</span>}>
                            <Icon style={{ ...getWarningStyle(selectedInfo.paymentDateError.errorLevel), verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                        </SrpTooltip>
                    </div> : null
                }
            </TableCell>
        </TableRow>
    )
}

PayableAccountRow.propTypes = {
    displayBorder: PropTypes.bool.isRequired,
    paymentInfo: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    selectedInfo: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
    paymentListLength: PropTypes.number.isRequired,
    selectableCount: PropTypes.number.isRequired,
    getSelectedPayableAccountsOnClick: PropTypes.func.isRequired,
    getPaymentAmountOnChange: PropTypes.func.isRequired,
    getPaymentDateOnChange: PropTypes.func.isRequired,
    viewBillClick: PropTypes.func.isRequired,
    deletePaymentClick: PropTypes.func.isRequired,
    cixClick: PropTypes.func.isRequired,
    datePickerDisabled: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(PayableAccountRow)