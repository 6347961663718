import React from "react"
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import Icon from '@mui/material/Icon'
import format from '../../srp_modules/format'

class MakeCreditCardPaymentLink extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMpowerPurchaseAckModalOpen: false,
            displaySafetyStatement: false,
            reconnectModalDisplay: false,
        }

        this.mpowerPurchaseAckModalOnClick = this.mpowerPurchaseAckModalOnClick.bind(this)
        this.toggleSafetyStatement = this.toggleSafetyStatement.bind(this)
    }

    mpowerPurchaseAckModalOnClick(isOpen) {
        this.setState({
            isMpowerPurchaseAckModalOpen: isOpen
        })
    }

    toggleSafetyStatement(display) {
        this.setState({
            displaySafetyStatement: display
        })
    }

    render() {
        const disableLink = (this.props.creditCardPaymentUrl === '' || this.props.creditCardPaymentUrl === "#")
        const target = disableLink ? "_self" : "_blank"
        
        let billAccountList = this.props.billAccountList
        let disconnectedAccounts = billAccountList ? billAccountList.filter((billAccount) => { return billAccount.billingStatusCode === "19" }) : []

        return (
           
            <div>
                {this.props.hasMpower
                    ? <button
                        onClick={() => {
                            this.mpowerPurchaseAckModalOnClick(true)
                        }}
                        disabled={disableLink}
                        className="displayAsLink">
                        {this.props.t("Make a credit/debit card payment (additional fee)")}
                    </button>
                    : disconnectedAccounts.length > 0 ? 
                        <button
                            onClick={() => {
                                this.setState({reconnectModalDisplay: true})
                            }}
                            className="displayAsLink">
                            {this.props.t("Make a credit/debit card payment (additional fee)")}
                        </button>
                        :
                        <a href={this.props.creditCardPaymentUrl} target={target} disabled={disableLink}>{this.props.t("Make a credit/debit card payment (additional fee)")}</a>
                }

                <Dialog
                    open={this.state.reconnectModalDisplay}
                    sx={{
                        ".MuiPaper-root" : {
                            maxWidth : {
                                xs : '420px',
                                lg : '680px'
                            },
                            padding: '20px'
                        }
                    }}
                >
                    <div className="mx-2 mb-2">
                        <h4><b>Disconnected</b></h4>
                    </div>
                    <div className="mx-2 mb-2 srp-alert-warn">
                        <div className="mb-2"><b>PLEASE CALL TO DISCUSS PAYMENT.</b> The following accounts have been disconnected due to non-payment:</div>
                        <ul className="mb-0">
                            {disconnectedAccounts.map((account, i)=>{
                                let accountNumber = format.srpAccountNumber(account.account.toString())
                                let accountNumberFormatted = format.formatBillAccountNumber(accountNumber)
                                if(account.accountName.length !== 0){
                                    return(<li key={i}>{account.accountName} ({accountNumberFormatted})</li>)
                                }else{
                                    return(<li key={i}>{accountNumberFormatted}</li>)
                                }
                            })}
                        </ul>
                    </div>
                    <div className="d-flex justify-content-end mx-2 my-2">
                        <button
                            className="btn srp-btn btn-blue"
                            onClick={()=>{
                                this.setState({reconnectModalDisplay: false})
                            }}>
                                Dismiss
                        </button>
                    </div>
                </Dialog>
                
                <Dialog
                    modal="true"
                    open={this.state.isMpowerPurchaseAckModalOpen}
                    PaperProps={{ className: "srp-modal" }}
                >
                    <div className="srp-modal-body" id="purchaseAckModal">
                        <div className="srp-modal-close" onClick={() => this.mpowerPurchaseAckModalOnClick(false)} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{this.props.t("Proceed with Credit/Debit Purchase?")}</h4>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        {this.props.t("mpower_warning")}
                                        <button
                                            className="displayAsLink font-weight-bold"
                                            onClick={() => this.toggleSafetyStatement(!this.state.displaySafetyStatement)}>
                                            <span className="d-flex align-items-center">
                                                {this.props.t("safety statement.")}
                                                <Icon>
                                                    {this.state.displaySafetyStatement ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                </Icon>
                                            </span>
                                        </button>
                                    </div>
                                    {this.state.displaySafetyStatement
                                        ? <div className="mt-2">
                                            {this.props.t("safety_statement_text")}
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="srp-modal-footer mb-0 pt-3">
                                <button className="btn srp-btn btn-lightblue mr-2" onClick={() => this.mpowerPurchaseAckModalOnClick(false)}>{this.props.t("Cancel")}</button>
                                <a
                                    href={this.props.creditCardPaymentUrl}
                                    onClick={() => this.mpowerPurchaseAckModalOnClick(false)}
                                    className="btn srp-btn btn-blue"
                                    target={target}>
                                    OK
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}

MakeCreditCardPaymentLink.propTypes = {
    creditCardPaymentUrl: PropTypes.string,
    hasMpower: PropTypes.bool,
    multiPayment: PropTypes.bool,
    billAccountList: PropTypes.arrayOf(PropTypes.object),
    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}

export default withTranslation("makeACreditCardPaymentLink")(MakeCreditCardPaymentLink)
