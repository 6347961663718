import React from "react"
import { Link } from 'react-router-dom'
import config from 'my-account-config'
import { useTranslation } from 'react-i18next'

const PageNotFound = () => {
    const { i18n } = useTranslation()
    const isSpanish = i18n.language==='es'

    return (
        <div>
            <div className="container py-3">
                <h3 className="mt-3">Page not found</h3>

                <div className="mt-4">
                    <Link to="/login"> &lt; Back to login page</Link>
                </div>

                <div className="mt-4">
                    <div className="srp-card-header">
                        We're sorry!
                    </div>

                    <div className="row">
                    <div className="col-lg-7 col-12">
                    <div className="srp-card-body">
                        <div className="srp-card-details">
                            <div className="text-muted">
                                <div className="d-inline-block font-weight-bold mr-1">Requested page:</div>
                                <div className="word-break">{`${window.location.href}`}</div>
                            </div>

                            <div className="text-muted mt-2" >
                                The page you requested cannot be found. Please try using the site navigation or
                                our <a href={`${config.srpnetBaseUrl}search`}>site search</a> to find what you need.
                            </div>

                            <div className="text-muted mt-2" >
                                {'If you still need help, please '}
                                <a href={isSpanish
                                    ? `${config.srpnetBaseUrlEs}servicio-al-cliente/contactanos`
                                    : `${config.srpnetBaseUrl}customer-service/contact-us`}>contact technical support</a>.
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;

