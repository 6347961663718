import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'
import { PatternFormat } from 'react-number-format'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'

import CallAnytime from '../../myaccount_header/call-anytime'
import validate from '../../../srp_modules/validate'
import PasswordValidation from "../password-validation"
import { myAccountConstants } from '../../../constants/myaccount-constants'

const AccountSetupPage = ({ registerNewLoginOnClick, newAccountDetails, t }) => {
    const validateForm = (values) => {
        let errors = {}
    
        if (values.username === undefined || values.username.trim().length === 0) {
            errors.username = "Email address is required"
        }
        else if (validate.emailAddress(values.username) !== true) {
            errors.username = "You must enter a valid email address"
        }
    
        if (values.password === undefined || values.password.trim().length === 0) {
            errors.password = "Password is required"
        }
        else if (validate.password(values.password) !== true) {
            errors.password = ''
        }
    
        if ((values.confirmPassword === undefined || values.confirmPassword.trim().length === 0)) {
            errors.confirmPassword = "Password confirmation is required"
        }
        else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Password does not match"
        }
    
        if (values.accountNumber === undefined || values.accountNumber.trim().length === 0) {
            errors.accountNumber = "SRP account number is required"
        }
    
        if (values.phoneNumber === undefined || values.phoneNumber.trim().length === 0) {
            errors.phoneNumber = "Phone number is required"
        }
        else if (values.phoneNumber.length !== 10) {
            errors.phoneNumber = "You must enter a valid phone number"
        }
    
        return errors
    }

    const phoneTextField = (props) => (
        <TextField {...props} InputProps={{endAdornment:(
            <Tooltip title={t("phone_associated_to_srp_account")} arrow placement="top" sx={{cursor: 'default'}}>
                <Icon style={{ color: 'rgb(33,108,224)' }}>help</Icon>
            </Tooltip>
        )}} />
    )

    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="mt-3">{t("Sign up for My Account")}</h3>
                <h5 className="text-muted">{t("join_today")}</h5>

                <div className="row">
                    <div className="col-lg-6 col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Enter sign up information")}
                            </div>

                        <div className="srp-card-body ">
                            <div className="srp-card-details">
                                <Formik
                                    initialValues={{
                                        username: newAccountDetails.email,
                                        password: '',
                                        confirmPassword: '',
                                        accountNumber: newAccountDetails.accountNumber,
                                        phoneNumber: '',
                                        showPassword: false,
                                        emailExists: false,
                                        invalidAccount: false
                                    }}
                                    validate={validateForm}
                                    onSubmit={registerNewLoginOnClick}>
                                    {({ values, errors, status, touched, dirty, handleChange, handleBlur, handleSubmit, setFieldValue, setValues, submitCount, isSubmitting }) => {

                                        let isEmailError = ((dirty || submitCount > 0) && touched.username === true && errors.username !== undefined) || errors.emailExists
                                        let isPasswordError = (dirty || submitCount > 0) && touched.password === true && errors.password !== undefined
                                        let isConfirmPasswordError = (dirty || submitCount > 0) && touched.confirmPassword === true && errors.confirmPassword !== undefined
                                        let isAccountError = (dirty || submitCount > 0) && touched.accountNumber === true && errors.accountNumber !== undefined
                                        let isPhoneError = (dirty || submitCount > 0) && touched.phoneNumber === true && errors.phoneNumber !== undefined

                                        let submitStatus = status || {} // status is undefined unless explicitly set through setStatus

                                        return (
                                            <form method="POST" onSubmit={handleSubmit}>
                                                {submitStatus.generalError ? (
                                                    <div className="srp-alert-error mb-2">
                                                        {!submitStatus.errorMsg ? t("error_call_customer_support") : submitStatus.errorMsg}
                                                    </div>
                                                ) : null}
                                                {submitStatus.emailExists ? (
                                                    <div className="srp-alert-error mb-2">
                                                        {t("Your email address is already registered.")} {t("Please")} <Link to="/login">{t("log in")}</Link> {t("or")} <Link to="/passwordReset">{t("reset your password")}</Link>.
                                                        </div>
                                                ) : null}
                                                {submitStatus.invalidAccount ? (
                                                    <div className="srp-alert-error mb-2">
                                                        {t("account_phone_combo_does_not_match")}
                                                            {` ${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}.`}
                                                    </div>
                                                ) : null}

                                                <TextField
                                                    fullWidth={true}
                                                    id="username"
                                                    name="username"
                                                    label={t("Email address")}
                                                    value={values.username}
                                                    error={isEmailError}
                                                    helperText={isEmailError ? t(errors.username) : ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />

                                                <PasswordValidation
                                                    togglePasswordVisibilityOnClick={() => setValues({ ...values, showPassword: !values.showPassword })}
                                                    showPassword={values.showPassword}
                                                    password={values.password}
                                                    passwordError={isPasswordError}
                                                    passwordErrorText={isPasswordError ? t(errors.password) : ''}
                                                    passwordOnChange={handleChange}
                                                    passwordOnBlur={handleBlur}
                                                    confirmPassword={values.confirmPassword}
                                                    confirmPasswordError={isConfirmPasswordError}
                                                    confirmPasswordErrorText={isConfirmPasswordError ? t(errors.confirmPassword) : ''}
                                                    confirmPasswordOnChange={handleChange}
                                                    confirmPasswordOnBlur={handleBlur} />

                                                <PatternFormat
                                                    id="accountNumber"
                                                    name="accountNumber"
                                                    label={t("SRP account number")}
                                                    value={values.accountNumber}
                                                    valueIsNumericString={true}
                                                    format="#########"
                                                    type="tel"
                                                    customInput={TextField}
                                                    fullWidth={true}
                                                    onValueChange={v => setFieldValue('accountNumber', v.value)}
                                                    onBlur={handleBlur}
                                                    error={isAccountError}
                                                    helperText={isAccountError ? t(errors.accountNumber) : ''} />

                                                <PatternFormat
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    label={t("Phone Number")}
                                                    value={values.phoneNumber}
                                                    valueIsNumericString={true}
                                                    format="(###) ###-####"
                                                    type="tel"
                                                    customInput={phoneTextField}
                                                    fullWidth
                                                    onValueChange={v => setFieldValue('phoneNumber', v.value)}
                                                    onBlur={handleBlur}
                                                    error={isPhoneError}
                                                    helperText={isPhoneError ? t(errors.phoneNumber) : ''}
                                                />

                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type="submit" disabled={isSubmitting} className="btn srp-btn btn-green">
                                                        {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} aria-label="creating login" alt="progress icon" /> : t("Sign up")}
                                                    </button>
                                                </div>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Reasons to love My Account")}
                        </div>

                        <div className="srp-card-body" style={{ backgroundColor: "#eff3f7" }}>
                            <div className="srp-card-details">
                                <ul className="mt-2 login-list">
                                    <li><span className="login-list">{t("Online_account_access_free_24_7")}</span></li>
                                    <li><span className="login-list">{t("View or pay your bill")}</span></li>
                                    <li><span className="login-list">{t("Customize notifications and payment options")}</span></li>
                                    <li><span className="login-list">{t("Access billing & usage history")}</span></li>
                                    <li><span className="login-list">{t("Helpful_calcs_and_energy_saving_tips")}</span></li>
                                    <li><span className="login-list">{t("Set_reminders_and_alerts")}</span></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

AccountSetupPage.propTypes = {
    registerNewLoginOnClick: PropTypes.func.isRequired,
    newAccountDetails: PropTypes.object,
    t: PropTypes.func
}

export default withTranslation('accountSignUp')(AccountSetupPage)