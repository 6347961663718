/* eslint react/no-multi-comp: "off" */

import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import { Button, Menu, MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { NumericFormat, PatternFormat } from 'react-number-format'
import format from '../../../srp_modules/format'
import utils from '../../../srp_modules/utils'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Icon from '@mui/material/Icon'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

import MultiPaymentAccountTypeSelector from '../multi-payment-account-type-selector'
import PayableAccountRow from './payable-account-row'

import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'


function getFullStreetAddress(address) {
    let unitNum = ""
    if (address.unitNumber) {
        unitNum = "#" + address.unitNumber
    }

    let fullStreetAddress = address.houseNumber + " " + address.addressDirection + " " + address.streetName + " " + unitNum
    return fullStreetAddress
}

function getAmountDue(paymentInfo) {
    let amountDue = paymentInfo.paymentInfo.amountDue
    if (amountDue < 0) amountDue = 0
    return Math.abs(amountDue).toLocaleString("en-US", { style: "currency", currency: "USD" })
}

function getTotalPurchase(purchaseList) {
    let totalPurchase = 0
    purchaseList.forEach(function (item) {
        let purchaseAmountInput = item.purchaseAmount
        if (typeof purchaseAmountInput === 'string' || purchaseAmountInput instanceof String)
            purchaseAmountInput = purchaseAmountInput.trim().replace(/[$,]/g, '')
        const purchaseAmount = Number(purchaseAmountInput)

        if (!isNaN(purchaseAmount)) {
            totalPurchase += purchaseAmount
        }
    })

    return totalPurchase.toFixed(2)
}

function getCombinedWarningStyle(paymentAmountErrorLevel) {
    let color = "rgb(204,138,0)"

    if (paymentAmountErrorLevel === myAccountConstants.ERROR_LEVEL.ERROR) {
        color = "rgb(158,42,43)"
    }

    return { color: color }
}

function getSelectedInfo(billAccount, purchaseList) {
    let selectedInfo = { isSelected: false }
    for (let i = 0; i < purchaseList.length; i++) {
        if (purchaseList[i].billAccount === billAccount) {
            selectedInfo = {
                ...purchaseList[i],
                isSelected: true
            }
        }
    }

    return selectedInfo
}

function getInitialPurchaseValue(selectedInfo) {
    let initialPurchase = ''

    if (selectedInfo.isSelected) {
        initialPurchase = selectedInfo.purchaseAmount
    }

    return initialPurchase
}

function getInitialPayAmountOwedToSRPValue(selectedInfo) {
    let initialValue = false

    if (selectedInfo.isSelected) {
        initialValue = selectedInfo.payAmountOwedToSRP
    }

    return initialValue
}

function getDisabled(paymentInfo, isSelected, purchaseListCount) {
    if (!isSelected && purchaseListCount >= multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS) {
        return true
    } else if (paymentInfo.isAdditionalPayment) {
        return false
    } else return (paymentInfo.disabled)

}

function getPurchaseBreakdownDisplay(selectedInfo, paymentInfo) {
    let breakdown = { amountToMeter: '- -', amountToSRP: '- -', payDownPercent: '' }

    if (!selectedInfo.isSelected || selectedInfo.purchaseAmount === '')
        return breakdown
    if (typeof paymentInfo === 'undefined')
        return breakdown

    const purchaseAmount = Number(selectedInfo.purchaseAmount)
    const applyWholePurchaseTowardsArrears = selectedInfo.payAmountOwedToSRP
    const amountOwedToSRP = paymentInfo.paymentInfo.amountDue <= 0 ? 0 : paymentInfo.paymentInfo.amountDue
    const payDownFraction = paymentInfo.paymentInfo.payDownFraction

    let amountToSRP = applyWholePurchaseTowardsArrears ? purchaseAmount : (Math.round(purchaseAmount * payDownFraction * 100) / 100)
    amountToSRP = Math.min(amountOwedToSRP, amountToSRP)
    const amountToMeter = purchaseAmount - amountToSRP

    if (!isNaN(amountToMeter))
        breakdown.amountToMeter = amountToMeter.toLocaleString("en-US", { style: "currency", currency: "USD" })

    if (!isNaN(amountToSRP)) {
        breakdown.amountToSRP = amountToSRP.toLocaleString("en-US", { style: "currency", currency: "USD" })
        if (amountOwedToSRP > 0 && applyWholePurchaseTowardsArrears === false) {
            const payDownPercent = Math.trunc(payDownFraction * 100)
            breakdown.payDownPercent = '(' + payDownPercent + '%)'
        }
    }

    return breakdown
}

const someAccountsInArrears = paymentInfoList => paymentInfoList.some(e => e.paymentInfo.amountDue > 0)

class PayableAccountsTablePure extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            menuAnchor: null,
            limitReachedTimerID: 0,
            isTextFiltering: false,
            allowTextFiltering: false
        }

        this.openMenu = this.openMenu.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleCheckChanged = this.handleCheckChanged.bind(this)
        this.showPayableAccountLimitReached = this.showPayableAccountLimitReached.bind(this)
        this.toggleTextFiltering = this.toggleTextFiltering.bind(this)
        this.handleFilterKeysUp = this.handleFilterKeysUp.bind(this)
        this.getCashOnlyElement = this.getCashOnlyElement.bind(this)
        this.getCombinedErrorText = this.getCombinedErrorText.bind(this)
        this.getPaymentAmountErrorText = this.getPaymentAmountErrorText.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.showGeneralWarning !== this.props.showGeneralWarning && nextProps.showGeneralWarning) ||
            (nextProps.showMaxPaymentWarning !== this.props.showMaxPaymentWarning && nextProps.showMaxPaymentWarning) ||
            (nextProps.errorVerifyingPurchases !== this.props.errorVerifyingPurchases && nextProps.errorVerifyingPurchases)) {
            document.getElementById("payable-accounts-table-header").scrollIntoView({ behavior: 'smooth' })
        }
    }

    getCashOnlyElement() {
        return (<SrpTooltip content={<span>{this.props.t("account_status_cashier_check_money_order")}</span>}>
            <Icon style={{ color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
        </SrpTooltip>)
    }


    getPaymentAmountErrorText(paymentAmountError) {
        const errorType = paymentAmountError.errorType
        let text = ""
        switch (errorType) {
            case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.INVALID_AMOUNT:
                text = this.props.t("Invalid payment amount")
                break
            case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.PAYMENT_LIMIT:
                {
                    let maxPaymentAmountDisplay = utils.delimitNumbers(paymentAmountError.paymentHardLimit)
                    text = this.props.t("Payments_of_over", { amount: maxPaymentAmountDisplay })
                }
                break
            case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.REQUIRED:
                text = this.props.t("This field is required")
                break
        }

        return text
    }

    getCombinedErrorText(paymentAmountError) {
        let paymentAmountErrorText = this.getPaymentAmountErrorText(paymentAmountError)

        let combinedError = ""

        if (paymentAmountErrorText) {
            combinedError += paymentAmountErrorText
        }

        return combinedError
    }

    openMenu(event) {
        this.setState({ ...this.state, menuAnchor: event.currentTarget })
    }

    handleClose() {
        this.setState({ ...this.state, menuAnchor: null })
    }

    handleChange(value) {
        return () => {
            this.handleClose()

            switch (value) {
                case 'all':
                    return this.props.selectAllAccountsOnClick()
                case 'first100':
                    return this.props.selectFirst100AccountsOnClick()
                case 'none':
                    return this.props.clearPurchaseListOnClick()
            }
        }
    }

    handleCheckChanged(event) {
        event.stopPropagation()

        if (this.props.purchaseList.length > 0) {
            return this.props.clearPurchaseListOnClick()
        } else {
            return this.props.selectAllAccountsOnClick()
        }
    }

    showPayableAccountLimitReached() {
        if (this.state.limitReachedTimerID > 0)
            window.clearTimeout(this.state.limitReachedTimerID)

        const limitReachedTimerID = window.setTimeout(() => {
            this.setState({ ...this.state, limitReachedTimerID: 0 })
        }, 2.5 * 1000)

        this.setState({ ...this.state, limitReachedTimerID })
    }

    handleFilterKeysUp(event) {
        if (event.target.value.length >= 3
            || this.state.allowTextFiltering
            || event.key === ' '
            || event.key === 'Enter') {
            this.props.filterAccountsText(true, event.target.value)
        } else if (event.target.value.length === 0) {
            this.props.filterAccountsText(false, event.target.value)
        }

        if (event.target.value.length >= 3 && !this.state.allowTextFiltering) {
            this.setState({ ...this.state, allowTextFiltering: true })
        }

        return
    }

    toggleTextFiltering() {
        let nextIsTextFiltering = !this.state.isTextFiltering
        if (!nextIsTextFiltering) {
            this.props.filterAccountsText(false, '')
        }

        this.setState({ ...this.state, isTextFiltering: nextIsTextFiltering })
    }

    render() {
        let maxPaymentsWarningText = this.props.t("Max_number_of_accounts_to_pay", { maxNumber: multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS })
        const isSpanish = (this.props.i18n.language==="es")
        let theSupportNumber = isSpanish ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : this.props.supportPhoneNumber

        let searchIcon = this.state.isTextFiltering
            ? (<IconButton color="primary" size="small"
                onClick={() => this.toggleTextFiltering()} >
                <Icon>clear</Icon>
            </IconButton>)
            : (<IconButton color="primary" size="small"
                onClick={() => { this.toggleTextFiltering() }} >
                <Icon>search</Icon>
            </IconButton>)

        let pageHeader = this.state.isTextFiltering
            ? (<TextField
                fullWidth
                inputRef={(input) => (input && input.focus())}
                onKeyUp={this.handleFilterKeysUp}
                onChange={() => { }}
                InputProps={{
                    style: {
                        fontWeight: 'bold',
                        fontSize: "24px",
                        color: '#004B87',
                        boxShadow: '0px 0px 8px rgb(8 126 216 / 99%)',
                        borderRadius: '5px'
                    }
                }}
            />)
            : <span className="align-middle" style={{ marginRight: "0.2rem", fontSize: "24px", color: "#004B87" }}><strong>{this.props.t("Payable accounts")}</strong></span>

        const showArrearsAndBreakdownCols = someAccountsInArrears(this.props.paymentInfoList)

        return (
            <div className="srp-card-body">
            <div className="srp-card-details">
                <div className="srp-card-header clearfix">
                    <div className="col-11" style={{ float: 'left', paddingLeft: '0px', paddingRight: '0px' }}>
                        {pageHeader}
                    </div>
                    <div className="col-1" style={{ float: 'right', textAlign: 'right' }}>
                        {searchIcon}
                    </div>
                </div>
                <MultiPaymentAccountTypeSelector t={this.props.t} />
                {/* desktop view */}
                <div className="d-none d-lg-block mb-4"  style={{ overflowX: 'auto', marginLeft: '-20px', marginRight: '-20px' }}>
                    {this.props.showMaxPaymentWarning &&
                        <div className="srp-alert-warn mt-3 pt-2 mb-1 w-100">
                            {maxPaymentsWarningText}
                        </div>
                    }
                    {this.props.showGeneralWarning &&
                        <div className="srp-alert-error mt-3 pt-2 mb-1 w-100">
                            {this.props.t("We_found_an_error_while_confirming_your_payment")}
                        </div>
                    }
                    {this.props.errorVerifyingPurchases &&
                        <div className="srp-alert-error mt-3 pt-2 mb-1 w-100">
                            {`${this.props.t("We_are_unable_to_complete_your_payment")} ${theSupportNumber}.`}
                        </div>
                    }

                    <div className="d-flex justify-content-end thick-blue-border-bottom pt-4 pb-2 pr-3">
                        {this.props.displayedCount} {this.props.displayedCount > 1 ? this.props.t("accounts") : this.props.t("account")} ({this.props.purchaseList.length} {this.props.t("selected")})
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: 'rgb(229,237,243)' }}>
                                <TableCell padding="none">
                                    <Button id="unlinked-checkbox" ref="unlinkedCheckbox" aria-haspopup="true"
                                        onClick={this.openMenu} style={{ textTransform: 'none' }}
                                        endIcon={<ArrowDropDownIcon />}
                                    >
                                        <Checkbox
                                            indeterminate={this.props.purchaseList.length > 0 && this.props.purchaseList.length < this.props.selectableCount}
                                            checked={this.props.purchaseList.length === this.props.selectableCount}
                                            onClick={this.handleCheckChanged}
                                            color="primary"
                                        />
                                    </Button>
                                    <Menu id="select-unlinked-accounts-menu" open={this.state.menuAnchor !== null}
                                        anchorEl={this.state.menuAnchor} onClose={this.handleClose}>
                                        {this.props.selectableCount < multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS ?
                                            <MenuItem onClick={this.handleChange('all')}>{this.props.t("All")}</MenuItem> :
                                            <MenuItem onClick={this.handleChange('first100')}>{this.props.t("First 100 accounts")}</MenuItem>
                                        }
                                        <MenuItem onClick={this.handleChange('none')}>{this.props.t("None")}</MenuItem>
                                    </Menu>
                                </TableCell>
                                <TableCell padding="none" className="font-weight-bold">{this.props.t("Account")}</TableCell>
                                <TableCell padding="none" className="font-weight-bold text-right pr-2">
                                    <Trans i18nKey="Amount owed to SRP" t={this.props.t}>
                                        <span className="text-nowrap">Amount owed</span>
                                        <span className="text-nowrap">to SRP</span>
                                    </Trans>
                                </TableCell>
                                {showArrearsAndBreakdownCols &&
                                    // NOTE: Setting a width of 1 should cause this column to auto-shrink to fit content
                                    <TableCell padding="none" className="font-weight-bold text-right px-2" width="1">
                                        <Trans i18nKey="Apply total purchase to amount owed to SRP" t={this.props.t}>
                                            Apply total purchase <span className="text-nowrap">to amount owed to SRP</span>
                                        </Trans>
                                    </TableCell>
                                }
                                <TableCell padding="none" className="font-weight-bold text-right pr-2">{this.props.t("Purchase amount")} <Icon className="invisible" style={{ fontSize: "20px" }}>error_outline</Icon></TableCell>
                                {showArrearsAndBreakdownCols &&
                                    <TableCell padding="none" className="font-weight-bold text-right pr-2">
                                        <Trans i18nKey="Amount to meter" t={this.props.t}>
                                            Amount <span className="text-nowrap">to meter</span>
                                        </Trans>
                                    </TableCell>
                                }
                                {showArrearsAndBreakdownCols &&
                                    <TableCell padding="none" className="font-weight-bold text-right pr-3">
                                        <Trans i18nKey="Amount to SRP" t={this.props.t}>
                                            Amount <span className="text-nowrap">to SRP</span>
                                        </Trans>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.paymentInfoList.map((n, index) => {
                                let selectedInfo = getSelectedInfo(n.billAccount, this.props.purchaseList)
                                let disabled = getDisabled(n, selectedInfo.isSelected, this.props.purchaseList.length)
                                let isSelected = disabled ? false : selectedInfo.isSelected

                                const breakdown = getPurchaseBreakdownDisplay(selectedInfo, n)

                                if (n.display) {
                                    return (
                                        <PayableAccountRow
                                            paymentInfo={n}
                                            viewBillClick={this.props.viewBillClick}
                                            selectedInfo={selectedInfo}
                                            purchaseBreakdown={breakdown}
                                            disabled={disabled}
                                            showArrearsAndBreakdownCols={showArrearsAndBreakdownCols}
                                            isSelected={isSelected}
                                            totalAccounts={this.props.paymentInfoList.length}
                                            totalSelected={this.props.purchaseList.length}
                                            getSelectedMpowerAccountOnClick={this.props.getSelectedMpowerAccountOnClick}
                                            payAmountOwedToSrpClick={this.props.payAmountOwedToSrpClick}
                                            getPurchaseAmountOnChange={this.props.getPurchaseAmountOnChange}
                                            index={index}
                                            key={`${n.billAccount}_${index}`}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </TableBody>
                    </Table>
                </div>

                {/* responsive view */}
                <div className="d-block d-lg-none mb-4">
                    {this.props.showMaxPaymentWarning &&
                        <div className="srp-alert-warn mt-2 pt-2 mb-1 w-100">
                            {maxPaymentsWarningText}
                        </div>
                    }
                    {this.props.showGeneralWarning &&
                        <div className="srp-alert-error mt-2 pt-2 mb-1 w-100">
                            {this.props.t("We_found_an_error_while_confirming_your_payment")}
                        </div>
                    }
                    {this.props.errorVerifyingPurchases &&
                        <div className="srp-alert-error mt-2 pt-2 mb-1 w-100">
                            {this.props.t("We_are_unable_to_complete_your_payment")} <a href={"tel:" + theSupportNumber}>{theSupportNumber}</a>.
                        </div>
                    }

                    <Table style={{ fontSize: '14px' }}>
                        <TableHead>
                            <TableRow className="pat-ngp-header-responsive">
                                <TableCell padding="none" colSpan={2}>
                                    <div className="thick-blue-border-top">
                                        <div className="d-flex justify-content-between align-items-center pat-ngp-header-responsive ngp">
                                            <div>
                                                <div className="d-flex">
                                                    <Button id="unlinked-checkbox"
                                                        ref="unlinkedCheckbox"
                                                        aria-haspopup="true"
                                                        onClick={this.openMenu}
                                                        endIcon={<ArrowDropDownIcon />}
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            onClick={this.handleCheckChanged}
                                                            indeterminate={this.props.purchaseList.length > 0 && this.props.purchaseList.length < this.props.selectableCount}
                                                            checked={this.props.purchaseList.length === this.props.selectableCount}
                                                        />
                                                    </Button>
                                                </div>
                                                <Menu id="select-unlinked-accounts-menu" open={this.state.menuAnchor !== null}
                                                    anchorEl={this.state.menuAnchor} onClose={this.handleClose}>
                                                    {this.props.selectableCount < multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS ?
                                                        <MenuItem onClick={this.handleChange('all')}>{this.props.t("Unpaid")}</MenuItem> :
                                                        <MenuItem onClick={this.handleChange('first100')}>{this.props.t("First 100 accounts")}</MenuItem>
                                                    }
                                                    <MenuItem onClick={this.handleChange('none')}>{this.props.t("None")}</MenuItem>
                                                </Menu>
                                            </div>
                                            <h5 className="mb-0">{this.props.displayedCount} {this.props.displayedCount > 1 ? this.props.t("accounts") : this.props.t("account")} ({this.props.purchaseList.length} {this.props.t("selected")})</h5>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.paymentInfoList.map((n, index) => {
                                let selectedInfo = getSelectedInfo(n.billAccount, this.props.purchaseList)
                                let disabled = getDisabled(n, selectedInfo.isSelected, this.props.purchaseList.length)
                                let isSelected = disabled ? false : selectedInfo.isSelected

                                const breakdown = getPurchaseBreakdownDisplay(selectedInfo, n)

                                const totalSelected = this.props.purchaseList.length
                                const allowSelect = (totalSelected < multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS || isSelected === true)

                                function getRowTitle(paymentInfo, viewBillClick) {
                                    const fmtAccountNumber = (<PatternFormat
                                        value={format.srpAccountNumber(paymentInfo.billAccount)}
                                        valueIsNumericString={true}
                                        format="###-###-###"
                                        displayType="text"
                                    />)
                                    return (
                                        <h6 className="mb-0">
                                            {(paymentInfo.accountName && paymentInfo.accountName.trim() !== '')
                                                ?
                                                <div>
                                                    <button
                                                        className="displayAsLink text-truncate"
                                                        type="button"
                                                        onClick={() => viewBillClick(paymentInfo.billAccount)}>
                                                        {paymentInfo.accountName}
                                                    </button>
                                                    <br />
                                                    {fmtAccountNumber}
                                                </div>
                                                :
                                                <button className="displayAsLink" type="button" onClick={() => viewBillClick(paymentInfo.billAccount)}>
                                                    {fmtAccountNumber}
                                                </button>
                                            }
                                        </h6>
                                    )
                                }

                                if (n.display) {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={`${n.billAccount}_${index}`}
                                            selected={isSelected}
                                            className="open-close pat-purchase-row-responsive"
                                        >
                                            <TableCell padding="none" style={{ verticalAlign: "top", borderBottomWidth: 0 }} colSpan={2}>
                                                <div className="d-flex align-items-start pat-payment-row-responsive ngp">
                                                    <Checkbox
                                                        onChange={() => {
                                                            if (allowSelect)
                                                                this.props.getSelectedMpowerAccountOnClick(n.billAccount, n.paymentInfo)
                                                            else
                                                                this.showPayableAccountLimitReached()
                                                        }}
                                                        disabled={disabled}
                                                        color="primary"
                                                        checked={isSelected}
                                                        className="mt-1 mr-3"
                                                    />
                                                    <div className="pl-0 pr-2 py-2 flex-grow-1">
                                                        <div className="d-flex align-items-center mb-2">
                                                            <div>
                                                                {getRowTitle(n, this.props.viewBillClick)}
                                                                {getFullStreetAddress(n.serviceAddress)}
                                                            </div>

                                                            <div>
                                                                {isSelected && (selectedInfo.purchaseAmountError.hasError) &&
                                                                    <SrpTooltip content={<span>{this.getCombinedErrorText(selectedInfo.purchaseAmountError)}</span>}>
                                                                        <Icon style={{ ...getCombinedWarningStyle(selectedInfo.purchaseAmountError.errorLevel), verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                                                                    </SrpTooltip>
                                                                }
                                                                {!n.paymentInfo.canMakeEChexPayment &&
                                                                    this.getCashOnlyElement()
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="d-flex justify-content-between mt-2">
                                                            <div className="text-muted">
                                                                <Trans i18nKey="Amount owed to SRP" t={this.props.t}>
                                                                    <span className="text-nowrap">Amount owed</span>
                                                                    <span className="text-nowrap">to SRP</span>
                                                                </Trans>
                                                            </div>
                                                            <div>{getAmountDue(n)}</div>
                                                        </div>

                                                        {showArrearsAndBreakdownCols &&
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <div className="text-muted">
                                                                    <Trans i18nKey="Apply total purchase to amount owed to SRP" t={this.props.t}>
                                                                        Apply total purchase <span className="text-nowrap">to amount owed to SRP</span>
                                                                    </Trans>
                                                                </div>
                                                                {isSelected === false
                                                                    ? "No"
                                                                    : <div>
                                                                        <label className="switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={() => { this.props.payAmountOwedToSrpClick(n.billAccount) }}
                                                                                checked={getInitialPayAmountOwedToSRPValue(selectedInfo)}
                                                                                disabled={n.paymentInfo.amountDue <= 0}
                                                                            />
                                                                            <span className={"yesNoToggle" + (isSpanish ? " es" : "")} />
                                                                        </label>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        <div className="d-flex justify-content-between align-items-center mt-2">
                                                            <div className="text-muted">{this.props.t("Purchase amount")}</div>
                                                            {isSelected === false
                                                                ? "- -"
                                                                : <NumericFormat
                                                                    id={"paymentAmount" + index + "m"}
                                                                    placeholder={this.props.t("Enter amount")}
                                                                    disabled={disabled}
                                                                    value={getInitialPurchaseValue(selectedInfo)}
                                                                    valueIsNumericString={true}
                                                                    onBlur={(values) => {
                                                                        const purchaseAmount = values.target.value
                                                                        if (purchaseAmount === "") {
                                                                            if (selectedInfo.isSelected)
                                                                                this.props.getSelectedMpowerAccountOnClick(n.billAccount, n.paymentInfo)
                                                                        }
                                                                        else {
                                                                            let fixedPurchaseAmount = Number(purchaseAmount.replace(/[$,]/g, '')).toFixed(2)
                                                                            this.props.getPurchaseAmountOnChange(n.billAccount, fixedPurchaseAmount)
                                                                        }
                                                                    }}
                                                                    decimalScale={2}
                                                                    thousandSeparator={true}
                                                                    prefix={'$'}
                                                                    allowNegative={false}
                                                                    customInput={TextField}
                                                                    type="text"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            inputMode: 'decimal',
                                                                            style: {
                                                                                textAlign: 'right'
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="pat-input-box-responsive m-0"
                                                                />
                                                            }
                                                        </div>

                                                        {showArrearsAndBreakdownCols &&
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <div className="text-muted">
                                                                    <Trans i18nKey="Amount to meter" t={this.props.t}>
                                                                        Amount <span className="text-nowrap">to meter</span>
                                                                    </Trans>
                                                                </div>
                                                                <div>{breakdown.amountToMeter}</div>
                                                            </div>
                                                        }

                                                        {showArrearsAndBreakdownCols &&
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-muted">
                                                                    <Trans i18nKey="Amount to SRP" t={this.props.t}>
                                                                            Amount <span className="text-nowrap">to SRP</span>
                                                                    </Trans>
                                                                </div>
                                                                <div>
                                                                    {breakdown.amountToSRP}
                                                                    {breakdown.payDownPercent &&
                                                                        <span className="text-muted"> {breakdown.payDownPercent}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </TableBody>
                    </Table>
                    {this.props.purchaseList.length > 0 &&
                        <div className="multi-payment-responsive-sticky-counter">
                            {this.state.limitReachedTimerID > 0 && this.props.purchaseList.length >= multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS ?
                                <div>{this.props.t("Payable account limit reached")}</div> :
                                <div>{this.props.purchaseList.length}/{multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS} {this.props.t("accounts selected")}</div>
                            }
                        </div>
                    }
                </div>
                <div className="d-flex flex-column align-items-end px-3">
                    <h6 className="font-weight-bold">
                        <span className="text-secondary">{this.props.t("Total purchase")} </span>
                        <NumericFormat
                            value={getTotalPurchase(this.props.purchaseList)}
                            valueIsNumericString={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                            displayType="text"
                        />
                    </h6>
                    <button disabled={this.props.isSubmitting || !this.props.hasBankAccounts} onClick={() => this.props.verifyPurchasesOnClick()} className="btn srp-btn btn-blue text-white mt-3 ngp-confirm-button w-100">{this.props.isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : this.props.showMaxPaymentWarning ? maxPaymentsWarningText : this.props.t("Confirm purchase")}</button>
                </div>
            </div>
            </div>
        )
    }
}

PayableAccountsTablePure.propTypes = {
    purchaseList: PropTypes.arrayOf(PropTypes.object),
    showMaxPaymentWarning: PropTypes.bool.isRequired,
    paymentInfoList: PropTypes.arrayOf(PropTypes.object),
    selectAll: PropTypes.bool.isRequired,
    selectAllAccountsOnClick: PropTypes.func.isRequired,
    selectFirst100AccountsOnClick: PropTypes.func.isRequired,
    clearPurchaseListOnClick: PropTypes.func.isRequired,
    getSelectedMpowerAccountOnClick: PropTypes.func.isRequired,
    getPurchaseAmountOnChange: PropTypes.func.isRequired,
    verifyPurchasesOnClick: PropTypes.func.isRequired,
    viewBillClick: PropTypes.func.isRequired,
    payAmountOwedToSrpClick: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    hasBankAccounts: PropTypes.bool.isRequired,
    showGeneralWarning: PropTypes.bool.isRequired,
    selectableCount: PropTypes.number.isRequired,
    displayedCount: PropTypes.number.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    errorVerifyingPurchases: PropTypes.bool.isRequired,
    filterAccountsText: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const PayableAccountsTable = withTranslation("multiPurchasePage")(PayableAccountsTablePure)
export { PayableAccountsTable as default, PayableAccountsTablePure }