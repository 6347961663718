import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import Dialog from '@mui/material/Dialog'

import OutageBanners from './outage-banners'

import OutagePageLoading from './outage-page-loading'
import OutagePageError from './outage-page-error'

import UserLocation from '../../images/outage_icons/current.svg'
import YellowIcon from '../../images/outage_icons/yellow.svg'
import OrangeIcon from '../../images/outage_icons/orange.svg'
import RedIcon from '../../images/outage_icons/red.svg'
import PurpleIcon from '../../images/outage_icons/purple.svg'
import GreenIcon from '../../images/outage_icons/maintenance.svg'

import {
    hideLegend,
    showLegend,
    hideOutageDetail,
    showOutageDetail,
    hideCustomerInOutageDetail,
    showCustomerInOutageDetail
} from '../../actions/auth/outage/outage-map-actions'
import { getAllOutages, updatePublicOutageActions } from '../../actions/guest/outage/outage-public-actions'

import * as loadingStatus from '../../constants/loading-status-constants'
import { myAccountConstants } from '../../constants/myaccount-constants'
import { rateMetaDataConstants } from '../../constants/rate-meta-data-constants'

import outageRestoration from '../../srp_modules/outage-restoration'

import { Trans } from 'react-i18next'

let google
window.outageMap = {}
let customerMarker = {}
let outageMarkers = []

class OutagesMapContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showLegendModal: false,
            showOutageDetailModal: false,
            showCustomerInOutageDetailModal: false,

            showIceReimbursementModel: false
        }

        this.closeLegendModalOnClick = this.closeLegendModalOnClick.bind(this)
        this.openLegendModalOnClick = this.openLegendModalOnClick.bind(this)
        this.closeOutageDetailModalOnClick = this.closeOutageDetailModalOnClick.bind(this)
        this.openOutageDetailModalOnClick = this.openOutageDetailModalOnClick.bind(this)
        this.closeCustInOutageDetailModalOnClick = this.closeCustInOutageDetailModalOnClick.bind(this)
        this.openCustInOutageDetailModalOnClick = this.openCustInOutageDetailModalOnClick.bind(this)
        this.iceReimbursementModalOnClick = this.iceReimbursementModalOnClick.bind(this)

        this.refreshOutageMap = this.refreshOutageMap.bind(this)
    }

    componentDidMount() {
        if (this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            window.outageMap = {}
            return
        }

        this.setOutageMap(this.props.serviceAddress.fullStreetAddress)

    }

    componentDidUpdate(prevProps) {
        if (this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            window.outageMap = {}
            return
        }

        if (prevProps.serviceAddress.fullStreetAddress !== this.props.serviceAddress.fullStreetAddress
            || prevProps.allOutagesStatus !== this.props.allOutagesStatus
            || prevProps.iceReimbursementInfoStatus !== this.props.iceReimbursementInfoStatus
            || prevProps.rateMetaDataStatus !== this.props.rateMetaDataStatus
            || prevProps.hasMedicalSupportOptionStatus !== this.props.hasMedicalSupportOptionStatus
            || prevProps.isIneligibleToReportStatus !== this.props.isIneligibleToReportStatus
            || prevProps.mapScriptLoadedStatus !== this.props.mapScriptLoadedStatus)
            this.setOutageMap(this.props.serviceAddress.fullStreetAddress)
    }

    componentWillUnmount() {
        this.cleanUpMarkers()
    }

    cleanUpMarkers() {
        if (outageMarkers.length != 0) {
            for (let i = 0; i < outageMarkers.length; i++) {
                outageMarkers[i].setMap(null)
                outageMarkers[i] = null
            }
        }

        outageMarkers = []
    }

    initMap() {
        google = window.google
        window.outageMap = new google.maps.Map(ReactDOM.findDOMNode(this.refs.hiddenOutageMap), {
            zoom: 10,
            minZoom: 8,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            styles: [{
                featureType: 'poi',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'poi.park',
                stylers: [{ visibility: 'on' }]
            }]
        })

        let southWest = new google.maps.LatLng(32.507020, -114.122679)
        let northEast = new google.maps.LatLng(36.956073, -109.112914)
        let allowedBounds = new google.maps.LatLngBounds(southWest, northEast)
        let lastValidCenter = window.outageMap.getCenter()

        window.addEventListener('resize', function () {
            window.outageMap.setCenter(lastValidCenter)
        })

        google.maps.event.addListener(window.outageMap, 'center_changed', function () {
            if (allowedBounds.contains(window.outageMap.getCenter())) {
                lastValidCenter = window.outageMap.getCenter()
                return
            }

            window.outageMap.panTo(lastValidCenter)
        })

    }

    setOutageMap(address) {
        if (this.props.serviceAddressStatus !== loadingStatus.LOADING_STATUS_SUCCESS
            || this.props.allOutagesStatus !== loadingStatus.LOADING_STATUS_SUCCESS
            || this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS
            || (this.props.iceReimbursementInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.iceReimbursementInfoStatus !== loadingStatus.LOADING_STATUS_FAILURE)
            || this.props.hasMedicalSupportOptionStatus !== loadingStatus.LOADING_STATUS_SUCCESS
            || this.props.isIneligibleToReportStatus !== loadingStatus.LOADING_STATUS_SUCCESS
            || this.props.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            return
        }

        this.cleanUpMarkers()
        if (!window.outageMap || Object.keys(window.outageMap).length === 0) {
            this.initMap()
        }

        if (window.outageMap.controls[google.maps.ControlPosition.LEFT].length !== 0) {
            window.outageMap.controls[google.maps.ControlPosition.LEFT].clear()
        }

        if (window.outageMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].length !== 0) {
            window.outageMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].clear()
        }

        ReactDOM.findDOMNode(this.refs.outageMap).appendChild(window.outageMap.getDiv())
        google.maps.event.trigger(window.outageMap, 'resize')

        let legend = ReactDOM.findDOMNode(this.refs.legend)
        window.outageMap.controls[google.maps.ControlPosition.LEFT].push(legend)

        let legendButton = ReactDOM.findDOMNode(this.refs.legendButton)
        window.outageMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legendButton)

        let legendButtonR = ReactDOM.findDOMNode(this.refs.legendButtonR)
        window.outageMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legendButtonR)

        let outageDetail = ReactDOM.findDOMNode(this.refs.outageDetail)
        window.outageMap.controls[google.maps.ControlPosition.LEFT].push(outageDetail)

        let customerInOutageDetail = ReactDOM.findDOMNode(this.refs.customerInOutageDetail)
        window.outageMap.controls[google.maps.ControlPosition.LEFT].push(customerInOutageDetail)

        this.getGeocode(window.outageMap, address)
        this.plotOutages(window.outageMap)
    }

    getGeocode(map, address) {
        if (window.google === undefined || google.maps === undefined) {
            return
        }

        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status === 'OK') {
                let center = results[0].geometry.location
                map.setCenter(center)

                window.addEventListener('resize', function () {
                    map.setCenter(center)
                })

                let iconUser = {
                    url: UserLocation,
                    scaledSize: new google.maps.Size(25, 36),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(12.5, 36)
                }

                if (customerMarker && customerMarker.setMap) {
                    customerMarker.setMap(null)
                    customerMarker = null
                }

                customerMarker = new google.maps.Marker({
                    icon: iconUser,
                    position: center,
                    map: map,
                    optimized: false
                })

            }
            else {
                window.addEventListener('resize', function () {
                    map.setCenter(new google.maps.LatLng(33.448261, -112.075768))
                })
            }
        })
    }

    plotOutages(map) {

        let outagePosition = {}
        let outageIcon = ""
        let outageMarkerInfo = {}

        if (Object.keys(this.props.allOutagesInfo).length !== 0) {
            for (let i = 0; i < this.props.allOutagesInfo.length; i++) {
                outagePosition = { lat: this.props.allOutagesInfo[i].latitude, lng: this.props.allOutagesInfo[i].longitude }

                let affectedCustomers = this.props.allOutagesInfo[i].numberCustomersAffected;
                let maintenanceOutage = this.props.allOutagesInfo[i].isMaintenanceOutage;

                if (1 <= affectedCustomers && affectedCustomers <= 100 && maintenanceOutage == false) {
                    outageIcon = YellowIcon;
                }
                else if (101 <= affectedCustomers && affectedCustomers <= 500 && maintenanceOutage == false) {
                    outageIcon = OrangeIcon;
                }
                else if (501 <= affectedCustomers && affectedCustomers <= 1000 && maintenanceOutage == false) {
                    outageIcon = RedIcon;
                }
                else if (affectedCustomers >= 1001 && maintenanceOutage == false) {
                    outageIcon = PurpleIcon;
                }
                else if (maintenanceOutage) {
                    outageIcon = GreenIcon;
                }

                outageMarkerInfo = {
                    startTime: this.props.allOutagesInfo[i].outageBegan,
                    estimatedRestorationTime: this.props.allOutagesInfo[i].estimatedRestorationTime,
                    customersImpacted: this.props.allOutagesInfo[i].numberCustomersAffected,
                    reason: this.props.allOutagesInfo[i].outageProblem,
                    reasonEs: this.props.allOutagesInfo[i].outageProblemEs,
                    impactedArea: this.props.allOutagesInfo[i].crossRoadText,
                    impactedAreaEs: this.props.allOutagesInfo[i].crossRoadTextEs
                }

                let markerIcon = {
                    url: outageIcon,
                    scaledSize: new google.maps.Size(25, 36),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(12.5, 36)
                }

                let markerIconClicked = {
                    url: outageIcon,
                    scaledSize: new google.maps.Size(37, 54),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(18.5, 54)
                }

                let marker = new google.maps.Marker({
                    icon: markerIcon,
                    position: outagePosition,
                    map: map,
                    data: outageMarkerInfo,
                    optimized: false
                })

                outageMarkers.push(marker)

                let markerThis = this.props
                google.maps.event.addListener(marker, 'click', () => {
                    if (window.innerWidth > 991) {
                        marker.setIcon(markerIconClicked)
                        marker.setAnimation(4)

                        for (let i = 0; i < outageMarkers.length; i++) {
                            if (marker !== outageMarkers[i]) {
                                outageMarkers[i].setIcon({
                                    url: outageMarkers[i].icon.url,
                                    scaledSize: new google.maps.Size(25, 36),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(12.5, 36)
                                })
                            }
                        }
                    }

                    markerThis.showOutageDetailOnClick(marker.data)

                    //mobile
                    if (window.innerWidth <= 991) {
                        this.openOutageDetailModalOnClick()
                    }

                    ReactDOM.findDOMNode(this.refs.hideDetails).addEventListener('click', () => {
                        marker.setIcon(markerIcon)
                    })

                })
            }
        }
    }

    closeLegendModalOnClick() {
        this.setState({
            showLegendModal: false
        })
    }

    openLegendModalOnClick() {
        this.setState({
            showLegendModal: true
        })
    }

    closeOutageDetailModalOnClick() {
        this.setState({
            showOutageDetailModal: false
        })

        this.props.hideOutageDetailOnClick()
    }

    openOutageDetailModalOnClick() {
        this.setState({
            showOutageDetailModal: true
        })
    }

    closeCustInOutageDetailModalOnClick() {
        this.setState({
            showCustomerInOutageDetailModal: false
        })
    }

    openCustInOutageDetailModalOnClick() {
        this.setState({
            showCustomerInOutageDetailModal: true
        })
    }

    iceReimbursementModalOnClick(isOpen) {
        this.setState({
            showIceReimbursementModel: isOpen,
        })
    }

    refreshOutageMap() {
        window.outageMap.setZoom(10)
        this.props.getPublicOutageInfo()

        this.props.hideOutageDetailOnClick()
        this.props.hideLegend()
        this.props.hideCustomerInOutageDetailOnClick()
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        const isSpanish = (i18n.language === "es")

        let supportNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let supportNumberFmt = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        if (this.props.selectedBillAccountDetails.isCommercial) {
            supportNumber = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
            supportNumberFmt = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        } else if (isSpanish) {
            supportNumber = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            supportNumberFmt = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        }

        let renderedCard = {}
        if ((this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (this.props.allOutagesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.allOutagesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || (this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_INIT || this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_INIT || this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (this.props.iceReimbursementInfoStatus === loadingStatus.LOADING_STATUS_INIT || this.props.iceReimbursementInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)) {
            renderedCard = <OutagePageLoading />
        }
        else if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.allOutagesStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && (this.props.iceReimbursementInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS || this.props.iceReimbursementInfoStatus === loadingStatus.LOADING_STATUS_FAILURE)
            && this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            renderedCard = (<div className="srp-card-details">
                {this.props.hasMedicalSupportOption || this.props.isIneligibleToReport || this.props.selectedBillAccountDetails.isPrePay
                    ? <div className="srp-alert-warn mb-3">
                        <span className="d-none d-lg-block">{t("power_outage_call_srp", {supportNumber: supportNumberFmt})}</span>
                        <span className="d-block d-lg-none">
                            <Trans i18nKey="power_outage_call_srp_mobile" t={t}>
                            If you are experiencing a power outage, please contact SRP Customer Service at <a href={`tel:${supportNumber}`}>{{supportNumber: supportNumberFmt}}</a>.
                            </Trans>
                        </span>
                    </div>
                    : null
                }
                {this.props.iceReimbursementInfo.showIceReimbursementOffer
                    ? <div className="srp-alert-notice mb-3">
                        {t("in_outage_ice_reimbursement", {minOutageHoursRequired: this.props.iceReimbursementInfo.minOutageHoursRequired})}
                        {' '}
                        <button
                            className="displayAsLink"
                            onClick={() => this.iceReimbursementModalOnClick(true)}>
                            {t("Learn more »")}
                        </button>
                    </div>
                    : null}
                <Dialog
                    modal="true"
                    open={this.state.showIceReimbursementModel}
                    PaperProps={{ className: "srp-modal" }}
                >
                    <div className="srp-modal-body" id="legendModal">
                        <div className="srp-modal-close" onClick={() => this.iceReimbursementModalOnClick(false)} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Ice reimbursement available")}</h4>
                            </div>
                            <div className="mb-3">
                                {t("ice_reimbursement_details", {minOutageHoursRequired: this.props.iceReimbursementInfo.minOutageHoursRequired, maxReimbursementAmount: this.props.iceReimbursementInfo.maxReimbursementAmount})}
                            </div>
                            <div className="mb-4">
                                {t("Ice may be helpful in preserving perishables in refrigerators and freezers.")}
                            </div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("How to receive reimbursement")}</h4>
                            </div>
                            <div>{t("Send_an_email_to", {emailAddress: this.props.iceReimbursementInfo.sendEmailTo})}</div>
                            <div>{t("Subject line: Ice Reimbursement")}</div>
                            <div>{t("Include: Name, address, copy of receipt of ice purchase.")}</div>
                            <div className="srp-modal-footer mb-0">
                                <button
                                    className="btn srp-btn btn-lightblue"
                                    onClick={() => this.iceReimbursementModalOnClick(false)}>
                                    {t("Close")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div style={{ display: 'none' }}>
                    <div className="outage-map-style" id="hiddenOutageMap" ref="hiddenOutageMap" />
                </div>
                <div className="outage-map-style" id="outageMapContainer" ref="outageMap" />

                <div style={{ height: '0' }}>
                    <div className="legendDetail d-none d-lg-block" id="legend" ref="legend" style={this.props.legendStyle}>
                        <div className="legend text-white">
                            <div className="mb-4 pb-5">
                                <div className="mt-2 legend-title mb-2" ><strong>{t("Outages affecting...")}</strong></div>
                                <div><img className="legend-icons mb-2 mr-1" src={YellowIcon} />{t("1-100 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={OrangeIcon} />{t("101-500 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={RedIcon} />{t("501-1,000 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={PurpleIcon} />{t("+1,001 customers")}</div>
                                <div className="mt-5 legend-title mb-2" ><strong>{t("Other")}</strong></div>
                                <div><img className="legend-icons mb-2 mr-1" src={GreenIcon} />{t("Maintenance outage")}</div>
                            </div>
                            <div className="legend-details-btn-container legend-details-btn-container-position">
                                <i className="material-icons">keyboard_arrow_left</i>
                                <button onClick={() => this.props.hideLegend()} className="legend-details-btn text-white button-focus" name="hideLegend">{t("Hide legend")}</button>
                            </div>
                        </div>
                    </div>

                    <div className="d-none d-lg-block" id="legendButton" ref="legendButton">
                        <button onClick={this.props.showLegend} className="btn show-legend-btn" name="showLegend" style={this.props.legendButtonStyle}>
                            <div className="d-flex align-items-center">
                                <span>{t("Show legend")} &nbsp;</span>
                                <i className="material-icons srp-icon">keyboard_arrow_right</i>
                            </div>
                        </button>
                    </div>

                    <div className="outageDetail d-none d-lg-block" id="outageDetail" ref="outageDetail" style={this.props.outageDetailStyle}>
                        <div className="legend text-white">
                            <div className="mt-2 legend-title"><strong>{t("Power outage details")}</strong></div>
                            <br />
                            <div className="legend-title"><strong>{t("Outage start time")}</strong></div>
                            <div className="mr-1" >{DateTime.fromISO(this.props.outageMarkerInfo.startTime).toFormat('M/dd/yyyy h:mm a')}</div>
                            <br />
                            <div className="legend-title"><strong>{t("Estimated restoration time")}</strong></div>
                            <div className="mr-1" >{outageRestoration.getRestorationForPage(this.props.outageMarkerInfo.estimatedRestorationTime, t)}</div>
                            <br />
                            <div className="legend-title"><strong>{t("Estimated customers impacted")}</strong></div>
                            <div className="mr-1" >{this.props.outageMarkerInfo.customersImpacted}</div>
                            <br />
                            <div className="legend-title"><strong>{t("Reason")}</strong></div>
                            <div className="mr-1" >{isSpanish ? this.props.outageMarkerInfo.reasonEs : this.props.outageMarkerInfo.reason}</div>
                            <br />
                            <div className="legend-title"><strong>{t("Impacted area")}</strong></div>
                            <div className="mr-1" >{isSpanish ? this.props.outageMarkerInfo.impactedAreaEs : this.props.outageMarkerInfo.impactedArea}</div>
                            <br />
                            <div className="legend-details-btn-container legend-details-btn-container-position">
                                <i className="material-icons">keyboard_arrow_left</i>
                                <button onClick={() => this.props.hideOutageDetailOnClick()} className="legend-details-btn text-white button-focus" id="hideDetails" ref="hideDetails">{t("Hide Details")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="outageDetail d-none d-lg-block" id="customerInOutageDetail" ref="customerInOutageDetail" style={this.props.customerOutageDetailStyle}>
                        {Object.keys(this.props.customerInOutageInfo).length !== 0
                            ? <div className="legend text-white">
                                <div className="mt-2 legend-title"><strong>{t("Power outage details")}</strong></div>
                                <br />
                                <div className="legend-title"><strong>{t("Outage start time")}</strong></div>
                                <div className="mr-1" >{DateTime.fromISO(this.props.customerInOutageInfo.outageBegan).toFormat('M/dd/yyyy h:mm a')}</div>
                                <br />
                                <div className="legend-title"><strong>{t("Estimated restoration time")}</strong></div>
                                <div className="mr-1" >{outageRestoration.getRestorationForPage(this.props.customerInOutageInfo.estimatedRestorationTime, t)}</div>
                                <br />
                                <div className="legend-title"><strong>{t("Estimated customers impacted")}</strong></div>
                                <div className="mr-1" >{this.props.customerInOutageInfo.estimatedUsersImpacted}</div>
                                <br />
                                <div className="legend-title"><strong>{t("Reason")}</strong></div>
                                <div className="mr-1" >{isSpanish ? this.props.customerInOutageInfo.outageProblemEs : this.props.customerInOutageInfo.outageProblem}</div>
                                <br />
                                <div className="legend-title"><strong>{t("Impacted area")}</strong></div>
                                <div className="mr-1" >{isSpanish ? this.props.customerInOutageInfo.crossRoadTextEs : this.props.customerInOutageInfo.crossRoadText}</div>
                                <br />
                                <div className="legend-details-btn-container legend-details-btn-container-position">
                                    <i className="material-icons">keyboard_arrow_left</i>
                                    <button onClick={() => this.props.hideCustomerInOutageDetailOnClick()} className="legend-details-btn text-white button-focus" id="hideCustInOutageDetail" ref="hideCustInOutageDetail">{t("Hide Details")}</button>
                                </div>
                            </div>
                            : <div />
                        }
                    </div>
                </div>


                {/*MOBILE*/}
                <div className="d-lg-none d-block" id="legendButtonR" ref="legendButtonR" >
                    <button className="btn show-legend-btn" onClick={() => this.openLegendModalOnClick()}>
                        <div className="d-flex align-items-center">
                            <span>{t("Show legend")} &nbsp;</span>
                            <i className="material-icons srp-icon">keyboard_arrow_right</i>
                        </div>
                    </button>
                </div>

                <Dialog
                    modal="true"
                    open={this.state.showLegendModal}
                    PaperProps={{ className: "srp-modal d-lg-none d-block" }}
                >
                    <div className="srp-modal-body" id="legendModal">
                        <div className="srp-modal-close" onClick={() => this.closeLegendModalOnClick()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Outage Legend")}</h4>
                            </div>
                            <div>
                                <div className="mt-2 legend-title mb-2"><strong>{t("Outages affecting...")}</strong></div>
                                <div><img className="legend-icons mb-2 mr-1" src={YellowIcon} />{t("1-100 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={OrangeIcon} />{t("101-500 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={RedIcon} />{t("501-1,000 customers")}</div>
                                <div><img className="legend-icons mb-2 mr-1" src={PurpleIcon} />{t("+1,001 customers")}</div>
                                <div className="mt-2 legend-title mb-2"><strong>{t("Other")}</strong></div>
                                <div><img className="legend-icons mb-2 mr-1" src={GreenIcon} />{t("Maintenance outage")}</div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    modal="true"
                    open={this.state.showOutageDetailModal}
                    PaperProps={{ className: "srp-modal d-lg-none d-block" }}
                >
                    <div className="srp-modal-body" id="outageDetailModal">
                        <div className="srp-modal-close" id="hideDetailsResp" ref="hideDetailsResp" onClick={() => this.closeOutageDetailModalOnClick()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Power outage details")}</h4>
                            </div>
                            <div>
                                <div className="legend-title"><strong>{t("Outage start time")}</strong></div>
                                <div className="mr-1 mb-2">{DateTime.fromISO(this.props.outageMarkerInfo.startTime).toFormat('M/dd/yyyy h:mm a')}</div>
                                <div className="legend-title"><strong>{t("Estimated restoration time")}</strong></div>
                                <div className="mr-1 mb-2">{outageRestoration.getRestorationForPage(this.props.outageMarkerInfo.estimatedRestorationTime, t)}</div>
                                <div className="legend-title"><strong>{t("Estimated customers impacted")}</strong></div>
                                <div className="mr-1 mb-2">{this.props.outageMarkerInfo.customersImpacted}</div>
                                <div className="legend-title"><strong>{t("Reason")}</strong></div>
                                <div className="mr-1 mb-2">{isSpanish ? this.props.outageMarkerInfo.reasonEs : this.props.outageMarkerInfo.reason}</div>
                                <div className="legend-title"><strong>{t("Impacted area")}</strong></div>
                                <div className="mr-1 mb-2">{isSpanish ? this.props.outageMarkerInfo.impactedAreaEs : this.props.outageMarkerInfo.impactedArea}</div>
                                <div className="srp-modal-footer">
                                    <div className="btn srp-btn btn-blue" id="hideDetailsOnOk" ref="hideDetailsOnOk" onClick={() => this.closeOutageDetailModalOnClick()}>{t("OK")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    modal="true"
                    open={this.state.showCustomerInOutageDetailModal}
                    PaperProps={{ className: "srp-modal d-lg-none d-block" }}
                >
                    <div className="srp-modal-body" id="custInOutageDetailModal">
                        <div className="srp-modal-close" id="hideCustOutageDetailsResp" ref="hideCustOutageDetailsResp" onClick={() => this.closeCustInOutageDetailModalOnClick()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Power outage details")}</h4>
                            </div>
                            {Object.keys(this.props.customerInOutageInfo).length !== 0
                                ? <div>
                                    <div className="legend-title"><strong>{t("Outage start time")}</strong></div>
                                    <div className="mr-1 mb-2">{DateTime.fromISO(this.props.customerInOutageInfo.outageBegan).toFormat('M/dd/yyyy h:mm a')}</div>
                                    <div className="legend-title"><strong>{t("Estimated restoration time")}</strong></div>
                                    <div className="mr-1 mb-2">{outageRestoration.getRestorationForPage(this.props.customerInOutageInfo.estimatedRestorationTime, t)}</div>
                                    <div className="legend-title"><strong>{t("Estimated customers impacted")}</strong></div>
                                    <div className="mr-1 mb-2">{this.props.customerInOutageInfo.estimatedUsersImpacted}</div>
                                    <div className="legend-title"><strong>{t("Reason")}</strong></div>
                                    <div className="mr-1 mb-2">{isSpanish ? this.props.customerInOutageInfo.outageProblemEs : this.props.customerInOutageInfo.outageProblem}</div>
                                    <div className="legend-title"><strong>{t("Impacted area")}</strong></div>
                                    <div className="mr-1 mb-2">{isSpanish ? this.props.customerInOutageInfo.crossRoadTextEs : this.props.customerInOutageInfo.crossRoadText}</div>
                                    <div className="srp-modal-footer">
                                        <button className="btn srp-btn btn-blue" id="hideCustOutageDetailsOnOk" ref="hideCustOutageDetailsOnOk" onClick={() => this.closeCustInOutageDetailModalOnClick()}>{t("OK")}</button>
                                    </div>
                                </div>
                                : <div />}
                        </div>
                    </div>
                </Dialog>

                <div className="d-flex flex-wrap justify-content-end mt-4">
                    <button onClick={() => this.refreshOutageMap()} className="btn srp-btn btn-lightblue text-white mr-2">{t("Refresh map")}</button>
                    {this.props.customerInOutageInfo.isInOutageArea
                        ? <span>
                            <button onClick={() => this.props.showCustomerInOutageDetailOnClick()} className="d-lg-block d-none btn srp-btn btn-lightblue">{t("View outage details")}</button>
                            <button onClick={() => this.openCustInOutageDetailModalOnClick()} className="d-lg-none d-block btn srp-btn btn-lightblue">{t("View outage details")}</button>
                        </span>
                        : null
                    }
                    {this.props.customerInOutageInfo.isInOutageArea
                        || (this.props.lastReportedOutage.hasReported
                            && !outageRestoration.isReportedOutageClosed(this.props.lastReportedOutage, this.props.outageHistoryList))
                        || this.props.hasMedicalSupportOption
                        || this.props.isIneligibleToReport
                        || this.props.selectedBillAccountDetails.isPrePay
                        || (this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT)
                        ? null
                        : <Link
                            to="/myaccount/reportOutage"
                            className="btn srp-btn btn-orange">
                            {t("Report outage")}
                            </Link>
                    }
                </div>

                <OutageBanners t={t} i18n={i18n} heatReliefLink={this.props.iceReimbursementInfo.heatReliefLink} showHeatReliefLink={this.props.iceReimbursementInfo.showHeatReliefLink} />

            </div>)
        }
        else {
            renderedCard = <OutagePageError dataThatFailed="Outage" refreshData={this.props.refreshOutageMapCard} t={s=>s} />
        }
        return renderedCard
    }
}

const mapStateToProps = state => {
    return { ...state.accountInfo.outage, ...state.accountInfo.reportOutage, ...state.accountInfo.billAccount, ...state.login, ...state.rateMetaData }
}

const mapDispatchToProps = dispatch => {
    return {
        hideLegend: () => {
            dispatch(hideLegend())
        },
        showLegend: () => {
            dispatch(showLegend())
        },
        hideOutageDetailOnClick: () => {
            dispatch(hideOutageDetail())
        },
        showOutageDetailOnClick: (outageMarkerInfo) => {
            dispatch(showOutageDetail(outageMarkerInfo))
        },
        hideCustomerInOutageDetailOnClick: () => {
            dispatch(hideCustomerInOutageDetail())
        },
        showCustomerInOutageDetailOnClick: () => {
            dispatch(showCustomerInOutageDetail())
        },
        getPublicOutageInfo: () => {
            dispatch(getAllOutages())
        },
        updateAllOutagesInfo: () => {
            dispatch(updatePublicOutageActions())
        }
    }
}

OutagesMapContainer.propTypes = {
    selectedBillAccountDetails: PropTypes.object,

    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string,
    mapScriptLoadedStatus: PropTypes.string,

    allOutagesInfo: PropTypes.arrayOf(PropTypes.object),
    allOutagesStatus: PropTypes.string,
    getPublicOutageInfo: PropTypes.func,
    updateAllOutagesInfo: PropTypes.func,

    legendStyle: PropTypes.object.isRequired,
    legendButtonStyle: PropTypes.object,
    hideLegend: PropTypes.func,
    showLegend: PropTypes.func,

    outageMarkerInfo: PropTypes.object,
    outageDetailStyle: PropTypes.object,
    hideOutageDetailOnClick: PropTypes.func,
    showOutageDetailOnClick: PropTypes.func,

    customerInOutageInfo: PropTypes.object,
    customerOutageDetailStyle: PropTypes.object,
    hideCustomerInOutageDetailOnClick: PropTypes.func,
    showCustomerInOutageDetailOnClick: PropTypes.func,

    hasMedicalSupportOptionStatus: PropTypes.string,
    hasMedicalSupportOption: PropTypes.bool,
    isIneligibleToReportStatus: PropTypes.string,
    isIneligibleToReport: PropTypes.bool,

    lastReportedOutage: PropTypes.object,
    outageHistoryList: PropTypes.array,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,

    refreshOutageMapCard: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    iceReimbursementInfoStatus: PropTypes.string,
    iceReimbursementInfo: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(OutagesMapContainer)