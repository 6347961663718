/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { Trans, withTranslation } from 'react-i18next'
import config from 'my-account-config'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { pricePlanConstants } from '../../constants/price-plan-constants'
import { myAccountConstants } from '../../constants/myaccount-constants'
import '../../styles/price-plan.css'

const PlanSelection = ({ selectedPricePlanResidential, selectNewPricePlanResidential, rateCode, description,
    learnMoreLink, learnMoreLinkEs, currentRateCode, disabled, t, i18n }) => {    

    let disabledProp = disabled ? { disabled : true } : {}
    const isSpanish = (i18n.language === 'es')

    function getStandardLabel() {
        return (
            <div>
                <span className="font-weight-bold mr-2">{description}</span>
                <a className="displayAsLink" href={isSpanish ? config.srpnetBaseUrlEs + learnMoreLinkEs : config.srpnetBaseUrl + learnMoreLink} target="_blank">{t("Learn more")} »</a>
            </div>
        )
    }
    function getGenerationLabel() {
        const solarSupportNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_SOLAR_PHONE_NUMBER
        const solarSupportNumberFmt = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_SOLAR_PHONE_NUMBER_FMTTD

        return (
            <div>
                <div className="d-lg-block d-none">
                    <span className="font-weight-bold mr-2" style={{ color: 'black' }}>
                        {description}
                    </span>
                    {disabled && <span className="mr-2" style={{ color: 'black' }}>
                        {t("produce_your_energy_with_solar", {supportPhoneFmt: solarSupportNumberFmt})}
                    </span>}
                    <a className="displayAsLink" href={isSpanish ? config.srpnetBaseUrlEs + learnMoreLinkEs : config.srpnetBaseUrl + learnMoreLink} target="_blank">{t("Learn more")} »</a>
                </div>
                <div className="d-lg-none d-block">
                    <span className="font-weight-bold mr-2" style={{ color: 'black' }}>
                        {description}
                    </span>
                    {disabled && <span className="mr-2" style={{ color: 'black' }}>
                        <Trans i18nKey="please_call_to_enroll_customer_generation_plan" t={t}>
                            Produce your own energy with solar technology. Please call <a href={`tel:${solarSupportNumber}`}>{{supportPhoneFmt: solarSupportNumberFmt}}</a> to enroll.
                        </Trans>
                    </span>}
                    <a className="displayAsLink" href={isSpanish ? config.srpnetBaseUrlEs + learnMoreLinkEs : config.srpnetBaseUrl + learnMoreLink} target="_blank">{t("Learn more")} »</a>
                </div>
            </div>
        )
    }

    function getMpowerLabel() {
        const resSupportNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        const resSupportNumberFmt = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

        return(
            <div>
                <div className="d-lg-block d-none">
                    <span className="font-weight-bold mr-2" style={{ color: 'black' }}>{description}</span>
                    <span className="mr-2" style={{ color: 'black' }}>{t("please_call_to_enroll", {supportPhoneFmt: resSupportNumberFmt})}</span>
                    <a className="displayAsLink" href={isSpanish ? config.srpnetBaseUrlEs + learnMoreLinkEs : config.srpnetBaseUrl + learnMoreLink} target="_blank">{t("Learn more")} »</a>
                </div>
                <div className="d-lg-none d-block">
                    <span className="font-weight-bold mr-2" style={{ color: 'black' }}>{description}</span>
                    <span className="mr-2" style={{ color: 'black' }}>
                        <Trans i18nKey="please_call_to_enroll_responsive" t={t}>
                            Please call <a href={`tel:${resSupportNumber}`}>{{supportPhoneFmt: resSupportNumberFmt}}</a> to enroll.
                        </Trans>
                    </span>
                    <a className="displayAsLink" href={isSpanish ? config.srpnetBaseUrlEs + learnMoreLinkEs : config.srpnetBaseUrl + learnMoreLink} target="_blank">{t("Learn more")} »</a>
                </div>
            </div>
        )
    }

    if (currentRateCode !== rateCode) {
        return (
            <FormControlLabel
                control={<Radio
                    checked={selectedPricePlanResidential === rateCode}
                    onChange={() => selectNewPricePlanResidential(rateCode)}
                    value={rateCode}
                    color="default"
                    {...disabledProp}
                />}
                label={
                    rateCode === pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.GENERATION ? getGenerationLabel() : 
                    rateCode === pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.MPOWER ? getMpowerLabel() :  getStandardLabel()
                }
            />
        
        )
    } else {
        return null
    }
}

PlanSelection.propTypes = {
    selectedPricePlanResidential: PropTypes.number,
    selectNewPricePlanResidential: PropTypes.func,
    rateCode: PropTypes.number,
    description: PropTypes.string,
    learnMoreLink: PropTypes.string.isRequired,
    learnMoreLinkEs: PropTypes.string.isRequired,
    currentRateCode: PropTypes.number,
    disabled: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('changePricePlan')(PlanSelection)

