import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import PayByBank from './pay-by-bank'
import PayByCash from './pay-by-cash'
import CallAnytime from '../../myaccount_header/call-anytime'
import Breadcrumb from '../../common_payment/breadcrumb'
import MakePaymentByCard from './make-payment-by-card'
import OtherPaymentOptions from '../../common_payment/other-payment-options'

const MultiPaymentPage = ({ isCash, billAccountList, isMpower, sendReconnectRemark, isSolar, getSelectedPaymentAccountRateCode, t }) => {
    let pageComp = isCash ? 
                    <PayByCash 
                        billAccountList={billAccountList} 
                        isMpower={isMpower} 
                        sendReconnectRemark={sendReconnectRemark} 
                        isSolar={isSolar}
                        getSelectedPaymentAccountRateCode={getSelectedPaymentAccountRateCode}/> 
                    : 
                    <PayByBank />

    return (
        <div>
            <div className="container py-3">
                <CallAnytime />

                <h3 className="mt-2 mb-0">{t("Make a payment")}</h3>
                <AccountInfoBarContainer disableSelector={false} />

                <Breadcrumb pageTitle={t("Make a payment")}/>
                <div className="row">
                    <div className="col-lg-6 col-12 mt-lg-4 mb-4">
                        <MakePaymentByCard />
                    </div>
                    <div className="col-lg-6 col-12 mt-4 d-none d-lg-block float right">
                        <OtherPaymentOptions
                            showAutoPay={true}
                            showCreditDebit={true}
                            showPayCenterLink={true}
                            multiPayment={true}
                        />
                    </div>
                </div>
                <div className="">
                    {pageComp}
                </div>
                <br />
                <div className="d-sm-block d-md-none">
                    <OtherPaymentOptions
                        showAutoPay={false}
                        showCreditDebit={true}
                        showGiftPayment={true}
                        showPayCenterLink={true}
                        multiPayment={true}
                    />
                </div>
            </div>
        </div>
    )
}
MultiPaymentPage.propTypes = {
    isCash: PropTypes.bool,
    billAccountList: PropTypes.array,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    isMpower: PropTypes.bool,
    sendReconnectRemark: PropTypes.func.isRequired,
    isSolar: PropTypes.bool,
    getSelectedPaymentAccountRateCode: PropTypes.func.isRequired
}
export default withTranslation("multiPayment")(MultiPaymentPage)