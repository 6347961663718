import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@mui/material/InputLabel'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import format from '../../../srp_modules/format'

import Card from '@mui/material/Card'
import '../../../styles/dashboard-page.css'

let rowStyle = {
    borderBottom: "1px solid #E5E5E5",
    borderRadius: "0"
}

let paymentStyle = {
    borderBottom: "1px solid rgba(204,204,204,1)",
    paddingBottom: "14px",
    paddingTop: "15px"
}

const SubmittedMultiPaymentPage = ({submitMultiPaymentResult, totalPayment, selectedBankAccountDescription, selectedBank, updateBankEmailAddressOnClick, disconnectedPaymentAccounts, authorizeReconnectFlag, isSolar, t}) => {
    let reconnectionNotice
    if(disconnectedPaymentAccounts.length > 1){
        reconnectionNotice = 
            <div className="mx-0 mb-2 srp-alert-general-orange">
                <div className="mb-1"><b>CALL TO RECONNECT</b> &nbsp; Power will remain disconnected until a phone call is made to SRP to confirm it is safe to resume service for the following accounts:</div>
                <ul className='mb-0'>
                    {disconnectedPaymentAccounts.map((account, i)=>{
                        let accountNumber = format.srpAccountNumber(account.account.toString())
                        let accountNumberFormatted = format.formatBillAccountNumber(accountNumber)
                        if(account.accountName.length !== 0){
                            return(<li key={i}>{account.accountName} ({accountNumberFormatted})</li>)
                        }else{
                            return(<li key={i}>{accountNumberFormatted}</li>)
                        }
                    })}
                </ul>
            </div> 
    }else if(disconnectedPaymentAccounts.length === 1){
        if(authorizeReconnectFlag === null){
            reconnectionNotice = null
        }else{
            let accountNickName = disconnectedPaymentAccounts[0].accountName
            let accountNumber = format.srpAccountNumber(disconnectedPaymentAccounts[0].account.toString())
            let accountNumberFormatted = format.formatBillAccountNumber(accountNumber)
            let accountToDisplay = accountNickName.length !== 0 ? `${accountNickName} (${accountNumberFormatted})` : accountNumberFormatted

            authorizeReconnectFlag ? 
                reconnectionNotice = isSolar ?
                    <div className="mx-0 mb-2 srp-alert-success">
                        <div className="mb-1"><b>SAFE TO RECONNECT</b> &nbsp;  Power is typically on within 2 to 4 hours once the payment has posted. 
                        If power is not back on by then, please give us a call to check the status.</div>
                        <ul className='mb-0'>
                            <li className="font-weight-bold">{accountToDisplay} </li>
                        </ul>
                    </div> 
                    :
                    <div className="mx-0 mb-2 srp-alert-success">
                        <div className="mb-1"><b>SAFE TO RECONNECT</b> &nbsp; Power is typically on within 30 minutes once the payment has posted; 
                        however, it could take up to one hour. If power is not back on in one hour, please give us a call to check the status.</div>
                        <ul className='mb-0'>
                            <li className="font-weight-bold">{accountToDisplay} </li>
                        </ul>
                    </div> 
            :
            reconnectionNotice = 
                <div className="mx-0 mb-2 srp-alert-general-orange">
                    <div className="mb-1"><b>CALL TO RECONNECT</b> &nbsp; You must call SRP to reconnect power.</div>
                    <ul className='mb-0'>
                        <li className="font-weight-bold">{accountToDisplay} </li>
                    </ul>
                </div>
        } 
    }
    
    return(
    <div className="container py-3 hidden-sm-down">
        <h3 className="mt-3">{t("Make a payment")}</h3>
        <br />
        <div className="row" id="section-to-print-payment-submitted">
            <div className="col-lg-6 col-12">
                <label className="h5">{t("Your payment has been submitted")}</label>

                {reconnectionNotice}
                
                <Card className="p-4" style={rowStyle}>
                    <div className="row srp-row">
                        <div className="col-4 srp-label">
                            <div>{t("Bank account")}</div>
                        </div>
                        <div className="col">
                            <div>{selectedBankAccountDescription}</div>
                        </div>
                    </div>
                </Card>
                <Card className="p-4" style={rowStyle}>
                    <div className="row srp-row">
                        <div className="col-4 srp-label">
                            <div>{t("Total payments")}</div>
                        </div>
                        <div className="col">
                            <div>
                                <NumericFormat
                                    value={totalPayment}
                                    valueIsNumericString={true}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2} fixedDecimalScale={true}
                                    displayType="text"
                                    style={{width: '100%'}}
                                />
                                </div>
                        </div>
                    </div>
                </Card>
                <Card className="p-4" style={{ ...rowStyle, paddingTop: "20px" }}>
                    <div className="row align-items-center" style={{ paddingLeft: "5px"}}>
                        <div className="col-3">
                            <InputLabel className="text-wrap" style={{ fontSize: "14px"}}>{t("Payment date")}</InputLabel>
                        </div>
                        <div className="col-3">
                            <InputLabel className="text-wrap" style={{ fontSize: "14px"}}>{t("Account number")}</InputLabel>
                        </div>
                        <div className="col-3" style={{ textAlign: "right" }}>
                            <InputLabel className="text-wrap" style={{ fontSize: "14px"}}>{t("Payment amount")}</InputLabel>
                        </div>
                        <div className="col-3" style={{ textAlign: "right" }}>
                            <InputLabel className="text-wrap" style={{ fontSize: "14px"}}>{t("Reference number")}</InputLabel>
                        </div>
                    </div>
                    {submitMultiPaymentResult.submitPaymentResults.map((paymentInfo, index) => {
                        let subTotal = 0
                        return (<div key={index}>
                            {paymentInfo.payments.map((payment, index) => {
                                subTotal += payment.paymentAmount
                                return (
                                    <div className="row" key={index} style={{ paddingLeft: "5px", fontSize: "14px"}}>
                                        <div className="col-3" style={index === 0 ? paymentStyle : {}}>
                                            {index === 0 ? DateTime.fromISO(paymentInfo.paymentDate).toFormat('M/d/yy') : ""}
                                        </div>
                                        <div className="col-3" style={paymentStyle}>
                                            <PatternFormat
                                                value={format.srpAccountNumber(payment.billAccount)}
                                                valueIsNumericString={true}
                                                format="###-###-###"
                                                displayType="text"
                                                style={{width: '100%'}}
                                            />
                                        </div>
                                        <div className="col-3" style={{ ...paymentStyle, textAlign: "right" }}>
                                            <NumericFormat
                                                value={payment.paymentAmount}
                                                valueIsNumericString={true}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                displayType="text"
                                                style={{width: '100%'}}
                                            />
                                        </div>
                                        <div className="col-3" style={{ ...paymentStyle, textAlign: "right" }}>
                                            {paymentInfo.referenceNumber}
                                        </div>
                                    </div>
                                )}
                            )}
                        <div className="row" style={{ paddingLeft: "5px", paddingBottom: "20px", fontSize: "14px" }}>
                            <div className="col-3" />
                            <div className="col-6" style={{ ...paymentStyle, textAlign: "right", fontWeight: "bold" }}>
                                {t("Subtotal")}&nbsp;&nbsp;
                                <NumericFormat
                                    value={subTotal}
                                    valueIsNumericString={true}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    style={{width: '100%'}}
                                />
                            </div>
                            <div className="col-3" style={{ ...paymentStyle, textAlign: "right"}}>
                                {paymentInfo.referenceNumber}
                            </div>
                        </div>
                        </div>)}
                    )}
                    <div>
                        {submitMultiPaymentResult.emailConfirmationSentSuccessfully &&
                        <p style={{fontSize: "14px", color: "rgb(112,112,112)"}}>
                            {t("A confirmation email has been sent to")}&nbsp;<strong>{submitMultiPaymentResult.emailAddress}</strong>&nbsp;{t("for this payment.")}&nbsp;
                            <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(selectedBank)}>{t("Update your email address")}&nbsp;&raquo;</button>
                            <br />
                        </p>}
                        {!submitMultiPaymentResult.emailConfirmationSentSuccessfully &&
                        <p style={{fontSize: "14px", color: "rgb(112,112,112)"}}>
                            {t("Unable to send a confirmation email to")}&nbsp;<strong>{submitMultiPaymentResult.emailAddress}</strong>&nbsp;{t("for this payment.")}&nbsp;
                            <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(selectedBank)}>{t("Update your email address")}&nbsp;&raquo;</button>
                            <br />
                        </p>}
                    </div>
                    <div className="d-print-none" style={{float: "right"}}>
                        <button className="btn srp-btn btn-lightblue" onClick={() => { window.print() }}>{t("Print")}</button><Link to="/myaccount/dashboard" className="btn srp-btn btn-blue ml-2">{t("Go to dashboard")}</Link>
                    </div>
                </Card>
            </div>
            <div className="col" />
        </div>
    </div>
    )
}

SubmittedMultiPaymentPage.propTypes = {
    totalPayment: PropTypes.number.isRequired,
    submitMultiPaymentResult: PropTypes.object.isRequired,
    selectedBankAccountDescription: PropTypes.string.isRequired,
    selectedBank: PropTypes.object,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired,
    disconnectedPaymentAccounts : PropTypes.array.isRequired,
    authorizeReconnectFlag : PropTypes.bool,
    isSolar : PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(SubmittedMultiPaymentPage)