import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import { Link } from 'react-router-dom'

import { DateTime } from 'luxon'

import CircularProgress from '@mui/material/CircularProgress'

import TextInput from '../common_formik/text-input'
import BankAccountNumberInput from '../common_formik/bank-account-number-input'
import EmailInput from '../common_formik/email-input'
import PhoneNumberInput from './payment_fields/phone-number-input'
import RoutingNumberInput from '../common_formik/routing-number-input'
import PaymentInput from './payment_fields/payment-input'
import DateInput from './common/date-input'
import YesNoToggleInput from '../common_formik/yes-no-toggle-input'
import AmountDueAndDueDateLabel from './common/amount-due-and-due-date-label'
import SelectorInput from '../common_formik/selector-input'
import BankRoutingNumberAccountNumberInfoIcon from '../common_payment/bank-routing-number-account-number-info-icon'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Switch from '@mui/material/Switch'

import { getShareAmountList } from '../../constants/payment/share-amount-list'

import paymentUtils from '../common_payment/payment-utils'

import * as loadingStatus from '../../constants/loading-status-constants'

import { myAccountConstants } from '../../constants/myaccount-constants'

class SinglePaymentAddBankForm extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            hasSurePayWarning: props.hasSurePayWarning,
            showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning: false,
            hasPaymentOverAmountWarning: props.hasPaymentOverAmountWarning,
            hasPaymentUnderAmountWarning: props.hasPaymentUnderAmountWarning,
            hasPaymentDateWarning: props.hasPaymentDateWarning
        }

        this.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease = this.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease.bind(this)
        this.getPaymentWarningText = this.getPaymentWarningText.bind(this)
    }

    componentDidMount() {
        const initialPaymentDate = this.props.values.paymentDate
        this.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease(initialPaymentDate)
    }

    componentDidUpdate(prevProps) {
        if (this.props.primaryEmailStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            prevProps.primaryEmailStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            this.props.setFieldValue('bankEmailAddress', this.props.primaryEmail, false)
        }
    }

    isPaymentDateWithinTwoBusinessDaysOfSurePayRelease(paymentDate) {
        const paymentDateWithinTwoBusinessDaysOfSurePayRelease = this.state.hasSurePayWarning &&
            DateTime.fromISO(this.props.payment.twoBusinessDaysBeforeSurePayReleaseDate) <= DateTime.fromISO(paymentDate) &&
            DateTime.fromISO(paymentDate) <= DateTime.fromISO(this.props.payment.surePayDate)

        this.setState({
            showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning: paymentDateWithinTwoBusinessDaysOfSurePayRelease
        })
    }

    getPaymentAmountWarning(paymentAmount){
        this.setState({
            hasSurePayWarning: this.props.payment.isSurePay && paymentAmount > 0,
            hasPaymentOverAmountWarning: paymentUtils.isPaymentOverAmountDue(paymentAmount, this.props.payment.amountDueWithShare),
            hasPaymentUnderAmountWarning: paymentUtils.isPaymentUnderAmountDue(paymentAmount, this.props.payment.amountDueWithShare)
        })
    }

    getPaymentDateWarning(date){
        let dueDate = DateTime.fromISO(this.props.payment.dueDate).startOf('day')
        let paymentDate = DateTime.fromISO(date).startOf('day')

        this.setState({
            hasPaymentDateWarning: paymentUtils.isPaymentDateAfterDueDate(paymentDate, dueDate) && this.props.payment.amountDueWithShare > 0
        })
    }

    getPaymentWarningText(){
        let attentionText = "";

        if (this.state.hasSurePayWarning)
            attentionText = this.props.t("Surepay_may_be_canceled")
        else if(this.props.isAccountDisconnected)
            attentionText = "Future date payments not allowed. Please call to make a payment."
        else if (this.state.hasPaymentDateWarning)
            attentionText = this.props.t("Paying_after_due_date")
        else if (this.state.hasPaymentUnderAmountWarning)
            attentionText = this.props.t("Paying_less_warning")
        else if (this.state.hasPaymentOverAmountWarning)
            attentionText = this.props.t("You are making a payment that is more than the amount due.")

        return attentionText
    }

    render() {
        let commonFormikProps = {
            onChange: e => { this.props.handleChange(e); this.props.formDataChanged(e); },
            onBlur: this.props.handleBlur,
            errors: this.props.errors,
            touched: this.props.touched
        }
        let alternateFormikProps = {
            errors: this.props.errors,
            touched: this.props.touched
        }
        let verifyStatus = this.props.status || {}
        let onToggleChange = e => {
            let name = e.target.name
            let value = e.target.checked
            this.props.setFieldValue(name, value, true)
            this.props.formDataChanged(e)
        }
        let onPaymentAmountChange = values => {
            let value = values.value
            this.props.setFieldValue('paymentAmount', value, true)
            this.getPaymentAmountWarning(value)
            this.props.formDataChanged(values)
        }
        let onPaymentDateChange = date => {
            let value = DateTime.fromISO(date).startOf('day').toFormat('yyyy-MM-dd')
            this.props.setFieldValue('paymentDate', value, true)
            this.getPaymentDateWarning(value)
            this.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease(value)
            this.props.formDataChanged(date)
        }
        let onBankPhoneNumberChange = values => {
            let value = values.value
            this.props.setFieldValue('bankPhoneNumber', value, true)
            this.props.formDataChanged(values)
        }
        let onShareChange = e => {
            let value = Math.trunc(e.target.value)
            this.props.setFieldValue('shareAmount', value, true)
            this.props.formDataChanged(e)
        }
        let dueDate = DateTime.fromISO(this.props.payment.dueDate).startOf('day').toISODate()

        const isSpanish = this.props.i18n.language === "es"
        let supportPhoneNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let supportPhoneNumberFormatted = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        if (!this.props.isResidential) {
            supportPhoneNumber = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
            supportPhoneNumberFormatted = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        } else if (isSpanish) {
            supportPhoneNumber = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            supportPhoneNumberFormatted = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        }

        let confirmButtonDisabled = this.props.isSubmitting 
                                || (this.props.isAccountDisconnected && (!this.props.reconnectPowerChoice || this.state.choiceSelected === false))

        return (
            <form onSubmit={this.props.handleSubmit} id="topOfAddBankForPayment">
                {verifyStatus.hasUnhandledBankError
                    ? <div className="srp-alert-error mt-3 mb-3 pt-2">
                        <span className="d-lg-block d-none">{`${this.props.t("Unable_add_bank_account")} ${supportPhoneNumberFormatted}.`}</span>
                        <span className="d-lg-none d-block">{this.props.t("Unable_add_bank_account")} <a href={"tel:" + supportPhoneNumber}>{supportPhoneNumberFormatted}</a>.</span>
                    </div>
                    : <span />}
                {verifyStatus.hasUnandledPaymentError
                    ? <div className="srp-alert-error mt-3 mb-3 pt-2">
                        <span className="d-lg-block d-none">{`${this.props.t("Unable_complete_payment")} ${supportPhoneNumberFormatted}.`}</span>
                        <span className="d-lg-none d-block">{this.props.t("Unable_complete_payment")} <a href={"tel:" + supportPhoneNumber}>{supportPhoneNumberFormatted}</a>.</span>
                    </div>
                    : <span />}
                <label className="h4 text-muted pt-2">{this.props.t("Bank account")}</label>
                <p className="text-muted">{this.props.t("Enter_bank_account_information")}</p>
                <div className="mt-1"><TextInput id="bankFirstName" label="First name" maxLength={30} value={this.props.values.bankFirstName} {...commonFormikProps} t={this.props.t} /></div>
                <div className="mt-3"><TextInput id="bankLastName" label="Last name" maxLength={30} value={this.props.values.bankLastName} {...commonFormikProps} t={this.props.t} /></div>
                <div className="mt-3">
                    <PhoneNumberInput
                        id="bankPhoneNumber"
                        label="Phone number"
                        value={this.props.values.bankPhoneNumber}
                        onValueChange={onBankPhoneNumberChange}
                        onBlur={() => this.props.setFieldTouched("bankPhoneNumber", true)}
                        t={this.props.t}
                        {...alternateFormikProps}
                    />
                </div>
                <div className="mt-3"><EmailInput id="bankEmailAddress" label="Email address" inputProps={{ maxLength: 50 }} value={this.props.values.bankEmailAddress} t={this.props.t} {...commonFormikProps} /></div>
                <div className="mt-3 mb-3 position-relative">
                    <RoutingNumberInput
                        id="bankRoutingNumber"
                        label="9 digit routing number"
                        value={this.props.values.bankRoutingNumber}
                        t={this.props.t}
                        endAdornment={
                            <BankRoutingNumberAccountNumberInfoIcon
                                showRoutingNumberDescription={true}
                                showBankAccountNumberDescription={false}
                                className="d-lg-none" />
                        }
                        {...commonFormikProps} />
                </div>
                <div className="mb-3 position-relative">
                    <BankAccountNumberInput
                        id="bankAccountNumberLeadingZeroesAreSignificant"
                        label="Bank account number"
                        value={this.props.values.bankAccountNumberLeadingZeroesAreSignificant}
                        t={this.props.t}
                        endAdornment={
                            <BankRoutingNumberAccountNumberInfoIcon
                                showRoutingNumberDescription={false}
                                showBankAccountNumberDescription={true}
                                className="d-lg-none" />
                        }
                        {...commonFormikProps} />
                </div>
                <div className="mt-3">
                    <TextInput
                        id="bankAccountNickname"
                        label="Nickname (optional)"
                        value={this.props.values.bankAccountNickname}
                        onChange={commonFormikProps.onChange}
                        onBlur={(e) => {
                            let value = e.target.value.substring(0,35)
                            this.props.setFieldValue('bankAccountNickname', value, true)
                            this.props.setFieldTouched('bankAccountNickname', true)
                        }}
                        maxLength={35}
                        t={this.props.t}
                        {...alternateFormikProps}
                    />
                </div>
                <div className="pt-3">
                    <p className="pb-2 pl-1 text-muted" style={{ marginTop: "0", marginBottom: "0" }}>{this.props.t("Make this my default bank account:")}</p>
                    <div className="pl-3"><YesNoToggleInput id="isDefaultOnMyAcct" label="For My Account payments" value={this.props.values.isDefaultOnMyAcct} onChange={onToggleChange} t={this.props.t} i18n={this.props.i18n} /></div>
                    <div className="pl-3"><YesNoToggleInput id="isDefaultWithIVR" label="For phone payments" value={this.props.values.isDefaultWithIVR} onChange={onToggleChange} t={this.props.t} i18n={this.props.i18n} /></div>
                </div>

                {this.props.showAddBankAccountCancelButton &&
                    <div className="mt-3 d-flex flex-row-reverse">
                        <div className="btn srp-btn btn-lightblue" onClick={() => this.props.onAddBankAccountCancelClick()}>{this.props.t("Cancel")}</div>
                    </div>
                }
                <label className="h4 text-muted pt-4">{this.props.t("Payment amount & date")}</label>
                <AmountDueAndDueDateLabel
                    amountDue={this.props.payment.amountDueWithShare}
                    dueDate={dueDate}
                    isValid={this.props.isPaymentInfoValid}
                    pastDue={this.props.payment.pastDue}
                    summaryBillingStatus={this.props.summaryBillingStatus}
                    isSurePay={this.props.payment.isSurePay}
                    t={this.props.t}
                />
                <div className="mt-1">
                    <PaymentInput
                        id="paymentAmount"
                        label="Payment amount"
                        value={this.props.values.paymentAmount}
                        onValueChange={onPaymentAmountChange}
                        setFieldValue={this.props.setFieldValue}
                        t={this.props.t}
                        {...alternateFormikProps}
                    />
                </div>
                <div className="mt-3">
                    <DateInput
                        id="paymentDate"
                        label={this.props.t("Payment date")}
                        value={this.props.values.paymentDate}
                        onChange={onPaymentDateChange}
                        onBlur={() => this.props.setFieldTouched("paymentDate", true)}
                        dateProps={this.props.dateFieldProps}
                        isAccountDisconnected={this.props.isAccountDisconnected}
                        i18n={this.props.i18n}
                        {...alternateFormikProps}
                    />
                </div>
                {(this.state.hasPaymentOverAmountWarning 
                    || this.state.hasPaymentUnderAmountWarning 
                    || this.state.hasPaymentDateWarning 
                    || this.state.hasSurePayWarning
                    || this.props.isAccountDisconnected) &&
                    <div className="srp-alert-warn mt-3 pt-2 mb-1">
                        <strong>{this.props.t("ATTENTION")}</strong>&nbsp;&nbsp;
                        {this.getPaymentWarningText()}
                        {(this.state.hasPaymentDateWarning || this.state.hasPaymentUnderAmountWarning) && this.props.creditExtension.isEligible &&
                            <span> {this.props.t("Need")} <Link to="/myaccount/dashboard" onClick={this.props.actions.startCIXFlowOnClick}>{this.props.t("more time to pay")}</Link>? {this.props.t("We may be able to assist you.")}</span>
                        }
                        {this.state.showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning &&
                        <div>
                        <br />
                        <strong>NOTE</strong>&nbsp;&nbsp;{this.props.t("Surepay_pending_payment")}
                        {this.props.t("Surepay_may_not_post")} {supportPhoneNumberFormatted}.
                        </div>
                        }
                    </div>
                }

                {this.props.isAccountDisconnected ?
                    <div className="mt-3 mb-2">
                        <div className="mx-1 mb-3 text-muted">
                            <h4>Safety First!</h4>
                        </div>
                        <div className="mx-1 mb-3 text-muted">
                            Before making a payment, SRP needs to know if it is safe to reconnect your power. <br /><br/>

                            Please be aware of the risk of fire if any appliances or other electric devices, such as an electric stove, are turned on
                            at the time power is delivered. Please ensure all of the appliances and electric devices at the premises are turned off.
                        </div>
                        <div className="srp-alert-notice-DCO">
                            <div className="font-weight-bold srp-dark-blue-color ml-2">Is it safe to reconnect power at this time?</div>
                            <RadioGroup
                                sx={{
                                    margin:'0 25px'
                                }}
                            >
                                <FormControlLabel
                                    control={<Radio color="primary"/>}
                                    value="yes"
                                    checked={this.props.reconnectPowerChoice === "yes"}
                                    onChange={()=>{
                                        this.props.setReconnectPowerChoice("yes")
                                        this.setState({choiceSelected : false}) 
                                    }}
                                    label={"Yes, reconnect power"} 
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontWeight: 'bold', // Customize the font size
                                            color: '#087ED8', // Customize the label color
                                        }
                                    }}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary"/>}
                                    value="no"
                                    checked={this.props.reconnectPowerChoice === "no"}
                                    onChange={()=>{
                                        this.props.setReconnectPowerChoice("no")
                                        this.setState({choiceSelected : false})
                                    }}
                                    label={"No, I will call when ready"} 
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontWeight: 'bold', // Customize the font size
                                            color: '#087ED8', // Customize the label color
                                        },
                                        marginTop: '-20px',
                                        marginBottom: '0px'
                                    }}
                                />
                            </RadioGroup>
                            
                            {this.props.reconnectPowerChoice === null ?
                                null :
                                <div>
                                    <div className="font-weight-bold srp-dark-blue-color ml-2 mb-1">Understand your choice</div>
                                    {this.props.reconnectPowerChoice === "yes" ? 
                                        <div className="mb-2">
                                            <div className="d-flex align-items-start ml-4 mb-2">
                                                <FormControlLabel
                                                    value="yes"
                                                    control={<Switch color="primary" />}
                                                    checked={this.state.choiceSelected}
                                                    labelPlacement="end"
                                                    onChange={(e)=>{
                                                        this.setState({choiceSelected : e.target.checked })
                                                    }}
                                                />
                                                {this.props.isSolar?
                                                    <div className="reconnection-notice pt-1">By selecting “Yes, reconnect power” I understand that my power will be reconnected. 
                                                        Power is typically on within 2 to 4 hours once the payment has posted. 
                                                        If power is not back on by then, please give us a call to check the status.</div>
                                                    :  
                                                    <div className="reconnection-notice pt-1">By selecting “Yes, reconnect power" I understand that my power will be reconnected. 
                                                    Power is typically on within 30 minutes once the payment has posted; 
                                                    however, it could take up to one hour. If power is not back on in one hour, please give us a call to check the status.</div>
                                                }
                                            </div>
                                        </div> :
                                        <div className="mb-2">
                                            <div className="d-flex align-items-start ml-4 mb-2">
                                            <FormControlLabel
                                                value="no"
                                                control={<Switch color="primary" />}
                                                checked={this.state.choiceSelected}
                                                labelPlacement="end"
                                                onChange={(e)=>this.setState({choiceSelected : e.target.checked })}
                                            />
                                            <div className="reconnection-notice pt-1">By selecting "No, I will call when ready" I understand I must call SRP to reconnect my power.</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                : null}

                {this.props.payment.shareOptionAmount === 0 &&
                    <div className="mt-3">
                        <p className="text-muted">
                            {this.props.t("Want to help fellow Arizonans through")}&nbsp;
                            <a className="displayAsLink" href={isSpanish
                                ? `${config.srpnetBaseUrlEs}cuenta/donacion/ayudando-vecinos`
                                : `${config.srpnetBaseUrl}account/donate/help-neighbors`} target="_blank">
                                {this.props.t("SHARE")}
                            </a>
                            {`? ${this.props.t("Donate $1, $2 or $5 and SRP will match your contribution.")}`}
                        </p>
                        <SelectorInput
                            id="shareAmount"
                            label={this.props.t("SHARE donation amount")}
                            value={this.props.values.shareAmount.toString()}
                            optionList={getShareAmountList(this.props.t)}
                            onChange={onShareChange}
                            onBlur={() => this.props.setFieldTouched("shareAmount", true)}
                            displayEmpty={true}
                            {...alternateFormikProps}
                        />
                    </div>
                }
                <input
                    id="srpAccountNumber"
                    name="srpAccountNumber"
                    type="hidden"
                    value={this.props.values.srpAccountNumber}
                    {...alternateFormikProps}
                />
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn srp-btn btn-blue" type="submit" disabled={confirmButtonDisabled}>
                        {this.props.isSubmitting ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : this.props.t("Confirm payment")}
                    </button>
                </div>
            </form>
        )
    }
}

SinglePaymentAddBankForm.propTypes = {
    payment: PropTypes.object.isRequired,
    isPaymentInfoValid: PropTypes.bool.isRequired,
    showAddBankAccountCancelButton: PropTypes.bool.isRequired,
    onAddBankAccountCancelClick: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    isResidential: PropTypes.bool.isRequired,
    creditExtension: PropTypes.object.isRequired,

    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    status: PropTypes.object,
    touched: PropTypes.object.isRequired,

    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,

    values: PropTypes.object.isRequired,

    dateFieldProps: PropTypes.object.isRequired,

    hasSurePayWarning: PropTypes.bool.isRequired,
    hasPaymentOverAmountWarning: PropTypes.bool.isRequired,
    hasPaymentUnderAmountWarning: PropTypes.bool.isRequired,
    hasPaymentDateWarning: PropTypes.bool.isRequired,

    isSubmitting: PropTypes.bool.isRequired,

    primaryEmailStatus: PropTypes.string.isRequired,
    primaryEmail: PropTypes.string.isRequired,
    summaryBillingStatus: PropTypes.number.isRequired,

    formDataChanged: PropTypes.func.isRequired,
    
    isAccountDisconnected: PropTypes.bool.isRequired,
    reconnectPowerChoice: PropTypes.string,
    setReconnectPowerChoice: PropTypes.func.isRequired,

    isSolar: PropTypes.bool.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default SinglePaymentAddBankForm