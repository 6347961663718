import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import { withRouter } from '../srp_modules/with-router'
import { useTranslation } from 'react-i18next'

const ErrorPageAuthenticated = ({router}) => {
    let appPathWithoutTrailingSlash = config.appPath.endsWith('/')
                                    ? config.appPath.substring(0, config.appPath.length - 1)
                                    : config.appPath

    const { i18n } = useTranslation()
    const isSpanish = i18n.language==='es'

    return (
        <div>
            <div className="container py-3">
                <h3 className="mt-3">We're sorry</h3>
                <h5 className="text-muted">Something went wrong</h5>

                <div className="mt-4">
                    <button className="displayAsLink" onClick={() => router.navigate(-1)}> &lt; Back to previous page</button>
                </div>

                <div className="mt-4">
                    <div className="srp-card-header">
                        Looks like an error occurred
                    </div>

                    <div className="srp-card-body ">
                        <div className="srp-card-details">
                            <div className="text-muted" >My Account has experienced an error processing your request. We apologize for the inconvenience.</div>
                            <br/>
                            <div className="text-muted mb-2" >Please try again or contact My Account technical support</div>
                            <div className="d-flex justify-content-end" style={{display: 'flex', flexWrap: 'wrap'}}>
                                <a href={isSpanish
                                    ? `${config.srpnetBaseUrlEs}servicio-al-cliente/contactanos`
                                    : `${config.srpnetBaseUrl}customer-service/contact-us` } className="btn srp-btn btn-lightblue mb-2">Contact technical support</a>
                                <a href={`${appPathWithoutTrailingSlash}/myaccount/dashboard`} className="btn srp-btn btn-lightblue ml-2 mb-2">Back to Dashboard</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

ErrorPageAuthenticated.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    })
}

export default withRouter(ErrorPageAuthenticated);

