import { multiPaymentConstants } from '../../../../constants/payment/multi-payment-constants'
import { myAccountConstants } from '../../../../constants/myaccount-constants'
import { bankAccountConstants } from '../../../../constants/bank-account-constants'
import { multiPaymentNgpConstants } from '../../../../constants/payment/multi-payment-ngp-constants'
import { billAccountConstants } from '../../../../constants/bill-account-constants'
import * as loadingStatus from '../../../../constants/loading-status-constants'
import utils from '../../../../srp_modules/utils'
import { DateTime } from 'luxon'
import { rateMetaDataConstants } from '../../../../constants/rate-meta-data-constants'
import format from '../../../../srp_modules/format'
import {paymentConstants} from '../../../../constants/payment-constants'

let hasScheduledPayment = (paymentInfo) => {
    let surePayDate = DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)
    let hasPendingSurePay = surePayDate.isValid && surePayDate !== DateTime.fromISO(myAccountConstants.MINIMUM_DATE)

    let scheduledPayment = paymentInfo.scheduledPayments.length > 0

    return (paymentInfo.paymentInfo.isSurePay) ? hasPendingSurePay : scheduledPayment
}

let getPaymentInfo = (billAccount, paymentInfoList) => {
    let paymentInfo = {}
    for (let i = 0; i < paymentInfoList.length; i++) {
        if (paymentInfoList[i].billAccount === billAccount)
        paymentInfo = paymentInfoList[i]
    }

    return paymentInfo
}

let getPaymentStatus = (paymentInfo, hasPendingPayment) => {
    let status = multiPaymentConstants.MULTI_PAYMENT_STATUS.UNKNOWN

    if (paymentInfo.isAdditionalPayment && paymentInfo.paymentInfo.isSurePay) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.ONE_TIME_PAYMENT
    } else if (paymentInfo.isAdditionalPayment) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.ONE_TIME_PAYMENT
    } else if (paymentInfo.paymentInfo.isSurePay) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.SUREPAY
    } else if (paymentInfo.isCashOnly) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.CASH_ONLY
    } else if (paymentInfo.paymentInfo.isBankrupt === true) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.BANKRUPTCY
    } else if (hasPendingPayment) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.SCHEDULED
    } else if (paymentInfo.paymentInfo.amountDueWithShare <= 0) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.NO_PAYMENT_DUE
    } else if (paymentInfo.dueYesterday) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.PAST_DUE
    } else if (paymentInfo.dueToday || paymentInfo.dueTomorrow) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.DUE
    } else if (paymentInfo.paymentInfo.pastDue > 0) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.PAST_DUE
    } else if (paymentInfo.paymentInfo.amountDueWithShare > 0) {
        status = multiPaymentConstants.MULTI_PAYMENT_STATUS.DUE
    }

    return status
}

let getPaymentAmountError = (billAccount, paymentInfoList, amount) => {

    let paymentInfo = getPaymentInfo(billAccount, paymentInfoList)
    let paymentAmount = utils.paymentToNumber(amount)
    let paymentAmountError = {
        hasError: false,
        errorLevel: myAccountConstants.ERROR_LEVEL.NONE,
        errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.NO_ERROR
    }
    if (amount === "") {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.REQUIRED
        }
    } else if (isNaN(paymentAmount) || paymentAmount===0.0) {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.INVALID_AMOUNT
        }
    } else if(Number(paymentAmount) > paymentInfo.paymentInfo.hardLimit) {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.PAYMENT_LIMIT,
            paymentHardLimit: paymentInfo.paymentInfo.hardLimit
        }
    } else if (Number(paymentAmount) < Number(paymentInfo.paymentInfo.amountDueWithShare) && paymentInfo.creditExtension.isEligible && !paymentInfo.isAdditionalPayment) {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.WARNING,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL_CIX_ELIGIBLE
        }
    } else if (Number(paymentAmount) < Number(paymentInfo.paymentInfo.amountDueWithShare) && !paymentInfo.isAdditionalPayment) {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.WARNING,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL
        }
    } else if (Number(paymentAmount) > Number(paymentInfo.paymentInfo.amountDueWithShare) && Number(paymentInfo.paymentInfo.amountDueWithShare) > 0 && !paymentInfo.isAdditionalPayment) {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.WARNING,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.MORE_THAN_TOTAL
        }
    }

    return paymentAmountError
}

let getPaymentDateError = (billAccount, paymentInfoList, selectedDateString, paymentAmount) => {
    let paymentInfo = getPaymentInfo(billAccount, paymentInfoList)
    let paymentDateError = {
        hasError: false,
        errorLevel: myAccountConstants.ERROR_LEVEL.NONE,
        errorType: multiPaymentConstants.PAYMENT_DATE_ERRORS.NO_ERROR
    }
    let sixtyDays = DateTime.now().startOf('day').plus({ days: 60 })
    let today = DateTime.now().startOf('day')
    let selectedDate = DateTime.fromISO(selectedDateString)
    let paymentDueDate = DateTime.fromISO(paymentInfo.paymentInfo.dueDate)

    if (!selectedDate.isValid) {
        paymentDateError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_DATE_ERRORS.INVALID_DATE
        }
    } else if (paymentDueDate.isValid && selectedDate > paymentDueDate && paymentAmount < paymentInfo.paymentInfo.amountDueWithShare) {
        paymentDateError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.WARNING,
            errorType: paymentInfo.creditExtension.isEligible ?
                multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL_CIX_ELIGIBLE :
                multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL
        }
    } else if (paymentDueDate.isValid && selectedDate > paymentDueDate && paymentInfo.creditExtension.isEligible) {
        paymentDateError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.WARNING,
            errorType: multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_CIX_ELIGIBLE
        }
    } else if (paymentDueDate.isValid && selectedDate >paymentDueDate) {
        paymentDateError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.WARNING,
            errorType: multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE
        }
    } else if (selectedDate < today) {
        paymentDateError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_BEFORE_TODAY
        }
    } else if (selectedDate > sixtyDays) {
        paymentDateError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_MORE_THAN_60
        }
    } else if (hasScheduledPayment(paymentInfo)) {
        paymentDateError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.WARNING,
            errorType: multiPaymentConstants.PAYMENT_DATE_ERRORS.PAYMENT_ALREADY_SCHEDULED
        }
    }
    return paymentDateError
}

let getPaymentList = (paymentInfoList, paymentList, billAccount, paymentAmount = '', paymentDate) => {
    let found = false
    let newPaymentList = paymentList.map((payment) => {
        if (payment.billAccount === billAccount) {
            found = true
        }

        return { ...payment }
    })

    if (!found) {
        newPaymentList = newPaymentList.concat({
            billAccount: billAccount,
            paymentDate: paymentDate,
            paymentDateError: getPaymentDateError(billAccount, paymentInfoList, paymentDate, paymentAmount),
            paymentAmount: paymentAmount,
            paymentAmountError: getPaymentAmountError(billAccount, paymentInfoList, paymentAmount),
            shareOptionAmount: 0
        })
    }

    return newPaymentList
}

let sortPaymentInfo = (a, b) => {
    if (a.billAccount === b.billAccount) {
        if (a.isAdditionalPayment) {
            return 1
        } else if (a.isAdditionalPayment === b.isAdditionalPayment) {
            return 0
        } else {
            return -1
        }
    } else {
        return a.billAccount - b.billAccount
    }
}

let isUnpaidAccount = (paymentInfo) => {
    if (paymentInfo.paymentInfo.isBankrupt === true) {
        return false
    } else if (!paymentInfo.paymentInfo.isSurePay && !paymentInfo.isCashOnly && paymentInfo.paymentInfo.amountDueWithShare > 0
                && paymentInfo.paymentInfo.canMakeEChexPayment) {
        // Defect 451 (TFS 80581). Just check amount due. Can't check past due
        // because there could be a past due without an amount due. This could
        // happen if "a bill account is a grandfathered E23 solar account. And it
        // had a past due balance and just had its annual settlement on the last bill.
        // The customer’s credit was applied to the past due amount."
        return true
    }

    return false
}

let createSelectedRecord = (billAccount, paymentInfoList) => {
    let paymentInfo = getPaymentInfo(billAccount, paymentInfoList)
    let selectedRecord = {
        billAccount: billAccount,
        paymentDate: DateTime.now().toFormat('yyyy-MM-dd'),
        paymentDateError: getPaymentDateError(billAccount, paymentInfoList, DateTime.now().toFormat('yyyy-MM-dd'), paymentInfo.paymentInfo.amountDueWithShare),
        paymentAmount: paymentInfo.paymentInfo.amountDueWithShare <= 0 ? "" : paymentInfo.paymentInfo.amountDueWithShare.toFixed(2),
        paymentAmountError: getPaymentAmountError(billAccount, paymentInfoList, paymentInfo.paymentInfo.amountDueWithShare <= 0 ? "" : paymentInfo.paymentInfo.amountDueWithShare),
        shareOptionAmount: paymentInfo.paymentInfo.shareOptionAmount
    }

    return selectedRecord
}

let resetPaymentInfoListDisplay = (paymentInfoList) => {
    let newPaymentInfoList = []

    paymentInfoList.forEach(paymentInfo => {
        let newPaymentInfo = {
            ...paymentInfo,
            display: true
        }

        newPaymentInfoList.push(newPaymentInfo)
    })

    return newPaymentInfoList
}

let getFullStreetAddress = (address) => {
    let unitNum = ""
    if (address.unitNumber) {
        unitNum = "#" + address.unitNumber
    }

    let fullStreetAddress = address.houseNumber + " " + address.addressDirection + " " + address.streetName + " " + unitNum
    return fullStreetAddress
}

let accountToString = (account) => {
    let billAccountString = String(account.billAccount)
    billAccountString = billAccountString.padStart(9, '0')

    let billAccountDashed = billAccountString.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})$/, '$1-$2-$3')

    let searchableString = billAccountString + ' ' + billAccountDashed + ' ' + account.accountName.toLowerCase() + ' ' + getFullStreetAddress(account.serviceAddress).toLowerCase()
    return searchableString
}

let isTextFilterMatch = (filterArray, paymentInfo) => {
    let isMatch = true
    let billAccountString = accountToString(paymentInfo)
    filterArray.forEach(filter => {
        if (!billAccountString.includes(filter)) {
            isMatch = false
        }
    })

    return isMatch
}

let applyFilter = (accountFilter, paymentInfoList) => {
    if (!accountFilter.enabled && !accountFilter.due && !accountFilter.pastDue && !accountFilter.scheduled && !accountFilter.surePay && !accountFilter.summaryMaster&& !accountFilter.textEnabled) {
        return resetPaymentInfoListDisplay(paymentInfoList)
    }

    let newPaymentInfoList = []

    paymentInfoList.forEach(paymentInfo => {
        let newDisplay = false
        if (accountFilter.due && paymentInfo.paymentStatus === multiPaymentConstants.MULTI_PAYMENT_STATUS.DUE) {
            newDisplay = true
        } else if (accountFilter.pastDue && paymentInfo.paymentStatus === multiPaymentConstants.MULTI_PAYMENT_STATUS.PAST_DUE) {
            newDisplay = true
        } else if (accountFilter.scheduled && paymentInfo.paymentStatus === multiPaymentConstants.MULTI_PAYMENT_STATUS.SCHEDULED) {
            newDisplay = true
        } else if (accountFilter.surePay && paymentInfo.paymentStatus === multiPaymentConstants.MULTI_PAYMENT_STATUS.SUREPAY) {
            newDisplay = true
        }

        if (!accountFilter.due && !accountFilter.pastDue && !accountFilter.scheduled && !accountFilter.surePay) {
            if (accountFilter.summaryMaster && paymentInfo.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
                newDisplay = true
            }
        } else if (accountFilter.summaryMaster) {
            if (paymentInfo.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
                newDisplay = newDisplay && true
            } else {
                newDisplay = false
            }
        }

        newPaymentInfoList.push({ ...paymentInfo, display: newDisplay })
    })


    if (accountFilter.enabled && accountFilter.textEnabled) {
        let filterArray = accountFilter.text.toLowerCase().trim().split(' ')
        newPaymentInfoList.forEach(paymentInfo => {
            if (!isTextFilterMatch(filterArray, paymentInfo)) {
                paymentInfo.display = false
            }
        })
    } else if (accountFilter.textEnabled) {
        let filterArray = accountFilter.text.toLowerCase().trim().split(' ')
        newPaymentInfoList.forEach(paymentInfo => {
            if (isTextFilterMatch(filterArray, paymentInfo)) {
                paymentInfo.display = true
            }
        })
    }

    return newPaymentInfoList
}

let applyFilterToPaymentList = (paymentInfoList, paymentList) => {
    let nextPaymentList = []

    let displayedAccounts = []
    paymentInfoList.forEach((paymentInfo) => {
        if (paymentInfo.display) {
            displayedAccounts.push(paymentInfo.billAccount)
        }
    })

    paymentList.forEach((payment) => {
        if (displayedAccounts.includes(payment.billAccount)) {
            nextPaymentList.push(payment)
        }
    })
    return nextPaymentList
}

let getFilterCounts = (paymentInfoList) => {
    let totalCreditAccounts = 0
    let selectableCount = 0
    let unpaidBillAccountsCount = 0

    paymentInfoList.forEach((paymentInfo) => {
        if (paymentInfo.display) {

            totalCreditAccounts++
            if (paymentInfo.status !== multiPaymentConstants.MULTI_PAYMENT_STATUS.SCHEDULED && isUnpaidAccount(paymentInfo))
                unpaidBillAccountsCount++

            if (paymentInfo.selectable)
                selectableCount++
        }
    })

    return {
        totalCreditAccounts,
        selectableCount,
        unpaidBillAccountsCount,
    }
}

let getNextAccountFilter = (due, pastDue, scheduled, surePay, summaryMaster, textEnabled, text) => {
    let nextAccountFilter = {
        enabled: false,
        due: false,
        pastDue: false,
        scheduled: false,
        surePay: false,
        summaryMaster: false,
        textEnabled: false,
        text: ''
    }

    if (due || pastDue || scheduled || surePay || summaryMaster) {
        nextAccountFilter = {
            enabled: true,
            due: due,
            pastDue: pastDue,
            scheduled: scheduled,
            surePay: surePay,
            summaryMaster: summaryMaster,
            textEnabled: false,
            text: ""
        }
    }

    if (textEnabled) {
        nextAccountFilter.textEnabled = true
        nextAccountFilter.text = text
    }

    return nextAccountFilter
}

const initialState = {
    selectedBankAccountId: 0,

    paymentInfoList: [],
    paymentInfoListLoadedBillAccounts: new Set(),

    paymentInfoListStatus: loadingStatus.LOADING_STATUS_INIT,
    paymentInfoCompleteStatus: loadingStatus.LOADING_STATUS_INIT,

    reloadMultiPaymentInfoStatus: loadingStatus.LOADING_STATUS_INIT,

    paymentList: [],
    sortedPaymentList: {},
    disconnectedPaymentAccounts : [],
    authorizeReconnectFlag : null,
    selectedPaymentAccountIsSolar : false,
    showMaxPaymentWarning: false,

    verifyMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_INIT,
    verifyMultiPaymentResult: {
        generalError: false,
        isSuccess: false,
        paymentErrors: [],
        paymentsReferenceNumber: 0
    },

    submitMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_INIT,
    submitMultiPaymentResult: {},

    selectAll: false,
    selectUnpaid: false,
    selectableCount: 0,

    formStartEventEmitted: false,
    errorVerifyingPayments: false,

    errorSubmittingPayments: false,

    unpaidBillAccounts: [],
    unpaidBillAccountsCount: 0,
    paymentBillAccountListCount: 0,
    totalCreditAccounts: 0,

    accountFilter: {
        enabled: false,
        due: false,
        pastDue: false,
        scheduled: false,
        surePay: false,
        summaryMaster: false,
        textEnabled: false,
        text: ''
    }
}

export default function multiPaymentReducer(state = initialState, action) {
    switch (action.type) {
        case bankAccountConstants.GET_BANK_ACCOUNT_LIST_SUCCESS: {
            const defaultBankAccount = action.payload.find((bankAccount) => bankAccount.isDefault)
            let defaultBankAccountId = defaultBankAccount
                                     ? defaultBankAccount.bankId
                                     : (action.payload.length > 0 ? action.payload[0].bankId : initialState.selectedBankAccountId)
            return { ...state, selectedBankAccountId: defaultBankAccountId }
        }

        case multiPaymentConstants.RESET_PAYMENT_INFO_LIST:
            return { ...state,
                paymentInfoList: initialState.paymentInfoList,
                paymentInfoListLoadedBillAccounts: initialState.paymentInfoListLoadedBillAccounts,
                paymentInfoListStatus: initialState.paymentInfoListStatus,
                paymentInfoCompleteStatus: initialState.paymentInfoCompleteStatus
            }

        case multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_FAILURE: {
            return { ...state,
                paymentInfoListStatus: loadingStatus.LOADING_STATUS_FAILURE,
                paymentInfoCompleteStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        }

        case multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_START: {
            return { ...state,
                paymentInfoListStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS,
                paymentInfoCompleteStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
            }
        }

        case multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_SUCCESS: {
            let newPaymentInfoList = []
            let newUnpaidBillAccounts = new Set()

            let paymentLookupObject = {}

            for (let key in action.multiPaymentInfoList) {
                let payload = action.multiPaymentInfoList[key]
                paymentLookupObject[payload.billAccount] = payload
            }
            
            action.billAccountList.forEach(billAccount => {
                let payload = paymentLookupObject[billAccount.account]

                if (!payload || payload.paymentInfo.supportsRemotePrepay) {
                    return
                }

                let summaryBillingSubAccounts = []
                if (billAccount.summaryBillingStatus === rateMetaDataConstants.SUMMARY_BILLING_PARENT) {
                    let subAccountReference = action.summaryBillAccounts.find(p => p.parentBillAccount === billAccount.account)

                    summaryBillingSubAccounts = subAccountReference.subAccounts
                }

                let isAdditionalPayment = payload.paymentInfo.isSurePay || hasScheduledPayment(payload)

                let hasPendingPayment = hasScheduledPayment(payload)

                let isDisconnected = billAccount.isCommercial === false && billAccount.billingStatusCode === paymentConstants.DISCONNECTED

                let newPaymentInfo = {
                    display: true,
                    paymentStatus: getPaymentStatus(payload, hasPendingPayment),
                    isAdditionalPayment: false,
                    hasPendingPayment: hasPendingPayment,
                    selectable: !(!payload.paymentInfo.canMakeEChexPayment || hasPendingPayment || payload.paymentInfo.isSurePay || payload.isCashOnly),
                    summaryBillingStatus: billAccount.summaryBillingStatus,
                    summaryBillingSubAccounts,
                    accountName: billAccount.accountName,
                    isDisconnected : isDisconnected,
                    ...payload
                }

                if (isAdditionalPayment) {
                    newPaymentInfoList.push({
                        ...payload,
                        paymentInfo: { ...payload.paymentInfo, amountDue: 0, dueDate: DateTime.now().plus({ months: 1}) },
                        isAdditionalPayment,
                        hasPendingPayment: hasPendingPayment,
                        display: true,
                        paymentStatus: getPaymentStatus(payload, hasPendingPayment),
                        selectable: true,
                        accountName: billAccount.accountName,
                        scheduledPaymentInfo: newPaymentInfo,
                        summaryBillingStatus: billAccount.summaryBillingStatus,
                        summaryBillingSubAccounts,
                        isDisconnected : isDisconnected
                    })
                } else {
                    newPaymentInfoList.push(newPaymentInfo)
                }
            })

            let landlordBillAccountList = [].concat(...Object.values(action.landlordAccounts).map(o => Object.values(o.landlordProperties))).map(b => b.billAccount)
            landlordBillAccountList = landlordBillAccountList.filter(ba => ba !== null && !newPaymentInfoList.some(p => p.billAccount===ba))

            landlordBillAccountList.forEach(billAccount => {
                let payload = paymentLookupObject[billAccount]

                if (!payload || payload.paymentInfo.supportsRemotePrepay) {
                    return
                }

                let hasPendingPayment =  hasScheduledPayment(payload)
                let newPaymentInfo = {
                    display: true,
                    paymentStatus: getPaymentStatus(payload, hasPendingPayment),
                    isAdditionalPayment: false,
                    hasPendingPayment: hasPendingPayment,
                    selectable: !(!payload.paymentInfo.canMakeEChexPayment || hasPendingPayment || payload.paymentInfo.isSurePay || payload.isCashOnly),
                    summaryBillingStatus: rateMetaDataConstants.SUMMARY_BILLING_NONE,
                    summaryBillingSubAccounts: [],
                    accountName: '',
                    ...payload
                }

                let isAdditionalPayment = payload.paymentInfo.isSurePay || hasScheduledPayment(payload)
                if (isAdditionalPayment) {
                    newPaymentInfoList.push({
                        ...payload,
                        // TODO: Please remove side-effects from the reducer!
                        paymentInfo: { ...payload.paymentInfo, amountDue: 0, dueDate: DateTime.now().plus({ months: 1 }) },
                        isAdditionalPayment,
                        hasPendingPayment: hasPendingPayment,
                        display: true,
                        paymentStatus: getPaymentStatus(payload, hasPendingPayment),
                        selectable: true,
                        accountName: '',
                        scheduledPaymentInfo: newPaymentInfo,
                        summaryBillingStatus: rateMetaDataConstants.SUMMARY_BILLING_NONE,
                        summaryBillingSubAccounts: [],

                    })
                } else {
                    newPaymentInfoList.push(newPaymentInfo)
                }
            })

            let newBillAccounts = new Set(action.multiPaymentInfoList.map(paymentInfo => paymentInfo.billAccount))

            let existingPaymentInfoList = []
            state.paymentInfoList.forEach(paymentInfo => {
                if (!newBillAccounts.has(paymentInfo.billAccount)) {
                    existingPaymentInfoList.push(paymentInfo)
                }
            })
            let nextStatePaymentInfoList = existingPaymentInfoList.concat(newPaymentInfoList)

            let nextSelectableCount = 0
            nextStatePaymentInfoList.forEach(paymentInfo => {
                nextSelectableCount += paymentInfo.selectable ? 1: 0
            })

            let existingBillAccounts = new Set()
            state.paymentInfoListLoadedBillAccounts.forEach(billAccount => {
                if (!newBillAccounts.has(billAccount))
                    existingBillAccounts.add(billAccount)
            })

            let allBillAccounts = new Set([...newBillAccounts, ...existingBillAccounts])
            let nextUnpaidBillAccounts = state.unpaidBillAccounts.concat([...newUnpaidBillAccounts])
            let nextState = { ...state,
                paymentInfoList: nextStatePaymentInfoList,
                paymentInfoListLoadedBillAccounts: allBillAccounts,
                unpaidBillAccounts: nextUnpaidBillAccounts,
                unpaidBillAccountsCount: nextUnpaidBillAccounts.length,
                selectableCount: nextSelectableCount,
                totalCreditAccounts: nextStatePaymentInfoList.length
            }
            nextState.paymentInfoList.sort((a, b) => { return sortPaymentInfo(a, b) })
            nextState.showMaxPaymentWarning = nextState.paymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS
            if (allBillAccounts.size > 0 && allBillAccounts.size >= state.paymentBillAccountListCount) {
                nextState.paymentInfoListStatus = loadingStatus.LOADING_STATUS_SUCCESS
                nextState.paymentInfoCompleteStatus = loadingStatus.LOADING_STATUS_SUCCESS
            } else {
                // Resetting status to init if not all expected accounts have loaded,
                // this should allow other components to start new requests
                nextState.paymentInfoListStatus = loadingStatus.LOADING_STATUS_INIT
                nextState.paymentInfoCompleteStatus = loadingStatus.LOADING_STATUS_INIT
            }

            return nextState
        }

        case multiPaymentConstants.GET_SELECTED_BANK_ACCOUNT_MULTI:
            return { ...state, selectedBankAccountId: action.bankAccount }

        case multiPaymentConstants.GET_SELECTED_PAYABLE_ACCOUNTS: {
            let selected = state.paymentList

            let selectedIndex = -1
            for (let i = 0; i < selected.length; i++) {
                if (selected[i].billAccount === action.billAccount) {
                    selectedIndex = i
                }
            }

            let newSelected = []

            if(selectedIndex === -1)
            {
                newSelected = newSelected.concat(selected, createSelectedRecord(action.billAccount, state.paymentInfoList))
            }
            else if(selectedIndex === 0)
            {
                newSelected = newSelected.concat(selected.slice(1))
            }
            else if(selectedIndex === selected.length -1)
            {
                newSelected = newSelected.concat(selected.slice(0, -1))
            }
            else if(selectedIndex > 0)
            {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                )
            }

            return { ...state, paymentList: newSelected, showMaxPaymentWarning: newSelected.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }

        case multiPaymentConstants.SELECT_ALL_PAYABLE_ACCOUNTS: {
            let newPaymentList = []

            for (let j = 0; j < state.paymentInfoList.length; j++) {
                if ((state.paymentInfoList[j].paymentInfo.canMakeEChexPayment && !state.paymentInfoList[j].paymentInfo.isSurePay && !state.paymentInfoList[j].hasPendingPayment && state.paymentInfoList[j].display) || (state.paymentInfoList[j].isAdditionalPayment && state.paymentInfoList[j].display)) {
                    newPaymentList.push({
                        billAccount: state.paymentInfoList[j].billAccount,
                        paymentAmount: state.paymentInfoList[j].paymentInfo.amountDueWithShare <= 0 ? "" : state.paymentInfoList[j].paymentInfo.amountDueWithShare.toFixed(2),
                        paymentAmountError: getPaymentAmountError(state.paymentInfoList[j].billAccount, state.paymentInfoList, state.paymentInfoList[j].paymentInfo.amountDueWithShare <= 0 ? "" : state.paymentInfoList[j].paymentInfo.amountDueWithShare),
                        paymentDate: DateTime.now().toFormat('yyyy-MM-dd'),
                        paymentDateError: getPaymentDateError(state.paymentInfoList[j].billAccount, state.paymentInfoList, DateTime.now().toFormat('yyyy-MM-dd'), state.paymentInfoList[j].paymentInfo.amountDueWithShare),
                        shareOptionAmount: state.paymentInfoList[j].paymentInfo.shareOptionAmount
                    })
                }
            }

            return { ...state, paymentList: newPaymentList, selectAll: true, showMaxPaymentWarning: newPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }

        case multiPaymentConstants.SELECT_FIRST_100_UNPAID_ACCOUNTS: {
            let newPaymentList = []

            let totalSelected = 0

            for (let j = 0; j < state.paymentInfoList.length; j++) {
                let paymentInfo = state.paymentInfoList[j].paymentInfo
                if (paymentInfo.canMakeEChexPayment && paymentInfo.amountDueWithShare > 0 && !paymentInfo.isSurePay && !state.paymentInfoList[j].hasPendingPayment && state.paymentInfoList[j].display) {
                    newPaymentList.push({
                        billAccount: state.paymentInfoList[j].billAccount,
                        paymentAmount: state.paymentInfoList[j].paymentInfo.amountDueWithShare <= 0 ? "" : state.paymentInfoList[j].paymentInfo.amountDueWithShare.toFixed(2),
                        paymentAmountError: getPaymentAmountError(state.paymentInfoList[j].billAccount, state.paymentInfoList, state.paymentInfoList[j].paymentInfo.amountDueWithShare <= 0 ? "" : state.paymentInfoList[j].paymentInfo.amountDueWithShare),
                        paymentDate: DateTime.now().toFormat('yyyy-MM-dd'),
                        paymentDateError: getPaymentDateError(state.paymentInfoList[j].billAccount, state.paymentInfoList, DateTime.now().toFormat('yyyy-MM-dd'), state.paymentInfoList[j].paymentInfo.amountDueWithShare),
                        shareOptionAmount: state.paymentInfoList[j].paymentInfo.shareOptionAmount
                    })

                    totalSelected += 1
                    if (totalSelected >= multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS) {
                        break
                    }
                }
            }

            return { ...state, paymentList: newPaymentList, selectAll: false, showMaxPaymentWarning: newPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }
        case multiPaymentConstants.SELECT_UNPAID_ACCOUNTS: {
            let newPaymentList = []

            for (let j = 0; j < state.paymentInfoList.length; j++) {
                let paymentInfo = state.paymentInfoList[j].paymentInfo
                if (paymentInfo.canMakeEChexPayment && paymentInfo.amountDueWithShare > 0 && !paymentInfo.isSurePay && !state.paymentInfoList[j].hasPendingPayment && state. paymentInfoList[j].display) {
                    newPaymentList.push({
                        billAccount: state.paymentInfoList[j].billAccount,
                        paymentAmount: state.paymentInfoList[j].paymentInfo.amountDue <= 0 ? "" : (state.paymentInfoList[j].paymentInfo.amountDue + state.paymentInfoList[j].paymentInfo.shareOptionAmount).toFixed(2),
                        paymentAmountError: getPaymentAmountError(state.paymentInfoList[j].billAccount, state.paymentInfoList, state.paymentInfoList[j].paymentInfo.amountDueWithShare <= 0 ? "" : state.paymentInfoList[j].paymentInfo.amountDueWithShare),
                        paymentDate: DateTime.now().toFormat('yyyy-MM-dd'),
                        paymentDateError: getPaymentDateError(state.paymentInfoList[j].billAccount, state.paymentInfoList, DateTime.now().toFormat('yyyy-MM-dd'), state.paymentInfoList[j].paymentInfo.amountDueWithShare),
                        shareOptionAmount: state.paymentInfoList[j].paymentInfo.shareOptionAmount
                    })
                }
            }

            return { ...state, paymentList: newPaymentList, selectAll: false, showMaxPaymentWarning: newPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }
        case multiPaymentConstants.SELECT_NONE_ACCOUNTS: {
            return { ...state, paymentList: initialState.paymentList, selectAll: false, showMaxPaymentWarning: false }
        }
        case multiPaymentConstants.GET_MULTI_PAYMENT_DATE: {
            let newPaymentListA = getPaymentList(state.paymentInfoList, state.paymentList, action.billAccount, action.paymentAmount, action.selectedDate)

            for (let i = 0; i < newPaymentListA.length; i++) {
                if (newPaymentListA[i].billAccount === action.billAccount) {
                    newPaymentListA[i].paymentDate = utils.parsePaymentDateDefaultToday(action.selectedDate)
                    newPaymentListA[i].paymentDateError = getPaymentDateError(action.billAccount, state.paymentInfoList, newPaymentListA[i].paymentDate, newPaymentListA[i].paymentAmount)
                }
            }

            return { ...state, paymentList: newPaymentListA }
        }
        case multiPaymentConstants.GET_MULTI_PAYMENT_AMOUNT: {
            let newPaymentListB = getPaymentList(state.paymentInfoList, state.paymentList, action.billAccount, action.paymentAmount, DateTime.now().toFormat('yyyy-MM-dd'))
            for (let i = 0; i < newPaymentListB.length; i++) {
                if (newPaymentListB[i].billAccount === action.billAccount) {
                    newPaymentListB[i].paymentAmount = action.paymentAmount
                    newPaymentListB[i].paymentAmountError = getPaymentAmountError(action.billAccount, state.paymentInfoList, action.paymentAmount),
                    newPaymentListB[i].paymentDateError = getPaymentDateError(action.billAccount, state.paymentInfoList, newPaymentListB[i].paymentDate, action.paymentAmount)
                }
            }

            return { ...state, paymentList: newPaymentListB }
        }

        case multiPaymentConstants.VERIFY_MULTIPLE_REQUEST:
            return { ...state, verifyMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS, errorVerifyingPayments: false }
        case multiPaymentConstants.VERIFY_MULTIPLE_SUCCESS:
            return { ...state, verifyMultiPaymentResult: action.payload,
                verifyMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                errorVerifyingPayments: !action.payload.isSuccess,
            }
        case multiPaymentConstants.VERIFY_MULTIPLE_FAILURE:
            return { ...state, verifyMultiPaymentResult: initialState.verifyMultiPaymentResult,
                verifyMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_FAILURE,
                errorVerifyingPayments: true,
            }

        case billAccountConstants.ADD_BILL_ACCOUNT_SUCCESS:
        case billAccountConstants.DELETE_BILL_ACCOUNT_SUCCESS:
        case multiPaymentConstants.CLEAR_MULTI_PAYMENT_INFO:
            return { ...state,
                paymentList: initialState.paymentList,
                showMaxPaymentWarning:initialState.showMaxPaymentWarning,
                paymentInfoList: initialState.paymentInfoList,
                paymentInfoListStatus: initialState.paymentInfoListStatus,
                paymentInfoListLoadedBillAccounts: initialState.paymentInfoListLoadedBillAccounts,
                sortedPaymentList: initialState.sortedPaymentList,
                verifyMultiPaymentResult: initialState.verifyMultiPaymentResult,
                verifyMultiPaymentResultStatus: initialState.verifyMultiPaymentResultStatus,
                submitMultiPaymentResult: initialState.submitMultiPaymentResult,
                submitMultiPaymentResultStatus: initialState.submitMultiPaymentResultStatus,
                reloadMultiPaymentInfoStatus: initialState.reloadMultiPaymentInfoStatus,
                paymentInfoCompleteStatus: initialState.paymentInfoCompleteStatus,
                unpaidBillAccounts: initialState.unpaidBillAccounts
            }

        case multiPaymentConstants.SUBMIT_MULTIPLE_REQUEST:
            return { ...state, submitMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS,
                errorSubmittingPayments: false }
        case multiPaymentConstants.SUBMIT_MULTIPLE_SUCCESS:
            return { ...state, submitMultiPaymentResult: action.payload,
                errorSubmittingPayments: !action.payload.isSuccess,
                submitMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                paymentBillAccountListCount: initialState.paymentBillAccountListCount }
        case multiPaymentConstants.SUBMIT_MULTIPLE_FAILURE:
            return { ...state, submitMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_FAILURE,
                submitMultiPaymentResult: initialState.submitMultiPaymentResult,
                errorSubmittingPayments: true, }

        case multiPaymentConstants.CLEAR_SUBMIT_PAYMENT_ERROR:
            return { ...state, errorSubmittingPayments: false, }

        case multiPaymentConstants.SORT_MULTI_PAYMENT_LIST: {
            let newSortedPaymentList = {}
            let sortedPayments = state.paymentList.slice()

            sortedPayments.sort((a,b) => {
                return DateTime.fromISO(a.paymentDate) > DateTime.fromISO(b.paymentDate) ? 1 : DateTime.fromISO(a.paymentDate) < DateTime.fromISO(b.paymentDate) ? -1 : a.billAccount - b.billAccount
            })

            sortedPayments.forEach(payment => {
                if (Object.prototype.hasOwnProperty.call(newSortedPaymentList, payment.paymentDate)) {
                    newSortedPaymentList[payment.paymentDate].paymentList.push(payment)
                } else {
                    newSortedPaymentList[payment.paymentDate] = {
                        paymentList: [],
                        subTotal: 0
                    }

                    newSortedPaymentList[payment.paymentDate].paymentList.push(payment)
                }
            })

            Object.keys(newSortedPaymentList).forEach(key => {
                let subTotal = 0
                newSortedPaymentList[key].paymentList.forEach(payment => {
                    subTotal += utils.paymentToNumber(payment.paymentAmount)
                })

                newSortedPaymentList[key].subTotal = subTotal
            })

            return { ...state, sortedPaymentList: newSortedPaymentList }
        }
        
        case multiPaymentConstants.GET_DCO_PAYMENT_LIST : {
            let sortedPaymentList = state.sortedPaymentList
            let billAccountList = action.billAccountList

            let paymentAccounts = []
            Object.keys(sortedPaymentList).map((date) => {
                let paymentObject = sortedPaymentList[date]
                let paymentAccountsOneDay = paymentObject.paymentList.map((each)=>{
                    return format.srpAccountNumber(each.billAccount)
                })
                paymentAccounts = paymentAccounts.concat(paymentAccountsOneDay)
            })
            
            const paymentAccountsSet = new Set(paymentAccounts)
    
            let newDisconnectedPaymentAccounts = 
                billAccountList 
                ? billAccountList.filter((billAccount) => { 
                    return billAccount.isCommercial === false 
                    && billAccount.billingStatusCode === paymentConstants.DISCONNECTED 
                    && paymentAccountsSet.has(format.srpAccountNumber(billAccount.account.toString())) 
                }) 
                : []

            return {...state, disconnectedPaymentAccounts : newDisconnectedPaymentAccounts}
        }

        case multiPaymentConstants.SET_PAYMENT_BILL_ACCOUNT_LIST_COUNT: {
            return { ...state, paymentBillAccountListCount: action.listCount }
        }
        case multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_SUCCESS: {
            return { ...state, paymentBillAccountListCount: initialState.paymentBillAccountListCount }
        }
        case bankAccountConstants.ADD_BANK_EARLY_WARNING_SUCCESS: {
            if (action.payload.isSuccess === false)
                return state
            if (state.selectedBankAccountId)
                return state

            const savedBankAccountId = action.payload.savedBankAccount.bankAccountId
            return { ...state, selectedBankAccountId: savedBankAccountId }
        }
        case multiPaymentConstants.FILTER_ACCOUNT_ENABLED: {
            if (!action.enabled) {
                let nextPaymentInfoList = resetPaymentInfoListDisplay(state.paymentInfoList)
                let nextCounts = getFilterCounts(nextPaymentInfoList)
                return { ...state,
                    accountFilter: initialState.accountFilter,
                    paymentInfoList: nextPaymentInfoList,
                    totalCreditAccounts: nextCounts.totalCreditAccounts,
                    selectableCount: nextCounts.selectableCount,
                    unpaidBillAccountsCount: nextCounts.unpaidBillAccountsCount
                }
            }

            let nextAccountFilter = getNextAccountFilter(true, true, true, true, false, '')
            let nextPaymentInfoList = applyFilter(nextAccountFilter, state.paymentInfoList)
            let nextPaymentList = applyFilterToPaymentList(nextPaymentInfoList, state.paymentList)
            let nextCounts = getFilterCounts(nextPaymentInfoList)
            return { ...state,
                accountFilter: nextAccountFilter,
                paymentInfoList: nextPaymentInfoList,
                paymentList: nextPaymentList,
                totalCreditAccounts: nextCounts.totalCreditAccounts,
                selectableCount: nextCounts.selectableCount,
                unpaidBillAccountsCount: nextCounts.unpaidBillAccountsCount,
                showMaxPaymentWarning: nextPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }
        case multiPaymentConstants.FILTER_ACCOUNT_DUE: {
            let nextAccountFilter = getNextAccountFilter(action.enabled, state.accountFilter.pastDue, state.accountFilter.scheduled, state.accountFilter.surePay, state.accountFilter.summaryMaster, state.accountFilter.textEnabled, state.accountFilter.text)
            let nextPaymentInfoList = applyFilter(nextAccountFilter, state.paymentInfoList)
            let nextPaymentList = applyFilterToPaymentList(nextPaymentInfoList, state.paymentList)
            let nextCounts = getFilterCounts(nextPaymentInfoList)
            return { ...state,
                accountFilter: nextAccountFilter,
                paymentInfoList: nextPaymentInfoList,
                paymentList: nextPaymentList,
                totalCreditAccounts: nextCounts.totalCreditAccounts,
                selectableCount: nextCounts.selectableCount,
                unpaidBillAccountsCount: nextCounts.unpaidBillAccountsCount,
                showMaxPaymentWarning: nextPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }
        case multiPaymentConstants.FILTER_ACCOUNT_PAST_DUE: {
            let nextAccountFilter = getNextAccountFilter(state.accountFilter.due, action.enabled, state.accountFilter.scheduled, state.accountFilter.surePay, state.accountFilter.summaryMaster, state.accountFilter.textEnabled, state.accountFilter.text)
            let nextPaymentInfoList = applyFilter(nextAccountFilter, state.paymentInfoList)
            let nextPaymentList = applyFilterToPaymentList(nextPaymentInfoList, state.paymentList)
            let nextCounts = getFilterCounts(nextPaymentInfoList)
            return { ...state,
                accountFilter: nextAccountFilter,
                paymentInfoList: nextPaymentInfoList,
                paymentList: nextPaymentList,
                totalCreditAccounts: nextCounts.totalCreditAccounts,
                selectableCount: nextCounts.selectableCount,
                unpaidBillAccountsCount: nextCounts.unpaidBillAccountsCount,
                showMaxPaymentWarning: nextPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }
        case multiPaymentConstants.FILTER_ACCOUNT_SCHEDULED: {
            let nextAccountFilter = getNextAccountFilter(state.accountFilter.due, state.accountFilter.pastDue, action.enabled, state.accountFilter.surePay, state.accountFilter.summaryMaster, state.accountFilter.textEnabled, state.accountFilter.text)
            let nextPaymentInfoList = applyFilter(nextAccountFilter, state.paymentInfoList)
            let nextPaymentList = applyFilterToPaymentList(nextPaymentInfoList, state.paymentList)
            let nextCounts = getFilterCounts(nextPaymentInfoList)
            return { ...state,
                accountFilter: nextAccountFilter,
                paymentInfoList: nextPaymentInfoList,
                paymentList: nextPaymentList,
                totalCreditAccounts: nextCounts.totalCreditAccounts,
                selectableCount: nextCounts.selectableCount,
                unpaidBillAccountsCount: nextCounts.unpaidBillAccountsCount,
                showMaxPaymentWarning: nextPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }
        case multiPaymentConstants.FILTER_ACCOUNT_SUREPAY: {
            let nextAccountFilter = getNextAccountFilter(state.accountFilter.due, state.accountFilter.pastDue, state.accountFilter.scheduled, action.enabled, state.accountFilter.summaryMaster, state.accountFilter.textEnabled, state.accountFilter.text)
            let nextPaymentInfoList = applyFilter(nextAccountFilter, state.paymentInfoList)
            let nextPaymentList = applyFilterToPaymentList(nextPaymentInfoList, state.paymentList)
            let nextCounts = getFilterCounts(nextPaymentInfoList)
            return { ...state,
                accountFilter: nextAccountFilter,
                paymentInfoList: nextPaymentInfoList,
                paymentList: nextPaymentList,
                totalCreditAccounts: nextCounts.totalCreditAccounts,
                selectableCount: nextCounts.selectableCount,
                unpaidBillAccountsCount: nextCounts.unpaidBillAccountsCount,
                showMaxPaymentWarning: nextPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }
        case multiPaymentConstants.FILTER_ACCOUNT_SUMMARYMASTER: {
            let nextAccountFilter = getNextAccountFilter(state.accountFilter.due, state.accountFilter.pastDue, state.accountFilter.scheduled, state.accountFilter.surePay, action.enabled, state.accountFilter.textEnabled, state.accountFilter.text)
            let nextPaymentInfoList = applyFilter(nextAccountFilter, state.paymentInfoList)
            let nextPaymentList = applyFilterToPaymentList(nextPaymentInfoList, state.paymentList)
            let nextCounts = getFilterCounts(nextPaymentInfoList)
            return { ...state,
                accountFilter: nextAccountFilter,
                paymentInfoList: nextPaymentInfoList,
                paymentList: nextPaymentList,
                totalCreditAccounts: nextCounts.totalCreditAccounts,
                selectableCount: nextCounts.selectableCount,
                unpaidBillAccountsCount: nextCounts.unpaidBillAccountsCount,
                showMaxPaymentWarning: nextPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }
        case multiPaymentConstants.FILTER_ACCOUNT_TEXT: {
            let nextAccountFilter = getNextAccountFilter(state.accountFilter.due, state.accountFilter.pastDue, state.accountFilter.scheduled, state.accountFilter.surePay, state.accountFilter.summaryMaster, action.enabled, action.filterText)
            let nextPaymentInfoList = applyFilter(nextAccountFilter, state.paymentInfoList)
            let nextPaymentList = applyFilterToPaymentList(nextPaymentInfoList, state.paymentList)
            let nextCounts = getFilterCounts(nextPaymentInfoList)
            return { ...state,
                accountFilter: nextAccountFilter,
                paymentInfoList: nextPaymentInfoList,
                paymentList: nextPaymentList,
                totalCreditAccounts: nextCounts.totalCreditAccounts,
                selectableCount: nextCounts.selectableCount,
                unpaidBillAccountsCount: nextCounts.unpaidBillAccountsCount,
                showMaxPaymentWarning: nextPaymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }

        case multiPaymentConstants.SET_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: true
            }
        case multiPaymentConstants.CLEAR_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: false
            }

        case multiPaymentConstants.RECONNECT_CHOICE_SELECTED:
            return {...state,
                authorizeReconnectFlag : action.authorizeReconnect
            }

        case multiPaymentConstants.GET_SELECTED_PAYMENT_ACCOUNT_RATE_CODE_SUCCESS:
            return {...state,
                selectedPaymentAccountIsSolar :  action.payload.isSolar
            }

        default:
            return state
    }
}