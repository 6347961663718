import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import AccountInfoBar from './account-info-bar'

import { getBillAccounts, getPhoneNumber, getRateCode, getServiceAddress, getAccountManager  } from '../../../actions/auth/bill_account/bill-account-actions'
import { getLandlordAgreements } from '../../../actions/auth/landlord/landlord-actions'
import { getRateMetaData } from '../../../actions/auth/usage/rate-meta-data-actions'
import { LOADING_STATUS_INIT, LOADING_STATUS_SUCCESS, LOADING_STATUS_FAILURE } from '../../../constants/loading-status-constants'
import { withRouter } from '../../../srp_modules/with-router'
import Dialog from '@mui/material/Dialog'
import {paymentConstants} from '../../../constants/payment-constants'

function updateDataIfNeeded(props) {
    if (props.billAccountListStatus === LOADING_STATUS_INIT || props.billAccountListStatus === LOADING_STATUS_FAILURE)
        props.updateBillAccountList()
    if (props.landlordAgreementListStatus === LOADING_STATUS_INIT)
        props.updateLandlordList()

    if (props.billAccountListStatus !== LOADING_STATUS_SUCCESS || props.selectedBillAccount === 0)
        return

    if (props.currentRateCodeStatus === LOADING_STATUS_INIT && !props.selectedBillAccountDetails.isPendingTurnOn)
        props.updateCurrentRateCode(props.selectedBillAccount)
    if (props.phoneNumberStatus === LOADING_STATUS_INIT)
        props.updatePhoneNumber(props.selectedBillAccount)
    if (props.serviceAddressStatus === LOADING_STATUS_INIT)
        props.updateServiceAddress(props.selectedBillAccount)
    if (props.accountManagerStatus === LOADING_STATUS_INIT)
        props.updateAccountManager(props.selectedBillAccount)
    if (props.rateMetaDataStatus === LOADING_STATUS_INIT && !props.selectedBillAccountDetails.isPendingTurnOn)
        props.updateRateMetaData(props.selectedBillAccount)
}

class AccountInfoBarContainer extends React.Component {
    constructor(props) {
        super(props)

        // Global variable to signal to other components that this component exists in the component tree.
        // Components can use this to decide whether they need to wait for the bill account list to load.
        window.pageHasBillAccountList = true
        this.state = {
            disconnectDialogVisible: this.props.selectedBillAccountDetails.isCommercial === false 
            && this.props.selectedBillAccountDetails.billingStatusCode === paymentConstants.DISCONNECTED
        }
    }

    componentDidMount() {
        updateDataIfNeeded(this.props)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.billAccountListStatus !== this.props.billAccountListStatus
            && this.props.billAccountListStatus === LOADING_STATUS_FAILURE) {
            this.props.router.navigate('/error', { replace: true })
            return
        }

        updateDataIfNeeded(this.props)
       
        if(this.props.selectedBillAccountDetails.isCommercial === false 
            && this.props.selectedBillAccountDetails.billingStatusCode === paymentConstants.DISCONNECTED){
            let visitedDCOAccount = JSON.parse(sessionStorage.getItem("visitedDCOAccount"))

            if(!visitedDCOAccount || visitedDCOAccount.includes(this.props.selectedBillAccountDetails.account) === false){
                sessionStorage.removeItem("dialogOpened")
                let visitedDCOAccountUpdated = !visitedDCOAccount ? 
                    [this.props.selectedBillAccountDetails.account] : 
                    [...visitedDCOAccount, this.props.selectedBillAccountDetails.account]
                sessionStorage.setItem("visitedDCOAccount", JSON.stringify(visitedDCOAccountUpdated))
            }

            if(this.state.disconnectDialogVisible === false){
                this.setState({disconnectDialogVisible : true})
            }
        }   
    }

    componentWillUnmount() {
        window.pageHasBillAccountList = false
    }

    render() {
        return (
            <Fragment>
                <AccountInfoBar
                    disableSelector={this.props.disableSelector}
                    displayLandlordLink={this.props.landlordAgreementList.length > 0}
                    t={this.props.t}
                    i18n={this.props.i18n}
                />
                <Dialog
                    open={!sessionStorage.getItem("dialogOpened") && this.state.disconnectDialogVisible}
                    sx={{
                        ".MuiPaper-root" : {
                            maxWidth : {
                                xs : '400px',
                                lg : '650px'
                            }
                        }
                    }}
                >
                    <div className="my-4 mx-4">
                        <h4>Disconnection Notice</h4>
                    </div>
                    <div className="mb-4 ml-4 mr-4 d-lg-block d-none">
                        Service has been disconnected due to non-payment. A disconnect fee has been charged to your account. 
                        To be reconnected, please make a payment. For assistance,
                        call (602) 236-8888, 24 hours a day, seven days a week.
                    </div>
                    <div className="mb-4 ml-4 mr-4 d-lg-none d-block">
                        Service has been disconnected due to non-payment. A disconnect fee has been charged to your account. 
                        To be reconnected, please make a payment. For assistance,
                        call <a href="">(602) 236-8888</a>, 24 hours a day, seven days a week.
                    </div>
                    <div className="d-flex justify-content-end mb-4 mr-4">
                        <button 
                            className="mx-2 btn srp-btn btn-lightblue"
                            onClick={()=>{
                                this.setState({disconnectDialogVisible: false})
                                sessionStorage.setItem("dialogOpened", true)
                        }}>
                            Dismiss
                        </button>
                        <button
                            className="mx-2 btn srp-btn btn-blue"
                            onClick={()=>{
                                this.props.router.navigate('/myaccount/payment', { replace: false })
                                sessionStorage.setItem("dialogOpened", true)
                        }}>
                            Make payment
                        </button>
                    </div>
                </Dialog>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.accountInfo.billAccount,
        ...state.rateMetaData,
        ...state.landlord,
        isLoggedIn: state.login.isLoggedIn
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentRateCode: (billAccount) => {
            return dispatch(getRateCode(billAccount))
        },
        updateServiceAddress: (billAccount) => {
            return dispatch(getServiceAddress(billAccount))
        },
        updatePhoneNumber: (billAccount) => {
            return dispatch(getPhoneNumber(billAccount))
        },
        updateAccountManager: (billAccount) => {
            return dispatch(getAccountManager(billAccount))
        },
        updateBillAccountList: () => {
            return dispatch(getBillAccounts())
        },
        updateLandlordList: () => {
            return dispatch(getLandlordAgreements())
        },
        updateRateMetaData: (billAccount) => {
            return dispatch(getRateMetaData(billAccount))
        }
    }
}

AccountInfoBarContainer.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    landlordAgreementList: PropTypes.array.isRequired,

    billAccountListStatus: PropTypes.string.isRequired,
    currentRateCodeStatus: PropTypes.string.isRequired,
    landlordAgreementListStatus: PropTypes.string.isRequired,
    phoneNumberStatus:  PropTypes.string.isRequired,
    rateMetaDataStatus: PropTypes.string.isRequired,
    serviceAddressStatus: PropTypes.string.isRequired,

    updateBillAccountList: PropTypes.func.isRequired,
    updateCurrentRateCode: PropTypes.func.isRequired,
    updatePhoneNumber: PropTypes.func.isRequired,
    updateServiceAddress: PropTypes.func.isRequired,
    updateRateMetaData: PropTypes.func.isRequired,

    disableSelector: PropTypes.bool,

    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("accountInfoBar")(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInfoBarContainer)))
