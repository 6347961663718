import { myAccountConstants } from '../../../constants/myaccount-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'

import { LOADING_STATUS_INIT, LOADING_STATUS_IN_PROGRESS, LOADING_STATUS_SUCCESS, LOADING_STATUS_FAILURE } from '../../../constants/loading-status-constants'
import localization from '../../../srp_modules/localization'
import i18n from '../../../i18n'

let dismissMessage = (state, index) => {
    let messages = state.dashboardMessages.slice()
    messages.splice(index, 1)
    return messages
}

let getInitialPreferredLanguage = () => {
    return i18n.language
}

let setPreferredLanguageFromBillingLanguage = (billingLanguage) => {
    let cookieLanguage = localization.getCookieLanguage()

    if (cookieLanguage){
        return cookieLanguage
    }
    else if(localization.getNavigatorLanguage() === "es" || billingLanguage === "sp"){
        //billing language from phx is "sp" for spansih
        return "es"
    }
    else {
        return "en"
    }
}

const initialState = {
    dashboardMessages: [],
    dashboardMessagesStatus: LOADING_STATUS_INIT,

    snackbarMessages: [],
    displayedLanguage: 'en',
    preferredLanguage: getInitialPreferredLanguage(),
    preventLocalization: true,
    billingLanguage: 'en'
}

export default function myAccountReducer(state = initialState, action) {
    switch (action.type) {
        case myAccountConstants.GET_DASHBOARD_MESSAGES_REQUEST:
            return { ...state, dashboardMessagesStatus: LOADING_STATUS_IN_PROGRESS }
        case myAccountConstants.DISMISS_MESSAGE_SUCCESS:
            return { ...state, dashboardMessages: dismissMessage(state, action.index) }
        case myAccountConstants.GET_DASHBOARD_MESSAGES_SUCCESS:
            return { ...state, dashboardMessages: action.payload, dashboardMessagesStatus: LOADING_STATUS_SUCCESS }
        case myAccountConstants.GET_DASHBOARD_MESSAGES_FAILURE:
            return { ...state, dashboardMessages: [], dashboardMessagesStatus: LOADING_STATUS_FAILURE }

        case myAccountConstants.SAVE_PREFERRED_LANGUAGE: {
            let displayedLanguage = state.preventLocalization ? 'en' : action.preferredLanguage
            return { ...state, preferredLanguage: action.preferredLanguage, displayedLanguage }
        }
        case myAccountConstants.ALLOW_LOCALIZATION: {
            return { ...state, preventLocalization: false, displayedLanguage: state.preferredLanguage }
        }
        case myAccountConstants.PREVENT_LOCALIZATION: {
            return { ...state, preventLocalization: true, displayedLanguage: 'en' }
        }
        case rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS:
            return {
                ...state,
                // NOTE: This introduces a side-effect, we may need to move this into an action or component
                preferredLanguage: setPreferredLanguageFromBillingLanguage(action.payload.billingLanguage),
                billingLanguage: action.payload.billingLanguage
            }
        case rateMetaDataConstants.GET_RATE_META_DATA_FAILURE:
            return { ...state }

        case myAccountConstants.ADD_SNACKBAR_MESSAGE: {
            let newSnackbarMessages = state.snackbarMessages.slice()
            newSnackbarMessages.push(action.snackbarMessage)
            return { ...state, snackbarMessages: newSnackbarMessages }
        }
        case myAccountConstants.DISMISS_SNACKBAR_MESSAGE: {
            let newSnackbarMessages = state.snackbarMessages.slice(1)
            return { ...state, snackbarMessages: newSnackbarMessages }
        }
        default:
            return state;
    }
}