import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import Divider from '@mui/material/Divider'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import OutageAlertDialog from './outage-alert-dialog'

import config from 'my-account-config'

const showReportOutage = { display: 'block' }
const hideReportOutage = { display: 'none' }
const CardOutage = ({ hasReported, isReportedOutageClosed, hasMedicalSupportOption, isIneligibleToReport, isPrePay, isSummaryMaster, selectedBillAccount, primaryEmail, outageAlertState, outageAlert, toggleOutageAlert, t, i18n }) => {
    const isSpanish = (i18n.language==='es')

    const supportPhoneFormatted = isSpanish ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    const supportPhone = isSpanish ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER

    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("Outages")}
            </div>
            <div className="srp-card-body ">
                <div className="srp-card-outage-no-outage d-flex justify-content-around">
                    <div className="d-flex flex-column align-items-center">
                        <div className="h4 text-white">{t("No outages")}</div>
                        &nbsp;
                    </div>
                </div>
                <div className="srp-card-details d-none d-lg-block">
                    <div>
                        <div className="d-flex justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Link to="/myaccount/outages" className="btn srp-btn btn-lightblue mb-3">{t("View map")}</Link>
                            {(hasReported && !isReportedOutageClosed)
                                || hasMedicalSupportOption
                                || isIneligibleToReport
                                || isPrePay
                                || isSummaryMaster
                                ? null
                                : <Link
                                    to="/myaccount/reportOutage"
                                    className="btn srp-btn btn-orange ml-2 mb-3">
                                    {t("Report outage")}
                                </Link>
                            }
                        </div>
                    </div>
                    <div className="srp-alert-warn mb-3" style={(hasMedicalSupportOption || isIneligibleToReport || isPrePay) ? showReportOutage : hideReportOutage}>
                        <span className="d-none d-lg-block">{t("If_power_outage_call_SRP_desktop", {phone: supportPhoneFormatted})}</span>
                        <span className="d-block d-lg-none">
                            <Trans i18nKey="If_power_outage_call_SRP_mobile" t={t}>
                                If you are experiencing a power outage, please contact SRP Customer Service at <a href={`tel:${supportPhone}`}>{{supportPhoneFormatted}}</a>.
                            </Trans>
                        </span>
                    </div>
                    <div>
                        {t("For_more_info_visit_SRP_outage_resources")}<br />
                        <a href={isSpanish
                                ? `${config.srpnetBaseUrlEs}servicio-al-cliente/seguridad/apagones-tormentas`
                                : `${config.srpnetBaseUrl}customer-service/safety/outages-storm`} target="_new">{t("Go to Outage Resources")} &raquo;</a>
                    </div>
                </div>
                <div className="srp-card-details">
                    <OutageAlertDialog
                        t={t}
                        selectedBillAccount={selectedBillAccount}
                        primaryEmail={primaryEmail}
                        outageAlertState={outageAlertState}
                        outageAlert={outageAlert}
                        toggleOutageAlert={toggleOutageAlert}
                    />
                </div>
                <div className="d-block d-lg-none">
                    <div className="srp-card-details">
                    {t("For_more_info_visit_SRP_outage_resources")}<br />
                        <a href={isSpanish
                                ? `${config.srpnetBaseUrlEs}servicio-al-cliente/seguridad/apagones-tormentas`
                                : `${config.srpnetBaseUrl}customer-service/safety/outages-storm`} target="_new">{t("Go to Outage Resources")} &raquo;</a>
                    </div>
                    <div>
                        <Divider />
                        <div className="srp-card-details">
                            <div className="d-flex justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Link to="/myaccount/outages" className="btn srp-btn btn-lightblue mb-2">{t("View map")}</Link>
                                {(hasReported && !isReportedOutageClosed)
                                    || hasMedicalSupportOption
                                    || isIneligibleToReport
                                    || isPrePay
                                    || isSummaryMaster
                                    ? null
                                    : <Link
                                        to="/myaccount/reportOutage"
                                        className="btn srp-btn btn-orange ml-2 mb-2">
                                        {t("Report outage")}
                                        </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

CardOutage.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    selectedBillAccount: PropTypes.number,
    primaryEmail: PropTypes.string,
    outageAlertState: PropTypes.bool,
    outageAlert: PropTypes.object,
    toggleOutageAlert: PropTypes.func,
    serviceReliability: PropTypes.number,
    hasReported: PropTypes.bool,
    isReportedOutageClosed: PropTypes.bool,
    hasMedicalSupportOption: PropTypes.bool,
    isIneligibleToReport: PropTypes.bool,
    isPrePay: PropTypes.bool,
    isSummaryMaster: PropTypes.bool,
    hasOutageAlert: PropTypes.bool
}

export default CardOutage