import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import config from 'my-account-config'

const BusinessStartCard = ({t, i18n}) => {
    const isSpanish = i18n.language === "es"

    return (
        <div>
        <div className="srp-card-header mt-4">
            {t("Looking for business electric service?")}
        </div>

        <div className="srp-card-body">
            <div className="srp-card-details srp-orange-background">
                {t("For service call (602) 236-8833 weekdays from 7 AM - 5 PM excluding holidays.")}&nbsp;
                <a className="displayAsLink" href={isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/pago-factura-electrica`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`}>
                    {t("Learn more »")}
                </a>
            </div>
        </div>
    </div>)
}

BusinessStartCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("startStopTransfer")(BusinessStartCard)