import React, {Fragment, useState} from "react"
import PropTypes from 'prop-types'

import creditCardIconGrey from '../../../images/payment/credit-card-grey.png'
import creditCardIconWhite from '../../../images/payment/credit-card-white.png'
import outgoingLinkIcon from '../../../images/nav/glyphicons-390-new-window-alt.png'

import SingleAccountReconnectDialog from '../../reconnect_notice/single-account-cash-recconnect-modal'

const iconSize = {
    height: "32px", width: "32px"
}

const mobileIconSize = {
    height: "24px", width: "24px"
}

const outgoingLinkIconSize = {
    height: "8px", width: "8px"
}

const PayByCreditCardLink = ({ creditCardPaymentUrl, selectedBillAccountDetails, isSolar, sendReconnectRemark, t }) => {

    const [reconnectDialogOpen, setReconnectDialogOpen] = useState(false)
    const [navUrl, setNavUrl] = useState('')
    const isUserDisconnected = selectedBillAccountDetails.isCommercial == false && selectedBillAccountDetails.billingStatusCode === "19"

    const disableLink = (creditCardPaymentUrl === '' || creditCardPaymentUrl === "#")
    const target = disableLink ? "_self" : "_blank"

    return (
        <Fragment>
        {
            !sessionStorage.getItem("SafetyDialogOpened") && reconnectDialogOpen ?
            <SingleAccountReconnectDialog 
                setReconnectDialogOpen={setReconnectDialogOpen} 
                isSolar={isSolar} 
                navUrl={navUrl}
                selectedBillAccountDetails={selectedBillAccountDetails} 
                sendReconnectRemark={sendReconnectRemark} 
            />
            : null
        }    
        <div>
            <div className="d-none d-md-block">
                <a
                    onClick={(e)=>{
                        if(!sessionStorage.getItem("SafetyDialogOpened") && isUserDisconnected){
                            e.preventDefault()
                            setReconnectDialogOpen(true)
                            setNavUrl(creditCardPaymentUrl)
                        }
                    }}
                    href={creditCardPaymentUrl}
                    target={target}
                    className="nav-link d-flex flex-column align-items-center"
                    disabled={disableLink}>
                    <img src={creditCardIconGrey} style={iconSize} className="icon" />
                    <img src={creditCardIconWhite} style={iconSize} className="iconSelected" />
                    <div>
                        {t("Credit/debit card")}<br />
                        <div className="clearfix">
                            <small className="mr-1">{t("(additional fee)")}</small>
                            <img src={outgoingLinkIcon} style={outgoingLinkIconSize} className="pull-right" />
                        </div>
                    </div>
                </a>
            </div>
            <div className="d-block d-md-none">
                <a
                    onClick={(e)=>{
                        if(!sessionStorage.getItem("SafetyDialogOpened") && isUserDisconnected){
                            e.preventDefault()
                            setReconnectDialogOpen(true)
                            setNavUrl(creditCardPaymentUrl)
                        }
                    }}
                    href={creditCardPaymentUrl}
                    target={target}
                    className="nav-link d-flex justify-content-start align-items-center p-1"
                    disabled={disableLink}>
                    <img src={creditCardIconGrey} style={mobileIconSize} className="icon ml-1 mr-2" />
                    <img src={creditCardIconWhite} style={mobileIconSize} className="iconSelected ml-1 mr-2" />
                    <div>
                        <span>{`${t("Credit/debit")} `}</span>
                        <span className="clearfix">
                            <span className="mr-1">{t("(add. fee)")}</span>
                            <img src={outgoingLinkIcon} style={outgoingLinkIconSize} className="pull-right" />
                        </span>
                    </div>
                </a>
            </div>
        </div>
        </Fragment>
    )
}

PayByCreditCardLink.propTypes = {
    creditCardPaymentUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

export default PayByCreditCardLink