import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import MyMPowerCard from './my-mpower-card'
import PurchaseHistoryCard from './purchase-history-card'
import BillPageLoading from '../bill-page-loading'
import BillPageError from '../bill-page-error'

import { getPrepayPurchaseHistoryDetails } from '../../../actions/auth/payment/prepay-history-actions'
import { getPayment } from '../../../actions/auth/payment/payment-actions'
import { getEBillList } from '../../../actions/auth/document/ebill-actions'
import { getSelectedBill } from '../../../actions/auth/bill_page/view-bill-actions'
import {
    getPurchaseHistStartMonth,
    getPurchaseHistEndMonth,
    getPurchaseHistoryByDate,
    getPrevPurchaseHistory,
    getNextPurchaseHistory
} from '../../../actions/auth/bill_page/purchase-history-table-actions'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { withTranslation } from 'react-i18next'

class MyPurchasesPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPurchaseHistoryDetails: []
        }

        this.setInitialPurchaseDetailButtons = this.setInitialPurchaseDetailButtons.bind(this)
        this.showPurchaseDetails = this.showPurchaseDetails.bind(this)
        this.refreshPurchaseHistoryPage = this.refreshPurchaseHistoryPage.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.purchaseHistoryDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getPrepayPurchaseHistoryDetails(this.props.selectedBillAccount)
        }

        if (this.props.paymentStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getPaymentInfo(this.props.selectedBillAccount)
        }

        if (this.props.purchaseHistoryDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            this.setInitialPurchaseDetailButtons(this.props.purchasesHistoryByMonth)
        }

        if (this.props.eBillStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getEBillListInfo(this.props.selectedBillAccount)
        }

        if (this.props.eBillStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.viewBillStatus === loadingStatus.LOADING_STATUS_INIT) {
            if (this.props.eBillDocumentList.length > 0) {
                this.props.getUrlsForSelectedBill(0, this.props.eBillDocumentList[0])
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.eBillStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && nextProps.viewBillStatus === loadingStatus.LOADING_STATUS_INIT) {
            if (nextProps.eBillDocumentList.length > 0) {
                nextProps.getUrlsForSelectedBill(0, nextProps.eBillDocumentList[0])
            }
        }

        if (this.props.purchasesHistoryByMonth !== nextProps.purchasesHistoryByMonth) {
            this.setInitialPurchaseDetailButtons(nextProps.purchasesHistoryByMonth)
        }

        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.purchaseHistoryDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getPrepayPurchaseHistoryDetails(nextProps.selectedBillAccount)
        }

        if (nextProps.paymentStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getPaymentInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.eBillStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEBillListInfo(nextProps.selectedBillAccount)
        }
    }

    setInitialPurchaseDetailButtons(purchaseHistory) {
        let showDetailsButtons = []
        for (let i = 0; i < purchaseHistory.length; i++) {
            showDetailsButtons.push(false)
        }

        this.setState({
            isShowPurchaseHistoryDetails: showDetailsButtons
        })
    }

    showPurchaseDetails(index) {
        let newIsShowPurchaseDetails = []
        for (let i = 0; i < this.state.isShowPurchaseHistoryDetails.length; i++) {
            if (i === index) {
                newIsShowPurchaseDetails.push(!this.state.isShowPurchaseHistoryDetails[i])
            }
            else {
                newIsShowPurchaseDetails.push(this.state.isShowPurchaseHistoryDetails[i])
            }
        }

        this.setState({
            isShowPurchaseHistoryDetails: newIsShowPurchaseDetails
        })
    }

    refreshPurchaseHistoryPage() {
        if (this.props.purchaseHistoryDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getPrepayPurchaseHistoryDetails(this.props.selectedBillAccount)
    }

    render() {
        let renderedCard = {}
        if (this.props.purchaseHistoryDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.purchaseHistoryDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.eBillStatus === loadingStatus.LOADING_STATUS_INIT || this.props.eBillStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.paymentStatus === loadingStatus.LOADING_STATUS_INIT || this.props.paymentStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            renderedCard = <BillPageLoading />
        }
        else if (this.props.purchaseHistoryDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.eBillStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.paymentStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            if(this.props.selectedBillAccountDetails.closedState === billAccountConstants.CLOSED_STATE.CLOSED
                && this.props.purchaseHistoryDetails.length === 0) {
                renderedCard = <BillPageError dataThatFailed={this.props.t("purchase history")} refreshData={this.refreshPurchaseHistoryPage} />
            }
            else {
                renderedCard =
                    (
                    <div>
                    <MyMPowerCard
                        eBillDocumentList={this.props.eBillDocumentList}
                        selectedBillName={this.props.selectedBillName}
                        selectedBillDownloadUrl={this.props.selectedBillDownloadUrl}
                        selectedBillViewUrl={this.props.selectedBillViewUrl}
                        getUrlsForSelectedBill={this.props.getUrlsForSelectedBill}
                        amountDue={this.props.payment.amountDue}
                        i18n={this.props.i18n}
                        t={this.props.t}
                    />

                    <div className="srp-card-header mt-4">{this.props.t("Purchase history")}</div>
                    <PurchaseHistoryCard
                        purchasesHistoryByMonth={this.props.purchasesHistoryByMonth}
                        grandTotals={this.props.grandTotals}
                        amountDue={this.props.payment.amountDue}
                        numOfMonthsDisplayedStart={this.props.numOfMonthsDisplayedStart}
                        numOfMonthsDisplayedEnd={this.props.numOfMonthsDisplayedEnd}
                        getStartMonthOnChange={this.props.getStartMonthOnChange}
                        getEndMonthOnChange={this.props.getEndMonthOnChange}
                        startMonth={this.props.startMonth}
                        endMonth={this.props.endMonth}
                        listOfStartMonths={this.props.listOfStartMonths}
                        listOfEndMonths={this.props.listOfEndMonths}
                        getPurchaseHistoryByDateOnClick={this.props.getPurchaseHistoryByDateOnClick}
                        isShowPurchaseHistoryDetails={this.state.isShowPurchaseHistoryDetails}
                        showPurchaseDetails={this.showPurchaseDetails}
                        purchasHistoryIndex={this.props.purchasHistoryIndex}
                        getPrevPurchaseHistoryOnClick={this.props.getPrevPurchaseHistoryOnClick}
                        getNextPurchaseHistoryOnClick={this.props.getNextPurchaseHistoryOnClick}
                        selectedBillAccount={this.props.selectedBillAccount}
                        serviceAddress={this.props.serviceAddress.fullStreetAddress}
                        customerName={this.props.customerNames.displayName}
                        flattenedPurchaseHistoryDetails={this.props.flattenedPurchaseHistoryDetails} 
                        t={this.props.t}
                    />
                    </div>
                    )
            }
        }
        else {
            renderedCard = <BillPageError dataThatFailed={this.props.t("purchase history")} refreshData={this.refreshPurchaseHistoryPage} />
        }

        return (
            <div className="row">
                <div className="col-12 mt-3">
                    <div className="srp-card-header">{this.props.t("My M-Power")}</div>
                    {renderedCard}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.accountInfo.billAccount, ...state.purchaseHistoryTable, ...state.prepayHistory, ...state.accountInfo.payment,
        eBillStatus: state.eBill.eBillStatus,
        eBillDocumentList: state.eBill.eBillDocumentList,
        selectedBillName: state.viewBill.selectedBillName,
        selectedBillDownloadUrl: (state.viewBill.viewBillStatus === loadingStatus.LOADING_STATUS_SUCCESS ? state.viewBill.downloadPdf : ""),
        selectedBillViewUrl: (state.viewBill.viewBillStatus === loadingStatus.LOADING_STATUS_SUCCESS ? state.viewBill.documentPdf : ""),
        viewBillStatus: state.viewBill.viewBillStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPrepayPurchaseHistoryDetails: (billAccount) => {
            dispatch(getPrepayPurchaseHistoryDetails(billAccount))
        },
        getPaymentInfo: (billAccount) => {
            dispatch(getPayment(billAccount))
        },
        getEBillListInfo: (billAccount) => {
            dispatch(getEBillList(billAccount))
        },
        getUrlsForSelectedBill: (selectedBillIndex, eBillDocument) => {
            dispatch(getSelectedBill(selectedBillIndex, eBillDocument))
        },
        getStartMonthOnChange: (month) => {
            dispatch(getPurchaseHistStartMonth(month))
        },
        getEndMonthOnChange: (month) => {
            dispatch(getPurchaseHistEndMonth(month))
        },
        getPurchaseHistoryByDateOnClick: (start, end) => {
            dispatch(getPurchaseHistoryByDate(start, end))
        },
        getPrevPurchaseHistoryOnClick: () => {
            dispatch(getPrevPurchaseHistory())
        },
        getNextPurchaseHistoryOnClick: (lengthOfList) => {
            dispatch(getNextPurchaseHistory(lengthOfList))
        }
    }
}

MyPurchasesPageContainer.propTypes = {
    navLocation: PropTypes.string,
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    serviceAddress: PropTypes.object,
    customerNames: PropTypes.object,

    purchasesHistoryByMonth: PropTypes.arrayOf(PropTypes.object),
    flattenedPurchaseHistoryDetails: PropTypes.arrayOf(PropTypes.object),
    purchaseHistoryDetails: PropTypes.array,
    grandTotals: PropTypes.object,
    purchaseHistoryDetailsStatus: PropTypes.string,
    getPrepayPurchaseHistoryDetails: PropTypes.func,

    payment: PropTypes.object,
    paymentStatus: PropTypes.string,
    getPaymentInfo: PropTypes.func,

    eBillStatus: PropTypes.string.isRequired,
    eBillDocumentList: PropTypes.arrayOf(PropTypes.shape({
        documentID: PropTypes.string.isRequired,
        billingID: PropTypes.number.isRequired,
        documentDate: PropTypes.string.isRequired,
        documentDisplayDate: PropTypes.string.isRequired,
        documentType: PropTypes.number.isRequired,
    })),
    getEBillListInfo: PropTypes.func.isRequired,

    getUrlsForSelectedBill: PropTypes.func.isRequired,
    selectedBillName: PropTypes.string.isRequired,
    selectedBillDownloadUrl: PropTypes.string.isRequired,
    selectedBillViewUrl: PropTypes.string.isRequired,
    viewBillStatus: PropTypes.string.isRequired,

    numOfMonthsDisplayedStart: PropTypes.number,
    numOfMonthsDisplayedEnd: PropTypes.number,

    getStartMonthOnChange: PropTypes.func,
    getEndMonthOnChange: PropTypes.func,
    startMonth: PropTypes.string,
    endMonth: PropTypes.string,
    listOfStartMonths: PropTypes.array,
    listOfEndMonths: PropTypes.array,
    getPurchaseHistoryByDateOnClick: PropTypes.func,

    purchasHistoryIndex: PropTypes.number,
    getPrevPurchaseHistoryOnClick: PropTypes.func,
    getNextPurchaseHistoryOnClick: PropTypes.func,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired
}

export default withTranslation("myPurchasePage")(connect(mapStateToProps, mapDispatchToProps)(MyPurchasesPageContainer))