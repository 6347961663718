import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import config from 'my-account-config'

import CallAnytime from '../../myaccount_header/call-anytime'
import { myAccountConstants } from '../../../constants/myaccount-constants'

const GuestPaymentLockedPagePure = ({t, i18n}) => {
    const language = i18n.language
    const isSpanish = language==="es"

    const supportPhoneNumber = isSpanish
        ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
        : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
    const supportPhoneNumberFormatted = isSpanish
        ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    const paymentOptionsUrl = isSpanish
        ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/pago-factura-electrica`
        : `${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`

    return (
        <div className="container py-3">
            <CallAnytime />

            <h3 className="mt-3 mb-4">{t("Banking features locked")}</h3>

            <div className="row">
                <div className="col-lg-6">
                    <div className="srp-card-header">{t("Some features have been disabled")}</div>
                    <div className="srp-card-body srp-card-details">
                        <div className="d-none d-md-block">
                            {t("account_locked_and_support_number")}{supportPhoneNumberFormatted}.
                        </div>
                        <div className="d-md-none">
                            {t("account_locked_and_support_number")}
                            <a href={"tel:" + supportPhoneNumber}>
                                {supportPhoneNumberFormatted}
                            </a>.
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            <a href={paymentOptionsUrl} className="btn srp-btn btn-lightblue">{t("Go to payment options")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

GuestPaymentLockedPagePure.propTypes = {
    t:  PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const GuestPaymentLockedPage = withTranslation("guestPayment")(GuestPaymentLockedPagePure)
export { GuestPaymentLockedPage as default, GuestPaymentLockedPagePure }