/* eslint-disable react/no-did-update-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { enUS, esES } from '@mui/x-date-pickers/locales'
import { CustomLuxonDateAdapter } from '../../srp_modules/custom-luxon-date-adapter'
import { changeHourlyDateRange } from '../../actions/auth/usage/usage-actions'

class HourlyDateRangePickers extends React.Component{
    constructor(props){
        super(props)
        this.state={
            startDate:this.props.hourlyStartDate,
            endDate: this.props.hourlyEndDate,
            maxCalendarDate: this.props.dailyMaxDate.startOf('day')
        }
    }

    componentDidUpdate(prevProps) {
        if(!this.props.hourlyEndDate.hasSame(prevProps.hourlyEndDate, 'day')
            || !this.props.hourlyStartDate.hasSame(prevProps.hourlyStartDate, 'day')
        )
            this.setState({endDate: this.props.hourlyEndDate, startDate: this.props.hourlyStartDate})
    }

    shouldDisableStartDate(date){
        if(date.setZone('MST') > this.state.endDate.startOf('day'))
            return true
        if(date.setZone('MST') < this.props.dailyMinDate.startOf('day'))
            return true
        return false
    }
    shouldDisableEndDate(date) {
        let dateMidnight = date.setZone('MST').startOf('day')
        if(dateMidnight < this.state.startDate.startOf('day'))
            return true
        if(dateMidnight > this.props.dailyMaxDate.startOf('day'))
            return true
        return false
    }

    render() {
        const i18n = this.props.i18n
        const isSpanish = (i18n.language === "es")
        const locale = isSpanish ? "es-MX" : "en-US"
        const localeText = (isSpanish ? esES : enUS).components.MuiLocalizationProvider.defaultProps.localeText

        return(
            <div className="mr-4 ml-4 mt-3">
            <div className="mb-2 d-none d-lg-flex row">
                <div className="pt-1 col-5">
                <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale={locale} localeText={localeText}>
                    <DatePicker
                        disableFuture
                        keyboard
                        format="M/d/yyyy"
                        label={this.props.t("Start date")}
                        shouldDisableDate={(date)=>{return this.shouldDisableStartDate(date)}}
                        value={this.state.startDate}
                        onChange={(value)=>{this.setState({startDate: value})}}
                        animateYearScrolling={false}
                        minDate={this.props.dailyMinDate}
                        maxDate={this.state.endDate}
                        invalidDateMessage={"Enter valid date (m/d/yyyy)"}
                        maxDateMessage={"Invalid date range"}
                        minDateMessage={"Invalid date range"}
                        className="mr-4 chart-date-picker"
                        slotProps={{ textField: { fullWidth: true }}}
                    />
                </LocalizationProvider>
                </div>
                <div className="pt-1 col-5">
                <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale={locale} localeText={localeText}>
                    <DatePicker
                        disableFuture
                        keyboard
                        label={this.props.t("End date")}
                        format="M/d/yyyy"
                        value={this.state.endDate}
                        minDate={this.state.startDate}
                        maxDate={this.state.maxCalendarDate}
                        shouldDisableDate={(date)=>{return this.shouldDisableEndDate(date)}}
                        onChange={(value)=>{this.setState({endDate: value})}}
                        animateYearScrolling={false}
                        invalidDateMessage={"Enter valid date (m/d/yyyy)"}
                        maxDateMessage={"Invalid date range"}
                        minDateMessage={"Invalid date range"}
                        className="mr-4 chart-date-picker"
                        slotProps={{ textField: { fullWidth: true }}}
                    />
                </LocalizationProvider>
                </div>
                <div className="col-2 mt-4">
                <button type="button"
                    className="btn srp-btn btn-green text-white"
                    value="Button"
                    onClick={()=>this.props.changeDateRange(this.props.selectedBillAccount,this.state.startDate,this.state.endDate)}>
                    {this.props.t("Submit")}
                </button>
                </div>
            </div>

            <div className="mb-2 d-block d-lg-none">
            <div>
                <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale={locale} localeText={localeText}>
                <DatePicker
                    disableFuture
                    keyboard
                    label={this.props.t("Start date")}
                    format="M/d/yyyy"
                    shouldDisableDate={(date)=>{return this.shouldDisableStartDate(date)}}
                    value={this.state.startDate}
                    onChange={(value)=>{this.setState({startDate: value})}}
                    animateYearScrolling={false}
                    minDate={this.props.dailyMinDate}
                    maxDate={this.state.endDate}
                    invalidDateMessage={"Enter valid date (m/d/yy)"}
                    maxDateMessage={"Invalid date range"}
                    minDateMessage={"Invalid date range"}
                    className="mr-2 chart-date-picker"
                    slotProps={{ textField: { fullWidth: true }}}
                />
                </LocalizationProvider>
            </div>
            <div>
                <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale={locale} localeText={localeText}>
                <DatePicker
                    disableFuture
                    keyboard
                    label={this.props.t("End date")}
                    format="M/d/yyyy"
                    value={this.state.endDate}
                    shouldDisableDate={(date)=>{return this.shouldDisableEndDate(date)}}
                    onChange={(value)=>{this.setState({endDate: value})}}
                    animateYearScrolling={false}
                    minDate={this.state.startDate}
                    maxDate={this.state.maxCalendarDate}
                    invalidDateMessage={"Enter valid date (m/d/yy)"}
                    maxDateMessage={"Invalid date range"}
                    minDateMessage={"Invalid date range"}
                    className="mr-2 chart-date-picker"
                    slotProps={{ textField: { fullWidth: true }}}
                />
                </LocalizationProvider>
            </div>
            <div className="d-flex justify-content-end">
                <button type="button"
                    className="btn srp-btn btn-green text-white "
                    value="Button"
                    onClick={()=>this.props.changeDateRange(this.props.selectedBillAccount,this.state.startDate,this.state.endDate)}>
                    {this.props.t("Submit")}
                </button>
            </div>

        </div>

            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        ...state.accountInfo.usage,
        ...state.accountInfo.usagePageUI,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        rateMetaData: state.rateMetaData

    }
}
const mapDispatchToProps = dispatch => {
    return {
            changeDateRange: (billAccount,startDate,endDate) => {
            dispatch(changeHourlyDateRange(startDate,endDate))

        }
    }
}
HourlyDateRangePickers.propTypes = {
    selectedBillAccount: PropTypes.number,
    hourlyStartDate: PropTypes.object,
    hourlyEndDate: PropTypes.object,
    dailyMinDate: PropTypes.object,
    dailyMaxDate: PropTypes.object,
    changeDateRange: PropTypes.func,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(HourlyDateRangePickers);