/* eslint react/no-multi-comp: "off" */

import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'

import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import TextField from '@mui/material/TextField'
import { NumericFormat, PatternFormat } from 'react-number-format'
import format from '../../../srp_modules/format'
import utils from '../../../srp_modules/utils'
import Icon from '@mui/material/Icon'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

const toggleMarginStyle = {
    textAlign: "left",
    marginTop: "0",
    marginBottom: "0",
    marginRight: "12px"
}

const PayableAccountRowPure = (props) => {
    const getCashOnlyElement = () => {
        return (<SrpTooltip content={<span>{props.t("account_status_cashier_check_money_order")}</span>}>
            <Icon style={{ color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
        </SrpTooltip>)
    }

    const getAmountDue = (paymentInfo) => {
        let amountDue = paymentInfo.paymentInfo.amountDue
        if (amountDue < 0) amountDue = 0
        return Math.abs(amountDue).toLocaleString("en-US", { style: "currency", currency: "USD" })
    }

    const getWarningStyle = (errorLevel) => {
        let color = "rgb(158,42,43)"

        if (errorLevel === myAccountConstants.ERROR_LEVEL.WARNING) {
            color = "rgb(204,138,0)"
        }

        return { color: color }
    }

    const getPaymentAmountErrorText = (paymentAmountError) => {
        const errorType = paymentAmountError.errorType
        let text = ""
        switch (errorType) {
            case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.INVALID_AMOUNT:
                text = props.t("Invalid payment amount")
                break
            case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.PAYMENT_LIMIT:
                {
                    let maxPaymentAmountDisplay = utils.delimitNumbers(paymentAmountError.paymentHardLimit)
                    text = props.t("Payments_of_over", { amount: maxPaymentAmountDisplay })
                }
                break
            case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.REQUIRED:
                text = props.t("This field is required")
                break
        }

        return text
    }

    const getInitialPurchaseValue = (selectedInfo) => {
        let initialPurchase = ''

        if (selectedInfo.isSelected) {
            initialPurchase = selectedInfo.purchaseAmount
        }

        return initialPurchase
    }

    const getInitialPayAmountOwedToSRPValue = (selectedInfo) => {
        let initialValue = false

        if (selectedInfo.isSelected) {
            initialValue = selectedInfo.payAmountOwedToSRP
        }

        return initialValue
    }

    const getRowCheckbox = (billAccount, paymentInfo, disabled, isSelected, totalSelected, getSelectedMpowerAccountOnClick) => {
        if (disabled) {
            return (
                <Checkbox
                    onChange={() => getSelectedMpowerAccountOnClick(billAccount, paymentInfo)}
                    disabled={true}
                    color="primary"
                    checked={isSelected}
                />
            )
        } else {
            const allowClick = (totalSelected < multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS || isSelected === true)
            let tooltipText = allowClick
                ? `${totalSelected}/${multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS} ${props.t("Selected")}`
                : props.t("Payable account limit reached")
            return (
                <SrpTooltip content={<span>{tooltipText}</span>} placement="bottom">
                    <Checkbox
                        onChange={() => { if (allowClick) getSelectedMpowerAccountOnClick(billAccount, paymentInfo) }}
                        disabled={false}
                        color="primary"
                        checked={isSelected}
                    />
                </SrpTooltip>
            )
        }
    }

    const getRowTitle = (paymentInfo, viewBillClick) => {
        if (paymentInfo.accountName && paymentInfo.accountName.trim() !== '') {
            return (
                <div style={{ fontSize: '18px', fontWeight: '800' }}>
                    <span>
                        <button
                            className="displayAsLink text-truncate"
                            type="button"
                            onClick={() => viewBillClick(paymentInfo.billAccount)}>
                            {paymentInfo.accountName}
                        </button>
                    </span>
                    &nbsp;
                    <span style={{ color: '#333333', fontWeight: '500' }}>
                        <PatternFormat
                            value={format.srpAccountNumber(paymentInfo.billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                        />
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    <button className="displayAsLink" style={{ fontSize: '18px', fontWeight: '800' }} type="button" onClick={() => viewBillClick(paymentInfo.billAccount)}>
                        <PatternFormat
                            value={format.srpAccountNumber(paymentInfo.billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                        />
                    </button>
                </div>
            )
        }
    }

    const purchaseAmountHasError = props.isSelected && props.selectedInfo.purchaseAmountError.hasError
    const isSpanish = (props.i18n.language==="es")

    return (
        <TableRow
            className="pat-payment-row pat-purchase-row"
            role="checkbox"
            aria-checked={props.isSelected}
            tabIndex={-1}
            selected={props.isSelected}
            style={{ height: "70px" }}
        >
            <TableCell padding="none" className="pl-2">
                {getRowCheckbox(props.paymentInfo.billAccount, props.paymentInfo.paymentInfo, props.disabled, props.isSelected, props.totalSelected, props.getSelectedMpowerAccountOnClick)}
            </TableCell>
            <TableCell style={{ color: '#087ED8' }} padding="none">
                <div className="d-flex">
                <div>
                    {getRowTitle(props.paymentInfo, props.viewBillClick)}
                    <div style={{ color: '#333333', fontSize: '15px', fontWeight: '500' }}>
                        {props.paymentInfo.serviceAddress.streetAddress}
                    </div>
                </div>
                {!props.paymentInfo.paymentInfo.canMakeEChexPayment &&
                    <div className="pl-1" style={{ marginTop: '15px' }}>{getCashOnlyElement()}</div>
                }
                </div>
            </TableCell>
            <TableCell className="text-right pl-0 pr-2 py-0">
                {getAmountDue(props.paymentInfo)}
            </TableCell>
            {props.showArrearsAndBreakdownCols &&
                <TableCell className="text-right pl-0 pr-2 py-0">
                    <label className="switch" style={toggleMarginStyle}>
                        <input
                            type="checkbox"
                            onChange={() => { props.payAmountOwedToSrpClick(props.paymentInfo.billAccount) }}
                            checked={getInitialPayAmountOwedToSRPValue(props.selectedInfo)}
                            disabled={props.paymentInfo.paymentInfo.amountDue <= 0 || props.disabled}
                        />
                        <span className={"yesNoToggle" + (isSpanish ? " es" : "")} />
                    </label>
                </TableCell>
            }
            <TableCell padding="none" align="right">
                {props.paymentInfo.paymentInfo.canMakeEChexPayment
                    ?
                    <div className="d-flex flex-row align-items-center align-content-end pr-2">
                        <NumericFormat
                            id={"paymentAmount" + props.index}
                            placeholder={props.paymentInfo.hasError ? "" : props.t("Enter amount")}
                            disabled={props.disabled}
                            value={getInitialPurchaseValue(props.selectedInfo)}
                            valueIsNumericString={true}
                            onBlur={(values) => {
                                const purchaseAmount = values.target.value
                                if (purchaseAmount === "") {
                                    if (props.isSelected)
                                        props.getSelectedMpowerAccountOnClick(props.paymentInfo.billAccount, props.paymentInfo.paymentInfo)
                                }
                                else {
                                    let fixedPurchaseAmount = Number(purchaseAmount.replace(/[$,]/g, '')).toFixed(2)
                                    props.getPurchaseAmountOnChange(props.paymentInfo.billAccount, fixedPurchaseAmount)
                                }
                            }}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                            allowNegative={false}
                            customInput={TextField}
                            type="text"
                            InputProps={{
                                inputProps: {
                                    inputMode: 'decimal',
                                    style: {
                                        textAlign: 'right'
                                    }
                                }
                            }}
                            className="pat-input-box ngp mr-1"
                        />
                        <div className={purchaseAmountHasError ? "visible" : "invisible"}>
                            <SrpTooltip content={<span>{purchaseAmountHasError ? getPaymentAmountErrorText(props.selectedInfo.purchaseAmountError) : ''}</span>}>
                                <Icon style={{ ...getWarningStyle(purchaseAmountHasError ? props.selectedInfo.purchaseAmountError.errorLevel : myAccountConstants.ERROR_LEVEL.NONE), verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                            </SrpTooltip>
                        </div>
                    </div>
                    :
                    <div className="d-flex align-items-center justify-content-end pr-2">
                        - -
                    </div>
                }
            </TableCell>
            {props.showArrearsAndBreakdownCols &&
                <TableCell className="text-right pl-0 pr-2 py-0">
                    {props.purchaseBreakdown.amountToMeter}
                </TableCell>
            }
            {props.showArrearsAndBreakdownCols &&
                <TableCell className="text-right pl-0 pr-3 py-0">
                    {props.purchaseBreakdown.amountToSRP}
                    {props.purchaseBreakdown.payDownPercent &&
                        <span className="text-muted"> {props.purchaseBreakdown.payDownPercent}</span>
                    }
                </TableCell>
            }
        </TableRow>
    )
}

PayableAccountRowPure.propTypes = {
    paymentInfo: PropTypes.object.isRequired,
    viewBillClick: PropTypes.func.isRequired,
    selectedInfo: PropTypes.object,
    purchaseBreakdown: PropTypes.shape({
        amountToMeter: PropTypes.string.isRequired,
        amountToSRP: PropTypes.string.isRequired,
        payDownPercent: PropTypes.string,
    }).isRequired,
    showArrearsAndBreakdownCols: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    totalAccounts: PropTypes.number.isRequired,
    totalSelected: PropTypes.number.isRequired,
    getSelectedMpowerAccountOnClick: PropTypes.func.isRequired,
    payAmountOwedToSrpClick: PropTypes.func.isRequired,
    getPurchaseAmountOnChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const PayableAccountRow = withTranslation("multiPurchasePage")(PayableAccountRowPure)
export { PayableAccountRow as default, PayableAccountRowPure }