import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import DeleteSafetyNetDialog from '../../forms/safety_net/delete-safety-net-dialog'
import { withRouter } from '../../../srp_modules/with-router'
import config from 'my-account-config'

class SafetyNetSettings extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showRemoveSafetyNetDialog: false
        }

        this.handleRemoveSafetyNetDialog = this.handleRemoveSafetyNetDialog.bind(this)
    }

    handleRemoveSafetyNetDialog(openDialog) {
        this.setState({
            showRemoveSafetyNetDialog: openDialog
        })
    }

    render() {

        let safetyNetComponent = (
            <div className="d-flex flex-row h-100 pt-lg-2 pb-2 pr-2">
                <div className="d-flex flex-wrap align-self-center" style={{ flex: '1' }}>
                    <div className="d-flex flex-wrap mr-2 p-lg-0 pl-lg-1 pl-1" style={{ maxWidth: '100%' }}>
                        {this.props.t("If a bill becomes due, we'll let someone know")}
                    </div>
                </div>
                <div className="text-right align-self-center">
                    <Link
                        className=""
                        to="/forms/safetynet/signup">
                        {this.props.t("Add partner")}
                    </Link>
                </div>
            </div>

        )

        if (this.props.safetyNetProgramInfo.isEnrolled)
            safetyNetComponent = (
                <div className="d-flex flex-row h-100">
                    <div className="d-flex flex-wrap align-self-center" style={{ flex: '1' }}>
                        <div
                            className="d-flex flex-wrap pr-2 mr-2 p-lg-0 pl-1 text-capitalize"
                            style={{ maxWidth: '100%' }}>
                            {this.props.safetyNetProgramInfo.safetyNetsName.toLowerCase()}
                        </div>
                    </div>
                    <div className="text-right">
                        <IconButton
                            className="align-self-center"
                            onClick={() => this.props.router.navigate('/forms/safetynet/change', { replace: true })}
                            color="primary"
                            size="large">
                            <i className="material-icons">create</i>
                        </IconButton>
                        <IconButton
                            className="align-self-center"
                            onClick={() => this.handleRemoveSafetyNetDialog(true)}
                            color="primary"
                            size="large">
                            <i className="material-icons">delete</i>
                        </IconButton>
                    </div>
                </div>
            )

        const isSpanish = this.props.i18n.language==="es"

        return (
            <div className="row border-bottom">
                <div className="col-lg-3 d-flex align-self-center mr-2">
                    <div className="text-muted text-nowrap p-lg-2 pt-2 pl-2 pb-0">
                    <a href={isSpanish
                        ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/alertas-safety-net`
                        : `${config.srpnetBaseUrl}account/electric/manage-bill/safety-net-alerts`} target="_blank">
                        {this.props.t("SRP Safety Net partner")}
                    </a>
                    </div>
                </div>
                <div className="col-lg">
                    <div className="pl-1">{safetyNetComponent}</div>
                </div>
                <DeleteSafetyNetDialog
                    showDialog={this.state.showRemoveSafetyNetDialog}
                    handleDialog={this.handleRemoveSafetyNetDialog} />
            </div>
        )
    }
}

SafetyNetSettings.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    safetyNetProgramInfo: PropTypes.object
}

export default withRouter(SafetyNetSettings)