import { purchaseHistoryTableConstants } from '../../../constants/purchase-history-table-constants'
import { prepayHistoryConstants } from '../../../constants/payment/prepay-history-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { DateTime } from 'luxon'

const initialState = {
    numOfMonthsDisplayedStart: 0,
    numOfMonthsDisplayedEnd: 3,
    startMonth: "",
    endMonth:"",
    listOfMonths: [],
    listOfStartMonths: [],
    listOfEndMonths: [],
    isShowPurchaseHistoryDetails: [false, false, false],
    purchasesHistoryByMonth: [
        /* shape:
        {
            month: 0,
            year: 0,
            totalTransAmount: 0,
            totalAdjustments: 0,
            totalAmountDue: 0,
            totalPowerPurchase: 0,
            purchases:[]
        }
        */
    ],
    grandTotals: {
        transAmount: 0,
        adjustments: 0,
        amountDue: 0,
        powerPurchase: 0
    },
    flattenedPurchaseHistoryDetails: [],
    purchasHistoryIndex: 0
}

export default function purchaseHistoryTableReducer (state = initialState, action) {
    switch (action.type) {
        case purchaseHistoryTableConstants.GET_PURCHASE_START_MONTH: {
            let indexOfStartMonth = state.listOfMonths.indexOf(action.month)
            return { ...state,
                    startMonth: action.month,
                    listOfEndMonths: state.listOfMonths.slice(0, indexOfStartMonth+1),
                    purchasHistoryIndex: 0 }
        }
        case purchaseHistoryTableConstants.GET_PURCHASE_END_MONTH: {
            let indexOfEndMonth = state.listOfMonths.indexOf(action.month)
            return { ...state,
                    endMonth: action.month,
                    listOfStartMonths: state.listOfMonths.slice(indexOfEndMonth, state.listOfMonths.length+1),
                    purchasHistoryIndex: 0 }
        }
        case purchaseHistoryTableConstants.GET_PURCHASE_HIST_BY_DATE: {
            let indexOfStartMonth = state.listOfMonths.indexOf(action.startMonth)
            let indexOfEndMonth = state.listOfMonths.indexOf(action.endMonth)
            let historyForMobile = state.purchasesHistoryByMonth.slice(indexOfEndMonth, indexOfStartMonth+1)
            return { ...state,
                    numOfMonthsDisplayedStart: indexOfEndMonth,
                    numOfMonthsDisplayedEnd: indexOfStartMonth+1,
                    grandTotals: getTotals(state.purchasesHistoryByMonth, indexOfEndMonth, indexOfStartMonth+1),
                    flattenedPurchaseHistoryDetails: setPurchaseHistoryForMobile(historyForMobile),
                    purchasHistoryIndex: 0 }
        }
        case prepayHistoryConstants.GET_PREPAY_PURCHASE_HISTORY_DETAILS_SUCCESS:{
            if(action.payload.length !== 0) {
                let historyByMonth = getPurchaseHistoryTable(action.payload)
                let itemForStartMonth = historyByMonth.length-1
                if(historyByMonth.length >= 3) {
                    itemForStartMonth = 2
                }
                let allMonths = getListOfMonths(historyByMonth)
                let historyForMobile = historyByMonth.slice(0, itemForStartMonth+1)
                return { ...state,
                    purchasesHistoryByMonth: historyByMonth,
                    grandTotals: getTotals(historyByMonth, state.numOfMonthsDisplayedStart, itemForStartMonth+1),
                    startMonth: allMonths[itemForStartMonth],
                    endMonth: allMonths[0],
                    flattenedPurchaseHistoryDetails: setPurchaseHistoryForMobile(historyForMobile),
                    listOfMonths: allMonths,
                    listOfStartMonths: allMonths,
                    listOfEndMonths: allMonths.slice(0,itemForStartMonth+1),
                    purchasHistoryIndex: 0 }
            }
            else {
                return { ...initialState }
            }
        }

        case purchaseHistoryTableConstants.GET_PREV_PURCHASE_HISTORY:
            return { ...state, purchasHistoryIndex: state.purchasHistoryIndex === 0 ? 0 : state.purchasHistoryIndex - 1 }
        case purchaseHistoryTableConstants.GET_NEXT_PURCHASE_HISTORY:
            return { ...state, purchasHistoryIndex: state.purchasHistoryIndex === (action.lengthOfList - 1) ? action.lengthOfList : state.purchasHistoryIndex + 1 }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
            default:
            return state
    }
}

function getPurchaseHistoryTable(purchaseHistory){
    let newPurchseHistory = [
        {
            month: DateTime.fromISO(purchaseHistory[0].purchaseDate).get('month'),
            year: DateTime.fromISO(purchaseHistory[0].purchaseDate).get('year'),
            totalTransAmount: 0,
            totalAdjustments: 0,
            totalAmountDue: 0,
            totalPowerPurchase: 0,
            purchases:[]
        }
    ]

    let newIterator = 0
    for(let i = 0; i < purchaseHistory.length; i++){
        if(DateTime.fromISO(purchaseHistory[i].purchaseDate).get('month') === newPurchseHistory[newIterator].month
            && DateTime.fromISO(purchaseHistory[i].purchaseDate).get('year') === newPurchseHistory[newIterator].year){
                newPurchseHistory[newIterator].purchases.push(purchaseHistory[i])
                newPurchseHistory[newIterator].totalTransAmount += purchaseHistory[i].paidAmount
                newPurchseHistory[newIterator].totalAdjustments += purchaseHistory[i].adjustments
                newPurchseHistory[newIterator].totalAmountDue += purchaseHistory[i].amountToArrears
                newPurchseHistory[newIterator].totalPowerPurchase += purchaseHistory[i].powerCredit
        }
        else{
            newPurchseHistory.push({
                month: DateTime.fromISO(purchaseHistory[i].purchaseDate).get('month'),
                year: DateTime.fromISO(purchaseHistory[i].purchaseDate).get('year'),
                totalTransAmount: purchaseHistory[i].paidAmount,
                totalAdjustments: purchaseHistory[i].adjustments,
                totalAmountDue: purchaseHistory[i].amountToArrears,
                totalPowerPurchase: purchaseHistory[i].powerCredit,
                purchases:[purchaseHistory[i]]
            })
            newIterator++
        }
    }

    return newPurchseHistory
}

function getListOfMonths(purchasHistoryByMonth){
    let listOfMonths = []
    for(let i=0; i < purchasHistoryByMonth.length; i++){
        let monthDisplay = `${DateTime.now().set({ month: purchasHistoryByMonth[i].month }).toFormat('MMMM', {locale : 'en'})} ${purchasHistoryByMonth[i].year}`
        listOfMonths.push(monthDisplay)
    }

    return listOfMonths
}

function getTotals(purchaseHistory, start, end) {
    let totalTransAmount = 0
    let totalAdjustments = 0
    let totalAmountDue = 0
    let totalPowerPurchase = 0

    for(let i = start; i < end; i++){
        totalTransAmount += purchaseHistory[i].totalTransAmount
        totalAdjustments += purchaseHistory[i].totalAdjustments
        totalAmountDue += purchaseHistory[i].totalAmountDue
        totalPowerPurchase += purchaseHistory[i].totalPowerPurchase
    }

    let totals = {
        transAmount: totalTransAmount,
        adjustments: totalAdjustments,
        amountDue: totalAmountDue,
        powerPurchase: totalPowerPurchase
    }

    return totals
}

function setPurchaseHistoryForMobile(purchaseHistory) {
    let flattenedArray = purchaseHistory.map(item => {
        let array = []
        array.push(createArray(item, false))
        for(let i=0; i < item.purchases.length; i++){
            array.push(createArray(item.purchases[i], true))
        }

        return array
    }).reduce((a,b) => a.concat(b))

    return flattenedArray
}

function createArray(item, isDetail) {
    return {
        date: item.purchaseDate ? DateTime.fromISO(item.purchaseDate).toFormat('MMM d, yyyy')
                                : DateTime.now().set({ month: item.month }).toFormat('MMMM') + " " + item.year, // TODO: is item.month 0 or 1-based month index?
        transactionName: item.transactionDescription || (item.purchases.length <= 1 ? item.purchases.length + " transaction" : item.purchases.length + " transactions"),
        transactionAmount: item.paidAmount !== undefined ? item.paidAmount : item.totalTransAmount,
        adjustment: item.adjustments !== undefined ? item.adjustments : item.totalAdjustments,
        amountDue: item.amountToArrears !== undefined ? item.amountToArrears : item.totalAmountDue,
        powerPurchase: item.powerCredit !== undefined ? item.powerCredit : item.totalPowerPurchase,
        isPurchaseDetail: isDetail
    }
}