import React from "react"
import PropTypes from 'prop-types'
import Card from "@mui/material/Card"

import PayUsingBankAccountLink from './pay-using-bank-account-link'
import PayByCreditCardLinkContainer from './pay-by-credit-card-link-container'
import SetupAutopayLink from './setup-autopay-link'
import PayByCashCardLink from '../../cash_card_page/common_payment_page/pay-by-cash-card-link'

const MakePaymentByCard = ({ isEnrolledInAutopay, sendReconnectRemark, t, i18n }) => (
    <div>
        <label className="h5">{t("Make payment by")}</label>
        <Card className="p-4 d-none d-lg-block">
            <div className="payment-nav nav nav-justified">
                <div className="nav-item">
                    <PayUsingBankAccountLink
                        t={t}
                        i18n={i18n}
                    />
                </div>
                <div className="nav-item">
                    <PayByCreditCardLinkContainer
                        t={t}
                        i18n={i18n}
                        sendReconnectRemark={sendReconnectRemark}
                    />
                </div>
                <div className="nav-item">
                    <PayByCashCardLink navPath="/myaccount/payment/single/cash" />
                </div>
                <div className="nav-item">
                    <SetupAutopayLink
                        isEnrolledInAutopay={isEnrolledInAutopay}
                        t={t}
                        i18n={i18n}
                    />
                </div>
            </div>
        </Card>
        <Card className="d-block d-lg-none pt-4 pb-4 pl-2 pr-2">
            <div className="payment-nav">
                <div className="nav-item">
                    <PayUsingBankAccountLink
                        t={t}
                        i18n={i18n}
                    />
                </div>
                <div className="nav-item">
                    <PayByCreditCardLinkContainer
                        t={t}
                        i18n={i18n}
                        sendReconnectRemark={sendReconnectRemark}
                    />
                </div>
                <div className="nav-item">
                    <PayByCashCardLink navPath="/myaccount/payment/single/cash" />
                </div>
                <div className="nav-item">
                    <SetupAutopayLink
                        isEnrolledInAutopay={isEnrolledInAutopay}
                        t={t}
                        i18n={i18n}
                    />
                </div>
            </div>
        </Card>
    </div>
)

MakePaymentByCard.propTypes = {
    isEnrolledInAutopay: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default MakePaymentByCard