import React from 'react'
import PropTypes from 'prop-types'
import SinglePaymentSubmittedCard from './single-payment-submitted-card'

import CallAnytime from '../../myaccount_header/call-anytime'

const SinglePaymentSubmittedPage = ({makePaymentInfo, makePaymentResult, selectedBank, updateBankEmailAddressOnClick, authorizeReconnectFlag, isSolar, t, i18n}) => (
    <div className="container py-3">
        <CallAnytime />

        <h3 className="mt-3 mb-5">{t("Make a payment")}</h3>

        <div className="row">
            <div className="col-lg-6">
                <SinglePaymentSubmittedCard
                    makePaymentInfo={makePaymentInfo}
                    makePaymentResult={makePaymentResult}
                    selectedBank={selectedBank}
                    updateBankEmailAddressOnClick={updateBankEmailAddressOnClick}
                    authorizeReconnectFlag={authorizeReconnectFlag}
                    isSolar={isSolar}
                    t={t}
                    i18n={i18n}
                />
            </div>
        </div>
    </div>
)

SinglePaymentSubmittedPage.propTypes = {
    makePaymentInfo: PropTypes.object.isRequired,
    makePaymentResult: PropTypes.object.isRequired,
    selectedBank: PropTypes.object,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired,
    authorizeReconnectFlag: PropTypes.bool,
    isSolar: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default SinglePaymentSubmittedPage