import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import { withTranslation } from 'react-i18next'

import '../../styles/price-plan.css'

import { pricePlanConstants } from '../../constants/price-plan-constants'
import ResidentialSelectPlan from './residential-select-plan'
import CommercialSelectPlan from './commercial-select-plan'
import ResidentialGenerationSelectPlan from './residential-generation-select-plan'

function isCustomerGeneration(currentRateCode) {
    switch (currentRateCode) {
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_EXPORT:
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.GENERATION:
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.AVERAGE_DEMAND:
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV_EXPORT:
            return true
        default:
            return false
    }
}

class SelectPlan extends React.Component {

    constructor(props) {
        super(props)

        if (isCustomerGeneration(props.currentRateCode)) {
            props.selectNewPricePlanResidential(pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.UNKNOWN)
        }

        this.state = {
            showError: false
        }
        this.nextStep = this.nextStep.bind(this)
        this.selectNewPricePlanCommercial = this.selectNewPricePlanCommercial.bind(this)
        this.selectNewPricePlanResidential = this.selectNewPricePlanResidential.bind(this)
    }

    selectNewPricePlanCommercial(pricePlan) {
        this.setState({ showError: false })
        this.props.selectNewPricePlanCommercial(pricePlan)
    }

    selectNewPricePlanResidential(pricePlan) {
        this.setState({ showError: false })
        this.props.selectNewPricePlanResidential(pricePlan)
    }

    nextStep(isCommercial, selectedPricePlanResidential, selectedPricePlanCommercial, nextStep, setActiveStep) {
        let showError = false
        if (isCommercial) {
            if (selectedPricePlanCommercial === pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.UNKNOWN) {
                showError = true
            }
        } else if (selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.UNKNOWN) {
            showError = true
        }

        if (showError) {
            this.setState({ showError: true })
        } else {
            setActiveStep(nextStep)
        }
    }

    render() {
        const { activeStep, setActiveStep, isLoggedIn, isCommercial, currentRateCode, currentRateCodeDescription,
            selectedPricePlanResidential, selectedPricePlanCommercial, backButtonClick } = this.props

        const isSpanish = (this.props.i18n.language === 'es')

        const plansOverviewLink = isCommercial
            ? `${config.srpnetBaseUrl}price-plans/business-electric/overview` // business rate plans in English only
            : (isSpanish ? `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/comparar-planes`
                         : `${config.srpnetBaseUrl}price-plans/residential-electric/compare-plans`)

        return (
            <div>
                {currentRateCodeDescription.length !== 0
                    ? <div className="srp-alert-notice mb-4">
                        <span className="font-weight-bold mr-3">{this.props.t("CURRENT PRICE PLAN")}</span>
                        <span className="text-muted">
                            {currentRateCodeDescription}
                        </span>
                    </div>
                    : null
                }
                {this.state.showError &&
                    <div className="srp-alert-error mb-4">
                        <span className="font-weight-bold mr-3">{this.props.t("SOMETHING WENT WRONG")}</span>
                        <span>{this.props.t("Please select a price plan")}</span>
                    </div>
                }
                <div className="text-muted mb-4" style={{ fontSize: '0.875rem' }}>
                    {`${this.props.t("If you need help selecting a price plan we have many")} `}
                    <a
                        className="displayAsLink"
                        href={plansOverviewLink}
                        target="_blank">
                        {`${this.props.t("resources that can assist you")}.`}
                    </a>
                </div>
                <div className="mb-3">
                    {isCommercial
                        ?
                        <CommercialSelectPlan
                            selectedPricePlanCommercial={selectedPricePlanCommercial}
                            selectNewPricePlanCommercial={this.selectNewPricePlanCommercial}
                            currentRateCode={currentRateCode}
                        />
                        : isCustomerGeneration(currentRateCode) ?
                            <ResidentialGenerationSelectPlan
                                selectedPricePlanResidential={selectedPricePlanResidential}
                                selectNewPricePlanResidential={this.selectNewPricePlanResidential}
                                currentRateCode={currentRateCode}
                            />
                            :
                            <ResidentialSelectPlan
                                selectedPricePlanResidential={selectedPricePlanResidential}
                                selectNewPricePlanResidential={this.selectNewPricePlanResidential}
                                currentRateCode={0}
                            />
                    }
                </div>
                <div className="d-flex flex-wrap justify-content-end">
                    <button
                        className="btn srp-btn btn-lightblue ml-2"
                        onClick={isLoggedIn ? backButtonClick : () => setActiveStep(activeStep - 1)}>
                        {this.props.t("Previous")}
                    </button>
                    <button
                        className="btn srp-btn btn-blue ml-2"
                        onClick={() => this.nextStep(isCommercial, selectedPricePlanResidential, selectedPricePlanCommercial, activeStep + 1, setActiveStep)}>
                            {this.props.t("Next")}
                        </button>
                </div>
            </div>
        )
    }
}

SelectPlan.propTypes = {
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isCommercial: PropTypes.bool,
    currentRateCode: PropTypes.number,
    currentRateCodeDescription: PropTypes.string,
    selectedPricePlanResidential: PropTypes.number,
    selectedPricePlanCommercial: PropTypes.number,
    selectNewPricePlanResidential: PropTypes.func,
    selectNewPricePlanCommercial: PropTypes.func,
    backButtonClick: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('changePricePlan')(SelectPlan)