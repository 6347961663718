import React from "react"
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import config from 'my-account-config'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const TextSignUpAlertMessage = ({t}) => {
    let [ showTextAlertMessage, setShowTextAlertMessage ] = React.useState(false)

    const { i18n } = useTranslation()
    const isSpanish = i18n.language === "es"

    return (
        <div className="srp-alert-notice mb-4" style={{ fontSize: "0.875rem" }}>
            <div className="d-flex align-items-center">
                <IconButton
                    onClick={()=>setShowTextAlertMessage(!showTextAlertMessage)}
                    className="button-focus"
                    style={{ textDecoration: 'none' }}
                    size="large">
                    <Icon>{showTextAlertMessage ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                </IconButton>
                <span className="font-weight-bold">{t("Not receiving your SRP Service Alerts?")}</span>
            </div>
            <ul className="ml-4" style={showTextAlertMessage ? {display: 'block'} : {display: 'none'}}>
                <li>
                    <Trans i18nKey="send_text_to" t={t}>
                        For Billing and Usage alerts, send texts to <span className="font-weight-bold">777797</span>. For Outage and Disconnect alerts, send texts to <span className="font-weight-bold">74722</span>
                    </Trans> 
                </li>
                
                <li>
                    <ul>
                        <li>
                            <Trans i18nKey="start_text" t={t}>
                                text <span className="font-weight-bold">START</span> to enable text alerts
                            </Trans>
                        </li>    
                        <li>
                            <Trans i18nKey="stop_text" t={t}>
                                text <span className="font-weight-bold">STOP</span> to remove text alerts
                            </Trans>    
                        </li>
                        <li>
                            <Trans i18nKey="help_text" t={t}>
                                text <span className="font-weight-bold">HELP</span> for more information
                            </Trans>
                        </li>
                    </ul>
                </li>
                <li>{t("Text message verification statuses")}</li>
                <li>
                    <ul>
                    <li><span className="materialCheck"> </span><span> {t("signifies an enrolled phone number")}</span></li>
                    <li><span className="materialDots"> </span><span> {t("signifies a START message is needed to verify the phone number")}</span></li>
                    </ul>
                </li>
                <li>{t("Only US mobile numbers are eligible to receive SRP text alerts")}</li>
                <li>{t("Message and data rates may apply")}</li>
                <li>{t("Message frequency is driven by service impacting scenarios and could be three to five texts per scenario")}</li>
                <li>
                <Trans i18nKey="please_visit" t={t}>
                    Please visit 
                    <a target="_new"
                        href={isSpanish
                            ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/alertas-facturas-recordatorios`
                            : `${config.srpnetBaseUrl}account/electric/manage-bill/billing-alerts-reminders`}>
                        <span className="font-weight-bold">SRP Service Alerts</span>
                    </a>
                    to learn more about the program and view the terms and conditions
                </Trans>
                </li>
            </ul>
        </div>
    )
}

TextSignUpAlertMessage.propTypes = {
    t: PropTypes.func.isRequired,
}

export default TextSignUpAlertMessage