import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { Formik } from 'formik'
import PasswordValidation from "../password-validation"
import validate from "../../../srp_modules/validate"
import CallAnytime from '../../myaccount_header/call-anytime'

const PasswordResetValidateNewPassword = ({ completePasswordResetSubmit, t }) => {
    let validateForm = (values, t) => {
        let errors = {}

        if (values.password === undefined || values.password.trim().length === 0) {
            errors.password = "New password is required"
        }
        else if (validate.password(values.password) !== true) {
            errors.password = ''
        }

        if (values.confirmPassword === undefined || values.confirmPassword.trim().length === 0) {
            errors.confirmPassword = "Confirm new password is required"
        }
        else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = "Confirm new password must match"
        }

        return errors
    }

    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="mt-3">{t("Update My Account")}</h3>
                <h5 className="text-muted">{t("Make changes to your SRP account")}</h5>

                <div className="row">
                    <div className="col-lg-6 col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Confirm new password")}
                        </div>

                        <div className="srp-card-body ">
                            <div className="srp-card-details">

                                <Formik
                                    initialValues={{
                                        password: '',
                                        confirmPassword: '',
                                        showPassword: false
                                    }}
                                    validate={(values) => validateForm(values, t)}
                                    onSubmit={completePasswordResetSubmit}>
                                    {({ values, errors, status, touched, dirty, handleChange, handleBlur, handleSubmit, setValues, submitCount, isSubmitting }) => {
                                        let formStatus = status || {}
                                        let isPasswordError = (dirty || submitCount > 0) && touched.password && errors.password !== undefined
                                        let isConfirmPasswordError = (dirty || submitCount > 0) && touched.confirmPassword && errors.confirmPassword !== undefined

                                        return (
                                            <React.Fragment>
                                                {formStatus.error ||
                                                    <div className="srp-alert-success">
                                                        {t("email_validated_choose_password")}
                                                    </div>}
                                                <form method="POST" onSubmit={handleSubmit}>
                                                    <PasswordValidation
                                                        togglePasswordVisibilityOnClick={() => setValues({ ...values, showPassword: !values.showPassword })}
                                                        showPassword={values.showPassword}
                                                        password={values.password}
                                                        passwordLabel={t("New password")}
                                                        passwordError={isPasswordError}
                                                        passwordErrorText={isPasswordError ? t(errors.password) : ''}
                                                        passwordOnChange={handleChange}
                                                        passwordOnBlur={handleBlur}
                                                        confirmPasswordLabel={t("Confirm new password")}
                                                        confirmPassword={values.confirmPassword}
                                                        confirmPasswordError={isConfirmPasswordError}
                                                        confirmPasswordErrorText={isConfirmPasswordError ? t(errors.confirmPassword) : ''}
                                                        confirmPasswordOnChange={handleChange}
                                                        confirmPasswordOnBlur={handleBlur}
                                                    />

                                                    <div className="d-flex justify-content-end mt-4">
                                                        <button type="submit" className="btn srp-btn btn-green" disabled={isSubmitting}>
                                                            {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} aria-label="updating password" alt="progress icon" /> : t("Update password")}
                                                        </button>
                                                    </div>
                                                </form>
                                            </React.Fragment>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

PasswordResetValidateNewPassword.propTypes = {
    completePasswordResetSubmit: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default PasswordResetValidateNewPassword

