let config = {
    navBaseUrl: 'https://test.srpnet.com/standalone/header',
    navBaseUrlEs: 'https://test.srpnet.com/es/standalone/header',
    footerBaseUrl: 'https://test.srpnet.com/standalone/footer',
    footerBaseUrlEs: 'https://test.srpnet.com/es/standalone/footer',
    apiBaseUrl: '/MyAccountApi/',
    appPath: '/power',
    srpnetBaseUrl: 'https://test.srpnet.com/',
    srpnetBaseUrlEs: 'https://test.srpnet.com/es/',
    waterBaseUrl: 'https://myaccountwater-isotest2/',
    misrpBaseUrl: 'https://test.misrp.com/',
    googleMapsApiKey: 'AIzaSyD_zLAPFDl19j03bbxG9U0zpmR3LhCTQp8',
    adobeLaunchScriptUrl: 'https://assets.adobedtm.com/launch-EN95d01f06b1904318a39934581d2ae742-staging.min.js',
    homeEnergyProfile: 'https://ei-srp-stage.opower.com/ei/x/login-redirect?return-to=%2Fei%2Fx%2Fhome-energy-profile',
    energyInsights: 'https://ei-srp-stage.opower.com/ei/x/energy-insights',
    isProduction: false,
    appDynamics: {
        appKey: 'EUM-AAB-AUN',
        adrumExtUrl: 'https://cdn.appdynamics.com',
        beaconUrl: 'https://umon.srpnet.com',
        scriptUrl: 'https://cdn.appdynamics.com/adrum/adrum-20.12.0.3360.js'
    }
}

export default config