export const multiPaymentConstants = {
    RESET_PAYMENT_INFO_LIST: 'RESET_PAYMENT_INFO_LIST',

    GET_MULTI_PAYMENT_REQUEST: 'GET_MULTI_PAYMENT_REQUEST',
    GET_MULTI_PAYMENT_SUCCESS: 'GET_MULTI_PAYMENT_SUCCESS',
    GET_MULTI_PAYMENT_FAILURE: 'GET_MULTI_PAYMENT_FAILURE',
    GET_MULTI_PAYMENT_FROM_CACHE: 'GET_MULTI_PAYMENT_FROM_CACHE',

    RELOAD_MULTI_PAYMENT_REQUEST: 'RELOAD_MULTI_PAYMENT_REQUEST',
    RELOAD_MULTI_PAYMENT_SUCCESS: 'RELOAD_MULTI_PAYMENT_SUCCESS',
    RELOAD_MULTI_PAYMENT_FAILURE: 'RELOAD_MULTI_PAYMENT_FAILURE',

    LOAD_MULTI_PAYMENT_INFO_LIST_START: 'LOAD_MULTI_PAYMENT_INFO_LIST_START',
    LOAD_MULTI_PAYMENT_INFO_LIST_SUCCESS: 'LOAD_MULTI_PAYMENT_INFO_LIST_SUCCESS',
    LOAD_MULTI_PAYMENT_INFO_LIST_FAILURE: 'LOAD_MULTI_PAYMENT_INFO_LIST_FAILURE',

    GET_SELECTED_BANK_ACCOUNT_MULTI: 'GET_SELECTED_BANK_ACCOUNT_MULTI',

    GET_SELECTED_PAYABLE_ACCOUNTS: 'GET_SELECTED_PAYABLE_ACCOUNTS',
    SELECT_ALL_PAYABLE_ACCOUNTS: 'SELECT_ALL_PAYABLE_ACCOUNTS',
    SELECT_FIRST_100_UNPAID_ACCOUNTS: 'SELECT_FIRST_100_UNPAID_ACCOUNTS',
    SELECT_UNPAID_ACCOUNTS: 'SELECT_UNPAID_ACCOUNTS',
    SELECT_NONE_ACCOUNTS: 'SELECT_NONE_ACCOUNTS',

    GET_MULTI_PAYMENT_DATE: 'GET_MULTI_PAYMENT_DATE',
    GET_MULTI_PAYMENT_AMOUNT: 'GET_MULTI_PAYMENT_AMOUNT',

    VERIFY_MULTIPLE_REQUEST: 'VERIFY_MULTIPLE_REQUEST',
    VERIFY_MULTIPLE_SUCCESS: 'VERIFY_MULTIPLE_SUCCESS',
    VERIFY_MULTIPLE_FAILURE: 'VERIFY_MULTIPLE_FAILURE',

    SUBMIT_MULTIPLE_REQUEST: 'SUBMIT_MULTIPLE_REQUEST',
    SUBMIT_MULTIPLE_SUCCESS: 'SUBMIT_MULTIPLE_SUCCESS',
    SUBMIT_MULTIPLE_FAILURE: 'SUBMIT_MULTIPLE_FAILURE',

    SORT_MULTI_PAYMENT_LIST: 'SORT_MULTI_PAYMENT_LIST',
    GET_DCO_PAYMENT_LIST: 'GET_DCO_PAYMENT_LIST',

    SEND_RECONNECTION_REMARK_REQUEST :'SEND_RECONNECTION_REMARK_REQUEST',
    SEND_RECONNECTION_REMARK_SUCCESS :'SEND_RECONNECTION_REMARK_SUCCESS',
    SEND_RECONNECTION_REMARK_FAILURE :'SEND_RECONNECTION_REMARK_FAILURE',

    RECONNECT_CHOICE_SELECTED : 'RECONNECT_CHOICE_SELECTED',

    GET_SELECTED_PAYMENT_ACCOUNT_RATE_CODE_REQUEST: 'GET_SELECTED_PAYMENT_ACCOUNT_RATE_CODE_REQUEST',
    GET_SELECTED_PAYMENT_ACCOUNT_RATE_CODE_SUCCESS: 'GET_SELECTED_PAYMENT_ACCOUNT_RATE_CODE_SUCCESS',
    GET_SELECTED_PAYMENT_ACCOUNT_RATE_CODE_FAILURE: 'GET_SELECTED_PAYMENT_ACCOUNT_RATE_CODE_FAILURE',


    CLEAR_MULTI_PAYMENT_INFO: 'CLEAR_MULTI_PAYMENT_INFO',

    FILTER_ACCOUNT_ENABLED: 'FILTER_ACCOUNT_ENABLED',
    FILTER_ACCOUNT_DUE: 'FILTER_ACCOUNT_DUE',
    FILTER_ACCOUNT_PAST_DUE: 'FILTER_ACCOUNT_PAST_DUE',
    FILTER_ACCOUNT_SCHEDULED: 'FILTER_ACCOUNT_SCHEDULED',
    FILTER_ACCOUNT_SUREPAY: 'FILTER_ACCOUNT_SUREPAY',
    FILTER_ACCOUNT_SUMMARYMASTER: 'FILTER_ACCOUNT_SUMMARYMASTER',
    FILTER_ACCOUNT_TEXT: 'FILTER_ACCOUNT_TEXT',
    
    SET_FORM_START_EVENT_EMITTED: 'SET_MULTI_PAYMENT_FORM_START_EVENT_EMITTED',
    CLEAR_FORM_START_EVENT_EMITTED: 'CLEAR_MULTI_PAYMENT_FORM_START_EVENT_EMITTED',

    CLEAR_SUBMIT_PAYMENT_ERROR: 'CLEAR_SUBMIT_PAYMENT_ERROR',

    MAX_SIMULTANEOUS_PAYMENTS: 100,

    MULTI_PAYMENT_STATUS: {
        NO_PAYMENT_DUE: 0,
        DUE: 1,
        PAST_DUE: 2,
        SCHEDULED: 3,
        SUREPAY: 4,
        CLOSED_ACCOUNT: 5,
        ONE_TIME_PAYMENT: 6,
        CASH_ONLY: 7,
        BANKRUPTCY: 8,
        UNKNOWN: 9
    },

    MULTI_TABLE_VIEW: {
        CREDIT: 0,
        MPOWER: 1
    },

    PAYMENT_AMOUNT_ERRORS: {
        NO_ERROR: 0,
        REQUIRED: 1,
        INVALID_AMOUNT: 2,
        PAYMENT_LIMIT: 3,
        LESS_THAN_TOTAL: 4,
        LESS_THAN_TOTAL_CIX_ELIGIBLE: 5,
        MORE_THAN_TOTAL: 6
    },

    PAYMENT_DATE_ERRORS: {
        NO_ERROR: 0,
        REQUIRED: 1,
        INVALID_DATE: 2,
        DATE_BEFORE_TODAY: 3,
        DATE_MORE_THAN_60: 4,
        DATE_AFTER_DUE_DATE: 5,
        DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL_CIX_ELIGIBLE: 6,
        DATE_AFTER_DUE_DATE_CIX_ELIGIBLE: 7,
        PAYMENT_ALREADY_SCHEDULED: 8,
        DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL: 9
    },

	SET_PAYMENT_BILL_ACCOUNT_LIST_COUNT: 'SET_PAYMENT_BILL_ACCOUNT_LIST_COUNT',
}