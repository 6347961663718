import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import BillPageLoading from '../bill-page-loading'
import BillPageError from '../bill-page-error'

import eBillBack from '../../../images/my_bill/billBack.pdf'

import { getSelectedBill, getViewBillTab } from '../../../actions/auth/bill_page/view-bill-actions'
import * as loadingStatus from '../../../constants/loading-status-constants'
import config from 'my-account-config'

const selectStyle = {
    verticalAlign: 'middle',
    width: 200,
    marginBottom: "8px"
}

const iframeStyle = {
    border: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: -1
}

class ViewBillCardContainer extends React.Component {
    constructor(props) {
        super(props)

        this.refreshViewBillCard = this.refreshViewBillCard.bind(this)
    }

    componentDidMount() {
        if (this.props.eBillStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.viewBillStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.downloadBillPdf()
        }

        if (this.props.viewBillStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            this.insertPdf()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.eBillStatus === loadingStatus.LOADING_STATUS_SUCCESS && prevProps.eBillStatus !== this.props.eBillStatus) {
            this.downloadBillPdf()
            return
        }

        if (this.props.viewBillStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            this.insertPdf()
        }
    }

    insertPdf() {
        let documentPdf = navigator.msSaveOrOpenBlob ? this.props.documentPdf : this.props.viewBillBlobUrl

        let frontOfBillHtml, frontOfBillTabletHtml
        let chromeIndex = navigator.userAgent.indexOf('Chrome/')
        if (chromeIndex >= 0 && parseInt(navigator.userAgent.substring(chromeIndex + 7)) >= 77) {
            frontOfBillHtml = '<iframe style="width: 100%; height: 430px; border: 0px; overflow: hidden"'
                            + ` srcDoc="<embed style=&quot;width: 100%; height: 410px&quot; src=&quot;${documentPdf}&quot; />" />`
            frontOfBillTabletHtml = '<iframe style="width: 100%; height: 920px; border: 0px; overflow: hidden"'
                                  + ` srcDoc="<embed style=&quot;width: 100%; height: 900px&quot; src=&quot;${documentPdf}&quot; />" />`
        }
        else {
            frontOfBillHtml = `<embed style="width: 100%; height: 410px; z-index: 0" src=${documentPdf} type="application/pdf" />`
            frontOfBillTabletHtml = `<embed style="width: 100%; height: 900px; z-index: 0" src=${documentPdf} type="application/pdf" />`
        }

        let frontOfBillTab = ReactDOM.findDOMNode(this.refs.frontOfBillTab)
        let frontOfBillTabTablet = ReactDOM.findDOMNode(this.refs.frontOfBillTabTablet)

        if (!frontOfBillTab || !frontOfBillTabTablet)
            return

        frontOfBillTab.innerHTML = frontOfBillHtml
        frontOfBillTabTablet.innerHTML = frontOfBillTabletHtml
    }

    downloadBillPdf() {
        if (this.props.eBillStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.eBillDocumentList.length > 0) {
            this.props.getSelectedBillOnClick(this.props.selectedBill, this.props.eBillDocumentList[this.props.selectedBill])
        }
    }

    refreshViewBillCard() {
        if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getAccountHistory(this.props.selectedBillAccount)

        if (this.props.eBillStatus === loadingStatus.LOADING_STATUS_FAILURE
            || (this.props.eBillStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.eBillDocumentList.length === 0))
            this.props.getEBillListInfo(this.props.selectedBillAccount)
    }

    render() {
        const isSpanish = (this.props.i18n.language === 'es')

        let downloadUrl = navigator.msSaveOrOpenBlob ? this.props.downloadPdf : this.props.viewBillBlobUrl
        let backOfBill, backOfBillResponsive

        let chromeIndex = navigator.userAgent.indexOf('Chrome/')
        if (chromeIndex >= 0 && parseInt(navigator.userAgent.substring(chromeIndex + 7)) >= 77) {
            backOfBill = (<iframe style={{ width: '100%', height: '430px', border: '0px', overflow: 'hidden' }}
                srcDoc={`<embed style="width: 100%; height: 410px" src="${eBillBack}" />`} />)
            backOfBillResponsive = (<iframe style={{ width: '100%', height: '920px', border: '0px', overflow: 'hidden' }}
                srcDoc={`<embed style="width: 100%; height: 900px" src="${eBillBack}" />`} />)
        }
        else {
            backOfBill = <embed style={{ width: '100%', height: '410px' }} src={eBillBack} type="application/pdf" />
            backOfBillResponsive = <embed style={{ width: '100%', height: '900px' }} src={eBillBack} type="application/pdf" />
        }

        let renderedCard = {}
        if ((this.props.eBillStatus === loadingStatus.LOADING_STATUS_INIT || this.props.eBillStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            renderedCard = <BillPageLoading />
        }
        else if (this.props.eBillStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            if (this.props.eBillDocumentList.length > 0) {
                renderedCard =
                    (<div className="srp-card-body">
                        <div>
                            <div className="srp-card-details">
                                <form style={{ display: 'inline-block' }}>
                                {/* <label style={{ verticalAlign: 'middle', marginRight: 10 }} >Viewing bill for</label> */}
                                    <TextField
                                        select
                                        label={this.props.t("Viewing bill for")}
                                        value={this.props.selectedBill}
                                        onChange={event => this.props.getSelectedBillOnClick(event.target.value, this.props.eBillDocumentList[event.target.value])}
                                        style={selectStyle}
                                    >
                                        {getBillDates(this.props.eBillDocumentList)}
                                        <iframe style={iframeStyle} />
                                    </TextField>
                                </form>
                            </div>
                            <div>
                                <AppBar position="static" style={{ backgroundColor: 'white', borderBottom: "1px solid lightgray" }} elevation={0}>
                                    <Tabs
                                        value={this.props.viewBillTab}
                                        onChange={(event, value) => this.props.getViewBillTabOnClick(event, value)}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                    >
                                        <Tab className="mui_tab" value={0} label={this.props.t("Front")} style={{ outline: 'none', boxShadow: 'none' }} />
                                        <Tab className="mui_tab" value={1} label={this.props.t("Back")} style={{ outline: 'none', boxShadow: 'none' }} />
                                    </Tabs>
                                </AppBar>
                                {this.props.viewBillTab === 0 &&
                                    (<div>
                                        <div className="srp-card-details pb-0 d-lg-block d-md-none d-block" ref="frontOfBillTab">
                                            {/* Front of bill will be inserted here */}
                                        </div>
                                        <div className="srp-card-details pb-0 d-lg-none d-md-block d-none" ref="frontOfBillTabTablet">
                                            {/* Front of bill will be inserted here */}
                                        </div>
                                    </div>)
                                }
                                {this.props.viewBillTab === 1 &&
                                    (<div>
                                        <div className="srp-card-details pb-0 d-lg-block d-md-none d-block" ref="backOfBill">
                                            {backOfBill}
                                        </div>
                                        <div className="srp-card-details pb-0 d-lg-none d-md-block d-none" ref="frontOfBillTabTablet">
                                            {backOfBillResponsive}
                                        </div>
                                    </div>)
                                }
                            </div>
                            <div className="srp-card-details">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <a className="btn srp-btn btn-lightblue mb-2"
                                       href={this.props.selectedBillAccountDetails.isCommercial
                                             ? `${config.srpnetBaseUrl}account/electric/business/business-credit-policy` // Business credit policy is in English only.
                                             : (isSpanish
                                                ? `${config.srpnetBaseUrlEs}cuenta/electricidad/residencial/politicas-credito-residencial`
                                                : `${config.srpnetBaseUrl}account/electric/residential/residential-credit-policy`)}>
                                        {this.props.t("View credit policy")}
                                                </a>
                                    <a href={downloadUrl} download={this.props.selectedBillName} className="btn srp-btn btn-lightblue mb-2 ml-2">{this.props.t("Download (PDF)")}</a>
                                    <Link to="/myaccount/payment" className="btn srp-btn btn-lightblue mb-2 ml-2">{this.props.t("Make payment")}</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    )
            }
            else {
                renderedCard = <BillPageError dataThatFailed="Bill" refreshData={this.refreshViewBillCard} />
            }
        }
        else {
            renderedCard = <BillPageError dataThatFailed="Bill" refreshData={this.refreshViewBillCard} />
        }
        return renderedCard
    }
}

const mapStateToProps = state => {
    return { ...state.viewBill, ...state.eBill, ...state.accountHistory, ...state.accountInfo.billAccount }
}

const mapDispatchToProps = dispatch => {
    return {
        getSelectedBillOnClick: (billId, doc) => {
            dispatch(getSelectedBill(billId, doc))
        },
        getViewBillTabOnClick: (evt, tab) => {
            dispatch(getViewBillTab(evt, tab))
        }
    }
}

ViewBillCardContainer.propTypes = {
    selectedBillAccountDetails: PropTypes.object,
    selectedBillAccount: PropTypes.number,

    selectedBill: PropTypes.number,
    getSelectedBillOnClick: PropTypes.func,
    documentPdf: PropTypes.string,
    getViewBillTabOnClick: PropTypes.func,
    viewBillTab: PropTypes.number,
    downloadPdf: PropTypes.string,

    eBillStatus: PropTypes.string,
    eBillDocumentList: PropTypes.arrayOf(PropTypes.object),
    getEBillListInfo: PropTypes.func,

    viewBillStatus: PropTypes.string,
    viewBillBlobUrl: PropTypes.string,
    selectedBillName: PropTypes.string,

    accountHistoryStatus: PropTypes.string,
    getAccountHistory: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBillCardContainer)

function getBillDates(list) {
    let items = [];
    for (let i = 0; i < list.length; i++) {
        items.push(<MenuItem
            key={"billDate" + (i + 1)}
            value={i}
        >{DateTime.fromISO(list[i].documentDate).toFormat('MMMM d, yyyy')}</MenuItem>);
    }
    return items
}