import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'

import AssociatedAccountRows from './associated-account-rows'
import LoginEmailSettings from './login-email-settings'
import BillingAddressSettings from './billing-address-settings'
import SafetyNetSettings from './safety-net-settings'
import ThirdPartyAuthorization  from './third-party-authorization'
import SafetyNetSettingsResponsive from './safety-net-settings-responsive'

const AccountDetailsCard = ({ loginEmail, billingAddress, serviceAddress, selectedBillAccount, safetyNetProgramInfo, customerNames, selectedBillAccountDetails, t, i18n }) => {
    return (
        <div className="col-lg-8 col-12">
            <div className="srp-card-header">
                {t("Account details")}
            </div>
            <Card>
                <div className="container" style={{ verticalAlign: 'middle' }}>
                    <LoginEmailSettings loginEmail={loginEmail} t={t}/>

                    <BillingAddressSettings
                        billingAddress={billingAddress}
                        selectedBillAccount={selectedBillAccount} 
                        t={t}/>

                    <div className="row p-2 border-bottom">
                        <div className="col-lg-3 mr-2">
                            <div className="text-muted text-nowrap">{t("Service address")}</div>
                        </div>
                        <div className="col-lg">
                            <span>{serviceAddress.streetAddress.length !== 0 ? serviceAddress.fullStreetAddress : serviceAddress.cityStateZip}</span>
                        </div>
                    </div>

                    {!selectedBillAccountDetails.isCommercial &&
                        <ThirdPartyAuthorization t={t} i18n={i18n} />
                    }

                    {(safetyNetProgramInfo.isAvailable && customerNames.firstName.length > 0)
                    || safetyNetProgramInfo.isEnrolled
                        ? (
                            <div>
                                <div className="d-lg-block d-none">
                                    <SafetyNetSettings safetyNetProgramInfo={safetyNetProgramInfo} t={t} i18n={i18n} />
                                </div>
                                <div className="d-lg-none d-block">
                                    <SafetyNetSettingsResponsive safetyNetProgramInfo={safetyNetProgramInfo} t={t}/>
                                </div>
                            </div>)
                        : null
                    }
                </div>
            </Card>
            <div className="p-3" />
            <Card>
                <AssociatedAccountRows t={t} i18n={i18n} />
            </Card>
        </div>
    );
}

AccountDetailsCard.propTypes = {
    selectedBillAccountDetails: PropTypes.shape({
        isCommercial: PropTypes.bool
    }),
    billAccountList: PropTypes.array,
    loginEmail: PropTypes.string,
    billingAddress: PropTypes.object,
    serviceAddress: PropTypes.object,
    selectedBillAccount: PropTypes.number,
    safetyNetProgramInfo: PropTypes.object,
    customerNames: PropTypes.object,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default AccountDetailsCard