import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'

import PaymentSubmittedDetailsTable from './payment-submitted-details-table'

const SinglePaymentSubmittedCard = ({makePaymentInfo, makePaymentResult, selectedBank, updateBankEmailAddressOnClick, authorizeReconnectFlag, isSolar, t, i18n}) => (
    <div>
        <h5>{t("Your payment has been submitted")}</h5>

        <Card className="py-3">
            <div>
                <PaymentSubmittedDetailsTable
                    bankName={selectedBank.nickName ? selectedBank.nickName : selectedBank.bankName}
                    truncatedBankAccountNumber={selectedBank.accountNumber}
                    paymentList={[{
                        srpAccountNumber: makePaymentResult.referenceNumbers[0].billAccountNumber,
                        paymentAmount: +makePaymentInfo.amount,
                        paymentDate: makePaymentInfo.paymentDate
                    }]}
                    referenceNumber={makePaymentResult.referenceNumbers[0].referenceNumber}
                    authorizeReconnectFlag={authorizeReconnectFlag}
                    isSolar={isSolar}
                    t={t}
                    i18n={i18n}
                />

                <h6 className="text-muted px-3">
                    {makePaymentResult.confirmationEmailSent ?
                    <div>
                        {t("A confirmation email has been sent to")} <span className="font-weight-bold">{makePaymentResult.confirmationEmailAddress}</span> {t("for this payment.")}&nbsp;
                        <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(selectedBank)}>{t("Update your email address")} &raquo;</button>
                    </div>
                    :
                    <div>
                        {t("Unable to send a confirmation email to")} <span className="font-weight-bold">{makePaymentResult.confirmationEmailAddress}</span> {t("for this payment.")}&nbsp;
                        <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(selectedBank)}>{t("Update your email address")} &raquo;</button>
                    </div>
                    }
                </h6>
            </div>

            <div className="d-flex justify-content-end mt-4 px-3 d-print-none">
                <button className="btn srp-btn btn-lightblue mr-2" onClick={window.print}>{t("Print")}</button>
                <Link to="/myaccount/dashboard" className="btn srp-btn btn-blue">{t("Go to dashboard")}</Link>
            </div>
        </Card>
    </div>
)

SinglePaymentSubmittedCard.propTypes = {
    makePaymentInfo: PropTypes.shape({
        amount: PropTypes.string.isRequired, // "3.14"
        paymentDate: PropTypes.string.isRequired // "2015-03-14"
    }).isRequired,
    makePaymentResult: PropTypes.shape({
        referenceNumbers: PropTypes.arrayOf(PropTypes.shape({
            billAccountNumber: PropTypes.number.isRequired,
            referenceNumber: PropTypes.number.isRequired
        })).isRequired,
        confirmationEmailSent: PropTypes.bool.isRequired,
        confirmationEmailAddress: PropTypes.string.isRequired
    }).isRequired,
    selectedBank: PropTypes.shape({
        nickName: PropTypes.string.isRequired,
        bankName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired // truncated bank account number
    }).isRequired,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired,
    authorizeReconnectFlag: PropTypes.bool,
    isSolar: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default SinglePaymentSubmittedCard
