import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import Divider from '@mui/material/Divider'
import outageRestoration from '../../../srp_modules/outage-restoration'
import OutageAlertDialog from './outage-alert-dialog'

import config from 'my-account-config'

const CardInOutage = ({defaultViewStyle, outageDetailViewStyle, getDefaultViewOnClick, getOutageDetailViewOnClick, customerInOutageInfo, allOutagesInfo, selectedBillAccount, primaryEmail, outageAlertState, outageAlert, toggleOutageAlert, t, i18n}) => {
    const isSpanish = (i18n.language==="es")

    // Luxon doesn't support "time, date", so we have to use the Intl formatters ourselves.
    const locale = 'en-US' // Our translator thinks that when displaying numeric-only dates that US format will be less confusing;
                           // we only need to use es-MX locale if we want dates with the month names spelled out.
    const dateFormatter = new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'numeric', year: 'numeric' })
    const timeFormatter = new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: '2-digit', hour12: true })

    const formatOutageStartDateTime = (date) => `${dateFormatter.format(date)} ${timeFormatter.format(date)}`
    const formatRestorationDateTime = (date) => `${timeFormatter.format(date)}, ${dateFormatter.format(date)}`

    let customerOutage
    let outageDetail = {
        outageStartTime: DateTime.now(),
        restorationDuration: "",
        restorationTime: DateTime.now(),
        impactedCustomers: 0,
        reason: "",
        reasonEs: "",
        impactedArea: "",
        impactedAreaEs: ""
    }

    if(allOutagesInfo.length !== 0)
    {
        for(let i = 0; i < allOutagesInfo.length; i++)
        {
            if(allOutagesInfo[i].outageId === customerInOutageInfo.outageId)
            {
                 customerOutage = allOutagesInfo[i]
            }
        }

        outageDetail = {
            outageStartTime: DateTime.fromISO(customerOutage.outageBegan),
            restorationDuration: outageRestoration.getRestorationForCard(customerOutage.estimatedRestorationTime),
            restorationTime: DateTime.fromISO(customerOutage.estimatedRestorationTime),
            impactedCustomers: customerOutage.numberCustomersAffected,
            reason: customerOutage.outageProblem,
            reasonEs: customerOutage.outageProblemEs,
            impactedArea: customerOutage.crossRoadText,
            impactedAreaEs: customerOutage.crossRoadTextEs,
        }

    }

    return(
        <div className="srp-card">
                <div className="srp-card-header">
                    {t("Outages")}
                </div>
                <div className="srp-card-body" id="defaultView" style={defaultViewStyle}>
                    <div className="srp-card-summary srp-alert">
                        <div className="d-flex flex-column align-items-center text-white">
                            <div className="h4">{t("In outage")}</div>
                            <div>
                                {t("Est. Restoration")} {formatRestorationDateTime(outageDetail.restorationTime.toJSDate())}
                            </div>
                        </div>
                    </div>
                    <div className="srp-card-details d-none d-lg-block">
                        <div className="d-flex justify-content-end" style={{display: 'flex', flexWrap: 'wrap'}}>
                            <Link to="/myaccount/outages" className="btn srp-btn btn-lightblue mb-3">{t("View map")}</Link>
                            <button className="btn srp-btn btn-lightblue text-white ml-2 mb-3" onClick={() => getOutageDetailViewOnClick()}>
                                {t("View outage detail")}
                            </button>
                        </div>
                        <div>
                            {t("For_more_info_visit_SRP_outage_resources")}<br />
                            <a href={isSpanish
                                ? `${config.srpnetBaseUrlEs}servicio-al-cliente/seguridad/apagones-tormentas`
                                : `${config.srpnetBaseUrl}customer-service/safety/outages-storm`} target="_new">{t("Go to Outage Resources")} &raquo;</a>
                        </div>
                    </div>
                    <div className="srp-card-details">
                        <OutageAlertDialog
                            t={t}
                            selectedBillAccount={selectedBillAccount}
                            primaryEmail={primaryEmail}
                            outageAlertState={outageAlertState}
                            outageAlert={outageAlert}
                            toggleOutageAlert={toggleOutageAlert}
                        />
                    </div>
                    <div className="d-sm-block d-lg-none">
                        <div className="srp-card-details">
                            {t("For_more_info_visit_SRP_outage_resources")}<br />
                            <a href={isSpanish
                                ? `${config.srpnetBaseUrlEs}servicio-al-cliente/seguridad/apagones-tormentas`
                                : `${config.srpnetBaseUrl}customer-service/safety/outages-storm`} target="_new">{t("Go to Outage Resources")} &raquo;</a>
                        </div>
                        <Divider />
                        <div className="srp-card-details">
                            <div className="d-flex justify-content-end" style={{display: 'flex', flexWrap: 'wrap'}}>
                                <Link to="/myaccount/outages" className="btn srp-btn btn-lightblue mb-2">{t("View map")}</Link>
                                <button className="btn srp-btn btn-lightblue text-white ml-2 mb-2" onClick={() => getOutageDetailViewOnClick()}>
                                    {t("View outage detail")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="srp-card-body" id="outageDetailView" style={outageDetailViewStyle}>
                    <div className="srp-card-details" style={{fontSize: '15px'}}>
                        <h5>{t("Outage details")}</h5>
                        <div className="d-flex align-items-center">
                        <i className="material-icons text-primary-ada">keyboard_arrow_left</i>
                        <button className="displayAsLink text-primary-ada d-flex vertical-align-middle" onClick={() => getDefaultViewOnClick()}>
                            {t("Back to Home")}
                        </button>
                        </div>
                        <div className="d-flex justify-content-between mt-4 text-muted">
                            <div className="vertical-align-middle">{t("Outage start time")}</div>
                            <div className="text-right">
                                <div className="d-lg-block d-none"><strong>{formatOutageStartDateTime(outageDetail.outageStartTime.toJSDate())}</strong></div>
                                <div className="d-lg-none d-block">
                                    <div><strong>{dateFormatter.format(outageDetail.outageStartTime.toJSDate())}</strong></div>
                                    <div><strong>{timeFormatter.format(outageDetail.outageStartTime.toJSDate())}</strong></div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="d-flex justify-content-between vertical-align-middle text-muted">
                            <div className="d-lg-block d-none">{t("Estimated restoration")}</div>
                            <div className="d-lg-none d-block">
                                <div>{t("Estimated")}</div>
                                <div>{t("restoration")}</div>
                            </div>
                            <div className="text-right">
                                <div className="d-lg-block d-none">
                                    <strong>{outageDetail.restorationDuration + " (" + timeFormatter.format(outageDetail.restorationTime.toJSDate()) + ")"}</strong>
                                </div>
                                <div className="d-lg-none d-block">
                                    <div><strong>{outageDetail.restorationDuration }</strong></div>
                                    <div><strong>{"(" + timeFormatter.format(outageDetail.restorationTime.toJSDate()) + ")"}</strong></div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="d-flex justify-content-between vertical-align-middle text-muted">
                            <div>{t("Estimated customers impacted")}</div>
                            <div><strong>{outageDetail.impactedCustomers}</strong></div>
                        </div>
                        <br />
                        <div className="text-muted">{t("Reason")}</div>
                        <div className="text-muted">{isSpanish ? outageDetail.reasonEs : outageDetail.reason}</div>
                        <br />
                        <div className="text-muted">{t("Impacted area")}</div>
                        <div className="text-muted">{isSpanish ? outageDetail.impactedAreaEs : outageDetail.impactedArea}</div>
                        <br />
                    </div>
                </div>
        </div>
    )
}

CardInOutage.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    selectedBillAccount: PropTypes.number,
    primaryEmail: PropTypes.string,
    outageAlertState: PropTypes.bool,
    outageAlert: PropTypes.object,
    toggleOutageAlert: PropTypes.func,
    defaultViewStyle: PropTypes.object,
    outageDetailViewStyle: PropTypes.object,
    getDefaultViewOnClick: PropTypes.func.isRequired,
    getOutageDetailViewOnClick: PropTypes.func.isRequired,
    customerInOutageInfo: PropTypes.object.isRequired,
    allOutagesInfo: PropTypes.arrayOf(PropTypes.object)
}

export default CardInOutage
