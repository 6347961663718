import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import { withTranslation } from 'react-i18next'

const TerritoryCard = ({t, i18n}) => {
  const isSpanish = i18n.language === "es"

  return (
    <div>
       <div className="srp-card-header mt-4">
        {t("Where does SRP serve?")}
      </div>

      <div className="srp-card-body">
          <div className="srp-card-details">
            {t("Check out our service territory map to learn more.")}&nbsp;<a target="_blank" href={isSpanish
              ? `${config.srpnetBaseUrlEs}acerca/territorio-zona-servicio`
              : `${config.srpnetBaseUrl}about/service-area-territory`}>{t("View map »")}</a>
          </div>
      </div>
    </div>
  )
}

TerritoryCard.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
      language: PropTypes.string.isRequired,
  }).isRequired,
}

export default withTranslation("startStopTransfer")(TerritoryCard)