import React from "react"
import Card from "@mui/material/Card"
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import PayUsingBankAccountLink from './pay-using-bank-account-link'
import PayByCashCardLink from '../../cash_card_page/common_payment_page/pay-by-cash-card-link'

const MakePaymentByCard = ({t}) => (
    <div>
        <label className="h5">{t("Make payment by")}</label>
        <Card className="p-4 d-none d-lg-block">
            <div className="payment-nav nav nav-justified">
                <div className="nav-item">
                    <PayUsingBankAccountLink t={t} />
                </div>
                <div className="nav-item">
                    <PayByCashCardLink navPath="/myaccount/payment/multi/cash" />
                </div>
            </div>
        </Card>
        <Card className="d-block d-lg-none pt-4 pb-4 pl-2 pr-2">
            <div className="payment-nav">
                <div className="nav-item">
                    <PayUsingBankAccountLink t={t} />
                </div>
                <div className="nav-item">
                    <PayByCashCardLink navPath="/myaccount/payment/multi/cash" />
                </div>
            </div>
        </Card>
    </div>
)

MakePaymentByCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(MakePaymentByCard)