import config from 'my-account-config'

export const StartServiceDictionary = {
    en: {
        about_you: "About you",
        about_your_account: "About your account",
        about_your_new_account: "About your new account",
        active: "Active",
        address: "Address",
        address_is_required: "Address is a required field",
        advance_30: "A $30 advance added to the meter",
        agree_safety_statement: "I have read and agree to the above safety statement.",
        and: "and",
        authorize_my_spouse: "Authorize my spouse to make changes to this account",
        average_demand_plan: "Average Demand Plan",
        basic_plan: "Basic Plan",
        basic_plan_description: "This plan is for customers who prefer not to shift their energy usage and typically use less power.",
        basic_plan_link: `${config.srpnetBaseUrl}price-plans/residential-electric/basic`,
        best_time_to_reach: "Best time to reach you",
        billing_due_date: "Billing due date",
        callback_window_required: "You must specify a valid callback window",
        certificate_of_naturalization: "Certificate of Naturalization",
        city: "City",
        city_required: "City is a required field",
        confirm_email: "Confirm email address",
        confirm_email_required: "Confirm email address is required",
        confirm_phone: "Confirm phone number",
        confirm_phone_required: "Confirm phone number is required",
        contact_business: "For service call (602) 236-6633 weekdays from 7 AM – 5 PM excluding holidays.",
        contact_customer_service: "For questions please contact SRP Residential Customer Services at (602) 236-8888, available to serve you 24 hours a day, seven days a week.",
        contact_via_email: "We will contact you via email for deposit payment if one is needed",
        contact_24_hours: "We may need to call or email you regarding your request. If the need arises, you will be contacted within 24 hours.",
        country: "Country (optional)",
        country_required: "Country is required",
        custom_due_date: "Custom due date",
        customer_generation_contact: "Please contact us at (602) 236-4448 to speak with a representative about your request. We're available to serve weekdays between 7 a.m. and 7 p.m.",
        customer_generation_plan: "Customer Generation Plan",
        customer_generation_plan_description: "Produce some of your own energy using rooftop solar or other technologies.",
        customer_generation_plan_link: `${config.srpnetBaseUrl}price-plans/residential-electric/solar/compare-solar-plans`,
        date_of_birth: "Date of birth",
        deposit_290: "A refundable deposit of $290 if applicable",
        drivers_license: "Driver's License",
        due_date_required: "Due date is required",
        solarchoice: "SRP Solar Choice",
        solar_choice: "SRP Solar Choice (optional)",
        solar_choice_description: "Green up your home or business with SRP Solar Choice. It can be added to any of the above price plans, excluding M-Power.",
        solar_choice_program: "SRP Solar Choice Program",
        electric_vehicle_details: "Electric vehicle details",
        email_address: "Email address",
        email_does_not_match: "Email does not match",
        email_invalid: "Invalid email address",
        email_must_match: "Email must match",
        email_required: "Email is required",
        english: "English",
        equipment_deposit: "An $102.50 refundable equipment deposit",
        error_text: "We are unable to complete your turn on at this time. Please try again or contact us at (602) 236-8888",
        estimated_startup_costs: "Your estimated start-up costs",
        ev_export_plan: "Electric Vehicle Export Plan",
        ev_plan: "Electric Vehicle Plan",
        ev_plan_description: "This plan offers the same hours as the standard Time-of-Use Plan plus year round Super Off-Peak hours of 11 p.m. - 5 a.m.",
        ev_plan_link: `${config.srpnetBaseUrl}price-plans/residential-electric/electric-vehicle`,
        ez3_3_6_description: "Minimize your usage during the hours of 3-6 p.m., Monday through Friday.",
        ez3_3_6_plan: "EZ-3 Plan (3-6pm)",
        ez3_4_7_description: "Minimize your usage during the hours of 4-7 p.m., Monday through Friday.",
        ez3_4_7_plan: "EZ-3 Plan (4-7pm)",
        ez3_long_description: "Our EZ-3 plans give you 21 hours of energy at a discounted rate. Try one for three months risk-free!",
        ez3_plan_link: `${config.srpnetBaseUrl}price-plans/residential-electric/ez-3`,
        first_name: "First name",
        first_name_required: "First name is required",
        go_paperless: "Go Paperless: We'll email you notifications about your bill instead.",
        go_to_srp_site: "Go to srpnet.com",
        government_id: "Government ID",
        i_dont_know: "I don't know",
        id: "ID #",
        id_type: "ID type",
        identification_number: "Identification number",
        identification_number_invalid: "Invalid identification number",
        identification_number_required: "Identification number and type are required",
        identification_required: "Identification is required",
        identification_type: "Identification Type",
        inactive: "Inactive",
        include_spouse: "Include your spouse",
        input_current: "Input current?",
        input_voltage: "Input voltage?",
        invalid_year: "Enter a valid year (yyyy)",
        itin_id: "ITIN",
        last_name: "Last name",
        last_name_required: "Last name is required",
        learn_more: "Learn more",
        lease: "Lease",
        mailing_address: "Mailing Address",
        make: "Make",
        make_sure_right: "Let's make sure everything is right",
        may_need_to_call_text: "We want to be respectful of your time, but we may need to call you in the next two hours.",
        mexico_voter_id: "Mexico voter ID",
        middle_name: "Middle name",
        military_id: "Military ID",
        model: "Model",
        mpower: "M-Power",
        mpower_initial_costs: "You have selected the M-Power price plan. These are your anticipated initial costs, which can be incorporated into your pay down (Social Security number required):",
        mpower_plan: "M-Power Plan",
        mpower_plan_description: "Monitor your usage with an in-home display and purchase your power only when you need it. Say hello to energy savings and goodbye to monthly bills!",
        mpower_plan_link: `${config.srpnetBaseUrl}price-plans/residential-electric/m-power`,
        mpower_purchase_acknowledgement: "Please be aware that all future purchases will immediately add credit to the meter.  If you are not ready for the meter to reconnect, please wait to make a purchase.",
        need_help: "Need help?",
        next: "Next",
        no_upfront_cost: "No up-front costs",
        no_upfront_cost_credit: "No upfront cost, with credit verification.",
        of_the_month: "of the month",
        ok_call_next_two: "It is okay to call in the next two hours",
        own: "Own",
        own_or_lease_vehicle: "Do you own or lease this vehicle?",
        paperless_billing: "Paperless billing",
        paperless_billing_sign_up: "Paperless billing sign up",
        passport: "Passport",
        phone: "Phone",
        phone_number: "Phone number",
        phone_number_required: "Phone number is required",
        phone_numbers_do_not_match: "Phone numbers do not match",
        phone_numbers_is_too_short: "Phone number is less than 10 digits",
        phone_numbers_match: "Phone numbers must match",
        please_call_between: "Please call between",
        preferred_language: "Preferred language",
        previous: "Previous",
        price_plan: "Price Plan",
        provide_account_details: "Provide your account details",
        refurbishment_fee: "An $11.50 + tax non-refundable refurbishment fee",
        request_submitted: "Service request submitted",
        required: "Required",
        safety: "Safety",
        safety_confirmation: "Safety and confirmation",
        select_a_day: "Select a date to start service.",
        select_dates_plan: "Select dates/plan",
        select_one: "Select one of the following",
        select_service_start_date: "Select a day to start service",
        selected_rate_plan: "You have selected the {rate plan}. These are your anticipated initial costs:",
        service_establishment_fee: "A $30 + tax non-refundable service establishment fee",
        service_is_mailing_address: "This service address is also my mailing address",
        social_security: "Social Security #",
        social_security_text: "By providing your Social Security number you agree to allow us to run a consumer credit report to determine if your deposit can be waived.",
        spanish: "Spanish",
        spouse: "Spouse",
        spouse_first: "Spouse first name",
        spouse_last: "Spouse last name",
        srp_site_link: config.srpnetBaseUrl,
        start_service_business: "Starting service for your business",
        start_service_date_required: "Start service date is required",
        start_service_header: "Start electric service",
        state: "State",
        state_id: "State ID",
        state_required: "State is required",
        submit: "Submit",
        summary_text_1: "You have requested to start service at",
        summary_text_2: "on",
        summary_text_3: "Your power may be turned on at any time during the day. Please be aware of the risk of fire if any appliances or other electric devices, such as an electric stove, are turned on at the time power is delivered. Please ensure all of the appliances and electric devices at the premise are turned off.",
        supply_equipment_type: "Supply equipment type?",
        tell_us_about_yourself: "Tell us about yourself",
        temporary_work_auth: "Temporary work auth",
        thank_you_request_text: "Thank you for submitting your request to start SRP electric service. You should receive an emailed response within two hours. We look forward to serving you.",
        tou_demand_plan: "Time-of-Use Demand Plan",
        tou_export_plan: "Time-of-Use Export Plan",
        tou_plan: "Time-of-Use Plan",
        tou_plan_description: "Minimize your usage Monday through Friday during 2-8 p.m. in the summer and 5-9 a.m and 5-9 p.m. in the winter.",
        tou_plan_link: `${config.srpnetBaseUrl}price-plans/residential-electric/time-of-use`,
        turn_on_details: "Turn on details",
        type1: "Type 1",
        type2: "Type 2",
        us_residency_id: "US Residency ID",
        waive_deposit_text: "Without a Social Security number, we are unable to run a credit check to determine if your deposit can be waived. Your full deposit is due by the date your service starts and will be applied to your final bill when the account is closed.",
        where_power: "Where are we turning power on?",
        why_cant_sign_up_for_solar: "Why can't I sign up for solar?",
        year: "Year",
        youre_all_set: "You're all set!",
        zip_code: "Zip code",
        zip_code_required: "Zip code is required"
    },
    es: {
        about_you: "Tu información",
        about_your_account: "Información sobre tu nueva cuenta",
        about_your_new_account: "Información sobre tu nueva cuenta",
        active: "Activado",
        address: "Dirección",
        address_is_required: "Dirección requerida",
        advance_30: "Un adelanto de $30 agregado al medidor",
        agree_safety_statement: "He leído y estoy de acuerdo con el aviso de seguridad anterior.",
        and: "y",
        authorize_my_spouse: "Doy mi autorización para que mi esposo (a) haga cambios a esta cuenta.",
        basic_plan: "Plan Básico",
        basic_plan_description: "Este plan es para los clientes que usan menos electricidad y no quieren modificar a qué hora la usan.",
        basic_plan_link: `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/basico`,
        best_time_to_reach: "Mejor hora para contactarte",
        billing_due_date: "Fecha de vencimiento",
        callback_window_required: "Favor de especificar disponibilidad para regresarte la llamada",
        certificate_of_naturalization: "Certificado de Naturalización",
        city: "Ciudad",
        city_required: "Ciudad requerida",
        confirm_email: "Confirmar correo electrónico",
        confirm_email_required: "Correo electrónico requerido",
        confirm_phone: "Confirmar número de teléfono",
        confirm_phone_required: "Número de teléfono requerido",
        contact_business: "Llama al (602) 236-8833 para asistirte. Estamos disponibles de 7 AM – 5 PM entre semana excepto los días feriados.",
        contact_customer_service: "Para cualquier pregunta comunícate por favor con nuestro departamento de servicio al cliente en español al (602) 236-1111. Estamos disponibles las 24 horas, siete días a la semana para asistirte.",
        contact_via_email: "Te contactaremos por correo electrónico si necesitas pagar un depósito.",
        contact_24_hours: "Podríamos necesitar llamarte o enviar un correo electrónico en relación a tu petición. De ser necesario, nos comunicaremos contigo dentro de 24 horas.",
        country: "País",
        country_required: "País requerido",
        custom_due_date: "Elige la fecha en que gustes pagar tu recibo",
        customer_generation_contact: "Comunícate por favor al (602) 236-4448 para hablar con un representante sobre este plan. Estamos disponibles para asistirte de 7 a.m. a 7 p.m. entre semana.",
        customer_generation_plan: "Plan Customer Generation",
        customer_generation_plan_description: "Este plan es para clientes que producen parte de la electricidad que utilizan mediante paneles solares u otra tecnología.",
        customer_generation_plan_link: `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/solar/comparacion-planes-solares`,
        date_of_birth: "Fecha de nacimiento",
        deposit_290: "Un depósito reembolsable de $290 si se incluye alguno",
        drivers_license: "Licencia de conducir",
        due_date_required: "Fecha de vencimiento requerida",
        solarchoice: "SRP Solar Choice",
        solar_choice: "SRP Solar Choice",
        solar_choice_description: "Usa energía verde en tu hogar o negocio con SRP Solar Choice. Puedes agregar este programa a cualquiera de los planes anteriores excepto M-Power. Ver más (en inglés) ",
        solar_choice_program: "Programa SRP Solar Choice",
        electric_vehicle_details: "Información del vehículo eléctrico",
        email_address: "Correo electrónico ",
        email_does_not_match: "Correo electrónico es distinto",
        email_invalid: "Dirección de correo electrónico inválida",
        email_must_match: "Correo electrónico debe ser igual",
        email_required: "Correo electrónico requerido",
        english: "Inglés",
        equipment_deposit: "Un depósito reembolsable de $102.50 por el equipo",
        error_text: "No podemos completar tu orden en este momento. Por favor intenta más tarde o llámanos al (602) 236-1111.",
        estimated_startup_costs: "Tus costos aproximados para empezar el servicio",
        ev_plan: "Plan Electric Vehicle",
        ev_plan_description: "Este plan te ofrece las mismas horas que el plan Time-of-Use y una tarifa aún más baja durante todo el año de 11 p.m. – 5 a.m.",
        ev_plan_link: `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/vehiculo-electrico`,
        ez3_3_6_description: "Limita tu uso de energía de 3-6 p.m. entre semana para ahorrar en tu recibo de luz.",
        ez3_3_6_plan: "Plan EZ-3 (3-6pm)",
        ez3_4_7_description: "Limita tu uso de energía de 4-7 p.m. entre semana para ahorrar en tu recibo de luz.",
        ez3_4_7_plan: "Plan EZ-3 (4-7pm)",
        ez3_long_description: "Nuestro plan EZ-3 te ofrece una tarifa de luz a precio de descuento 21 horas al día. Pruébalo sin compromiso por 90 días.",
        ez3_plan_link: `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/ez-3`,
        first_name: "Nombre",
        first_name_required: "Nombre requerido",
        go_paperless: "Inscríbete en recibos electrónicos. En lugar de recibos de papel te enviaremos notificaciones sobre tu recibo por correo electrónico.",
        go_to_srp_site: "Visita MiSRP.com",
        government_id: "ID Gubernamental",
        i_dont_know: "No se",
        id: "# de ID",
        id_type: "Tipo de ID",
        identification_number: "Número de identificación",
        identification_number_invalid: "Número de identificación inválido",
        identification_number_required: "Número de identificación requerido",
        identification_required: "Identificación requerida",
        identification_type: "Tipo de identificación",
        inactive: "Desactivado",
        include_spouse: "Incluye a tu esposo (a)",
        input_current: "¿Tipo de corriente?",
        input_voltage: "¿Tipo de voltaje?",
        invalid_year: "Ingresa un año válido (yyyy)",
        itin_id: "ITIN",
        last_name: "Apellido",
        last_name_required: "Apellido requerido",
        learn_more: "Ver más ",
        lease: "Lo rento",
        mailing_address: "Dirección postal",
        make: "Marca",
        make_sure_right: "Confirma que toda la informacion este correcta",
        may_need_to_call_text: "En SRP valoramos tu tiempo. No queremos molestarte. Sin embargo, es posible que tengamos que hablarte en las próximas dos horas.",
        mexico_voter_id: "Credencial de Elector Mexicana u otro País",
        middle_name: "Segundo nombre",
        military_id: "ID Militar",
        model: "Modelo",
        mpower: "M-Power",
        mpower_initial_costs: "Haz seleccionado el Plan M-Power. Estos son tus costos iniciales aproximados, los cuales puedes pagar poco a poco (Número de Seguro Social requerido):",
        mpower_plan: "Plan M-Power",
        mpower_plan_description: "Monitorea tu consumo de energía con una unidad que te permite ver esa información y compra luz conforme la necesites. Dale la bienvenida al ahorro de luz y despídete de los recibos. Ver más.",
        mpower_plan_link: `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/m-power`,
        mpower_purchase_acknowledgement: "Tenga en cuenta que todas las futuras compras de luz se añadirán inmediatamente como crédito al medidor. Si su servicio está actualmente desconectado, le será reconectado. Si no está preparado para la reconexión del medidor, espere en realizar la compra.",
        need_help: "¿Necesitas ayuda?",
        next: "Siguiente",
        no_upfront_cost: "No se requieren costos por adelantado",
        no_upfront_cost_credit: "El siguiente plan no incluye cargos por adelantado con verificación de crédito.",
        of_the_month: "del mes",
        ok_call_next_two: "Está bien si me hablan en las siguientes dos horas",
        own: "Soy dueño",
        own_or_lease_vehicle: "¿Eres dueño de este vehículo o lo estás rentando?",
        paperless_billing: "Recibos electrónicos",
        paperless_billing_sign_up: "Inscríbete en recibos electrónicos",
        passport: "Pasaporte",
        phone: "Teléfono",
        phone_number: "Número de teléfono",
        phone_number_required: "Número de teléfono requerido",
        phone_numbers_do_not_match: "los números de teléfono no coinciden",
        phone_numbers_is_too_short: "El número de teléfono es menos de 10 dígitos",
        phone_numbers_match: "los números de teléfono deben ser igual",
        please_call_between: "Por favor llámenme entre",
        preferred_language: "Idioma en que prefieres recibir tu recibo",
        previous: "Anterior",
        price_plan: "Plan de electricidad",
        provide_account_details: "Ingresa información de tu cuenta",
        refurbishment_fee: "Un cargo no reembolsable de $11.50 + más impuestos por reconstrucción de equipo",
        request_submitted: "Tu solicitud para empezar servicio eléctrico ha sido enviada",
        required: "Requerido",
        safety: "Seguridad",
        safety_confirmation: "Seguridad y confirmación",
        select_a_day: "Elige fecha de conexión.",
        select_dates_plan: "Elige fecha/plan",
        select_one: "Elige una de las siguientes opciones",
        select_service_start_date: "Elige fecha de conexión",
        selected_rate_plan: "Has seleccionado el [rate plan]. Estos son tus costos iniciales previstos:",
        service_establishment_fee: "Un cargo no reembolsable de $30 + impuestos por establecimiento de servicio",
        service_is_mailing_address: "Dirección de servicio eléctrico es donde recibo mi correspondencia",
        social_security: " # de Seguro Social",
        social_security_text: "Al proporcionar tu número de seguro social estas dando tu consentimiento para que verifiquemos tu crédito y determinar si necesitas pagar un depósito.",
        spanish: "Español",
        spouse: "Esposo (a)",
        spouse_first: "Nombre de esposo (a)",
        spouse_last: "Apellido de esposo (a)",
        srp_site_link: config.misrpBaseUrl,
        start_service_business: "¿Necesitas iniciar servicio eléctrico para tu negocio?",
        start_service_date_required: "Fecha de conexión requerida",
        start_service_header: "Empezar servicio eléctrico",
        state: "Estado",
        state_id: "ID Estatal",
        state_required: "Estado requerido",
        submit: "Enviar",
        summary_text_1: "Has solicitado conectar el servicio eléctrico en el",
        summary_text_2: "el",
        summary_text_3: "El servicio eléctrico puede ser conectado a cualquier hora del dia. Por favor tenga presente el riesgo de incendios si algún electrodoméstico o aparato electrónico, como una estufa eléctrica se encuentra prendida al momento de conectar el servicio eléctrico. Por favor asegúrese de que todos los electrodomésticos y aparatos electrónicos en la propiedad estén apagados.",
        supply_equipment_type: "¿Con qué tipo de equipo cuenta?",
        tell_us_about_yourself: "Ingresa tu información",
        temporary_work_auth: "Permiso de Trabajo Temporal",
        thank_you_request_text: "Gracias por enviar tu solicitud para empezar servicio eléctrico con SRP. Te estaremos respondiendo en dos horas. Gracias por la oportunidad de poder servirte.",
        tou_demand_plan: "Plan Time-of-Use Demand",
        tou_plan: "Plan Time-of-Use",
        tou_plan_description: "Limita tu uso de energía de 2-8 p.m. entre semana en el verano y de 5-9 a.m. y 5-9 p.m. en el invierno.",
        tou_plan_link: `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/time-of-use`,
        turn_on_details: "Detalles de conexión",
        type1: "Tipo 1",
        type2: "Tipo 2",
        us_residency_id: "Tarjeta de Residencia de EU",
        waive_deposit_text: "Sin un número de Seguro Social, no es posible realizar una verificación de crédito para determinar si tu depósito se puede perdonar. El total de tu depósito se debe pagar a la fecha en que comienza tu servicio y se aplicará a tu factura final cuando la cuenta sea cerrada.",
        where_power: "¿Dónde deseas conectar el servicio eléctrico?",
        why_cant_sign_up_for_solar: "¿Por qué no puedo inscribirme en el plan de energía solar?",
        year: "Año",
        youre_all_set: "Tu solicitud ha sido completada",
        zip_code: "Código Postal",
        zip_code_required: "Código postal requerido"
    }
}