import React from "react"
import PropTypes from 'prop-types'
import utils from '../../../srp_modules/utils'

let baseStyle = {
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '1px',
    height: '285px',
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'center',
    marginRight: '10px'
}

let currentBoxStyle = {
    ...baseStyle,
    borderColor: 'rgb(103,135,183)'
}

let regularBoxStyle={
    ...baseStyle,
    borderColor: 'rgba(215,215,215,1)'
}

let subTextStyle= {
    fontSize: '12px',
    color: 'rgb(112, 112, 112)'
}

let bubbleWrapperStyle= {
    position: 'absolute',
    left: '0',
    top: '-15px',
    width: '100%',
    paddingRight: '10px'
}
let bubbleStyle= {
    color: '#ffffff',
    borderRadius: '16px',
    backgroundColor: 'rgb(103,135,183)',
    width: '82px',
    height: '34px',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '30px',
    marginLeft: 'auto',
    marginRight: 'auto'
}

let planNameStyle = {
    fontSize: '16px',
    fontWeight: '600',
    color:'rgb(0, 75, 135)',
    marginTop: '12px',
    lineHeight: '18px'
}

let planDetailsStyle = {
    position: 'absolute',
    top: '40px',
    width: '100%',
    paddingRight: '12px'

}

class MyPricingRateCard extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        const infoLink = this.props.infoLinks[i18n.language] ?? this.props.infoLink

        return (
            <div style={{position:"relative"}}>
                {
                    this.props.isCurrent &&
                    <div style={bubbleWrapperStyle}>
                        <div style={bubbleStyle}>
                            <b>
                                {t("Current")}
                            </b>
                        </div>
                    </div>
                }
                <div style={this.props.isCurrent ? currentBoxStyle : regularBoxStyle}>
                    <div style={planNameStyle}>
                        {this.props.shortName.toUpperCase()}
                    </div>
                    <div style={planDetailsStyle}>
                        <div style={{fontWeight:"600", marginRight:"5px", marginTop: "20px"}}>
                            <span style={{fontSize:"16px", verticalAlign:"top", marginTop:"10px"}}>$</span>
                            <span style={{fontSize: "30px"}}>{utils.delimitNumbers(this.props.monthlyAverage)}</span>
                        </div>
                        <div style={subTextStyle}>
                            {t("monthly average")}
                        </div>
                        <div style={{marginRight: "3px", marginTop: "10px"}}>
                            <b>
                                <span style={{fontSize:"14px", verticalAlign:"top"}}>$</span>
                                <span style={{fontSize: "24px"}}>{utils.delimitNumbers(this.props.annualProjection)}</span>
                            </b>
                        </div>
                        <div style={subTextStyle}>
                            {t("annual projection")}
                        </div>
                        {infoLink &&
                            <div className="srp-blue-color" style={{fontSize:"14px", fontWeight:"800", marginTop: "15px"}}>
                                <a href={infoLink} target="_new">
                                    {t("Learn more")}
                                </a>
                            </div>}
                        {!infoLink && <div style={{ marginTop: "15px", height: "21px"}}>
                            {' '}
                        </div>}
                        {
                            !this.props.isCurrent &&
                            <div style={{marginTop: "10px"}}>
                                <button name={`changePricePlan_${this.props.index}`} className="btn srp-btn btn-blue" style={{width: '90%'}} onClick={() => {this.props.selectPricePlanClick(this.props.businessOffering)}}>
                                    {t("Change price plan")}
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

MyPricingRateCard.propTypes = {
    index: PropTypes.number.isRequired,
    businessOffering: PropTypes.number.isRequired,
    monthlyAverage: PropTypes.number.isRequired,
    annualProjection: PropTypes.number.isRequired,
    isCurrent: PropTypes.bool.isRequired,
    isRecommended: PropTypes.bool.isRequired,
    shortName: PropTypes.string.isRequired,
    infoLink: PropTypes.string.isRequired,
    infoLinks: PropTypes.objectOf(PropTypes.string).isRequired,
    selectPricePlanClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default MyPricingRateCard