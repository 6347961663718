import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { Trans } from 'react-i18next'

import { printContentInNewWindow } from '../../../../srp_modules/print'
import utils from '../../../../srp_modules/utils'

const ConfirmCIXCard = ({ endCIXFlow, shouldSendCIXConfirmationEmail, emailToSendCIXConfirm, maxExtensionDate, maxExtensionAmount, totalAmountDue, isCommercial, t, i18n }) => {
    const isSpanish = i18n.language==="es"
    const csPhoneNumber = isSpanish ? utils.getCSSpanishPhoneNumber() : utils.getCSPhoneNumber(isCommercial)
    const formattedCSPhoneNumber = isSpanish ? utils.getFormattedCSSpanishPhoneNumber() : utils.getFormattedCSPhoneNumber(isCommercial)

    const _maxExtensionDate = DateTime.fromISO(maxExtensionDate)
    const maxExtensionMonthDay = _maxExtensionDate.toFormat(isSpanish ? 'd/M' : 'M/d')
    let maxExtensionMonthDayYear = _maxExtensionDate.toFormat('MMMM d, yyyy')
    let maxExtensionDowMonthDayYear = _maxExtensionDate.toFormat('EEE, MMM d, yyyy')
    if (isSpanish) {
        maxExtensionMonthDayYear = `${_maxExtensionDate.day} de ${utils.getSpanishMonth(_maxExtensionDate.month)}, ${_maxExtensionDate.toFormat("yyyy")}`
        // NOTE: Luxon: 1 = Monday, 6 = Saturday, 7 = Sunday, we need the mod 7 to convert Sunday back to a 0
        maxExtensionDowMonthDayYear = `${utils.getSpanishDayOfWeekAbbreviation(_maxExtensionDate.weekday % 7)}, ${utils.getSpanishMonthAbbreviation(_maxExtensionDate.month)} ${_maxExtensionDate.day}, ${_maxExtensionDate.year}`
    }

    return (
    <div className="srp-card">
        <div className="srp-card-header">
            {t("My bill")}
        </div>
        <div className="srp-card-body">
            <div className="srp-card-details">
                <div id="printCix" className="d-print-block">
                    <h5 className="mb-4"><strong>{t("You're all set!")}</strong></h5>
                    <div className="mb-4 text-muted">{t("safe_from_disconnect_through_date", {safeThruDate: maxExtensionMonthDayYear})}</div>
                    <div className="row text-muted mb-2">
                        <div className="col-6">{t("New due date:")}</div>
                        <div className="col-6"><strong>{maxExtensionDowMonthDayYear}</strong></div>
                    </div>
                    <div className="row text-muted mb-2">
                        <div className="col-6">{t("Required payment:")}</div>
                        <div className="col-6"><strong>${maxExtensionAmount}</strong></div>
                    </div>
                    <div className="row text-muted mb-2">
                        <div className="col-6">{t("Total amount due:")}</div>
                        <div className="col-6"><strong>${totalAmountDue}</strong></div>
                    </div>
                    {shouldSendCIXConfirmationEmail
                        ? <div className="mb-3 text-muted">{t("Confirmation email sent to")} <strong>{(emailToSendCIXConfirm).toLowerCase()}</strong></div>
                        : null
                    }
                    <div className="d-lg-block d-none mb-2 text-muted">{t("payment_not_received_warning_desktop", {maxExtensionMonthDay, phone: formattedCSPhoneNumber})}</div>
                    <div className="d-block d-lg-none mb-2 text-muted">
                        <Trans i18nKey="payment_not_received_warning_mobile" t={t}>
                        If the required payment is not received by {{maxExtensionMonthDay}}, your account will be subject to disconnection without further notice. If you are unable to make the payment by the new due date, please call SRP Residential Customer Services at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a>, available 24 hours a day, seven days a week.
                        </Trans>
                    </div>
                </div>
                <div className="d-flex justify-content-end d-print-none" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {utils.isLocalStorageAvailable() && <button
                        className="btn srp-btn btn-lightblue text-white mb-2"
                        onClick={() => printContentInNewWindow(document.getElementById("printCix").outerHTML, 'CreditExtensionConfirmation')}>
                        {t("Print")}
                    </button>}
                    <button className="btn srp-btn btn-blue text-white ml-2 mb-2" onClick={()=>endCIXFlow()}>{t("Back to Home")}</button>
                </div>
            </div>
        </div>
    </div>
    )
}

ConfirmCIXCard.propTypes = {
    endCIXFlow: PropTypes.func,
    shouldSendCIXConfirmationEmail: PropTypes.bool,
    emailToSendCIXConfirm: PropTypes.string,
    maxExtensionDate: PropTypes.string,
    maxExtensionAmount: PropTypes.string.isRequired,
    totalAmountDue: PropTypes.string.isRequired,
    isCommercial: PropTypes.bool,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default ConfirmCIXCard