import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { connect } from 'react-redux'
import BillProjectionCard from './bill-projection-card'
import PeakDemandCard from './peak-demand-card'
import DailyUsageFormContainer from './daily-usage-form-container'
import HourlyUsageFormContainer from './hourly-usage-form-container'
import MonthlyUsageFormContainer from './monthly-usage-form-container'

import { rateMetaDataConstants } from '../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../constants/loading-status-constants'

import config from 'my-account-config'
import { withTranslation } from 'react-i18next' 

const UsagePage = ({ getUsagePageChartTabOnClick, selectedUsagePageChartTab, pricePlan, rateMetaData, isCommercial, hasAverageDemand, t, i18n }) => {
    let showDemand = rateMetaData.rateMetaData.isDemand && !rateMetaData.rateMetaData.hasMonthlyDemandOnly
    let showOnPeakDemand = rateMetaData.rateMetaData.hasOnPeakDemand && !rateMetaData.rateMetaData.hasOnOffShoulder
    let isSummaryMaster = rateMetaData.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT

    const isSpanish = i18n.language === "es"
    let plansOverviewUrl = isCommercial
        ? `${config.srpnetBaseUrl}price-plans/business-electric/overview`
        : `${config.srpnetBaseUrl}price-plans/residential-electric/compare-plans`
    if (isSpanish) {
        plansOverviewUrl = isCommercial
            ? `${config.srpnetBaseUrl}price-plans/business-electric/overview` // in English only, as of 8/2/2024
            : `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/comparar-planes`
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-9 col-12 mt-4">
                    <div className="srp-card-header">
                        {t("My usage data")}
                    </div>

                    <div className="srp-card-body text-muted tooltip-overflow">
                        {rateMetaData.rateMetaData.summaryBilling !== rateMetaDataConstants.SUMMARY_BILLING_PARENT
                            ? <div className="srp-card-details">
                                <div className="mb-2 d-none d-lg-block">
                                    <span className="mr-2">{t("Current price plan")}</span>
                                    <a
                                        target="_blank"
                                        href={pricePlan.displayUrl.length !== 0
                                            ? pricePlan.displayUrl
                                            : plansOverviewUrl
                                        }>
                                        {pricePlan.description}
                                    </a>
                                </div>
                                <div className="mb-2 d-block d-lg-none">
                                    <div className="mr-2">{t("Current price plan")}</div>
                                    <a
                                        target="_blank"
                                        href={pricePlan.displayUrl.length !== 0
                                            ? pricePlan.displayUrl
                                            : plansOverviewUrl
                                        }>
                                        {pricePlan.description}
                                    </a>
                                </div>
                            </div>
                            : null
                        }
                        <AppBar position="static" style={{ backgroundColor: 'white', borderBottom: "1px solid lightgray" }} elevation={0}>
                            <Tabs
                                value={selectedUsagePageChartTab}
                                onChange={(evt, value) => getUsagePageChartTabOnClick(evt, value)}
                                indicatorColor="primary"
                                textColor="primary"
                                style={{ color: 'black' }}
                                variant="fullWidth"
                            >
                                {
                                    rateMetaData.rateMetaData.isDemand &&
                                    <Tab
                                        className="mui_tab"
                                        label={t("Hourly/Interval")}
                                        style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }}
                                        disabled={rateMetaData.rateMetaData.isNonSmartMeterRate} />
                                }
                                {
                                    !rateMetaData.rateMetaData.isDemand &&
                                    <Tab
                                        className="mui_tab"
                                        label={t("Hourly")}
                                        style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }}
                                        disabled={rateMetaData.rateMetaData.isNonSmartMeterRate} />
                                }
                                <Tab
                                    className="mui_tab"
                                    label={t("Daily")}
                                    style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }}
                                    disabled={rateMetaData.rateMetaData.isNonSmartMeterRate} />
                                <Tab
                                    className="mui_tab"
                                    label={t("Billing month")}
                                    style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }} />
                            </Tabs>
                        </AppBar>

                        {selectedUsagePageChartTab === 0 && <HourlyUsageFormContainer />}
                        {selectedUsagePageChartTab === 1 && <DailyUsageFormContainer />}
                        {selectedUsagePageChartTab === 2 && <MonthlyUsageFormContainer />}

                    </div>
                </div>

                <div className="col-lg-3 col-12">
                    {showDemand ?
                        (
                            <div>
                                <div className="srp-card-header mt-4">
                                    {showOnPeakDemand ?
                                        <span>
                                            {t("On-peak demand")}
                                        </span>
                                        : hasAverageDemand
                                            ?
                                            <span>
                                                {t("On-peak average demand")}
                                                </span>
                                            :
                                            <span>
                                                {t("High demand")}
                                                </span>
                                    }
                                </div>
                                <PeakDemandCard t={t}/>
                            </div>
                        )
                        : null
                    }

                    {rateMetaData.rateMetaData.isMPower || rateMetaData.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                        ? null
                        : <BillProjectionCard isSummaryMaster={isSummaryMaster} t={t}/>
                    }
                </div>

            </div>
        </div>
    )
}

UsagePage.propTypes = {
    getUsagePageChartTabOnClick: PropTypes.func,
    selectedUsagePageChartTab: PropTypes.number,
    pricePlan: PropTypes.object,
    rateMetaData: PropTypes.object,
    isCommercial: PropTypes.bool,
    hasAverageDemand: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}
const mapStateToProps = state => {
    return {
        rateMetaData: state.rateMetaData,
    }
}
export default withTranslation("usagePage")(connect(mapStateToProps)(UsagePage))