import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import CircularProgress from '@mui/material/CircularProgress'

import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'
import TextInput from '../common_formik/text-input'
import BankAccountNumberInput from '../common_formik/bank-account-number-input'
import EmailInput from '../common_formik/email-input'
import PhoneNumberInput from './payment_fields/phone-number-input'
import RoutingNumberInput from '../common_formik/routing-number-input'
import BankRoutingNumberAccountNumberInfoIcon from '../common_payment/bank-routing-number-account-number-info-icon'
import SelectorInput from '../common_formik/selector-input'

import { Trans } from 'react-i18next'

import { myAccountConstants } from '../../constants/myaccount-constants'

import { autopayConstants } from '../../constants/autopay-constants'
import * as loadingStatus from '../../constants/loading-status-constants'

const EnrollAutopayForm = (props) => {
    let autopaySharePaymentInput = React.useRef();

        const t = props.t
        const isSpanish = props.i18n.language === "es"

    let commonFormikProps = {
        onChange: props.handleChange,
        onBlur: props.handleBlur,
        errors: props.errors,
        touched: props.touched
    }

    let alternateFormikProps = {
        errors: props.errors,
        touched: props.touched
    }

    let verifyStatus = props.status || {}

    let onBankPhoneNumberChange = values => {
        let value = values.value
        props.setFieldValue('bankPhoneNumber', value)
    }

    let onBankAccountTypeChange = values => {
        let value = Math.trunc(values.target.value)
        props.setFieldValue('bankAccountType', value, true)
    }

    let isShareAmountAllowed = values => {
        return values.value >= 0
                && values.value < 100
                && Math.round(values.value, 0) == values.value
                && (values.value.length <= 4 || props.values.shareAmount.length >= 3)
    }

    let formatShareAmountOnBlur = () => {
        props.setFieldTouched('shareAmount', true)
        props.setFieldValue('shareAmount', Number(props.values.shareAmount).toFixed(2), false)
    }

    let setShareAmountOnChanged = values => {
        props.setFieldValue('shareAmount', values.value, true)
    }

        let servicePhoneNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let servicePhoneNumberFmt = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        if (!props.autopayInfo.isResidential) {
            servicePhoneNumber = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
            servicePhoneNumberFmt = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        } else if (isSpanish) {
            servicePhoneNumber = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            servicePhoneNumberFmt = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        }
    
    return (
        <form onSubmit={props.handleSubmit} id="topOfSurepayForm">
                {verifyStatus.hasUnhandledBankError &&
                    <div className="srp-alert-error mt-3 ">
                    <span className="d-lg-block d-none">
                            {t("unable_to_enroll", {servicePhoneNumber: servicePhoneNumberFmt})}
                    </span>
                    <span className="d-lg-none d-block">
                            <Trans i18nKey="unable_to_enroll_phone_number_link" t={t}>
                            We are unable to enroll your account in SurePay at this time. Please try again or contact us at
                            <a href={"tel:" + servicePhoneNumber}>{{servicePhoneNumber: servicePhoneNumberFmt}}</a>.
                            </Trans>
                        </span>
                </div>
                }
                {verifyStatus.matchesExistingAutopayError &&
                    <div className="srp-alert-error mt-3">{t("already_enrolled_in_SurePay")}</div>
                }
                {verifyStatus.hasPendingAutopayError &&
                    <div className="srp-alert-error mt-3">
                    <span className="d-lg-block d-none">
                            {t("pending_SurePay_enrollment", {servicePhoneNumber: servicePhoneNumberFmt})}
                    </span>
                    <span className="d-lg-none d-block">
                            <Trans i18nKey="pending_SurePay_enrollment_phone_number_link" t={t}>
                            An account with the details you provided is currently pending SurePay enrollment.
                            This process usually takes 2 business days to complete. If you have questions,
                            please call SRP Customer Service at
                            <a href={"tel:" + servicePhoneNumber}>{{servicePhoneNumber: servicePhoneNumberFmt}}</a>.
                            </Trans>
                        </span>
                </div>
                }
                <label className="h4 text-muted pt-2 mt-3">{t("Bank information")}</label>
                <div className="mt-1"><TextInput id="bankFirstName" label="First name" value={props.values.bankFirstName} {...commonFormikProps} t={t} /></div>
                <div className="mt-3"><TextInput id="bankLastName" label="Last name" value={props.values.bankLastName} {...commonFormikProps} t={t} /></div>
            <div className="mt-3">
                <PhoneNumberInput
                    id="bankPhoneNumber"
                    label="Phone number"
                    value={props.values.bankPhoneNumber}
                    onValueChange={onBankPhoneNumberChange}
                    onBlur={() => props.setFieldTouched("bankPhoneNumber", true)}
                        t={t}
                    {...alternateFormikProps}
                />
            </div>
                <div className="mt-3"><EmailInput id="bankEmailAddress" label="Email address" value={props.values.bankEmailAddress} t={t} {...commonFormikProps} /></div>
            <div className="mt-3">
                <SelectorInput
                    id="bankAccountType"
                        label={t("Account type")}
                    value={props.values.bankAccountType.toString()}
                    optionList={[
                        {
                            value: autopayConstants.BANK_ACCOUNT_CHECKING.toString(),
                                text: t("Checking")
                        },
                        {
                            value: autopayConstants.BANK_ACCOUNT_SAVINGS.toString(),
                                text: t("Savings")
                        }
                    ]}
                    onChange={onBankAccountTypeChange}
                    onBlur={() => props.setFieldTouched("bankAccountType", true)}
                    displayEmpty={false}
                    {...alternateFormikProps}
                />
            </div>
            <div className="mt-3 position-relative">
                <RoutingNumberInput
                    id="bankRoutingNumber"
                    label="9 digit routing number"
                    value={props.values.bankRoutingNumber}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={true}
                            showBankAccountNumberDescription={false}
                            className="d-lg-none" />
                    }
                        t={t}
                    {...commonFormikProps} />
            </div>
            <div className="mt-3 position-relative">
                <BankAccountNumberInput
                    id="bankAccountNumberLeadingZeroesAreSignificant"
                    label="Bank account number"
                    value={props.values.bankAccountNumberLeadingZeroesAreSignificant}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={false}
                            showBankAccountNumberDescription={true}
                            className="d-lg-none" />
                    }
                        t={t}
                    {...commonFormikProps} />
            </div>
            <input id="srpAccountNumber" name="srpAccountNumber" type="hidden" value={props.values.srpAccountNumber} {...alternateFormikProps} />

            <div className="mt-3">
                    <label className="h4 text-muted pt-2 mt-2 mb-3">{t("SHARE donation amount")}</label>
                <p className="text-muted">
                        <Trans i18nKey="Want_to_help_fellow_Arizonans" t={t}>
                        Want to help fellow Arizonans through
                        <a className="displayAsLink" href={isSpanish
                            ? `${config.srpnetBaseUrlEs}cuenta/donacion/ayudando-vecinos`
                            : `${config.srpnetBaseUrl}account/donate/help-neighbors`} target="_blank">SHARE</a>?
                        Donate $1, $2 or $5 and SRP will match your contribution.
                        </Trans>
                </p>

                <div>
                    <NumericFormat
                        id="shareAmount"
                        name="shareAmount"
                        getInputRef={autopaySharePaymentInput}
                        value={props.values.shareAmount}
                        valueIsNumericString={true}
                        displayType="input"
                        type="text"
                        isAllowed={isShareAmountAllowed}
                        onValueChange={setShareAmountOnChanged}
                        onBlur={formatShareAmountOnBlur}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        allowNegative={false}
                        customInput={TextField}
                            helperText={props.touched["shareAmount"] && Object.prototype.hasOwnProperty.call(props.errors, "shareAmount") ? t(props.errors["shareAmount"]) : ''}
                        error={props.touched["shareAmount"] && Object.prototype.hasOwnProperty.call(props.errors, "shareAmount")}
                        {...alternateFormikProps}
                        label={t("My monthly donation amount (optional)")}
                        sx={{minWidth:'50%'}}
                    />
                </div>
            </div>

            <div className="d-flex flex-wrap justify-content-end mt-4">
                    <a className="btn srp-btn btn-lightblue" onClick={() => props.actions.cancelClick()}>{t("Cancel")}</a>
                <button className="btn srp-btn btn-blue ml-2" type="submit" disabled={props.bankDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                    {props.bankDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                        ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                            : t("Next")
                    }
                </button>
            </div>
        </form>
    )
}

EnrollAutopayForm.propTypes = {
    actions: PropTypes.object.isRequired,
    autopayInfo: PropTypes.object.isRequired,
    autopayFormData: PropTypes.object.isRequired,
    bankDataStatus: PropTypes.string.isRequired,
    loginEmail: PropTypes.string.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    // Formik props
    errors: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    status: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object
}

export default EnrollAutopayForm
