import React from "react"
import PropTypes from 'prop-types'

import HowToCashPaymentCard from "./how-to-cash-payment-card"
import IneligibleCashPaymentCard from "./ineligible-cash-payment-card"

const HowToCashPayCardContainer = ({ isBarcodeEligible, isMpower, selectedBillAccountDetails, isSolar, sendReconnectRemark }) => {
    return (
        <div>
            {isBarcodeEligible
                ? <HowToCashPaymentCard 
                    isMpower={isMpower} 
                    selectedBillAccountDetails={selectedBillAccountDetails} 
                    isSolar={isSolar} 
                    sendReconnectRemark={sendReconnectRemark} 
                />
                : <IneligibleCashPaymentCard />
            }
        </div>
    )
}

HowToCashPayCardContainer.propTypes = {
    isBarcodeEligible: PropTypes.bool,
    isMpower: PropTypes.bool
}

export default HowToCashPayCardContainer
