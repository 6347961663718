import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { getEnrolledProgramsDetails } from '../../actions/auth/payment/payment-actions'
import { getRateMetaData } from '../../actions/auth/usage/rate-meta-data-actions'
import { getContacts } from '../../actions/auth/contact/contact-actions'
import { withTranslation } from 'react-i18next'

import eppStatusModule from '../../srp_modules/epp-status-module'
import * as loadingStatus from '../../constants/loading-status-constants'
import { eppConstants } from '../../constants/epp-constants'
import config from 'my-account-config'

class EppStatusMessage extends React.Component {
    constructor(props) {
        super(props)

        this.displayEppAuditStatusMessage = this.displayEppAuditStatusMessage.bind(this)
        this.shouldDisplayStatusMessage = this.shouldDisplayStatusMessage.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getRateMetaData(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactsInfo(this.props.selectedBillAccount)
        }
    }

    //switch to componentDidUpdate(prevProps)
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.enrolledProgramsDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnrolledProgramsDetails(nextProps.selectedBillAccount)
        }

        if (nextProps.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getRateMetaData(nextProps.selectedBillAccount)
        }

        if (nextProps.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactsInfo(nextProps.selectedBillAccount)
        }
    }

    shouldDisplayStatusMessage(eppProgramDetails) {
        let display = false
        if (eppProgramDetails.isEnrolled && eppProgramDetails.isInActiveAudit) {
            display = true
        } else if (eppProgramDetails.removedWithinOneYear) {
            display = true
        }

        return display
    }

    displayEppAuditStatusMessage() {
        let statusMessageInfo = eppStatusModule.getEppStatusMessageInfo(this.props.eppProgramDetails)
        const t = this.props.t
        const isSpanish = this.props.i18n.language === "es"

        if (statusMessageInfo.showMessage) {
            return (
                <div className={`${statusMessageInfo.bannerType} srp-card-body mt-3`}>
                    <div className="font-weight-bold">{t("EPP Alert")}</div>
                    <div>
                        <span className="font-weight-bold text-uppercase">{t(statusMessageInfo.title)}: </span>
                        <span>{t(statusMessageInfo.description)} </span>
                        {this.props.eppProgramDetails.statusId !== eppConstants.AUDIT_STATUS.APPROVED &&
                            !this.props.eppProgramDetails.isPendingReview &&
                            <Link to="/myaccount/epp/application" target="_blank" className="displayAsLink font-weight-bold">{t("Reapply")} »</Link>
                        }
                    </div>
                    {this.props.eppProgramDetails.hasMissingInformation && !this.props.eppProgramDetails.isPendingReview &&
                    <div className="mt-3">
                        <div className="font-weight-bold">{t("Here's what you are missing")}:</div>
                        <ul className="pl-4">
                            <li>{this.props.eppProgramDetails.missingInformationDescription}</li>
                        </ul>
                    </div>
                    }
                    {this.props.eppProgramDetails.statusId !== eppConstants.AUDIT_STATUS.APPROVED
                        ? <div className="mt-3">
                            <a
                                className="displayAsLink font-weight-bold"
                                href={isSpanish
                                    ? `${config.srpnetBaseUrlEs}servicio-al-cliente/electricidad-residencial/plan-precio-economico`
                                    : `${config.srpnetBaseUrl}customer-service/residential-electric/economy-price-plan`}
                                target="_blank">
                                {t("Learn more about EPP")} »
                            </a>
                        </div>
                        : null
                    }
                </div>
            )
        }
        return null
    }

    render() {
        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.contactsStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            if (this.shouldDisplayStatusMessage(this.props.eppProgramDetails)) {
                return this.displayEppAuditStatusMessage()
            }

            return null
        }
        return null
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.accountInfo.billAccount,
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        eppProgramDetails: state.accountInfo.payment.enrolledProgramsDetails.economicPricePlan,
        loginEmail: state.login.loginEmail,
        ...state.accountInfo.contact
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEnrolledProgramsDetails: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        getRateMetaData: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getContactsInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        }
    }
}

EppStatusMessage.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    loginEmail: PropTypes.string,

    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetails: PropTypes.func,
    eppProgramDetails: PropTypes.object,

    customerNames: PropTypes.object,
    getRateMetaData: PropTypes.func,
    customerNamesStatus: PropTypes.string,

    primaryPhone: PropTypes.string,
    getContactsInfo: PropTypes.func,
    contactsStatus: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("eppStatus")(connect(mapStateToProps, mapDispatchToProps)(EppStatusMessage))