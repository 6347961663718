import React from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import { Link } from 'react-router-dom'

import { myAccountConstants } from '../../../constants/myaccount-constants'

import config from 'my-account-config'

const CardOutageClosedAccount = ({closedDate, t, i18n}) => {
    const closedDateMDYYYY = DateTime.fromISO(closedDate).toFormat('M/d/yyyy')

    const isSpanish = (i18n.language === 'es')
    const outagesStormUrl = isSpanish ?
        `${config.srpnetBaseUrl}es/servicio-al-cliente/seguridad/apagones-tormentas` :
        `${config.srpnetBaseUrl}customer-service/safety/outages-storm`

    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("Outages")}
            </div>
            <div className="srp-card-body ">
                <div className="srp-card-outage-closed-account d-flex justify-content-around">
                    <div className="d-flex flex-column align-items-center text-white">
                        <div className="h4">{t("Account closed")}</div>
                        <div>{t("as_of_date", {closedDateMDYYYY})}</div>
                    </div>
                </div>
                <div className="srp-card-details d-none d-lg-block">
                    <div className="d-flex justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Link to="/myaccount/outages" className="btn srp-btn btn-lightblue mb-3">{t("View map")}</Link>
                    </div>
                    {t("If_you_have_questions_call_SRP", {phone: i18n.language==='es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}

                </div>
                <div className="srp-card-details">
                    {t("For_more_info_visit_SRP_outage_resources")}<br />
                    <a href={outagesStormUrl} target="_new">{t("Go to Outage Resources")} &raquo;</a>
                </div>
                <div className="d-block d-lg-none">
                    <Divider />
                    <div className="srp-card-details">
                        <div>
                            <div className="d-flex justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Link to="/myaccount/outages" className="btn srp-btn btn-lightblue mb-3">{t("View map")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

CardOutageClosedAccount.propTypes = {
    closedDate: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default CardOutageClosedAccount