import React from 'react'
import PropTypes from 'prop-types'
import utils from '../srp_modules/utils'
import { withTranslation } from 'react-i18next'

const EmptyState = ({ isCommercial, dataType, t, i18n }) => {
    const isSpanish = i18n.language==="es"
    const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
    const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

    return (
        <div className="srp-card-body">
            <div className="srp-card-summary srp-inactive d-flex justify-content-around">
                <div className="d-flex flex-column align-items-center text-white">
                    <div><i className="material-icons">error_outline</i></div>
                    <div>{t("Something went wrong")}</div>
                </div>
            </div>
            <div className="srp-card-details">
                <p className="d-lg-block d-none mb-0" style={{ color: '#707070' }}>
                    {t("sorryNote", {dataType: dataType, phoneNumber: supportNumberFmt})}
                </p>
                <p className="d-lg-none d-block mb-0">
                    {t("sorryNoteWithLink", {dataType: dataType})}
                    <a href={"tel:" + supportNumber}>{supportNumberFmt}</a>.
                </p>
            </div>
        </div>
    )
}

EmptyState.propTypes = {
    isCommercial: PropTypes.bool.isRequired,
    dataType: PropTypes.string,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("emptyState")(EmptyState)