import React from 'react'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { myAccountConstants } from '../constants/myaccount-constants'
import siteOfflineImage from '../images/maintenance/site_offline.png'
import config from 'my-account-config'
import MainLayout from '../components/main-layout'
import { useTranslation } from 'react-i18next'

const siteOfflineImageStyle = {
    width: '72px',
    height: '72px',
    textAlign: 'center',
    margin: 'auto'
}

const defaultOfflineMessage = "My Account is undergoing maintenance at this time. We apologize for any inconvenience this may cause."

const SiteOffline = ({startDate, endDate, offlineMessage}) => {
    const { i18n } = useTranslation()
    const isSpanish = i18n.language==='es'

    let displayMessage = offlineMessage || defaultOfflineMessage
    startDate = startDate || DateTime.now()
    endDate = endDate || DateTime.now()
    return (
        <MainLayout>
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <h3 className="mt-3">My Account maintenance</h3>
                    <h5 className="text-muted">We'll be up and running soon!</h5>
                </div>
            </div>
            <div className="row">
                <div className="srp-card col-12 col-lg-6 pb-3">
                    <div className="srp-card-header ">
                        We're down for scheduled maintenance
                    </div>
                    <div className="srp-card-body p-3">
                        <div style={siteOfflineImageStyle}>
                            <img src={siteOfflineImage} style={siteOfflineImageStyle} />
                        </div>
                        {(
                            <>
                            <p>{displayMessage}</p>
                            <p>
                                {'While waiting, you can always explore our '}
                                <a href={isSpanish
                                            ? `${config.srpnetBaseUrlEs}control-red-de-agua/control-del-agua/conservacion-de-agua`
                                            : `${config.srpnetBaseUrl}grid-water-management/water-management/conserving-water`}>water conservation</a>
                                {' and '}
                                <a href={isSpanish
                                            ? `${config.srpnetBaseUrlEs}reembolsos-ahorros-de-energia/hogar/diy-proyectos-ahorros`
                                            : `${config.srpnetBaseUrl}energy-savings-rebates/home/diy-energy-saving-projects`}>energy saving tips</a>.
                            </p>
                            </>
                        )}
                    </div>
                </div>
                <div className="srp-card col-12 col-lg-5 pb-3">
                    <div className="srp-card-header">
                        Maintenance details
                    </div>
                    <div className="srp-card-body">
                        <div className="p-3 m-0 d-flex align-items-center flex-wrap">
                            <div className="p-0" style={{ flex: 1 }}>
                                <h5 className="text-nowrap">Expected down time</h5>
                            </div>
                            <div className="p-0 d-flex justify-content-end">
                                <h4 className="text-nowrap">{startDate.toFormat('h:mm a')} - {endDate.toFormat('h:mm a')}</h4>
                            </div>
                        </div>
                        <hr className="p-0 m-0" />
                        <p className="p-3 m-0">
                            {'If you need immediate assistance, please call us at '}
                            <span className="text-nowrap d-lg-inline d-none">{myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}</span>
                            <span className="text-nowrap d-lg-none">
                                <a href={`tel:${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER}`}>
                                {myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                                </a>
                            </span>
                            . We're available to serve you 24 hours a day, seven days a week.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </MainLayout>
    )
}

SiteOffline.propTypes = {
    startDate: PropTypes.shape({
        toFormat: PropTypes.func.isRequired
    }).isRequired,
    endDate: PropTypes.shape({
        toFormat: PropTypes.func.isRequired
    }).isRequired,
    offlineMessage: PropTypes.string
}

const mapStateToProps = (state) => {
    return {
        startDate: state.login.siteOfflineInfo.startDate,
        endDate: state.login.siteOfflineInfo.endDate,
        offlineMessage: state.login.siteOfflineInfo.offlineMessage
    }
}

export default connect(mapStateToProps)(SiteOffline)