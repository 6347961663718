import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import config from 'my-account-config'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import '../../styles/price-plan.css'

import { pricePlanConstants } from '../../constants/price-plan-constants'

const CommercialSelectPlan = ({ selectedPricePlanCommercial, selectNewPricePlanCommercial, currentRateCode, t }) => {
    return (
        <FormGroup>
            {currentRateCode !== pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.TOU &&
                <FormControlLabel
                    control={<Radio
                        checked={selectedPricePlanCommercial === pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.TOU}
                        onChange={() => selectNewPricePlanCommercial(pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.TOU)}
                        value={pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.TOU}
                        color="primary"
                    />}
                    label={
                        <div>
                            <span className="font-weight-bold mr-3">{t("Business Time-of-Use Plan")}</span>
                            {/* Business Time-of-Use plan is in English only */}
                            <a className="displayAsLink" href={`${config.srpnetBaseUrl}price-plans/business-electric/time-of-use`} target="_blank">{t("Learn more")} »</a>
                        </div>
                    }
                />
            }
            {currentRateCode !== pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.GENERAL &&
                <FormControlLabel
                    control={<Radio
                        checked={selectedPricePlanCommercial === pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.GENERAL}
                        onChange={() => selectNewPricePlanCommercial(pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.GENERAL)}
                        value={pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.GENERAL}
                        color="primary"
                    />}
                    label={
                        <div>
                            <span className="font-weight-bold mr-3">{t("Business General Service Plan")}</span>
                            {/* Business General Service plan is in English only */}
                            <a className="displayAsLink" href={`${config.srpnetBaseUrl}price-plans/business-electric/general-service`} target="_blank">{t("Learn more")} »</a>
                        </div>
                    }
                />
            }
        </FormGroup>
    )
}

CommercialSelectPlan.propTypes = {
    selectedPricePlanCommercial: PropTypes.number,
    selectNewPricePlanCommercial: PropTypes.func,
    currentRateCode: PropTypes.number,
    t: PropTypes.func
}

export default withTranslation('changePricePlan')(CommercialSelectPlan)