import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'

import config from 'my-account-config'

import OutageIcon from '../../images/nav/nav_icons/blue_icons/outages-blue.svg'
import PricePlansProgramsIcon from '../../images/nav/nav_icons/blue_icons/price-plan-programs-blue.svg'

const OutageBanners = ({t, i18n, heatReliefLink, showHeatReliefLink}) => (
<>
<div className="d-none d-lg-flex mt-2">
<div className={"outage-banners px-3 py-4 mr-2 " + (showHeatReliefLink ? "w-50" : "w-100")}>
<div className="d-flex">
<img alt="outage" className="outage-banner-icons mr-3" src={OutageIcon} />
<div>
<p className="h5 font-weight-bold">{t("Stay safe during outages")}</p>
<Trans i18nKey="visit_outage_resources" t={t}>
    For more information about outage preparedness and safety, please visit SRP's
    <a className="displayAsLink" target="_blank"
        href={i18n.language === 'es'
            ? `${config.srpnetBaseUrlEs}servicio-al-cliente/seguridad/apagones-tormentas`
            : `${config.srpnetBaseUrl}customer-service/safety/outages-storm`}>Outage Resource Center</a>.
</Trans>
</div>
</div>
</div>

{showHeatReliefLink &&
<div className="w-50 outage-banners px-3 py-4 ml-2">
<div className="d-flex">
<img alt="outage" className="outage-banner-icons mr-3" src={PricePlansProgramsIcon} />
<div>
<p className="h5 font-weight-bold">{t("Looking for heat relief?")}</p>
<Trans i18nKey="adhs_heat_relief" t={t}>
    <a className="displayAsLink" target="_blank"
        href={heatReliefLink}>Visit the interactive ADHS map</a>
     to find cooling centers, hydration stations, and other sites throughout the valley to help you stay safe while temperatures are high.                
</Trans>
</div>
</div>
</div>
}
</div>

<div className="d-flex d-lg-none flex-column mt-2">
<div className="outage-banners px-3 py-5 mb-2">
<div className="d-flex justify-content-center mb-3"><img alt="outage" className="outage-banner-icons" src={OutageIcon} /></div>
<p className="h5 font-weight-bold">{t("Stay safe during outages")}</p>
<Trans i18nKey="visit_outage_resources" t={t}>
    For more information about outage preparedness and safety, please visit SRP's
    <a className="displayAsLink" target="_blank"
        href={i18n.language === 'es'
            ? `${config.srpnetBaseUrlEs}servicio-al-cliente/seguridad/apagones-tormentas`
            : `${config.srpnetBaseUrl}customer-service/safety/outages-storm`}>Outage Resource Center</a>.
</Trans>
</div>

{showHeatReliefLink &&
<>
<div className="outage-banners px-3 py-5 mt-2">
<div className="d-flex justify-content-center mb-3"><img alt="outage" className="outage-banner-icons" src={PricePlansProgramsIcon} /></div>
<p className="h5 font-weight-bold">{t("Looking for heat relief?")}</p>
<Trans i18nKey="adhs_heat_relief" t={t}>
    <a className="displayAsLink" target="_blank"
        href={heatReliefLink}>Visit the interactive ADHS map</a>
     to find cooling centers, hydration stations, and other sites throughout the valley to help you stay safe while temperatures are high.                
</Trans>
</div>
</>
}
</div>
</>
)


OutageBanners.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    heatReliefLink: PropTypes.string.isRequired,
    showHeatReliefLink: PropTypes.bool.isRequired,
}

export default OutageBanners