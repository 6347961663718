import React from 'react'
import PropTypes from 'prop-types'

const NoMonthlyUsageMessage = ({t}) => {
    return (
        <div className="usage-chart-error">
            <div className="text-center">
                <div><i className="material-icons">error_outline</i></div>
                <div className="usage-chart-error-title">{t("Usage data unavailable")}</div>
                <div>{t("We're sorry, but there is no recent monthly usage data available for the account.")}</div>
            </div>
        </div>
    )
}

NoMonthlyUsageMessage.propTypes = {
    t: PropTypes.func
}

export default NoMonthlyUsageMessage