/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'

import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'

import Icon from '@mui/material/Icon'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { enUS, esES } from '@mui/x-date-pickers/locales'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { DateTime } from 'luxon'
import { CustomLuxonDateAdapter } from '../../../srp_modules/custom-luxon-date-adapter'
import format from '../../../srp_modules/format'
import utils from '../../../srp_modules/utils'

import SrpTooltip from '../../common_tooltip/srp-tooltip'

let dateFieldProps = {
    minDate: DateTime.now().startOf('day'),
    maxDate: DateTime.now().startOf('day').plus({ days: 59}),
    minDateMessage: "",
    maxDateMessage: "",
    invalidDateMessage: "",
    disablePast: true
}

function getWarningStyle(errorLevel) {
    let color = "rgb(158,42,43)"

    if (errorLevel === myAccountConstants.ERROR_LEVEL.WARNING) {
        color = "rgb(204,138,0)"
    }

    return { color: color }
}

function getFullStreetAddress(address) {
    let unitNum = ""
    if (address.unitNumber) {
        unitNum = "#" + address.unitNumber
    }

    let fullStreetAddress = address.houseNumber + " " + address.addressDirection + " " + address.streetName + " " + unitNum

    if (fullStreetAddress.length > 24) {
        fullStreetAddress = fullStreetAddress.substring(0, 11) + "..." + fullStreetAddress.substring(fullStreetAddress.length - 10)
    }

    return fullStreetAddress
}

function getStatusText(text, color) {
    return (
        <span style={{marginLeft: 'auto', marginRight: 'auto', color: color}}>{text}</span>
    )
}

function getPaymentStatus(paymentInfo, t) {
    if (paymentInfo.isAdditionalPayment && paymentInfo.paymentInfo.isSurePay) {
        return t("One-time payment")
    }

    if (paymentInfo.isAdditionalPayment) {
        return t("One-time payment")
    }

    if (paymentInfo.paymentInfo.isSurePay) {
        return (
            getStatusText(t('SurePay'), 'rgb(115,123,76)')
        )
    }

    if (paymentInfo.isCashOnly) {
        return t("Cash only")
    }

    if (paymentInfo.paymentInfo.isBankrupt === true) {
        return <span>{t('Bankruptcy account')}&nbsp;&nbsp;&nbsp;&nbsp;{getBankruptcyElement(t)}</span>
    }

    if (paymentInfo.hasPendingPayment) {
        return <span style={{ color: "rgb(115, 123, 76)" }}>{t('Scheduled')}</span>
    }

    if (paymentInfo.paymentInfo.amountDue <= 0) {
        if (paymentInfo.paymentInfo.isClosedAccountWithZeroBalance) {
            return t("Closed account")
        }

        if (paymentInfo.hoursSinceLastPayment > myAccountConstants.SINGLE_DAY_HOURS) {
            return <span style={{ color: "rgb(115, 123, 76)" }}>{t('No payment due')}</span>
        } else {
            return <span style={{ color: "rgb(115, 123, 76)" }}>{t('Paid in full')}</span>
        }
    }

    // There's an amount due at this point.

    if (DateTime.fromISO(paymentInfo.paymentInfo.dueDate) === DateTime.fromISO(myAccountConstants.MINIMUM_DATE)) {
        // No due date but there's an amount due. This is possible
        // if the account is on MPower or it's write-off.
        return t("Payment due")
    }

    // There's a payment due date from this point on.

    if (paymentInfo.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
        return t("See bill")
    }

    if (paymentInfo.dueYesterday) {
        return getStatusText(t('Due yesterday'), '#9E2A2B')
    }

    if (paymentInfo.dueToday) {
        return getStatusText(t('Due today'), 'rgb(0,75,135)')
    }

    if (paymentInfo.dueTomorrow) {
        return getStatusText(t('Due tomorrow'), 'rgb(0,75,135)')
    }

    if (paymentInfo.paymentInfo.pastDue > 0) {
        let text = t('Past due') + ' (' + DateTime.fromISO(paymentInfo.paymentInfo.dueDate).toFormat("M/d/yy") + ')'
        return getStatusText(text, '#9E2A2B')
    }

    return getStatusText(t("Due") + " " + DateTime.fromISO(paymentInfo.paymentInfo.dueDate).toFormat("M/d/yy"), 'rgb(0,75,135)')
}

function getBankruptcyElement(t) {
    return (<SrpTooltip content={<span>{t('bankruptcy_warning')}</span>}>
        <Icon style={{ color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
    </SrpTooltip>)
}

function getAmountDueDisplay(amountDue) {
    const displayAmountDue = Math.abs(amountDue).toLocaleString("en-US", { style: "currency", currency: "USD" })

    return amountDue >= 0 ?
        displayAmountDue :
        displayAmountDue + " CR"
}

function getAmountDue(paymentInfo) {
    if (paymentInfo.isAdditionalPayment)
        return "- -"

    let amountDue = paymentInfo.paymentInfo.amountDueWithShare

    const displayAmountDue = getAmountDueDisplay(amountDue)

    if (amountDue >= 0)
        return displayAmountDue

    return <span style={{ color: "green" }}>{displayAmountDue}</span>
}

function getPaymentAmountErrorText(paymentAmountError, billAccount, cixClick, t) {
    const errorType = paymentAmountError.errorType
    let text = null
    switch (errorType) {
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.INVALID_AMOUNT:
            text = <span>{t('Invalid payment amount')}</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL:
            text = <span>{t('paying_less')}</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL_CIX_ELIGIBLE:
            text = (
                <span>
                    {t('Paying less than the total amount due may negatively affect your account, including disconnection.')}
                    {' '}
                    {t('Need')} <span className="myaccount-pointer" style={{ textDecoration: "underline" }} onClick={() => cixClick(billAccount)}>{t('more time to pay')}</span>? {t('We may be able to assist you.')}
                </span>
            )
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.MORE_THAN_TOTAL:
            text = <span>{t('You are making a payment that is more than the amount due.')}</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.PAYMENT_LIMIT:
            {
                let maxPaymentAmountDisplay = utils.delimitNumbers(paymentAmountError.paymentHardLimit)
                text = <span>{t("Payments of over") + " " + maxPaymentAmountDisplay + " " + t("are not allowed")}</span>
            }
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.REQUIRED:
            text = <span>{t('This field is required')}</span>
            break
    }

    return text
}

function getPaymentDateErrorText(errorType, billAccount, cixClick, t) {
    let text = null
    switch (errorType) {
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.REQUIRED:
            text = <span>{t('This field is required')}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.INVALID_DATE:
            text = <span>{t('Invalid payment date: Please enter a date in MM/DD/YY format')}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_CIX_ELIGIBLE:
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL_CIX_ELIGIBLE:
            text = (
                <span>
                    {t('pay_after_due_date')}
                    {' '}
                    {t('Need')} <span className="myaccount-pointer" style={{ textDecoration: "underline" }} onClick={() => cixClick(billAccount)}>{t('more time to pay')}</span>? {t('We may be able to assist you.')}
                </span>
            )
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE:
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL:
            text = <span>{t('pay_after_due_date')}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_BEFORE_TODAY:
            text = <span>{t("Payment date cannot be earlier than today's date")}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_MORE_THAN_60:
            text = <span>{t("You cannot schedule a payment more than 60 days in advance")}</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.PAYMENT_ALREADY_SCHEDULED:
            text = <span>{t("already_scheduled_payment")}</span>
            break
    }

    return text
}

function getInitialPaymentDateValue(paymentInfo, selectedInfo) {
    let initialDate = DateTime.now().startOf('day').toISODate()
    let surePayDate = DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)
    let minimumDate = DateTime.fromISO(myAccountConstants.MINIMUM_DATE)

    if (paymentInfo.hasPendingPayment && !paymentInfo.isAdditionalPayment) {
        if (paymentInfo.paymentInfo.isSurePay && surePayDate !== minimumDate) {
            initialDate = paymentInfo.paymentInfo.surePayDate
        } else {
            initialDate = paymentInfo.scheduledPayments[0].paymentDate
        }
    } else if (selectedInfo.isSelected) {
        initialDate = selectedInfo.paymentDate
    }

    return initialDate
}

function getInitialPaymentValue(paymentInfo, selectedInfo) {
    let initialPayment = ''
    let surePayDate = DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)
    let minimumDate = DateTime.fromISO(myAccountConstants.MINIMUM_DATE)

    if (paymentInfo.hasPendingPayment && !paymentInfo.isAdditionalPayment) {
        if (paymentInfo.paymentInfo.isSurePay && surePayDate !== minimumDate) {
            initialPayment = paymentInfo.paymentInfo.amountDueWithShare.toFixed(2)
        } else {
            initialPayment = paymentInfo.scheduledPayments[0].paymentAmount.toFixed(2)
        }
    } else if (paymentInfo.paymentInfo.isSurePay && !paymentInfo.isAdditionalPayment) {
        // do nothing. If there is no SurePay payment scheduled, leave blank
    } else if (selectedInfo.isSelected) {
        initialPayment = selectedInfo.paymentAmount
    }

    return initialPayment
}

function getSummaryMasterIcon(summaryBillingStatus, t) {
    if (summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
        return (
            <div className="text-nowrap summary-account-parent-chip" style={{paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px'}}>
                <span style={{marginLeft: 'auto', marginRight: 'auto'}}>{t('Master')}</span>
            </div>
        )
    } else {
        return null
    }
}

function getRowTitle(paymentInfo, viewBillClick, t) {
    if (paymentInfo.accountName && paymentInfo.accountName.trim() !== '') {
        return (
            <div className="clearfix" style={{position: 'relative'}}>
                <div style={{fontSize: '18px', fontWeight: '800', float: 'left'}}>
                    <div>
                        <button
                            className="displayAsLink text-truncate"
                            type="button"
                            style={{ maxWidth: '200px'}}
                            onClick={() => viewBillClick(paymentInfo.billAccount)}
                        >
                            {paymentInfo.accountName}
                        </button>
                    </div>
                    <div style={{color: '#333333', fontWeight: '500'}}>
                        <PatternFormat
                            value={format.srpAccountNumber(paymentInfo.billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                        />
                    </div>
                </div>
                <div style={{paddingTop: '5px', float: 'right'}}>
                    {getSummaryMasterIcon(paymentInfo.summaryBillingStatus, t)}
                </div>
            </div>
        )
    } else {
        return (
            <div className="clearfix" style={{position: 'relative'}}>
                <div style={{float: 'left'}}>
                    <button
                        className="displayAsLink"
                        style={{fontSize: '18px', fontWeight: '800', maxWidth: '200px'}}
                        type="button"
                        onClick={() => viewBillClick(paymentInfo.billAccount)}
                    >
                        <PatternFormat
                            value={format.srpAccountNumber(paymentInfo.billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                        />
                    </button>
                </div>
                <div style={{paddingTop: '5px', float: 'right'}}>
                    {getSummaryMasterIcon(paymentInfo.summaryBillingStatus, t)}
                </div>
            </div>
        )
    }
}

const PayableAccountRowResponsive = (props) => {
    const i18n = props.i18n
    const t = props.t
    const isSpanish = (i18n.language === "es")
    const locale = isSpanish ? "es-MX" : "en-US"
    const localeText = (isSpanish ? esES : enUS).components.MuiLocalizationProvider.defaultProps.localeText

    let paymentInfo = props.paymentInfo
    let selectedInfo = props.selectedInfo
    let disabled = props.disabled
    let isSelected = disabled ? false : selectedInfo.isSelected
    let index = props.index

    let leftClass = "pat-payment-row-responsive-left"
    let rightClass = "pat-payment-row-responsive-right"

    if (paymentInfo.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
        leftClass = "pat-payment-row-responsive-left-parent"
        rightClass = "pat-payment-row-responsive-right-parent"
    }
    return (
        <>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                selected={isSelected}
                className="open-close"
            >
            <TableCell className={leftClass} padding="none" style={{ verticalAlign: "top", paddingTop: "10px", width: "45px"}}>
                <Checkbox
                    onChange={() => props.getSelectedPayableAccountsOnClick(paymentInfo.billAccount, paymentInfo.paymentInfo)}
                    disabled={disabled}
                    color="primary"
                    checked={isSelected}
                />
            </TableCell>
            <TableCell className={rightClass}>
                {getRowTitle(paymentInfo, props.viewBillClick, t)}
                <div className="clearfix" style={{ position: "relative" }}>
                    <div style={{maxWidth: "200px"}}>
                        {paymentInfo.summaryBillingStatus !== billAccountConstants.SUMMARY_BILLING_STATUS.PARENT && getFullStreetAddress(paymentInfo.serviceAddress)}
                    </div>
                    <div style={{paddingTop: '3px', paddingBottom: '3px'}}>
                        {getPaymentStatus(paymentInfo, t)}
                    </div>
                </div>
                <div className="clearfix" style={{position: 'relative'}}>
                        <div style={{float: 'left', color: 'rgba(33, 33, 33, 0.537)'}}>
                            {t('Amount due')}:
                        </div>
                        <div style={{float: 'right', fontWeight: '800'}}>
                            {getAmountDue(paymentInfo)}
                        </div>
                    </div>
                {/* collapsible input  */}
                <div style={isSelected && (paymentInfo.hasPendingPayment || !disabled) ? { display: 'block'} : { display: 'none'}}>
                    <div className="clearfix" style={{ paddingTop: "15px", position: "relative" }}>
                        <div style={{width: "85%", float: "left"}}>
                            <NumericFormat
                                id={"paymentAmount" + index + "m"}
                                placeholder={t("Enter amount")}
                                disabled={disabled}
                                value={getInitialPaymentValue(paymentInfo, selectedInfo)}
                                valueIsNumericString={true}
                                onBlur={(values) => {
                                    const paymentAmount = values.target.value
                                    if (paymentAmount === "") {
                                        if (selectedInfo.isSelected)
                                            props.getSelectedPayableAccountsOnClick(paymentInfo.billAccount, paymentInfo.paymentInfo)
                                    }
                                    else {
                                        let fixedPaymentAmount = Number(paymentAmount.replace(/[$,]/g, '')).toFixed(2)
                                        props.getPaymentAmountOnChange(paymentInfo.billAccount, fixedPaymentAmount)
                                    }
                                }}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={'$'}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                customInput={TextField}
                                type="text"
                                fullWidth={true}
                                InputProps={{ inputProps: { inputMode: 'decimal' } }}
                                className="pat-input-box-responsive"
                                label={t("Payment amount")}
                            />
                        </div>
                        <div style={{width: "15%", paddingTop: "30px", paddingLeft: "10px", float: "left"}}>
                            {isSelected && (selectedInfo.paymentAmountError.hasError) ?
                                props.datePickerDisabled 
                                && selectedInfo.paymentAmountError.errorType === (multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL 
                                || multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL_CIX_ELIGIBLE) 
                                    ?
                                    null : 
                                    <SrpTooltip
                                        content={<span>{getPaymentAmountErrorText(selectedInfo.paymentAmountError, props.paymentInfo.billAccount, props.cixClick, t)}</span>}>
                                        <Icon style={{ ...getWarningStyle(selectedInfo.paymentAmountError.errorLevel), verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                                    </SrpTooltip>
                                :null
                            }
                        </div>
                    </div>
                    <div className="clearfix" style={{ paddingTop: "10px", paddingBottom: "15px", position: "relative" }}>
                        <div style={{ width: "85%", float: "left"}}>
                            {/* <InputLabel>Payment date</InputLabel> */}
                            <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale={locale} localeText={localeText}>
                                <DatePicker
                                    {...dateFieldProps}
                                    keyboard
                                    clearable
                                    disableOpenOnEnter
                                    disablePast
                                    disabled={disabled || paymentInfo.isAdditionalPayment || props.datePickerDisabled}
                                    className="multi-payment-date-picker-mobile pat-input-box-responsive"
                                    slotProps={{ textField: { fullWidth: true } }}
                                    value={DateTime.fromISO(getInitialPaymentDateValue(paymentInfo, selectedInfo))}
                                    format="M/d/yyyy"
                                    onChange={(selectedDate) => props.getPaymentDateOnChange(paymentInfo.billAccount, selectedDate.toISODate(), selectedInfo.paymentAmount)}
                                    label={t("Payment date")}
                                    views={['day']}
                                />
                            </LocalizationProvider>
                        </div>
                        <div style={{width: "15%", paddingTop: "30px", paddingLeft: "10px", float: "left"}}>
                            {props.datePickerDisabled?
                                <div>
                                    <SrpTooltip
                                        content={<span>Future date payments not allowed. Please call to make a payment. </span>}>
                                        <Icon style={{ color: "rgb(158,42,43)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                                    </SrpTooltip>
                                </div>
                            : isSelected && (selectedInfo.paymentDateError.hasError) ?
                                <div>
                                    <SrpTooltip
                                        content={<span>{getPaymentDateErrorText(selectedInfo.paymentDateError.errorType, props.paymentInfo.billAccount, props.cixClick, t)}</span>}>
                                        <Icon style={{ ...getWarningStyle(selectedInfo.paymentDateError.errorLevel), verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                                    </SrpTooltip>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </TableCell>
            </TableRow>
            {paymentInfo.summaryBillingStatus !== billAccountConstants.SUMMARY_BILLING_STATUS.PARENT &&
            <TableRow style={{height: "10px", maxWidth: "300px"}} />
            }
        </>
    )
}

PayableAccountRowResponsive.propTypes = {
    paymentInfo: PropTypes.object.isRequired,
    selectedInfo: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    getSelectedPayableAccountsOnClick: PropTypes.func.isRequired,
    getPaymentAmountOnChange: PropTypes.func.isRequired,
    getPaymentDateOnChange: PropTypes.func.isRequired,
    cixClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    viewBillClick: PropTypes.func.isRequired
}

export default PayableAccountRowResponsive