import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import format from '../../../srp_modules/format'

import BillingDocuments from './billing-documents'

import config from 'my-account-config'


const MyMPowerCard = ({eBillDocumentList, selectedBillName, selectedBillDownloadUrl, selectedBillViewUrl, getUrlsForSelectedBill, amountDue, i18n, t}) => {
    const amountDueDisplay = format.formatDollarAmount(amountDue < 0 ? 0 : amountDue)

    const isSpanish = (i18n.language === 'es')
    const srpCreditPolicy = isSpanish
        ? `${config.srpnetBaseUrlEs}cuenta/electricidad/residencial/politicas-credito-residencial`
        : `${config.srpnetBaseUrl}account/electric/residential/residential-credit-policy`

    return (
        <div className="srp-card-body">
            <div className="d-lg-block d-none container p-3">
                <div className="row">
                    <div className="col">
                        <p className="h5 font-weight-bold text-muted">{t("Amount due to SRP")}</p>
                        <p className="h2 font-weight-bold mt-4 srp-dark-blue-color">{amountDueDisplay}</p>
                        <Link to="/myaccount/payment" className="btn srp-btn btn-blue float-right mt-1 mb-3">{t("Make purchase")}</Link>
                    </div>
                    <div className="col">
                        <BillingDocuments eBillDocumentList={eBillDocumentList}
                            selectedBillDownloadUrl={selectedBillDownloadUrl} selectedBillViewUrl={selectedBillViewUrl}
                            selectedBillName={selectedBillName} getUrlsForSelectedBill={getUrlsForSelectedBill} t={t}/>
                    </div>
                    <div className="col">
                        <p className="h5 font-weight-bold text-muted">{t("I would like to...")}</p>
                        <a href={srpCreditPolicy} target="_blank" className="nav-link mt-3 px-0">{t("View SRP's credit policy")}</a>
                    </div>
                </div>
            </div>
            <div className="d-lg-none d-block p-3">
                <p className="h5 font-weight-bold text-muted">{t("Amount due to SRP")}</p>
                <p className="h2 font-weight-bold mt-4 srp-dark-blue-color">{amountDueDisplay}</p>
                <Link to="/myaccount/payment" className="btn srp-btn btn-blue w-100 mt-2 mb-5">{t("Make purchase")}</Link>

                <BillingDocuments eBillDocumentList={eBillDocumentList}
                    selectedBillDownloadUrl={selectedBillDownloadUrl} selectedBillViewUrl={selectedBillViewUrl}
                    selectedBillName={selectedBillName} getUrlsForSelectedBill={getUrlsForSelectedBill} t={t}/>

                <p className="h5 font-weight-bold text-muted">{t("I would like to...")}</p>
                <a href={srpCreditPolicy} target="_blank" className="nav-link mt-2 px-0">{t("View SRP's credit policy")}</a>
            </div>
        </div>
    )
}

MyMPowerCard.propTypes = {
    eBillDocumentList: PropTypes.arrayOf(PropTypes.shape({
        documentID: PropTypes.string.isRequired,
        billingID: PropTypes.number.isRequired,
        documentDate: PropTypes.string.isRequired,
        documentDisplayDate: PropTypes.string.isRequired,
        documentType: PropTypes.number.isRequired,
    })),
    selectedBillName: PropTypes.string.isRequired,
    selectedBillDownloadUrl: PropTypes.string.isRequired,
    selectedBillViewUrl: PropTypes.string.isRequired,
    getUrlsForSelectedBill: PropTypes.func.isRequired,
    amountDue: PropTypes.number.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired
}

export default MyMPowerCard