import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import { useTranslation } from 'react-i18next'

// leereamsnyder and sam-woodridge. Jan 2023. https://github.com/mui/material-ui/issues/30591#issuecomment-1377997824
export class CustomLuxonDateAdapter extends AdapterLuxon {
    getWeekdays = () => {
        const { i18n } = useTranslation()
        return i18n.language==='es'
            ? ['D', 'L', 'M', 'M', 'J', 'V', 'S']
            : ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    }

    getWeekArray = (date) => {
        const { days } = date
            .endOf('month')
            .endOf('week')
            // Doug McClean - NOTE: We need to add an extra week to the original implementation,
            // because Luxon thinks weeks end on Sunday, weeks unrelated to the viewed month will be filtered below.
            // We don't need to do the same for start of week, because Luxon will always include the previous week
            // for months that begin on Sunday (for the same reason, the week "starts" the previous Monday).
            .plus({ weeks: 1})
            .diff(date.startOf('month').startOf('week'), 'days')
            .toObject()

        let weeks = []
        new Array(Math.round(days ?? 0))
            .fill(0)
            .map((_, i) => i)
            .map(day =>
                date.startOf('month')
                    .startOf('week')
                    .minus({ days: 1 })
                    .plus({ days: day }),
            )
            .forEach((v, i) => {
                if (i === 0 || (i % 7 === 0 && i > 6)) {
                    weeks.push([v]);
                    return;
                }

                weeks[weeks.length - 1].push(v)
            })

        weeks = weeks.filter(week => {
            // do not allow weeks with start or end outside of current month
            return (
                week[0].hasSame(date, 'month') ||
                week[week.length - 1].hasSame(date, 'month')
            )
        })

        return weeks;
    }
}