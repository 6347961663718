import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'

import CardOutage from './card-outage'
import CardInOutage from './card-outage-in-outage'
import CardOutageClosedAccount from './card-outage-closed-account'
import EmptyState from '../../empty-state'
import CardLoading from '../card-loading'
import * as loadingStatus from '../../../constants/loading-status-constants'

import { getDefaultView, getOutageDetailView } from '../../../actions/auth/dashboard/card-outage-actions'
import { getAllOutages, updatePublicOutageActions } from '../../../actions/guest/outage/outage-public-actions'
import { getOutageActions, updateIsInOutageArea, getOutageHistory } from '../../../actions/auth/outage/outage-actions'
import { getMedicalSupportOption, getIsIneligibleToReport, getLastReportedOutage } from '../../../actions/auth/outage/report-outage-actions'
import { getRateMetaData } from '../../../actions/auth/usage/rate-meta-data-actions'
import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getNotifications, toggleOutageAlert, refreshNotification } from '../../../actions/auth/notification/notification-actions'

import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import outageRestoration from '../../../srp_modules/outage-restoration'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { addSnackbarMessage } from '../../../actions/common/snackbar'

const title = "Outages"

class CardOutageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getPublicOutageInfo()

        if (this.props.selectedBillAccount === 0)
            return

        this.props.getCustomerOutageInfo(this.props.selectedBillAccount)
        this.props.getOutageHistoryInfo(this.props.selectedBillAccount)
        this.props.getLastReportedOutageInfo(this.props.selectedBillAccount)

        if (this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getMedicalSupportOptionInfo(this.props.selectedBillAccount)
        }

        if (this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getIsIneligibleToReportInfo(this.props.selectedBillAccount)
        }

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }

        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getNotifications(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getContacts(this.props.selectedBillAccount)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if((this.props.selectedBillAccount === nextProps.selectedBillAccount && nextProps.selectedBillAccount !== 0)
            && (this.props.customerInOutageInfo.isInOutageArea && !nextProps.customerInOutageInfo.isInOutageArea)){
                this.props.getOutageHistoryInfo(nextProps.selectedBillAccount)
            }

        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.allOutagesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getPublicOutageInfo()
        }

        if (nextProps.customerInOutageInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomerOutageInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.hasMedicalSupportOptionStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getMedicalSupportOptionInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.isIneligibleToReportStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getIsIneligibleToReportInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getRateMetaDataInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.lastReportedOutageStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getLastReportedOutageInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.outageHistoryStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getOutageHistoryInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.notificationsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getNotifications(nextProps.selectedBillAccount)
        }

        if (nextProps.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContacts(nextProps.selectedBillAccount)
        }
    }

    updateCustomerOutageInfo() {
        this.props.updatePublicOutageInfo()

        if (this.props.selectedBillAccount === 0)
            return

        this.props.updateCustomerOutageInfo(this.props.selectedBillAccount)
    }

    hasLoaded(props) {
        let loaded = true
        if ((props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (props.outageHistoryStatus === loadingStatus.LOADING_STATUS_INIT || props.outageHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_INIT || props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_INIT || props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (props.lastReportedOutageStatus === loadingStatus.LOADING_STATUS_INIT || props.lastReportedOutageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (props.outageHistoryStatus === loadingStatus.LOADING_STATUS_INIT || props.outageHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT || props.notificationsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || props.contactsStatus ==- loadingStatus.LOADING_STATUS_IN_PROGRESS)) {
                loaded = false
            }

            return loaded
    }

    render() {
        const localizedTitle = this.props.t(title)

        let renderedCard = {}
        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            if (this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT) {
                renderedCard = <span />
            }
            else {
                if (!this.hasLoaded(this.props)) {
                    renderedCard = <CardLoading title={localizedTitle} />
                }
                else if(this.props.selectedBillAccountDetails.closedState === billAccountConstants.CLOSED_STATE.CLOSED) {
                    renderedCard = <CardOutageClosedAccount closedDate={this.props.selectedBillAccountDetails.closedDate} t={this.props.t} i18n={this.props.i18n} />
                }
                else if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
                    && this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
                    if (this.props.customerInOutageInfoStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerInOutageInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                        renderedCard = <CardLoading title={localizedTitle} />
                    }
                    else if (this.props.customerInOutageInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
                        if (this.props.customerInOutageInfo.isInOutageArea) {
                            renderedCard = (<CardInOutage
                                t={this.props.t} i18n={this.props.i18n} 
                                defaultViewStyle={this.props.defaultViewStyle}
                                outageDetailViewStyle={this.props.outageDetailViewStyle}
                                getDefaultViewOnClick={this.props.getDefaultViewOnClick}
                                getOutageDetailViewOnClick={this.props.getOutageDetailViewOnClick}
                                customerInOutageInfo={this.props.customerInOutageInfo}
                                allOutagesInfo={this.props.allOutagesInfo}
                                selectedBillAccount={this.props.selectedBillAccount}
                                primaryEmail={this.props.primaryEmail}
                                outageAlertState={this.props.outageAlertState} 
                                outageAlert={this.props.allAlerts.outageAlert}
                                toggleOutageAlert={this.props.toggleOutageAlert}
                            />)
                        }
                        else {
                            renderedCard = (<CardOutage
                                t={this.props.t} i18n={this.props.i18n} 
                                hasReported={this.props.lastReportedOutage.hasReported}
                                isReportedOutageClosed={outageRestoration.isReportedOutageClosed(this.props.lastReportedOutage, this.props.outageHistoryList)}
                                hasMedicalSupportOption={this.props.hasMedicalSupportOption}
                                isIneligibleToReport={this.props.isIneligibleToReport}
                                isPrePay={this.props.selectedBillAccountDetails.isPrePay}
                                isSummaryMaster={this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT}
                                selectedBillAccount={this.props.selectedBillAccount}
                                primaryEmail={this.props.primaryEmail}
                                outageAlertState={this.props.outageAlertState} 
                                outageAlert={this.props.allAlerts.outageAlert}
                                toggleOutageAlert={this.props.toggleOutageAlert}
                            />)
                        }
                    }
                    else {
                        renderedCard = (<CardOutage
                            t={this.props.t} i18n={this.props.i18n} 
                            hasReported={this.props.lastReportedOutage.hasReported}
                            isReportedOutageClosed={outageRestoration.isReportedOutageClosed(this.props.lastReportedOutage, this.props.outageHistoryList)}
                            hasMedicalSupportOption={this.props.hasMedicalSupportOption}
                            isIneligibleToReport={this.props.isIneligibleToReport}
                            isPrePay={this.props.selectedBillAccountDetails.isPrePay}
                            isSummaryMaster={this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT}
                            selectedBillAccount={this.props.selectedBillAccount}
                            primaryEmail={this.props.primaryEmail}
                            outageAlertState={this.props.outageAlertState} 
                            outageAlert={this.props.allAlerts.outageAlert}
                            toggleOutageAlert={this.props.toggleOutageAlert}
                        />)
                    }
                }
                else {
                    renderedCard =
                        (<div className="srp-card">
                            <div className="srp-card-header">
                                {this.props.t("Outages")}
                        </div>
                            <EmptyState isCommercial={this.props.selectedBillAccountDetails.isCommercial} dataType={this.props.t("outage")} t={this.props.t} i18n={this.props.i18n} />
                        </div>)
                }
            }
        }
        else {
            renderedCard = null
        }
        return renderedCard
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.outage,
        ...state.accountInfo.reportOutage,
        ...state.dashboard.cardOutage,
        ...state.accountInfo.billAccount,
        ...state.login,
        ...state.rateMetaData,
        ...state.accountInfo.contact,
        ...state.accountInfo.notification }
}

const mapDispatchToProps = dispatch => {
    return {
        getDefaultViewOnClick: () => {
            dispatch(getDefaultView())
        },
        getOutageDetailViewOnClick: () => {
            dispatch(getOutageDetailView())
        },
        getCustomerOutageInfo: (billAccount) => {
            if (billAccount > 0)
                dispatch(getOutageActions(billAccount))
        },
        updateCustomerOutageInfo: (billAccount) => {
            if (billAccount > 0)
                dispatch(updateIsInOutageArea(billAccount))
        },
        getPublicOutageInfo: () => {
            dispatch(getAllOutages())
        },
        updatePublicOutageInfo: () => {
            dispatch(updatePublicOutageActions())
        },
        getMedicalSupportOptionInfo: (billAccount) => {
            dispatch(getMedicalSupportOption(billAccount))
        },
        getIsIneligibleToReportInfo: (billAccount) => {
            dispatch(getIsIneligibleToReport(billAccount))
        },
        getRateMetaDataInfo: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getLastReportedOutageInfo: (billAccount) => {
            dispatch(getLastReportedOutage(billAccount))
        },
        getOutageHistoryInfo: (billAccount) => {
            dispatch(getOutageHistory(billAccount))
        },
        getContacts: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getNotifications: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },
        toggleOutageAlert: async (billAccount, outageAlertState) => {
            let response = await dispatch(toggleOutageAlert(outageAlertState))
            const success = !response.refreshNotification && response.payload
            if (success) {
                dispatch(refreshNotification(billAccount))
            } else {
                dispatch(addSnackbarMessage(<span>Unable to {outageAlertState ? "turn on" : "turn off"} outage alerts. Please try again later.</span>))
            }

            return success
        }
    }
}

CardOutageContainer.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    selectedBillAccountDetails: PropTypes.object,
    customerInOutageInfo: PropTypes.object.isRequired,
    customerInOutageInfoStatus: PropTypes.string.isRequired,
    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string.isRequired,
    defaultViewStyle: PropTypes.object,
    outageDetailViewStyle: PropTypes.object,
    allOutagesInfo: PropTypes.arrayOf(PropTypes.object),

    outagesAreLoading: PropTypes.bool.isRequired,
    outagesHaveLoaded: PropTypes.bool.isRequired,

    getDefaultViewOnClick: PropTypes.func.isRequired,
    getOutageDetailViewOnClick: PropTypes.func.isRequired,
    getCustomerOutageInfo: PropTypes.func.isRequired,
    updateCustomerOutageInfo: PropTypes.func.isRequired,
    getPublicOutageInfo: PropTypes.func.isRequired,
    allOutagesStatus: PropTypes.string.isRequired,
    updatePublicOutageInfo: PropTypes.func.isRequired,

    getMedicalSupportOptionInfo: PropTypes.func,
    hasMedicalSupportOptionStatus: PropTypes.string,
    hasMedicalSupportOption: PropTypes.bool,
    getIsIneligibleToReportInfo: PropTypes.func,
    isIneligibleToReport: PropTypes.bool,
    isIneligibleToReportStatus: PropTypes.string,

    lastReportedOutage: PropTypes.object,
    lastReportedOutageStatus: PropTypes.string,
    getLastReportedOutageInfo: PropTypes.func,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,
    getRateMetaDataInfo: PropTypes.func,

    outageHistoryList: PropTypes.arrayOf(PropTypes.object),
    outageHistoryStatus: PropTypes.string,
    getOutageHistoryInfo: PropTypes.func,

    getContacts: PropTypes.func,
    getNotifications: PropTypes.func,
    refreshNotification: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    notificationsStatus: PropTypes.string,
    contactsStatus: PropTypes.string,
    primaryEmail: PropTypes.string,
    outageAlertState: PropTypes.bool,
    allAlerts: PropTypes.object,
    toggleOutageAlert: PropTypes.func

}

export default withTranslation('dashboardOutageCards')(connect(mapStateToProps, mapDispatchToProps)(CardOutageContainer))