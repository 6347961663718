import React from "react"
import { Link } from 'react-router-dom'
import config from 'my-account-config'
import { useTranslation } from 'react-i18next'

const ErrorPage = () => {
    const { i18n } = useTranslation()
    const isSpanish = i18n.language==='es'

    return (
        <div>
            <div className="container py-3">
                <h3 className="mt-3">We're sorry</h3>
                <h5 className="text-muted">Something went wrong</h5>

                <div className="mt-4">
                    <Link to="/login"> &lt; Back to login page</Link>
                </div>

                <div className="mt-4">
                    <div className="srp-card-header">
                        Looks like an error occurred
                    </div>

                    <div className="srp-card-body ">
                        <div className="srp-card-details">
                            <div className="text-muted" >My Account has experienced an error processing your request. We apologize for the inconvenience.</div>
                            <br/>
                            <div className="text-muted mb-2" >Please try again or contact My Account technical support</div>
                            <div className="d-flex justify-content-end" style={{display: 'flex', flexWrap: 'wrap'}}>
                                <a href={isSpanish
                                    ? `${config.srpnetBaseUrlEs}servicio-al-cliente/contactanos`
                                    : `${config.srpnetBaseUrl}customer-service/contact-us`} className="btn srp-btn btn-lightblue">Contact technical support</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ErrorPage

