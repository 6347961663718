import config from 'my-account-config'

export const rateMetaDataConstants = {
    GET_RATE_META_DATA_REQUEST: 'GET_RATE_META_DATA_REQUEST',
    GET_RATE_META_DATA_SUCCESS: 'GET_RATE_META_DATA_SUCCESS',
    GET_RATE_META_DATA_FAILURE: 'GET_RATE_META_DATA_FAILURE',

    SUMMARY_BILLING_NONE: 0,
    SUMMARY_BILLING_PARENT: 1,
    SUMMARY_BILLING_CHILD: 2,

    RATE_TO_META_DATA_TABLE: {
        'E27P': {
            shortCode: "E27P",
            shortName: "Customer Generation",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/solar/customer-generation`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/solar/customer-generation`,
            },
        },
        0: {
            shortCode: "E00",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        1: {
            shortCode: "E27",
            shortName: "Time-of-Use Demand",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/demand`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/demand`,
            },
        },
        2: {
            shortCode: "E22",
            shortName: "EZ-3 (4 - 7 p.m.)",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/ez-3`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/ez-3`,
            },
        },
        3: {
            shortCode: "E17",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        4: {
            shortCode: "E18",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        5: {
            shortCode: "E20",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        6: {
            shortCode: "E21",
            shortName: "EZ-3 (3 - 6 p.m.)",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/ez-3`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/ez-3`,
            },
        },
        7: {
            shortCode: "E25",
            shortName: "EZ-3 (2 - 5 p.m.)",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        8: {
            shortCode: "E23",
            shortName: "Basic",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/basic`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/basico`,
            },
        },
        9: {
            shortCode: "E26",
            shortName: "Time-of-Use",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/time-of-use`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/time-of-use`,
            },
        },
        10: {
            shortCode: "E29",
            shortName: "Electric Vehicle",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/electric-vehicle`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/vehiculo-electrico`,
            },
        },
        11: {
            shortCode: "E28",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        12: {
            shortCode: "E31",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        13: {
            shortCode: "E32",
            shortName: "Business Time-of-Use",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/business-electric/time-of-use`, // in English only, as of 8/2/2024
            infoLinks: {},
        },
        14: {
            shortCode: "E33",
            shortName: "Business EZ-3",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        15: {
            shortCode: "E34",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        16: {
            shortCode: "E35",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        17: {
            shortCode: "E35",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        18: {
            shortCode: "E36",
            shortName: "Business General Service",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/business-electric/general-service`, // in English only, as of 8/2/2024
            infoLinks: {},
        },
        19: {
            shortCode: "E38",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        20: {
            shortCode: "E47",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        21: {
            shortCode: "E48",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        22: {
            shortCode: "E49",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        23: {
            shortCode: "E50",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        24: {
            shortCode: "E51",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        25: {
            shortCode: "E52",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        26: {
            shortCode: "E53",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        27: {
            shortCode: "E55",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        28: {
            shortCode: "E56",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        29: {
            shortCode: "E61",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        30: {
            shortCode: "E63",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        31: {
            shortCode: "E64",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        33: {
            shortCode: "E11",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        34: {
            shortCode: "E12",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        35: {
            shortCode: "E13",
            shortName: "Time-of-Use Export",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/solar/time-of-use-export`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/solar/time-of-use-export`,
            },
        },
        36: {
            shortCode: "E14",
            shortName: "Electric Vehicle Export",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/solar/electric-vehicle-export`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/solar/electric-vehicle-export`,
            },
        },
        37: {
            shortCode: "E15",
            shortName: "Average Demand",
            longName: "",
            infoLink: `${config.srpnetBaseUrl}price-plans/residential-electric/solar/average-demand`,
            infoLinks: {
                'es': `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/solar/average-demand`,
            },
        },
        38: {
            shortCode: "E24",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        40: {
            shortCode: "C76",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        41: {
            shortCode: "C77",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        42: {
            shortCode: "C78",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        44: {
            shortCode: "C83",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        46: {
            shortCode: "C86",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        49: {
            shortCode: "C70",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        50: {
            shortCode: "C71",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        51: {
            shortCode: "C72",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        52: {
            shortCode: "C73",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        53: {
            shortCode: "C75",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        54: {
            shortCode: "E57",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        58: {
            shortCode: "E58",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        59: {
            shortCode: "E59",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        66: {
            shortCode: "E66",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
        67: {
            shortCode: "E67",
            shortName: "",
            longName: "",
            infoLink: "",
            infoLinks: {},
        },
    }
}