import React from 'react'
import PropTypes from 'prop-types'
import MyPricingRateCard from './my-pricing-rate-card'
import MyPricingRateCardResponsive from './my-pricing-rate-card-responsive'
import MyPricingLoadingCard from './my-pricing-loading-card'
import MyPricingLoadingCardResponsive from './my-pricing-loading-card-responsive'
import utils from '../../../srp_modules/utils'
import { pricePlanConstants } from '../../../constants/price-plan-constants'
import Icon from '@mui/material/Icon'
import Checkbox from '@mui/material/Checkbox'
import SolarEstimatorAd from '../../myaccount_ads/solar-estimator-ad'

let transitionDelay = 1000

let cmppComparer = (a,b) => {
    if (a.annualProjection == b.annualProjection) {
        return a.shortCode.localeCompare(b.shortCode)
    }

    return a.annualProjection - b.annualProjection
}

let sortPricePlans = (pricePlans) => {
    return pricePlans.sort(cmppComparer)
}

let isRateEv = (businessOffering) => {
    switch (businessOffering) {
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV:
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV_EXPORT:
            return true
        default:
            return false
    }
}

let buildCompareMyPricePlan = (cmpp, includeEV, isSolar) => {
    let pricePlans = []
    let unsortedPricePlans = []

    let currentMetaData = utils.rateCodeToMetaData(cmpp.currentPlan.businessOffering, isSolar)
    pricePlans.push({...cmpp.currentPlan,
        isCurrent: true,
        shortCode: currentMetaData.shortCode,
        shortName: currentMetaData.shortName,
        infoLink: currentMetaData.infoLink,
        infoLinks: currentMetaData.infoLinks,
    })

    cmpp.comparisonPlans.forEach(plan => {
        let metaData = utils.rateCodeToMetaData(plan.businessOffering, isSolar)
        unsortedPricePlans.push({...plan,
            isCurrent: false,
            shortCode: metaData.shortCode,
            shortName: metaData.shortName,
            infoLink: metaData.infoLink,
            infoLinks: metaData.infoLinks,
        })
    })

    unsortedPricePlans = sortPricePlans(unsortedPricePlans).slice(0,3)

    if (includeEV) {
        unsortedPricePlans = unsortedPricePlans.slice(0,2)
        let evMetaData = utils.rateCodeToMetaData(cmpp.evPlan.businessOffering, isSolar)
        unsortedPricePlans.push({...cmpp.evPlan,
            isCurrent: false,
            shortCode: evMetaData.shortCode,
            shortName: evMetaData.shortName,
            infoLink: evMetaData.infoLink,
            infoLinks: evMetaData.infoLinks,
        })

        unsortedPricePlans = sortPricePlans(unsortedPricePlans)
    }

    pricePlans = pricePlans.concat(unsortedPricePlans)

    return pricePlans
}

class CompareMyPricePlan extends React.Component {
    constructor(props) {
        super(props)
        let currentPlanMetaData = utils.rateCodeToMetaData(props.compareMyPricePlan.currentPlan.businessOffering)
        this.state = {
            showAnnualProjection: false,
            showSaveMore: false,
            showEvRates: false,
            compareMyPricePlans: props.compareMyPricePlan.isEligible ? buildCompareMyPricePlan(props.compareMyPricePlan, false, props.currentRateCode.isSolar) : {},
            currentCompareMyPricePlan: props.compareMyPricePlan.isEligible ? {...props.compareMyPricePlan.currentPlan,
                isCurrent: true,
                shortCode: currentPlanMetaData.shortCode,
                shortName: currentPlanMetaData.shortName,
                infoLink: currentPlanMetaData.infoLink,
                infoLinks: currentPlanMetaData.infoLinks,
            } : {},
            currentCompareMyPricePlanIndex: 0,
            displayLoadingCards: false
        }

        this.toggleAnnualProjection = this.toggleAnnualProjection.bind(this)
        this.toggleSaveMore = this.toggleSaveMore.bind(this)
        this.toggleEvRates = this.toggleEvRates.bind(this)
        this.changeSelectedCompareMyPricePlanIndex = this.changeSelectedCompareMyPricePlanIndex.bind(this)
        this.hideLoadingCards = this.hideLoadingCards.bind(this)
    }

    toggleAnnualProjection() {
        let showAnnualProjection = !this.state.showAnnualProjection
        this.setState({...this.state, showAnnualProjection: showAnnualProjection})
    }

    toggleSaveMore() {
        let showSaveMore = !this.state.showSaveMore
        this.setState({...this.state, showSaveMore: showSaveMore})
    }

    hideLoadingCards() {
        let newDisplayLoadingCards = false
        this.setState({...this.state, displayLoadingCards: newDisplayLoadingCards})
    }

    toggleEvRates() {
        let showEvRates = !this.state.showEvRates
        let newCompareMyPricePlans
        if (showEvRates) {
            newCompareMyPricePlans = buildCompareMyPricePlan(this.props.compareMyPricePlan, true, this.props.currentRateCode.isSolar)
            setTimeout(this.hideLoadingCards, transitionDelay)
        } else {
            newCompareMyPricePlans = buildCompareMyPricePlan(this.props.compareMyPricePlan, false, this.props.currentRateCode.isSolar)
            setTimeout(this.hideLoadingCards, transitionDelay)
        }
        let nextCurrentCompareMyPricePlan = newCompareMyPricePlans[0]
        this.setState({...this.state,
            showEvRates: showEvRates,
            compareMyPricePlans: newCompareMyPricePlans,
            displayLoadingCards: true,
            currentCompareMyPricePlan: nextCurrentCompareMyPricePlan,
            currentCompareMyPricePlanIndex: 0})
    }

    changeSelectedCompareMyPricePlanIndex(advance) {
        let nextIndex = this.state.currentCompareMyPricePlanIndex + advance
        let nextCurrentCompareMyPricePlan = this.state.compareMyPricePlans[nextIndex]
        this.setState({...this.state, currentCompareMyPricePlan: nextCurrentCompareMyPricePlan, currentCompareMyPricePlanIndex: nextIndex})
    }

    render() {
        let loadingArray = [];
        for (let i = 0; i < this.state.compareMyPricePlans.length - 1; i++) {
            loadingArray.push(i)
        }
        const t = this.props.t
        const i18n = this.props.i18n

        return(
            <div className="srp-card-details">
                {/* Desktop view */}
                <label className="d-none d-lg-block h4 text-muted">{t("Price plan comparison")}</label>
                    <div className="d-block d-lg-none text-muted text-left p-0">
                            <b>{t("Price plan comparison")}</b>
                    </div>
                    <div className="text-muted pt-2 pb-2"><b>{t("Am I on the right plan?")}</b> {t("Below are the lowest cost plans for you based on your historical power usage and the prices at that time.")}</div>
                    <div style={{marginTop: "20px"}} className="d-none d-lg-block">
                        <div className="clearfix" style={{textAlign: 'center', display: 'flex'}}>
                            {
                                this.state.displayLoadingCards &&
                                <div style={{ display: 'flex', width: '100%'}}>
                                    <div style={{float:'left', flexBasis: '100%'}}>
                                        <MyPricingRateCard
                                            businessOffering={this.state.compareMyPricePlans[0].businessOffering}
                                            monthlyAverage={this.state.compareMyPricePlans[0].monthlyAverage}
                                            annualProjection={this.state.compareMyPricePlans[0].annualProjection}
                                            isCurrent={this.state.compareMyPricePlans[0].isCurrent}
                                            isRecommended={false}
                                            shortName={this.state.compareMyPricePlans[0].shortName}
                                            infoLink={this.state.compareMyPricePlans[0].infoLink}
                                            infoLinks={this.state.compareMyPricePlans[0].infoLinks}
                                            selectPricePlanClick={this.props.selectPricePlanClick}
                                            t={t} i18n={i18n}
                                        />
                                    </div>
                                    {loadingArray.map((x) => {
                                        return (
                                            <div style={{float:'left', flexBasis: '100%'}} key={'loading_' + x.toString()}>
                                                <MyPricingLoadingCard />
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            {
                                !this.state.displayLoadingCards && this.state.compareMyPricePlans.map((plan, index) => {
                                return (
                                    <div style={{float: 'left', flexBasis: '100%'}} key={'ratecard_' + index.toString()}>
                                        <MyPricingRateCard
                                            index={index}
                                            businessOffering={plan.businessOffering}
                                            monthlyAverage={plan.monthlyAverage}
                                            annualProjection={plan.annualProjection}
                                            isCurrent={plan.isCurrent}
                                            isRecommended={false}
                                            shortName={plan.shortName}
                                            infoLink={plan.infoLink}
                                            infoLinks={plan.infoLinks}
                                            selectPricePlanClick={this.props.selectPricePlanClick}
                                            t={t} i18n={i18n}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* responsive view */}
                    <div className="d-block d-lg-none" style={{marginTop:"1.25rem"}}>
                        {this.state.displayLoadingCards && <div>
                            <MyPricingLoadingCardResponsive />
                        </div>}
                        {!this.state.displayLoadingCards &&
                        <div>
                            <MyPricingRateCardResponsive
                                index={this.state.currentCompareMyPricePlanIndex}
                                businessOffering={this.state.currentCompareMyPricePlan.businessOffering}
                                monthlyAverage={this.state.currentCompareMyPricePlan.monthlyAverage}
                                annualProjection={this.state.currentCompareMyPricePlan.annualProjection}
                                isCurrent={this.state.currentCompareMyPricePlan.isCurrent}
                                isRecommended={false}
                                shortName={this.state.currentCompareMyPricePlan.shortName}
                                infoLink={this.state.currentCompareMyPricePlan.infoLink}
                                infoLinks={this.state.currentCompareMyPricePlan.infoLinks}
                                selectPricePlanClick={this.props.selectPricePlanClick}
                                t={t} i18n={i18n}
                            />
                        </div>}
                    </div>
                    {!isRateEv(this.props.currentRateCode.rateCode) && !this.props.isCommercial && <div className="mt-4 srp-blue-color" style={{position:"relative"}}>
                        {/* desktop view */}
                        <div className="d-none d-lg-block">
                            <Checkbox
                                onChange={this.toggleEvRates}
                                checked={this.state.showEvRates}
                                color="primary"
                            /><label className="form-check-label"><b>{t("Already own an EV?")}</b></label>
                        </div>
                        {/* responsive view */}
                        <div className="d-block d-lg-none clearfix">
                            <div style={{float: "left"}}>
                                <Checkbox
                                    onChange={this.toggleEvRates}
                                    checked={this.state.showEvRates}
                                    color="primary"
                                /><label className="form-check-label"><b>{t("Already own an EV?")}</b></label>
                            </div>
                        </div>
                    </div>}
                    {/* responsive view buttons */}
                    <div className="row p-2">
                        <div className="col-lg d-flex" style={{position:"relative"}}>
                            <div className="d-lg-none text-nowrap" style={{width:"50%"}}>
                                <button className="btn srp-btn btn-lightblue" style={{width:"35%"}}
                                        disabled={this.state.currentCompareMyPricePlanIndex == 0}
                                        onClick={() => {this.changeSelectedCompareMyPricePlanIndex(-1)}}>
                                    <strong>{" < "}</strong>
                                </button>
                            </div>
                            <div className="d-lg-none text-nowrap" style={{width:"50%", textAlign:"right"}}>
                                <button className="btn srp-btn btn-lightblue" style={{width:"35%"}}
                                        disabled={this.state.currentCompareMyPricePlanIndex == this.state.compareMyPricePlans.length - 1}
                                        onClick={() => {this.changeSelectedCompareMyPricePlanIndex(1)}}>
                                    <strong>{" > "}</strong>
                                </button>
                            </div>
                        </div>
                    </div>
                    {!this.props.currentRateCode.isSolar && !this.props.isCommercial && <div className="srp-alert-notice" style={{backgroundColor: "rgba(227, 228, 219, 1)"}}>
                        <span className="font-weight-bold">{t("Considering solar?")}</span>
                        {' '}
                        <span style={{ fontSize: "14px", color: "rgb(66, 66, 66)"}}>{t("See how solar can impact your bills with our solar calculator.")}</span>
                        {/* desktop view */}
                        <div className="font-weight-bold d-none d-lg-block" style={{float: "right", fontSize: "14px"}}>
                            <SolarEstimatorAd><div>{t("LEARN MORE")}</div></SolarEstimatorAd>
                        </div>
                        {/* responsive view */}
                        <div className="font-weight-bold d-block d-lg-none" style={{fontSize: "14px", textAlign: "right"}}>
                            <SolarEstimatorAd><div>{t("LEARN MORE")}</div></SolarEstimatorAd>
                        </div>
                    </div>}
                    <div className="mt-4">
                        {/* desktop view */}
                        <div className="srp-blue-color d-none d-lg-block" style={{fontWeight:"600", cursor:"pointer"}} onClick={this.toggleAnnualProjection}>
                            <Icon style={{verticalAlign: "top"}}>{this.state.showAnnualProjection ? 'expand_less' : 'expand_more'}</Icon>{t("What is the annual projection and how is it calculated?")}
                        </div>
                        {/* responsive view */}
                        <div className="srp-blue-color d-block d-lg-none clearfix d-flex flex-row" style={{fontWeight:"600", cursor:"pointer"}} onClick={this.toggleAnnualProjection}>
                            <Icon style={{verticalAlign: "top"}}>{this.state.showAnnualProjection ? 'expand_less' : 'expand_more'}</Icon>{t("What is the annual projection and how is it calculated?")}
                        </div>
                        {this.state.showAnnualProjection &&
                        <div>
                            <p className="ml-4 mt-2" style={{fontSize:"14px"}}>
                                {t("No_two_accounts_use_energy_in_the_same_way")}
                            </p>
                            <p className="ml-4" style={{fontSize:"14px"}}>
                                <i>{t("*Including solar generation")}</i>
                            </p>
                        </div>}
                    </div>
                    <div>
                        <div className="srp-blue-color" style={{fontWeight:"600", cursor:"pointer"}} onClick={this.toggleSaveMore}><Icon style={{verticalAlign: "top"}}>{this.state.showSaveMore ? 'expand_less' : 'expand_more'}</Icon>{t("How can I save more?")}</div>
                        {this.state.showSaveMore &&
                        <div>
                            <p className="ml-4 mt-2" style={{fontSize:"14px"}}>
                                {t("Savings can be greater on time-of-use plans if you change your behavior and shift energy usage to lower-cost off-peak time periods.")}
                            </p>
                        </div>}
                    </div>
            </div>
        )
    }
}

CompareMyPricePlan.propTypes = {
    currentRateCode: PropTypes.object,
    compareMyPricePlan: PropTypes.object,
    selectPricePlanClick: PropTypes.func.isRequired,
    isCommercial: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default CompareMyPricePlan