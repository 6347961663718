import React from "react"
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Card from "@mui/material/Card"
import config from 'my-account-config'

const CashOnlyPaymentCard = ({ t, i18n }) => (
    <div>
        <label className="h5">{t("Unable to make online payments")}</label>
        <Card className="p-4">
            <div className="srp-alert-warn">
                <p>{t("sorry_account_status")}</p>
                <ul className="mb-0 ml-3 pl-0">
                    <li>{t("Cashier's check or money order by mail")}</li>
                    <li>{t("Make in-person cash payments at select retail locations and SRP PayCenters.")}</li>
                </ul>
            </div>
            <div className="d-flex justify-content-end flex-wrap mt-3">
                <a href={i18n.language==='es'
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/pago-factura-electrica`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`} className="btn srp-btn btn-lightblue mt-2">
                    {t("Pay by mail")}
                </a>
                <Link to="/myaccount/paymentlocations" className="btn srp-btn btn-lightblue mt-2 ml-2">{t("Find a payment location")}</Link>
                <Link to="/myaccount/dashboard" className="btn srp-btn btn-blue mt-2 ml-2">{t("Back to dashboard")}</Link>
            </div>
        </Card>
    </div>
)

CashOnlyPaymentCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("cashOnlyPayment")(CashOnlyPaymentCard)