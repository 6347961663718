import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import CircularProgress from '@mui/material/CircularProgress'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { DateTime } from 'luxon'
import { withTranslation } from 'react-i18next'

import format from '../../../srp_modules/format'

import * as loadingStatus from '../../../constants/loading-status-constants'
import CardInformation from '../../common_error/card-information'

import Card from '@mui/material/Card'
import '../../../styles/dashboard-page.css'
import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

import { formEvent } from '../../../srp_modules/analytics-events'
import { paymentConstants } from '../../common_payment/payment-constants'
import { withRouter } from '../../../srp_modules/with-router'

import { myAccountConstants } from '../../../constants/myaccount-constants'

import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Switch from '@mui/material/Switch'

let rowStyle = {
    borderBottom: "1px solid #E5E5E5",
    borderRadius: "0"
}

let paymentStyle = {
    borderBottom: "1px solid rgba(204,204,204,1)",
    paddingBottom: "14px",
    paddingTop: "15px"
}
class VerifyMultiPaymentPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            confirmPaymentFormEventEmitted: false,
            reconnectPower : null,
            choiceSelected : false
        }

        this.getWarningStyleAndToolTip = this.getWarningStyleAndToolTip.bind(this)
        this.goBack = this.goBack.bind(this)
        this.emitFormStepEvent = this.emitFormStepEvent.bind(this)
    }

    componentDidMount() {
        this.emitFormStepEvent()
        if(this.props.disconnectedPaymentAccounts.length === 1){
            this.props.getSelectedPaymentAccountRateCode(this.props.disconnectedPaymentAccounts[0].account)
        }
    }

    componentDidUpdate() {
        this.emitFormStepEvent()
    }

    getWarningStyleAndToolTip(payment, disconnectedPaymentAccountsSet) {
        if (payment.paymentAmountError.hasError) {
            if(payment.paymentAmountError.errorType === multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL 
                && disconnectedPaymentAccountsSet.has(payment.billAccount)){
                return null
            }
            let toolTipText = "Paying less than the total amount due may negatively affect your account, including disconnection."

            if (payment.paymentAmountError.errorType === multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.MORE_THAN_TOTAL) {
                toolTipText = "You are making a payment that is more than the amount due."
            }

            return (
                <span>
                    <SrpTooltip content={<span>{toolTipText}</span>}>
                        <Icon style={{color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px", marginBottom: "4px"}}>info_outline</Icon>
                    </SrpTooltip>
                </span>
                )
        }

        return null
    }

    goBack() {
        const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME
        const stepName = paymentConstants.PAYMENT_STEPS[0]
        formEvent(formName, stepName)

        this.props.clearSubmitPaymentError()

        this.props.router.navigate(-1)
    }

    emitFormStepEvent() {
        if (this.state.confirmPaymentFormEventEmitted === false) {
            const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = paymentConstants.PAYMENT_STEPS[1]
            formEvent(formName, stepName)

            this.setState({...this.state, confirmPaymentFormEventEmitted: true})
        }
    }

    submitButtonClickHanlder(disconnectedPaymentAccounts, authorizeReconnect){
        this.props.onSubmitClick()
        if(disconnectedPaymentAccounts.length === 1){
            let billAccount = Number(format.srpAccountNumber(disconnectedPaymentAccounts[0].account))
            let authorizeReconnectFlag = authorizeReconnect === "yes" ? true : false
            this.props.sendReconnectRemark(billAccount, authorizeReconnectFlag)
        }
    }

    render() {
        let t = this.props.t
        let dateWarningText = t("payment_after_due_date")
        let accountToDisplay
        if(this.props.disconnectedPaymentAccounts.length === 1){
            let accountNickName = this.props.disconnectedPaymentAccounts[0].accountName
            let accountNumber = format.srpAccountNumber(this.props.disconnectedPaymentAccounts[0].account.toString())
            let accountNumberFormatted = format.formatBillAccountNumber(accountNumber)
            accountToDisplay = accountNickName.length !== 0 ? `${accountNickName} (${accountNumberFormatted})` : accountNumberFormatted
        }
        let disconnectedPaymentAccountsSet = new Set()
        this.props.disconnectedPaymentAccounts.forEach((each)=>{
            disconnectedPaymentAccountsSet.add(each.account)
        })

        return (
            
            <div className="container py-3 hidden-sm-down">
                <h3 className="mt-3">{t("Make a payment")}</h3>
                <br />
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label className="h5">{t("Confirm payment details")}</label>
                        <Card className="p-4" style={rowStyle}>
                            {this.props.showSubmitError &&
                            <div className="srp-alert-error mb-3">
                                {`${t("unable_complete_payment")} ${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}.`}
                            </div>
                            }
                            <div className="row srp-row">
                                <div className="col-4 srp-label">
                                    <div>{t("Bank account")}</div>
                                </div>
                                <div className="col">
                                    <div>{this.props.selectedBankAccountDescription}</div>
                                </div>
                            </div>
                        </Card>
                        <Card className="p-4" style={rowStyle}>
                            <div className="row srp-row">
                                <div className="col-4 srp-label">
                                    <div>{t("Total payments")}</div>
                                </div>
                                <div className="col">
                                    <div>
                                        <NumericFormat
                                            value={this.props.totalPayment}
                                            valueIsNumericString={true}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card className="p-4" style={{ ...rowStyle, paddingTop: "20px" }}>
                            <div style={{marginBottom: "10px", display: this.props.displayDateWarning ? "block" : "none"}}>
                                <CardInformation open={this.props.displayDateWarning} message={dateWarningText} />
                            </div>

                            {this.props.disconnectedPaymentAccounts.length === 1 ?
                                <div className="mb-4">
                                    <div className="mx-1 mb-3">
                                        <h4>Safety First!</h4>
                                    </div>
                                    <div className="mx-1 mb-3">
                                        Before making a payment, SRP needs to know if it is safe to reconnect your power. <br /><br/>

                                        Please be aware of the risk of fire if any appliances or other electric devices, such as an electric stove, are turned on
                                        at the time power is delivered. Please ensure all of the appliances and electric devices at the premises are turned off.
                                    </div>
                                    <div className="srp-alert-notice-DCO">
                                        <div className="font-weight-bold srp-dark-blue-color ml-2">Is it safe to reconnect power at this time?</div>
                                        <div className="font-weight-bold srp-dark-blue-color ml-2">{`For ${accountToDisplay}`} </div>
                                        <RadioGroup
                                            defaultValue="yes"
                                            sx={{
                                                margin:'0 25px'
                                            }}
                                        >
                                            <FormControlLabel
                                                defaultChecked
                                                control={<Radio color="primary"/>}
                                                value="yes"
                                                checked={this.state.reconnectPower === "yes"}
                                                onChange={()=>{
                                                    this.setState({choiceSelected : false, reconnectPower : "yes"}) 
                                                }}
                                                label={"Yes, reconnect power"} 
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        fontWeight: 'bold', // Customize the font size
                                                        color: '#087ED8', // Customize the label color
                                                    }
                                                }}
                                            />
                                            <FormControlLabel
                                                control={<Radio color="primary"/>}
                                                value="no"
                                                checked={this.state.reconnectPower === "no"}
                                                onChange={()=>{
                                                    this.setState({choiceSelected : false, reconnectPower : "no"})
                                                }}
                                                label={"No, I will call when ready"} 
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        fontWeight: 'bold', // Customize the font size
                                                        color: '#087ED8', // Customize the label color
                                                    },
                                                    marginTop: '-20px'
                                                }}
                                            />
                                        </RadioGroup>
                                        {this.state.reconnectPower === null ?
                                            null :
                                            <div>
                                                <div className="font-weight-bold srp-dark-blue-color ml-2 mb-1">Understand your choice</div>
                                                {this.state.reconnectPower === "yes" ? 
                                                    <div className="mb-2">
                                                        <div className="d-flex align-items-start ml-4 mb-2">
                                                            <FormControlLabel
                                                                value="yes"
                                                                control={<Switch color="primary" />}
                                                                checked={this.state.choiceSelected}
                                                                labelPlacement="end"
                                                                onChange={(e)=>{
                                                                    this.setState({choiceSelected : e.target.checked })
                                                                }}
                                                            />
                                                            {this.props.isSolar?
                                                                <div className="reconnection-notice pt-1">By selecting “Yes, reconnect power” I understand that my power will be reconnected. 
                                                                    Power is typically on within 2 to 4 hours once the payment has posted. 
                                                                    If power is not back on by then, please give us a call to check the status.</div>
                                                                :  
                                                                <div className="reconnection-notice pt-1">By selecting “Yes, reconnect power" I understand that my power will be reconnected. 
                                                                Power is typically on within 30 minutes once the payment has posted; 
                                                                however, it could take up to one hour. If power is not back on in one hour, please give us a call to check the status.</div>
                                                            }
                                                        </div>
                                                    </div> :
                                                    <div className="mb-2">
                                                        <div className="d-flex align-items-start ml-4 mb-2">
                                                        <FormControlLabel
                                                            value="no"
                                                            control={<Switch color="primary" />}
                                                            checked={this.state.choiceSelected}
                                                            labelPlacement="end"
                                                            onChange={(e)=>this.setState({choiceSelected : e.target.checked })}
                                                        />
                                                        <div className="reconnection-notice pt-1">By selecting "No, I will call when ready" I understand I must call SRP to reconnect my power.</div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            : this.props.disconnectedPaymentAccounts.length > 1 ?
                                <div className="mx-0 mb-2 srp-alert-general-orange">
                                    <div className="mb-1"><b>CALL TO RECONNECT</b> &nbsp; Power will remain disconnected until a phone call is made to SRP to confirm it is safe to resume service for the following accounts:</div>
                                    <ul className='mb-0'>
                                        {this.props.disconnectedPaymentAccounts.map((account, i)=>{
                                            let accountNumber = format.srpAccountNumber(account.account.toString())
                                            let accountNumberFormatted = format.formatBillAccountNumber(accountNumber)
                                            if(account.accountName.length !== 0){
                                                return(<li key={i}>{account.accountName} ({accountNumberFormatted})</li>)
                                            }else{
                                                return(<li key={i}>{accountNumberFormatted}</li>)
                                            }
                                        })}
                                    </ul>
                                </div> 
                                : null
                            }

                            <div className="row" style={{ paddingLeft: "5px"}}>
                                <div className="col-4">
                                    <InputLabel className="overflow-visible text-wrap" style={{ fontSize: "14px"}}>{t("Payment date")}</InputLabel>
                                </div>
                                <div className="col-4">
                                    <InputLabel className="overflow-visible text-wrap" style={{ fontSize: "14px" }}>{t("Account number")}</InputLabel>
                                </div>
                                <div className="col-3" style={{ textAlign: "right" }}>
                                    <InputLabel className="overflow-visible text-wrap" style={{ fontSize: "14px"}}>{t("Payment amount")}</InputLabel>
                                </div>
                            </div>
                            {Object.keys(this.props.sortedPaymentList).map((key, index) => {
                                let paymentObject = this.props.sortedPaymentList[key]

                                return (<div key={index}>
                                    {paymentObject.paymentList.map((payment, index) => {
                                    return (
                                        <div className="row" key={index} style={{ paddingLeft: "5px", fontSize: "14px"}}>
                                            <div className="col-4" style={index === 0 ? paymentStyle : {}}>
                                                {index === 0 ? DateTime.fromISO(key).toFormat('M/d/yy') : ""}
                                            </div>
                                            <div className="col-4 text-nowrap" style={paymentStyle}>
                                                <PatternFormat
                                                    value={format.srpAccountNumber(payment.billAccount)}
                                                    valueIsNumericString={true}
                                                    format="###-###-###"
                                                    displayType="text"
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            <div className="col-3" style={{ ...paymentStyle, textAlign: "right" }}>
                                                <NumericFormat
                                                    value={payment.paymentAmount}
                                                    valueIsNumericString={true}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    displayType="text"
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            <div className="col-1" style={{ ...paymentStyle, paddingLeft: "0px", paddingRight: "0px"}}>
                                                {this.getWarningStyleAndToolTip(payment, disconnectedPaymentAccountsSet)}
                                            </div>
                                        </div>
                                    )}
                                )}
                                <div className="row" style={{ paddingLeft: "5px", paddingBottom: "20px", fontSize: "14px" }}>
                                    <div className="col-4" />
                                    <div className="col-1" style={{ ...paymentStyle, paddingBottom: "20px" }} />
                                    <div className="col-6" style={{ ...paymentStyle, textAlign: "right", fontWeight: "bold" }}>
                                        {t("Subtotal")}&nbsp;&nbsp;
                                        <NumericFormat
                                            value={paymentObject.subTotal}
                                            valueIsNumericString={true}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                    <div className="col-1 d-none d-md-block" style={{ ...paymentStyle, paddingLeft: "0px", paddingRight: "0px"}} />
                                </div>
                                </div>)}
                            )}

                            <div>
                                <p style={{fontSize: "14px", color: "rgb(112,112,112)"}}>
                                    {t("submit_payment_agreement")}
                                </p>
                            </div>
                            <div style={{float: "right"}}>
                                <button
                                    disabled={this.props.submitMultiPaymentResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                    className="btn srp-btn btn-lightblue d-inline d-md-none"
                                    onClick={this.goBack}>
                                        {t("Edit")}
                                </button>
                                <button
                                    disabled={this.props.submitMultiPaymentResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                    className="btn srp-btn btn-lightblue d-none d-md-inline"
                                    onClick={this.goBack}>
                                        {t("Edit payment details")}
                                </button>
                                <button
                                    disabled={this.props.submitMultiPaymentResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS || (this.props.disconnectedPaymentAccounts.length === 1 && !this.state.choiceSelected)}
                                    className="btn srp-btn btn-green ml-2"
                                    onClick={()=>this.submitButtonClickHanlder(this.props.disconnectedPaymentAccounts, this.state.reconnectPower)}>
                                        {this.props.submitMultiPaymentResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : t("Submit payment")}
                                </button>
                            </div>
                        </Card>
                    </div>
                <div className="col" />
            </div>
        </div>)
    }
}

VerifyMultiPaymentPage.propTypes = {
    sortedPaymentList: PropTypes.object.isRequired,
    totalPayment: PropTypes.number.isRequired,
    submitMultiPaymentResultStatus: PropTypes.string.isRequired,
    selectedBankAccountDescription: PropTypes.string.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    displayDateWarning: PropTypes.bool.isRequired,
    clearSubmitPaymentError: PropTypes.func.isRequired,
    showSubmitError: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    sendReconnectRemark: PropTypes.func.isRequired,
    getSelectedPaymentAccountRateCode: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(withRouter(VerifyMultiPaymentPage))