// eslint is detecting the extra functions as multiple React components
/* eslint react/no-multi-comp: "off" */
import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'

import SinglePaymentAddBankFormik from './single-payment-add-bank-formik'
import SinglePaymentSelectBankFormik from './single-payment-select-bank-formik'
import { myAccountConstants } from '../../constants/myaccount-constants'

import { formStartEvent } from '../../srp_modules/analytics-events'
import { paymentConstants } from '../common_payment/payment-constants'

const SinglePaymentForm = ({
    bankAccountList, billAccount, hasScheduledPayment,
    isPaymentInfoValid, payment,
    customerNames, phoneNumber, makePaymentInfo,
    creditExtension, summaryBillingStatus, bankFormOpen,
    onAddBankAccountClick, onAddBankAccountCancelClick,
    formStartEventEmitted, setFormStartEventEmitted, selectedBillAccountDetails,isSolar,
    t, i18n
    }) => {

    const formDataChanged = (data) => {
        data === data // to fix ESLint no-unused-vars warning
        if (formStartEventEmitted === false) {
            const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = paymentConstants.PAYMENT_STEPS[0]
            formStartEvent(formName, stepName)

            setFormStartEventEmitted()
        }
    }
    
    return (
    <div className="mt-3">
        <label className="h5">{t("Payment Information")}</label>
        <Card className="px-4 pb-4">
            {bankFormOpen &&
            <SinglePaymentAddBankFormik
                billAccount={billAccount}
                payment={payment}
                isPaymentInfoValid={isPaymentInfoValid}
                showAddBankAccountCancelButton={bankAccountList && bankAccountList.length > 0}
                onAddBankAccountCancelClick={onAddBankAccountCancelClick}
                showSpanish={false}
                eChexValidationErrors={{ErrExceedHardLimit: payment.hardLimit}}
                supportPhoneNumbers={{
                    residentialCustomerServicePhoneNumber: myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD,
                    residentialCustomerServicePhoneNumberSpanish: myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD,
                    commercialCustomerServicePhoneNumber: myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
                }}
                hasScheduledPayment={hasScheduledPayment}
                customerNames={customerNames}
                phoneNumber={phoneNumber}
                makePaymentInfo={makePaymentInfo}
                creditExtension={creditExtension}
                formDataChanged={formDataChanged}
                selectedBillAccountDetails={selectedBillAccountDetails}
                isSolar={isSolar}
                t={t}
                i18n={i18n}
            />
            }

            {!bankFormOpen &&
            <SinglePaymentSelectBankFormik
                billAccount={billAccount}
                payment={payment}
                isPaymentInfoValid={isPaymentInfoValid}
                onAddBankAccountClick={onAddBankAccountClick}
                showSpanish={false}
                eChexValidationErrors={{ErrExceedHardLimit: payment.hardLimit}}
                supportPhoneNumbers={{
                    residentialCustomerServicePhoneNumber: myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD,
                    residentialCustomerServicePhoneNumberSpanish: myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD,
                    commercialCustomerServicePhoneNumber: myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
                }}
                hasScheduledPayment={hasScheduledPayment}
                makePaymentInfo={makePaymentInfo}
                creditExtension={creditExtension}
                summaryBillingStatus={summaryBillingStatus}
                formDataChanged={formDataChanged}
                selectedBillAccountDetails={selectedBillAccountDetails}
                isSolar={isSolar}
                t={t}
                i18n={i18n}
            />
            }
        </Card>
    </div>
    )
}

SinglePaymentForm.propTypes = {
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    billAccount: PropTypes.number.isRequired,
    hasScheduledPayment: PropTypes.bool.isRequired,
    isPaymentInfoValid: PropTypes.bool.isRequired,
    payment: PropTypes.object.isRequired,
    customerNames: PropTypes.object.isRequired,
    phoneNumber: PropTypes.object.isRequired,
    makePaymentInfo: PropTypes.object.isRequired,
    creditExtension: PropTypes.object.isRequired,
    summaryBillingStatus: PropTypes.number.isRequired,
    bankFormOpen: PropTypes.bool.isRequired,
    onAddBankAccountClick: PropTypes.func.isRequired,
    onAddBankAccountCancelClick: PropTypes.func.isRequired,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,
    isSolar: PropTypes.bool.isRequired, 
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default SinglePaymentForm