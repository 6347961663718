import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import '../../../styles/dashboard-page.css'

import { getBankAccountToEdit } from '../../../actions/auth/payment/bank-account-actions'
import { withRouter } from '../../../srp_modules/with-router'

import SinglePaymentSubmittedPage from './single-payment-submitted-page'

class SinglePaymentSubmittedPageContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.isStateEmpty())
            this.props.router.navigate('/myaccount/payment/single', { replace: true })
    }

    isStateEmpty() {
        return this.props.makePaymentResult.referenceNumbers === undefined
            || this.props.makePaymentResult.referenceNumbers.length <= 0
    }

    render() {
        return (
            !this.isStateEmpty() &&
            <SinglePaymentSubmittedPage
                makePaymentInfo={this.props.makePaymentInfo}
                makePaymentResult={this.props.makePaymentResult}
                selectedBank={this.props.selectedBank}
                updateBankEmailAddressOnClick={this.props.updateBankEmailAddressOnClick}
                authorizeReconnectFlag={this.props.authorizeReconnectFlag}
                isSolar={this.props.isSolar}
                t={this.props.t}
                i18n={this.props.i18n}
            />
        )
    }
}

const mapStateToProps = (state) => {
    const makePaymentInfo = state.accountInfo.payment.makePaymentInfo
    const selectedBank = state.bankAccounts.bankAccountList.find((bankAccount) => bankAccount.bankId === makePaymentInfo.bankAccountId)
    const authorizeReconnectFlag = state.multiPayment.authorizeReconnectFlag
    const isSolar = state.accountInfo.billAccount.currentRateCode.isSolar
    return {
        makePaymentInfo,
        makePaymentResult: state.accountInfo.payment.makePaymentResult,
        selectedBank,
        authorizeReconnectFlag,
        isSolar
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateBankEmailAddressOnClick: (selectedBank) => {
        if (selectedBank === undefined) {
            ownProps.router.navigate('/myaccount/profile/banks')
        } else {
            dispatch(getBankAccountToEdit(selectedBank))
            ownProps.router.navigate('/myaccount/profile/banks/editbankaccount')
        }
    }
})

SinglePaymentSubmittedPageContainer.propTypes = {
    makePaymentInfo: PropTypes.object.isRequired,
    makePaymentResult: PropTypes.object.isRequired,
    selectedBank: PropTypes.object,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,
    authorizeReconnectFlag: PropTypes.bool,
    isSolar: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("singlePayment")(withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePaymentSubmittedPageContainer)))