import React from 'react'
import Dialog from '@mui/material/Dialog'
import format from '../../srp_modules/format'
import PropTypes from 'prop-types'

const CashPaymentReconnectDialog = ({dialogOpen, setDialogOpen, disconnectedAccounts, navUrl}) => {    
    return (
        <Dialog
            open={dialogOpen}
            sx={{
                ".MuiPaper-root" : {
                    maxWidth : {
                        xs : '420px',
                        lg : '680px'
                    },
                    padding: '20px'
                }
            }}
        >
            <div className="mx-2 mb-3">
                <h4><b>Safety First!</b></h4>
            </div>
            <div className="mx-2 mb-3">
                Before making a payment, SRP needs to know if it is safe to reconnect your power. <br /><br/>

                Please be aware of the risk of fire if any appliances or other electric devices, such as an electric stove, are turned on
                at the time power is delivered. Please ensure all of the appliances and electric devices at the premises are turned off.
            </div>
            <div className="mx-2 mb-4 srp-alert-notice-DCO">
                <div className="mb-2 font-weight-bold">Power will remain disconnected until a phone call is made to SRP to confirm it is safe to resume service for the following accounts:</div>
                <ul>
                    {disconnectedAccounts.map((account, i)=>{
                        let accountNumber = format.srpAccountNumber(account.account.toString())
                        let accountNumberFormatted = format.formatBillAccountNumber(accountNumber)
                        if(account.accountName.length !== 0){
                            return(<li key={i}>{account.accountName} ({accountNumberFormatted})</li>)
                        }else{
                            return(<li key={i}>{accountNumberFormatted}</li>)
                        }
                    })}
                </ul>
            </div>
            <div className="d-flex justify-content-end mx-2 mb-2">
                <button 
                    className="mx-2 btn srp-btn btn-lightblue"
                    onClick={()=>{
                        setDialogOpen(false)
                        sessionStorage.setItem("SafetyDialogOpened", true)
                }}>
                    Dismiss
                </button>
                <button
                    className="btn srp-btn btn-blue"
                    onClick={()=>{
                        setDialogOpen(false)
                        sessionStorage.setItem("SafetyDialogOpened", true)
                        if(navUrl !== "/myaccount/payment/multi/cash"){
                            window.location.replace("/myaccount/payment/multi")
                        }
                    }}>
                        Proceed
                </button>
            </div>
        </Dialog>
    )
}

CashPaymentReconnectDialog.propTypes = {
    dialogOpen:PropTypes.bool.isRequired,
    setDialogOpen:PropTypes.func.isRequired,
    disconnectedAccounts:PropTypes.arrayOf(PropTypes.object),
    navUrl:PropTypes.string.isRequired
}

export default CashPaymentReconnectDialog
