import React from "react"
import PropTypes from "prop-types"
import Card from "@mui/material/Card"
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import SingleAccountReconnectDialog from '../../reconnect_notice/single-account-cash-recconnect-modal'
import { useState } from "react"
import {paymentConstants} from '../../../constants/payment-constants'
import config from 'my-account-config'


const HowToCashPaymentCard = ({isMpower, selectedBillAccountDetails, isSolar, sendReconnectRemark, t}) => {
    const [reconnectDialogOpen, setReconnectDialogOpen] = useState(false)
    const [navUrl, setNavUrl] = useState('')
    const isUserDisconnected = 
        selectedBillAccountDetails.isCommercial === false 
        && selectedBillAccountDetails.billingStatusCode === paymentConstants.DISCONNECTED
    let appPath = config.appPath === "/" ? "" : config.appPath

    return(
        <div>
            {
                !sessionStorage.getItem("SafetyDialogOpened") && reconnectDialogOpen && isUserDisconnected ?
                <SingleAccountReconnectDialog 
                    setReconnectDialogOpen={setReconnectDialogOpen} 
                    isSolar={isSolar} 
                    navUrl={navUrl}
                    selectedBillAccountDetails={selectedBillAccountDetails} 
                    sendReconnectRemark={sendReconnectRemark} />
                : null
            }
            <label className="h5">{t("My cash payment")}</label>
            <Card className="p-4">
                {!sessionStorage.getItem("SafetyDialogOpened") && isUserDisconnected ?
                <div className="d-flex flex-wrap justify-content-start mb-2">
                    <button 
                        className="btn srp-btn btn-blue mr-1 mb-2" 
                        onClick={()=>{
                            setReconnectDialogOpen(true)
                            setNavUrl(appPath + '/myaccount/cashpaymentcard')
                        }}
                    >
                        {t("View my cash payment card")}
                    </button>
                    <button 
                        className="btn srp-btn btn-blue mr-1 mb-2" 
                        onClick={()=>{
                            setReconnectDialogOpen(true)
                            setNavUrl(appPath + '/myaccount/cashpaymentcard?print=true')
                        }}
                    >
                        {t("Print my cash payment card")}
                    </button>
                </div>:
                <div className="d-flex flex-wrap justify-content-start mb-2">
                    <Link to="/myaccount/cashpaymentcard" target="_blank" className="btn srp-btn btn-blue mr-1 mb-2">{t("View my cash payment card")}</Link>
                    <Link to="/myaccount/cashpaymentcard?print=true" target="_blank" className="btn srp-btn btn-lightblue mb-2">{t("Print my cash payment card")}</Link>
                </div>
                }
                <h4 className="mb-3 text-muted">{t("How to use your cash payment card")}</h4>
                <div className="row m-0 fw-700">
                    <div className="flex-column">
                        <div className="ccp-circle border rounded-circle pt-2 pb-2">1</div>
                    </div>
                    <div className="col-9 srp-navy-color align-self-center">{t("Find a location")}</div>
                </div>
                <div className="row m-0 mb-3">
                    <div className="col-width-42" />
                    <div className="col-9 text-muted">
                        {`${t("Use our")} `} <Link to="/myaccount/paymentlocations">{t("In-person Payment Locator")}</Link>
                        {` ${t("to find a participating In-person payment location")}`}</div>
                </div>
                <div className="row m-0 fw-700">
                    <div className="flex-column">
                        <div className="ccp-circle border rounded-circle pt-2 pb-2">2</div>
                    </div>
                    <div className="col-9 srp-navy-color align-self-center">{t("Share your barcode or account details")}</div>
                </div>
                <div className="row m-0 mb-3">
                    <div className="col-width-42" />
                    <div className="col-9 text-muted">{t("present_barcode_or_house")}</div>
                </div>
                <div className="row m-0 fw-700">
                    <div className="flex-column">
                        <div className="ccp-circle border rounded-circle pt-2 pb-2">3</div>
                    </div>
                    <div className="col-9 srp-navy-color align-self-center">{t("Make your cash payment")}</div>
                </div>
                <div className="row m-0">
                    <div className="col-width-42" />
                    <div className="col-9 text-muted">
                        {isMpower
                            ? t("Purchases_made_with_barcode")
                            : t("Payments_made_with_barcode")
                        }
                    </div>
                </div>
            </Card>
        </div>
    )
}

HowToCashPaymentCard.propTypes = {
    t: PropTypes.func.isRequired,
    isMpower: PropTypes.bool
}

export default withTranslation('cashPaymentCard')(HowToCashPaymentCard)