import React from "react"
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"

import config from 'my-account-config'

const OtherPaymentOptions = ({t, i18n}) => {
    const isSpanish = (i18n.language === 'es')
    const morePaymentMethodsUrl = isSpanish ? 
        config.srpnetBaseUrlEs + "cuenta/electricidad/manejar-factura/pago-factura-electrica" : 
        config.srpnetBaseUrl + "account/electric/manage-bill/pay-electric-bill"

    return (
        <div>
            <label className="h5">{t("Other payment options")}</label>
            <Card className="px-1 py-4">
                <ul className="mb-0">
                    <li>
                        <a href={morePaymentMethodsUrl}>{t("More payment methods")}</a>
                    </li>
                </ul>
            </Card>
        </div>
    )
}

OtherPaymentOptions.propTypes = {
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
}

export default OtherPaymentOptions